import { useGeolocationStore } from '@/stores/geolocation.store';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import { storeToRefs } from 'pinia';
import { LocationType } from '../data/location/location.type';

const normalize = (value?: number) => {
  if (!value || value === Infinity) {
    return 0;
  } else {
    return value;
  }
};
export default class GeolocationHelper {
  static getMyLocationOption = (): GamDropdownItem => {
    const { userLocation } = storeToRefs(useGeolocationStore());

    return {
      id: 'current',
      value: LocationType.MY_LOCATION,
      label: 'app.component.search.location.current',
      icon: GamIconName.TARGET,
      isFullSize: true,
      coordinates: {
        lat: normalize(userLocation.value?.location.lat),
        lng: normalize(userLocation.value?.location.lng),
      },
    };
  };
}
