import { type AuthParams, type AuthUserDto } from '@/core/data/auth/auth.interface';
import { LoginMethodType } from '@/core/data/auth/auth.type';
import type { CodeResponse } from '@/core/data/auth/google/oauth2';
import { RouterAuthType, RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import type { Result } from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import useGoogle from '@/views/composables/auth/social/useGoogle';
import useLichess from '@/views/composables/auth/social/useLichess';
import type { AuthModuleData, AuthModuleState, AuthScreenMethod } from '@/views/composables/auth/useAuth.interface';
import { GamButtonMessageVariant } from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import { onBeforeMount, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const useAuth = (moduleData: AuthModuleData) => {
  const state = reactive<AuthModuleState>({
    activeMethod: null,
    authMethodGroups: null,
    accessToken: '',
    showAuthMethods: false,
  });

  const { initGoogle, googleSignIn } = useGoogle();
  const { lichessSignIn } = useLichess();
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();

  onBeforeMount(async () => {
    setAuthMethods();
    await checkCodeParams();
  });

  const checkCodeParams = async (): Promise<void> => {
    const authParams: AuthParams = route.query;

    if (authParams?.code) {
      authStore.setConnecting(gambits.authService.getConnecting());
      await getToken(authParams.code);
    }
  };

  const getToken = async (code: string): Promise<void> => {
    if (state.activeMethod) state.activeMethod.isLoading = true;
    const result = await gambits.authService.getToken(code);
    if (state.activeMethod) state.activeMethod.isLoading = false;
    await finalizeSignIn(result);
  };

  const setAuthMethods = (): void => {
    state.authMethodGroups = gambits.authService.getAuthMethods();
    state.authMethodGroups?.forEach((methodGroup) => {
      methodGroup.items?.forEach((signUpMethod: AuthScreenMethod) => {
        if (signUpMethod.type === LoginMethodType.Lichess) {
          state.activeMethod = signUpMethod;
        }
        initSocialMethods(signUpMethod);
      });
    });
    state.showAuthMethods = true;
  };

  const initSocialMethods = (loginMethod: AuthScreenMethod) => {
    switch (loginMethod.type) {
      case LoginMethodType.Google:
        if (loginMethod.clientId) {
          initGoogle((loaded) => {
            loginMethod.active = loaded;
          });
        }
        break;
      default:
        break;
    }
  };

  const signInWith = async (method: AuthScreenMethod): Promise<void> => {
    state.activeMethod = method;
    switch (method.type) {
      case LoginMethodType.Email: {
        await router.push({ name: RouterAuthType.AUTH_EMAIL });
        break;
      }
      case LoginMethodType.Google: {
        googleSignIn(method, googleLogin);
        break;
      }
      case LoginMethodType.Lichess: {
        await lichessSignIn(moduleData.serverUrl, method);
        break;
      }
      default:
        break;
    }
  };

  const googleLogin = async (response: CodeResponse): Promise<void> => {
    if (response.error) {
      setActiveMethodError({
        label: response.error,
        variant: GamButtonMessageVariant.ERROR,
      });
    } else if (response.code) {
      setActiveMethodError(null);
      authStore.setConnecting(LoginMethodType.Google);
      const result = await gambits.authService.authWithGoogle(response);
      await finalizeSignIn(result);
    }
  };

  const finalizeSignIn = async (result: Result<AuthUserDto>): Promise<void> => {
    if (result.data) {
      await authStore.authorizeUser(result.data, true);
      if (result.data.isActive) {
        await router.push({ name: RouterNameType.LOCATIONS });
      } else {
        await router.push({ name: RouterAuthType.AUTH_REGISTRATION });
      }
    } else if (result.error) {
      setActiveMethodError({
        label: result.error.message,
        variant: GamButtonMessageVariant.ERROR,
      });
    }
    gambits.authService.removeConnecting();
    authStore.setConnecting(null);
  };

  const setActiveMethodError = (errorMessage: GamButtonMessageType | null): void => {
    if (state.activeMethod) {
      state.activeMethod.errorMessage = errorMessage;
    }
  };

  return {
    ...toRefs(state),
    signInWith,
  };
};

export { useAuth };
