<template>
  <div
    v-touch:tap="setFocusedElement"
    :class="['gam-code']"
    @mouseenter="isOutOfBox = false"
    @mouseleave="isOutOfBox = true"
    @click="setFocusedElement"
  >
    <div :class="['gam-code-container', getContainerClasses]">
      <div :class="['input-content', getContentClasses]">
        <div
          v-for="index in length"
          :key="index"
          class="input-wrapper"
          :class="['input-wrapper', { empty: !inputValue[index - 1]?.length }]"
        >
          <input
            ref="inputs"
            v-bind="$attrs"
            class="input"
            type="number"
            :autofocus="autofocus && index === 1"
            :value="inputValue[index - 1]"
            :readonly="readOnly"
            :disabled="isDisabled"
            :maxlength="1"
            @paste.prevent="handlePaste($event.clipboardData?.getData('Text') || '', index - 1)"
            @keydown.backspace="handleBackspace(index - 1)"
            @keydown="keyCatcher"
            @input.prevent.stop="(e) => handleChange((e.target as HTMLInputElement).value, index - 1)"
            @focus="setFocus"
            @blur="setBlur"
            @change="updateCode"
          />
        </div>
      </div>
    </div>
    <transition name="dropdown" mode="out-in">
      <gam-button-message v-if="message" v-bind="message" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamCodeType } from '@/views/composables/models/components/GamCode';
import { computed, reactive, ref } from 'vue';

const props = withDefaults(defineProps<GamCodeType>(), {
  length: 6,
  autofocus: true,
  inputFormatter: (t: string) => {
    return t.replace(/[^a-z0-9A-Z]/g, '').toUpperCase();
  },
});

const inputValue = reactive(new Array(props?.length).fill(''));
const inputs = ref<Record<number, HTMLInputElement>>({});
const isActive = ref<boolean>(false);
const isOutOfBox = ref<boolean>(true);
const numberTest: RegExp = new RegExp('^[0-9]*$');

const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);

const keyCatcher = (e: KeyboardEvent): void => {
  if (numberTest.test(e.key) || e.key === 'Control' || e.key === 'v' || e.key === 'Backspace') {
    return;
  }
  e.preventDefault();
};

const setFocus = (): void => {
  isActive.value = true;
};

const setBlur = (): void => {
  if (isOutOfBox.value) {
    isActive.value = false;
  }
};

const handleChange = (value: string, index: number): void => {
  if (inputValue?.join('')?.length + 1 !== props?.length) {
    const inputRef = inputs.value[index];
    setValue(value, index);
    if (inputRef) inputRef.value = inputValue[index];
    inputs.value[Math.min(index + 1, props?.length - 1)]?.focus();
  } else {
    setValue(value, index);
    inputs.value[props?.length - 1]?.blur();
    isActive.value = false;
  }
};

const setValue = (value: string, index: number): void => {
  const formattedValue = props.inputFormatter(value);
  if (formattedValue === '') {
    inputValue[index] = formattedValue;
    const inputRef = inputs.value[index];
    if (inputRef) {
      inputs.value = inputValue[index];
    }
    return;
  }

  inputValue[index] = formattedValue?.length === 1 ? formattedValue : formattedValue.replace(inputValue[index], '')[0];
};

const handlePaste = (value: string, index: number) => {
  const valueArr = props.inputFormatter(value).split('');
  if (valueArr?.length === 0 || !numberTest.test(value)) return;

  for (let i = 0; i < valueArr?.length; i++) {
    if (inputValue[index + i] === undefined) break;
    inputValue[index + i] = valueArr[i];
  }
  inputs.value[Math.min(index + value?.length, props?.length - 1)]?.focus();
  updateCode();
};

const handleBackspace = (index: number): void => {
  if (inputValue[index] !== '') {
    inputValue[index] = '';
    updateCode();
    return;
  }

  if (index === 0) return;
  inputs.value[index - 1]?.focus();
};

const setFocusedElement = (event?: Event): void => {
  event?.stopPropagation();
  let selectedIndex: number = 0;
  for (let i = 0; i < props?.length; i++) {
    if (inputs.value[i].value) {
      selectedIndex = i + 1;
    }
  }

  if (selectedIndex < props?.length) {
    inputs.value[selectedIndex]?.focus();
  } else {
    inputs.value[props?.length - 1]?.focus();
  }
};

const updateCode = () => {
  emit(GamComponentsEmits.UPDATE_VALUE, inputValue.join(''));
};

const getContainerClasses = computed(() => ({
  'is-active': isActive.value,
  'is-disabled': props.isDisabled,
  'is-error': props.isError,
  'read-only': props.readOnly,
}));

const getContentClasses = computed(() => ({
  active: isActive.value || props.readOnly,
}));
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  .gam-code-container {
    width: inherit;
    display: flex;
    align-items: center;
    border-radius: var(--radius-large);
    height: auto;
    cursor: pointer;
    border: 1px solid var(--color-white-5);
    transition: border var(--transition);
    position: relative;
    gap: 16px;
    @extend .gam-special-border !optional;

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0;
      inset: -1px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .input-content {
      width: 100%;
      padding: var(--spacing-md) var(--spacing-xl);
      position: relative;
      transition: inherit;
      display: flex;
      align-items: center;
      gap: var(--spacing-xl);
      justify-content: center;

      .input-wrapper {
        width: fit-content;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;

        .input {
          position: relative;
          align-self: center;
          font-size: 28px;
          font-weight: 700;
          color: var(--color-white-100);
          caret-color: var(--color-white-70);
          transition: all 0.2s;
          text-align: left;
          max-width: 18px;
          pointer-events: none;
        }

        &:focus-within {
          &:before {
            left: 4px !important;
            width: 12px !important;
            font-size: 28px;
          }
        }

        &:before {
          content: '';
          background: var(--color-white-70);
          width: 12px;
          height: 3px;
          position: absolute;
          bottom: 6px;
          opacity: 0;
          left: 4px;
          transition:
            left 100ms var(--cubic-bezier) 0s,
            width 100ms var(--cubic-bezier) 0s;
        }

        &.empty {
          &:before {
            opacity: 1 !important;
            width: 14px;
            left: 2px;
          }
        }
      }
    }

    &.is-active {
      &:before {
        opacity: 1;
      }
    }

    &.is-disabled {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.3;

      &:before {
        content: none;
      }
    }

    &.is-error {
      border-color: var(--color-red) !important;

      &:before {
        content: none;
      }
    }

    &.read-only {
      cursor: default;
      border-color: transparent !important;

      .input {
        font-size: 28px;
        font-weight: 700;
      }

      &:before {
        content: none;
      }
    }
  }
}
</style>
