export enum PlayerType {
  UNTITLED = 'untitled',
  TITLED = 'titled',
  COACH = 'coach',
  STREAMER = 'streamer',
  GAMBIT_MASTER = 'gambitmaster',
}

export enum PlayerTitle {
  GM = 'GM',
  FM = 'FM',
  IM = 'IM',
  CM = 'CM',
  WGM = 'WGM',
  WFM = 'WFM',
  WIM = 'WIM',
  WCM = 'WCM',
}

export enum PlayerStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  PLAYING = 'playing',
}
