<template>
  <gam-side-panel v-bind="editLocationsSidepanel">
    <div class="edit-locations" :class="{ 'is-mobile': isMobile }">
      <p>These are your home locations where you usually host gambits.</p>

      <div v-if="clubData && clubData.homeLocations.length > 0" class="current">
        <p>Current locations:</p>

        <div v-for="(location, index) in clubData?.homeLocations" :key="`cur-${index}`" class="location">
          {{ location.name }}

          <GamButton
            label="Remove"
            :is-disabled="isRemoving"
            :size="GamButtonSize.X_SMALL"
            :variant="GamButtonVariant.SECONDARY"
            @click="removeLocation(location.id, String(location.name))"
          />
        </div>
      </div>

      <div v-if="clubData && clubData.homeLocations.length < 3" class="add">
        <form @submit="handleAddLocation">
          <GamInput v-if="false" v-bind="computedAddLocation" v-model="addLocation" />

          <GamSimpleLocationSearch v-bind="getSearchInput" :key="clubData.homeLocations.length" />

          <GamButton v-bind="getAddButton()" @click="handleAddLocation" />
        </form>

        <div v-if="addError" class="form-errors">
          <transition name="dropdown" mode="out-in">
            <GamButtonMessage v-bind="addError" />
          </transition>
        </div>
      </div>

      <div v-if="clubData && clubData.homeLocations.length === 0" class="instructions">
        <p>Add a location on the map to make it assignable to a club:</p>

        <RouterLink
          :to="{
            name: RouterNameType.LOCATIONS,
            query: {
              side_panel: 'add_location',
              return_path: `/club/${clubData.username}`,
              return_key: 'side_panel',
              return_value: 'edit_club_locations',
            },
          }"
        >
          Add location
        </RouterLink>
      </div>

      <div v-if="clubData && clubData.homeLocations.length >= 3" class="add">
        <p>You have the maximum number of locations.</p>
      </div>
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import type { DetailClubDto } from '@/core/data/club/club.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import type { Result } from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import GamButton from '@/views/components/GamButton.vue';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamSimpleLocationSearch from '@/views/components/GamSimpleLocationSearch.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { getClubSidePanel } from '@/views/composables/constants/main/club.constants';
import type { GamButtonMessageType, GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamInputType, GamSimpleInputSearchType } from '@/views/composables/models/components/GamInput';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  id: string;
  club: DetailClubDto;
}
const props = defineProps<Props>();

const formId = FormId.EDIT_GAMBIT;
const listId = GamListId.GAMBIT;

export interface ClubLocationForm {
  locationId?: string;
  name?: string;
}

export interface ClubLocationValidator {
  locationId: {
    required: ValidationRuleWithoutParams<string>;
  };
  name: {
    required: ValidationRuleWithoutParams<string>;
  };
}

const formStore = useFormStore<ClubLocationForm, ClubLocationValidator>(formId)();
const { gamForm } = storeToRefs(formStore);
const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

const getForm = reactive({
  locationId: undefined,
  name: undefined,
});

const getRules = {
  locationId: { required },
  name: { required },
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
});

onBeforeMount(async () => {
  if (!userDetails.value) {
    await authStore.fetchUserInfo();
  }

  if (!route.query.new_club_location) {
    return;
  }

  addLocation.value = String(route.query.new_club_location);

  await handleAddLocation();

  router.replace({ ...route, query: { ...route.query, new_club_location: undefined } });
});

const router = useRouter();
const route = useRoute();

const isLoading = ref<boolean>(false);
const loadError = ref<GamButtonMessageType | null>(null);
const isRemoving = ref<boolean>(false);
const removeError = ref<GamButtonMessageType | null>(null);
const isAdding = ref<boolean>(false);
const addError = ref<GamButtonMessageType | null>(null);

function resetForm() {
  isLoading.value = false;
  loadError.value = null;
  isAdding.value = false;
  addError.value = null;
  isRemoving.value = false;
  removeError.value = null;
  addLocation.value = '';
}

// Club data

const clubData = ref<DetailClubDto | null>(props.club);

const getClub = async () => {
  isLoading.value = true;
  loadError.value = null;

  const result = await gambits.clubService.getClub(props.id);

  isLoading.value = false;

  if (result.error) {
    loadError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }

  if (!result.data) return;

  // @ts-ignore TODO: Harmonize types
  clubData.value = result.data;
};

watch(
  () => [props.id, route.query.side_panel],
  () => {
    if (route.query.side_panel !== 'edit_club_locations') {
      return;
    }

    getClub();
  },
);

const addLocation = ref<string>('');
const computedAddLocation = computed((): GamInputType => {
  return {
    name: 'location',
    value: addLocation.value,
    placeholder: 'Location',
    isRequired: true,
  };
});

const getAddButton = (): GamButtonType => ({
  label: 'Add',
  isDisabled: isAdding.value || addLocation.value.trim().length === 0,
  size: GamButtonSize.LARGE,
  variant: GamButtonVariant.SECONDARY,
});

const handleAddLocation = async (e?: any) => {
  e?.preventDefault?.();

  addError.value = null;
  isAdding.value = true;

  const result = await gambits.clubService.addLocation(props.id, addLocation.value);

  isAdding.value = false;

  if (result?.error) {
    addError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };

    return;
  }

  addLocation.value = '';

  getClub();
};

watch(addLocation, () => {
  addError.value = null;
});

async function removeLocation(locationId: string, locationName: string) {
  if (!window.confirm(`Remove @${locationName} from @${clubData.value?.username} locations?`)) {
    return;
  }

  removeError.value = null;
  isRemoving.value = true;

  const result = await gambits.clubService.removeLocation(props.id, locationId);

  isRemoving.value = false;

  if (result?.error) {
    removeError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };

    return;
  }

  getClub();
}

const handleClearLocation = () => {
  if (!gamForm.value) return;

  gamForm.value.form.locationId = undefined;
  gamForm.value.form.name = undefined;
};

const getSearchInput = computed((): GamSimpleInputSearchType<GamDropdownItem> => {
  return {
    id: GamListId.CLUB_LOCATIONS,
    onClickClear: handleClearLocation,
    input: {
      canClear: false,
      name: GamInputName.LOCATION,
      placeholder: 'locations.add.form.search.choose',
      leftIcon: GamIconName.LOCATION_EMPTY,
      value: gamForm.value?.form.name,
    },
    dropdown: {
      id: listId,
    },
    loadCallback: async (query: string): Promise<Result<GamDropdownItem[]>> => {
      const result = await gambits.locationService.searchLocationsByQuery(query, { internal: true });
      return {
        ...result,
        data: LocationHelper.mapSearchLocations(result.data),
      };
    },
    selectCallback: (locationData: GamDropdownItem | null) => {
      if (!locationData) {
        return;
      }

      if (locationData.source === 'internal') {
        addLocation.value = locationData.value;
      }
    },
  };
});

const editLocationsSidepanel = computed((): GamSidePanelType => {
  return getClubSidePanel({
    panelId: GamSidePanelId.CLUB_FORM,
    isOpened: route.query.side_panel === 'edit_club_locations',
    title: 'Club locations',
    icon: GamIconName.LOCATION_EMPTY,
    errorMessage: loadError.value || removeError.value,
    isLoading: isLoading.value,
    onCancel: closeEditLocations,
  });
});

function closeEditLocations() {
  resetForm();

  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
}
</script>

<style lang="scss" scoped>
.edit-locations {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.is-mobile {
    width: 100% !important;
    border: 0;
  }
}

p {
  color: var(--color-white-70);
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;
}

.current {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 1rem;
}

.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .gam-button {
    width: 6rem;
  }
}

.add {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 1rem;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    gap: 1rem;

    .gam-button {
      width: 6rem;
      flex-shrink: 0;
    }
  }
}

.instructions {
  background: var(--color-dark-600);
  padding: var(--spacing-l);
  border-radius: var(--radius-small);
  margin-top: var(--spacing-l);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-l);

  div {
    line-height: 1.6;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: var(--color-white-100);
    border: 1px solid var(--color-white-30);
    border-radius: var(--radius-large);
    padding: var(--spacing-md) var(--spacing-l);
    font-size: 14px;
    transition: border-color var(--transition);

    &:hover {
      border-color: var(--color-white-70);
    }
  }
}
</style>

<style lang="scss">
.edit-locations .gam-dropdown {
  width: calc(100% + 7rem) !important;
}
</style>
