<template>
  <div
    ref="containerEl"
    :class="['ScrollOverflow', { isHorizontal, isVertical, isMobile, isDesktop: !isMobile }]"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { ref } from 'vue';

interface Props {
  isVertical?: boolean;
  isHorizontal?: boolean;
}

withDefaults(defineProps<Props>(), {});

const containerEl = ref<HTMLDivElement>();
function resetScroll() {
  if (!containerEl.value) return;

  containerEl.value.scrollTop = 0;
}

defineExpose({
  resetScroll,
});
</script>

<style lang="scss" scoped>
.ScrollOverflow {
  overflow-x: hidden;
  overflow-y: hidden;

  &.isHorizontal {
    overflow-x: auto;
  }

  &.isHorizontal::-webkit-scrollbar {
    display: none;
  }

  &.isVertical {
    overflow-y: auto;
  }
}

.ScrollOverflow.isMobile {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.ScrollOverflow.isDesktop {
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  @media (hover: hover) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-dark-500);
    }
  }
}
</style>
