<template>
  <div class="list-header">
    <gam-tab v-bind="getTabItems" @update="onTabItemClick" />
    <div class="player-filtering">
      <gam-input-search v-bind="getSearchInput" />
      <!-- <div class="player-filter">
        <GamButton v-bind="getFilterButton" />
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { TabItemType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import GeolocationHelper from '@/core/helpers/geolocation.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import type { GetPlayerTableFilters } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import GamInputSearch from '@/views/components/GamInputSearch.vue';
import GamTab from '@/views/components/gam-tab/GamTab.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamTabSize } from '@/views/composables/constants/components/gamTab.constants';
import { FilterStoreAction } from '@/views/composables/constants/main/store.constants';
import type { GamInputSearchType } from '@/views/composables/models/components/GamInput';
import type { GamTabType } from '@/views/composables/models/components/GamTab';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount } from 'vue';

const tableStore = useTableStore<BaseUserDto>(GamListId.PLAYERS)();
const filterStore = useFilterStore<GetPlayerTableFilters>(GamListId.PLAYERS)();
const { listTabItems } = storeToRefs(filterStore);

// const router = useRouter();
// const route = useRoute();

onBeforeMount(() => {
  filterStore.setListTabItems(gambits.configService.getPlayerViewTabItems());
});

const onTabItemClick = (name: TabItemType) => {
  filterStore.setActiveTab(name);
};

const getTabItems = computed((): GamTabType => {
  return {
    tabs: listTabItems.value,
    background: true,
    size: GamTabSize.SMALL,
  };
});

const getSearchInput = computed((): GamInputSearchType<BaseUserDto> => {
  return {
    id: GamListId.PLAYERS,
    input: {
      name: GamInputName.SEARCH,
      placeholder: 'players.general.search.placeholder',
      leftIcon: GamIconName.SEARCH,
      canClear: true,
    },
    selectFirstItem: !isMobile.value,
  };
});

// const getFilterButton = computed((): GamButtonType => {
//   return {
//     leftIcon: GamIconName.FILTER,
//     isIconOnly: true,
//     size: GamButtonSize.LARGE,
//     variant: GamButtonVariant.TERTIARY,
//     isIconWhite: true,
//     onClick: async () => {
//       await router.push({
//         ...route,
//         query: {
//           ...route.query,
//           side_panel: isFiltersOpened.value ? undefined : GamSidePanelId.PLAYER_FILTER,
//         },
//       });
//     },
//   };
// });

// const isFiltersOpened = computed((): boolean => {
//   return route.query?.side_panel === GamSidePanelId.PLAYER_FILTER;
// });

filterStore.$onAction(async (action) => {
  switch (action.name) {
    case FilterStoreAction.SET_FILTERS: {
      await nextTick(() => {
        if (!filterStore.getFilters()) clearSelectedLocation();
        tableStore.loadItems({ selectFirstItem: !isMobile.value });
      });
      break;
    }
    case FilterStoreAction.SET_ACTIVE_TAB: {
      await nextTick(() => {
        tableStore.loadItems({ selectFirstItem: !isMobile.value });
      });
      break;
    }
    default:
      break;
  }
});

const clearSelectedLocation = () => {
  const tableStore = useTableStore(GamListId.PLAYERS_FILTER_LOCATION)();
  tableStore.setSelectedItem(GeolocationHelper.getMyLocationOption(), -1);
  gambits.filterService.saveSelectedTableLocation(GamListId.PLAYERS, GamListId.PLAYERS_FILTER_LOCATION);
};
</script>

<style scoped lang="scss">
.list-header {
  gap: var(--spacing-l);
  display: flex;
  flex-direction: column;

  .player-filtering {
    display: flex;
    align-self: stretch;
    gap: var(--spacing-xs);
  }
}
</style>
