<template>
  <div :class="['search-affordance', { side_panel: sidePanel }]" @click="handleClick">
    <div class="search-affordance-input">
      <InlineSvg :src="getIconURL()"></InlineSvg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { useRoute } from 'vue-router';

interface Props {
  isExpanded?: boolean;
  onClick?: (e: MouseEvent) => void;
}
const props = defineProps<Props>();

const route = useRoute();

const sidePanel = computed(() => {
  return route.query.side_panel !== undefined;
});

const handleClick = (e: MouseEvent) => {
  if (!props.onClick) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  props.onClick(e);
};

const getIconURL = () => new URL('../../../ui/icons/search-affordance.svg', import.meta.url).href;
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.search-affordance {
  width: calc(367px - 100px);
  height: 51px;
  z-index: 2;
  cursor: text;

  .search-affordance-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5rem;
    height: 51px;
    width: 3.125rem;
    border: 1px solid var(--color-white-30);
    transition:
      border var(--transition),
      width 256ms ease-out;
    border-radius: 1.5rem;

    @extend .gam-special-border !optional;

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0;
      inset: -1px;
    }

    &:hover,
    &.side_panel {
      width: 367px;
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      transition:
        border var(--transition),
        width 128ms ease-out;

      &:before {
        opacity: 1;
      }

      g {
        fill: var(--color-pink-border-100);
      }
    }
  }

  &:hover {
    .search-affordance-input {
      width: 367px;
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      transition:
        border var(--transition),
        width 128ms ease-out;
    }
  }
}
</style>

<style lang="scss">
svg.icon-search {
  width: 32px;
  height: 32px;
  transform: translate(0, -1px);

  g {
    fill: var(--color-white-100);
    transition: fill linear 256ms;
  }
}
</style>
