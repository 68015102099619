<template>
  <div :class="['gam-image', getClasses, size, format]">
    <div v-touch:tap="openFile" class="image-container" @click="openFile">
      <div v-if="add && image" class="reload">
        <gam-button v-bind="getReloadButton" />
      </div>
      <div
        v-if="image"
        class="image"
        :class="{ skeleton: imgLoading, 'no-filter': noFilter, monochrome: isMonochrome }"
      >
        <img
          loading="lazy"
          :src="gambits.imageService.getImageSrc(image)"
          alt="image"
          @load="loaded"
          @loadstart="loadingImage"
        />
      </div>
      <div v-else-if="add || isMultiAdd" class="add-button">
        <gam-button v-bind="getAddButton" />
        <div class="label">
          <span v-if="isLoading">{{ localize('app.component.image.add.loading') }}</span>
          <span v-else>{{ localize('app.component.image.add.label') }}</span>
        </div>
      </div>
      <div v-else class="missing-icon">
        <gam-icon v-bind="getMissingIcon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gambits, localize } from '@/core/gambits';
import FormHelper from '@/core/helpers/form.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamImageFormat, GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamImageType } from '@/views/composables/models/components/GamImage';
import { useFileDialog } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

const props = withDefaults(defineProps<GamImageType>(), {
  size: GamImageSize.SMALL,
  format: GamImageFormat.VERTICAL,
});

const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE, GamComponentsEmits.EXPAND, GamComponentsEmits.ADD]);
const isLoading = ref<boolean>(false);
const imgLoading = ref<boolean>(!props.add && !props.isMultiAdd);

const { open, onChange, reset } = useFileDialog({
  accept: '.jpg, .jpeg, .png',
});

const getMissingIconSize = (): string => {
  switch (props.size) {
    case GamImageSize.LARGE:
      return '48px';
    case GamImageSize.MEDIUM:
      return '32px';
    default:
      return '18px';
  }
};

const loadingImage = () => {
  imgLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    imgLoading.value = false;
  });
};

const openFile = () => {
  if (props.noExpand || isLoading.value || imgLoading.value) return;
  if ((props.add || props.isMultiAdd) && !props.image) {
    open();
  } else {
    emit(GamComponentsEmits.EXPAND, props.id || props.image);
  }
};

const reloadFile = () => {
  open();
};

const getMissingIcon = computed((): GamIconType => {
  return {
    name: GamIconName.LOCATION_EMPTY,
    size: getMissingIconSize(),
  };
});

const getAddButton = computed((): GamButtonType => {
  return {
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    isIconOnly: true,
    leftIcon: GamIconName.PLUS,
    isLoading: isLoading.value || props.uploading,
    onClick: openFile,
  };
});

const getReloadButton = computed((): GamButtonType => {
  return {
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    isIconOnly: true,
    leftIcon: GamIconName.ARROW_UP,
    onClick: reloadFile,
  };
});

const getClasses = computed(() => {
  return {
    missing: !props.image,
    add: props.add || props.isMultiAdd,
    'no-expand': props.noExpand,
    loading: isLoading.value || imgLoading.value || props.uploading,
    featured: props.featured,
    monochrome: props.isMonochrome,
  };
});

const getImageBlobHash = computed((): string | null | undefined => {
  return props.image;
});

const addSingle = async (file: File): Promise<void> => {
  const tempImage = `data:image/png;base64,${await FormHelper.readFileAsBase64(file)}`;
  emit(GamComponentsEmits.UPDATE_VALUE, tempImage);
};

const addMulti = async (files: FileList): Promise<void> => {
  for (const file of files) {
    if (file) {
      const tempImage = `data:image/png;base64,${await FormHelper.readFileAsBase64(file)}`;
      emit(GamComponentsEmits.ADD, tempImage);
    }
  }
  reset();
};

onChange(async (files: FileList | null) => {
  if (files?.length) {
    isLoading.value = true;
    if (props.add) {
      await addSingle(files[0]);
    } else if (props.isMultiAdd) {
      await addMulti(files);
    }
    isLoading.value = false;
  }
});

watch(getImageBlobHash, (value) => {
  if (!value) {
    reset();
  }
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-image {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  border-radius: var(--radius-x-small) !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  z-index: 1;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-white-100);
    padding: 1px;
    opacity: 0;
    z-index: 100;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.missing {
    background: var(--color-dark-700);
  }

  &.no-expand,
  &.loading {
    pointer-events: none;

    &:before {
      display: none !important;
    }

    .image-container {
      cursor: default;
    }
  }

  .monochrome img {
    filter: grayscale(100%) !important;
  }

  &.small {
    width: 60px;
    height: 75px;
  }

  &.medium {
    width: 100px;
    height: 125px;
  }

  &.large {
    width: 200px;
    height: 250px;
  }

  &.auto {
    width: 100%;
    height: auto;

    .image-container {
      width: auto;
      height: auto;

      .image {
        height: auto;
        width: auto;
      }
    }
  }

  &.dynamic {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 5;

    &.featured {
      aspect-ratio: 5 / 4 !important;
    }

    .image-container {
      width: 100%;
      height: 100%;
    }
  }

  &.square {
    height: auto !important;
  }

  &.add {
    background: var(--color-dark-600);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    width: inherit;
    height: inherit;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    .add-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 17px;
      gap: var(--spacing-l);

      .label {
        color: var(--color-white-70);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }
    }

    .delete-button {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 999;
    }

    .reload {
      position: absolute;
      z-index: 999;
    }

    .image {
      width: inherit;
      height: inherit;

      &.skeleton {
        border-radius: 0 !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
      }

      &.no-filter {
        img {
          filter: none;
        }
      }
    }
  }
}
</style>
