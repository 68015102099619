<template>
  <div class="gam-ratings">
    <gam-rating-item
      v-for="rating in gamRating"
      :key="`rating-${rating.platform}`"
      ref="ratingItem"
      v-bind="rating"
      @change="toggleRating"
    />
  </div>
</template>

<script setup lang="ts">
import type { BaseUserInfo } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useRatingStore } from '@/stores/rating.store';
import GamRatingItem from '@/views/components/gam-ratings/GamRatingItem.vue';
import useLichess from '@/views/composables/auth/social/useLichess';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { GamRatingPlatform, ratingItems } from '@/views/composables/constants/main/ratings.constants';
import type { GamRatingItemType, GamRatingsType } from '@/views/composables/models/components/GamRatings';
import { storeToRefs } from 'pinia';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps<GamRatingsType>();
const route = useRoute();
const ratingStore = useRatingStore();
const { lichessGetRating, parseLichessRatings } = useLichess();
const emits = defineEmits([GamComponentsEmits.CHANGE]);
const ratingItem = ref<(typeof GamRatingItem)[]>();
const authUser = ref<BaseUserInfo>();
const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

onBeforeMount(async () => {
  await setConnected();
  gambits.ratingService.removeConnect();
});

const getAuthUser = async (): Promise<void> => {
  const result = await gambits.userService.getUserInfo();

  if (result.data) {
    authUser.value = result.data;
    setLichessRatings(result.data);
  }
};

const getConnectCallback = (platform: GamRatingPlatform): void => {
  switch (platform) {
    case GamRatingPlatform.LICHESS:
      gambits.ratingService.setConnect(platform);
      lichessGetRating(gambits.configService.getServiceUrl(), props.isAuth);
      break;
    default:
      break;
  }
};

const setConnected = async () => {
  if (gambits.ratingService.getConnect() === GamRatingPlatform.LICHESS) {
    const lichessRatings = parseLichessRatings(route);

    if (lichessRatings) {
      ratingStore.setLichessRatings(lichessRatings);
      if (userDetails.value) setOtherRatings(userDetails.value);
      if (props.isAuth) toggleRating(GamRatingPlatform.LICHESS, true);
    }
  } else if (props.isAuth) {
    await getAuthUser();
  } else if (userDetails.value) {
    setLichessRatings(userDetails.value);
    setOtherRatings(userDetails.value);
  }
};

const setLichessRatings = (user: BaseUserInfo) => {
  ratingStore.setLichessRatings({
    title: user.title || undefined,
    fideRating: user.fideRating || undefined,
    lichessComBlitzRating: user.lichessComBlitzRating || undefined,
    lichessComRapidRating: user.lichessComRapidRating || undefined,
    lichessComBulletRating: user.lichessComBulletRating || undefined,
    lichessComClassicalRating: user.lichessComClassicalRating || undefined,
  });
};

const setOtherRatings = (user: BaseUserInfo) => {
  ratingStore.setByRatingKey('rating', user.rating);
  ratingStore.setByRatingKey('chessComBlitzRating', user.chessComBlitzRating);
  ratingStore.setByRatingKey('chessComRapidRating', user.chessComRapidRating);
  ratingStore.setByRatingKey('chessComBulletRating', user.chessComBulletRating);
  ratingStore.setByRatingKey('chessComClassicalRating', user.chessComClassicalRating);
};

const gamRating = ref<GamRatingItemType[]>(ratingItems(getConnectCallback, props.isAuth));

const toggleRating = (platform: GamRatingPlatform, isOpen?: boolean) => {
  gamRating.value?.forEach((rating) => {
    if (rating.platform === platform) {
      rating.isOpened = isOpen || !rating.isOpened;
      setTimeout(() => {
        ratingItem.value?.find((item) => item.getPlatform() === platform)?.updateChildHeight();
      });
    } else {
      rating.isOpened = false;
    }
  });
  emits(GamComponentsEmits.CHANGE, platform);
};
</script>

<style scoped lang="scss">
.gam-ratings {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--spacing-l);
}
</style>
