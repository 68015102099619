<template>
  <div :class="['gambit-mobile-time', { 'is-mobile': isMobile }]">
    <div class="day-info">
      <div class="date">{{ format(start, 'MMM do') }}</div>
      <div :class="['day day-desktop', `type-${startDateType}`]">
        {{ getWeekdayOnDesktop }}
      </div>
      <div :class="['day day-mobile', `type-${startDateType}`]">
        {{ getWeekdayOnMobile }}
      </div>
    </div>

    <div class="time">{{ format(start, 'HH:mm') }}</div>

    <template v-if="end">
      <div class="divider">—</div>

      <div v-if="format(start, 'ccc') !== format(end, 'ccc')" class="day-info">
        <div class="date">{{ format(end, 'MMM do') }}</div>
        <div class="day day-desktop">{{ format(end, 'cccc') }}</div>
        <div class="day day-mobile">{{ format(end, 'ccc') }}</div>
      </div>

      <div class="end">
        <div class="time">{{ format(end, 'HH:mm') }}</div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import GambitHelper from '@/core/helpers/gambit.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { GamDateType } from '@/views/composables/constants/components/gamGambit.constants';
import { format } from 'date-fns';
import { computed } from 'vue';

interface GambitTimeProps {
  start: string;
  end?: string;
}

const props = defineProps<GambitTimeProps>();

const startDateType = computed(() => {
  const result = GambitHelper.getGambitsDateType(props.start, !!props.end, props.end);

  return result;
});

const getWeekdayOnDesktop = computed(() => {
  // TODO: Examine situations where startDateType !== GamDateType.DAY
  const formattedDay = startDateType.value === GamDateType.DAY ? format(props.start, 'cccc') : startDateType;

  return formattedDay;
});

const getWeekdayOnMobile = computed(() => {
  const dayFormat = props.end && format(props.start, 'ccc') !== format(props.end, 'ccc') ? 'ccc' : 'cccc';

  // TODO: Examine situations where startDateType !== GamDateType.DAY
  const formattedDay = startDateType.value === GamDateType.DAY ? format(props.start, dayFormat) : startDateType;

  return formattedDay;
});
</script>

<style lang="scss" scoped>
.gambit-mobile-time {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-items: stretch;
  justify-content: flex-start;
  gap: 2rem;

  &.is-mobile {
    padding-left: calc(var(--spacing-s) + var(--spacing-s));
    padding-right: calc(var(--spacing-s) + var(--spacing-s));
  }

  &:not(.is-mobile) {
    padding-left: calc(var(--spacing-md) + var(--spacing-l));
    padding-right: calc(var(--spacing-md) + var(--spacing-l));
  }

  .start,
  .end {
    display: flex;
    flex-direction: row-reverse;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .day-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    flex-grow: 1;

    color: var(--color-white-100);
  }

  .day {
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--color-white-100);
  }

  .day.type-now,
  .day.type-soon {
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
  }

  .day.type-tomorrow {
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .date {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .time {
    font-size: 36px;
    font-weight: 100;
    color: var(--color-white-70);
  }

  .divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-white-70);
  }
}

.gambit-mobile-time {
  .day-mobile {
    display: none;
  }

  .day-desktop {
    display: flex;
  }
}

.gambit-mobile-time.is-mobile {
  gap: 1rem;

  .day-info {
    width: auto;
  }

  .day-desktop {
    display: none;
  }

  .day-mobile {
    display: flex;
  }
}
</style>
