<template>
  <div :class="['gam-tab-item', { active: isActive, disabled: isDisabled }]" @click="setTabItem">
    <div class="gam-tab-item-container">
      <span class="tab-name">{{ localize(displayName) }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { localize } from '@/core/gambits';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamTabItemType } from '@/views/composables/models/components/GamTab';

const props = defineProps<GamTabItemType>();

const emit = defineEmits([GamComponentsEmits.UPDATE]);

const setTabItem = () => {
  if (props.isDisabled) return;
  emit(GamComponentsEmits.UPDATE, props.name);
};
</script>
<style scoped lang="scss">
.gam-tab-item {
  display: flex;
  padding: var(--spacing-xs) var(--spacing-s);
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--radius-normal);
  cursor: pointer;
  transition:
    background-color var(--transition),
    color var(--transition);

  .gam-tab-item-container {
    width: max-content;
    height: max-content;
    display: flex;

    .tab-name {
      font-size: 14px;
      font-weight: 700;
      color: var(--color-white-70);
    }
  }

  &.disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }

  &.active {
    background-color: var(--color-white-100);

    .gam-tab-item-container {
      .tab-name {
        color: var(--color-dark-600);
      }
    }
  }
}
</style>
