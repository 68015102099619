<template>
  <div :class="['gam-counter']">
    <div :class="['gam-counter-container', getContainerClasses]">
      <div v-if="getIcon" class="icon">
        <gam-icon v-bind="getIcon" />
      </div>
      <div v-if="label" class="label">
        {{ localize(label) }}
      </div>
      <div class="input-count">
        <gam-input v-bind="getInput" v-model="inputCounter" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import GamIcon from '@/views/components/GamIcon.vue';
import GamInput from '@/views/components/GamInput.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamCounterType } from '@/views/composables/models/components/GamCounter';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import { computed, onMounted, ref, watch } from 'vue';

const props = withDefaults(defineProps<GamCounterType>(), {
  value: 0,
});
const emits = defineEmits([GamComponentsEmits.UPDATE_VALUE]);

const inputCounter = ref<number | string>(props.value);

onMounted(() => {
  emits(GamComponentsEmits.UPDATE_VALUE, inputCounter.value);
});

const getContainerClasses = computed(() => ({
  'is-disabled': props.isDisabled,
  'is-error': props.isError,
  'read-only': props.readOnly,
}));

const getInput = computed((): GamInputType => {
  return {
    name: GamInputName.COUNTER,
    leftIcon: GamIconName.MINUS,
    rightIcon: GamIconName.PLUS,
    type: GamInputValueType.NUMBER,
    value: inputCounter.value || '0',
    isError: props.isError,
    isCentered: true,
    readOnly: props.readOnly,
    counter: {
      min: props.input?.min || 0,
      max: props.input?.max || Number.MAX_SAFE_INTEGER,
      step: props.input?.step || 1,
    },
  };
});

const getIconValues = (name: GamIconName): GamIconType => {
  return {
    name,
    isPrimary: true,
  };
};

const getIcon = computed((): GamIconType | undefined => {
  if (props.icon) {
    return getIconValues(props.icon);
  }
  return undefined;
});

watch(inputCounter, (value) => {
  emits(GamComponentsEmits.UPDATE_VALUE, value);
});
</script>

<style lang="scss" scoped>
.gam-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .gam-counter-container {
    width: inherit;
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: var(--radius-large);
    height: auto;
    position: relative;
    transition: opacity var(--transition-fast);
    gap: 16px;

    .label {
      width: inherit;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      color: var(--color-white-100);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }

    .icon {
      display: block;
      align-items: center;
      justify-content: center;
    }

    .input-count {
      min-width: 120px;
    }

    &.is-disabled {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
</style>
