<template>
  <div disabled-v-on-click-outside="setBlur" :class="['gam-input']">
    <div :class="['gam-input-container', getContainerClasses]">
      <div v-if="getLeftIcon" class="icon icon-left">
        <gam-icon
          v-bind="getLeftIcon"
          @mousedown="mousedownEvent(-1)"
          @mouseup="mouseupEvent"
          @mouseleave="mouseupEvent"
        />
      </div>
      <div :class="['input-content', getContentClasses]">
        <div v-if="placeholder" class="placeholder">
          {{ localize(placeholder) }}
        </div>
        <div v-if="placeholder" class="label">
          {{ localize(placeholder) }}
        </div>
        <!-- <div class="input-area"> -->
        <div v-if="flagIcon" class="input-flag">
          <gam-icon :name="flagIcon" :is-flag="true" />
        </div>
        <input
          ref="input"
          v-bind="$attrs"
          v-model="inputValue"
          :name="name"
          class="input"
          :type="props.type === GamInputValueType.NUMBER && isSafari ? 'text' : props.type"
          :readonly="readOnly"
          :maxlength="maxLength"
          :minlength="minLength"
          :placeholder="counter && value ? value?.toString() : undefined"
          :style="inputWidth"
          :disabled="isDisabled"
          :required="isRequired"
          :autocomplete="autocomplete"
          :pattern="props.isLowerCase ? '[a-z]+' : undefined"
          @focus="setFocus"
          @blur="setBlur"
          @keydown="validateInput"
          @change="updateInput"
        />
        <div v-if="suffix" class="input-suffix">
          {{ suffix }}
        </div>
        <!-- </div> -->
      </div>
      <div v-if="getRightIcon" class="icon icon-right">
        <gam-icon
          v-bind="getRightIcon"
          @mousedown="mousedownEvent(1)"
          @mouseup="mouseupEvent"
          @mouseleave="mouseupEvent"
        />
      </div>
      <div v-if="isLoading" class="icon icon-right spin">
        <gam-icon class="load-icon" :size="GamIconSize.SMALL" :name="GamIconName.LOADING" :is-primary="true" />
      </div>
      <div v-if="inputValue && canClear" class="icon icon-close icon-right">
        <gam-icon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" @click="clearInput" />
      </div>
      <div v-else-if="props.isSearch && props.onClickClear" class="icon icon-close icon-right">
        <gam-icon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" @click="() => handleClear()" />
      </div>
    </div>
    <transition name="dropdown" mode="out-in">
      <gam-button-message v-if="message" class="error-message" v-bind="message" @click="onClickMessage" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { isSafari } from '@/core/helpers/ui.helper';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import { Key } from '@/views/composables/constants/gam.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import { computed, onMounted, ref, watch, type StyleValue } from 'vue';

const props = withDefaults(defineProps<GamInputType>(), {
  type: GamInputValueType.TEXT,
  autocomplete: 'off',
});

const emit = defineEmits([
  GamComponentsEmits.UPDATE_VALUE,
  GamComponentsEmits.UPDATE,
  GamComponentsEmits.CLEAR,
  GamComponentsEmits.CLOSE,
]);

const lockInput = ref<boolean>(false);

const setInputValue = (): string | number | null | undefined => {
  let value: string | number | undefined = 0;
  if (!props.counter) {
    if (props.type === GamInputValueType.NUMBER) {
      value = props.value || undefined;
    }
  }
  return value || props.value;
};

const input = ref<HTMLInputElement>();
const inputValue = ref<string | number | null | undefined>(setInputValue());
const isActive = ref<boolean>(false);
const operation = ref<number>(0);
const incrementInterval = ref<NodeJS.Timeout>();

const getIconValues = (name: GamIconName): GamIconType => {
  return {
    name,
    isPrimary: props.isPrimary,
  };
};

// const onClick = (event: MouseEvent): void => {
//   event.stopPropagation();
//   if (!props.isDisabled && !props.isLoading) {
//     input.value?.focus();
//     setFocus();
//   }
// };

const setFocus = (): void => {
  props.onFocus?.();
  setTimeout(() => {
    if (props.readOnly) return;
    isActive.value = true;
  });
};

const setBlur = (): void => {
  if (props.isDropdownOpened) return;
  isActive.value = false;
  inputValue.value = setInputValue();
  emit(GamComponentsEmits.CLOSE);
};

const mouseupEvent = () => {
  if (props.counter) {
    clearInterval(incrementInterval?.value);
  }
};

const mousedownEvent = (opr: number) => {
  if (props.counter) {
    operation.value = opr;
    setCounterValue();
    incrementInterval.value = setInterval(setCounterValue, 250);
  }
};

const setCounterValue = (): void => {
  isActive.value = true;
  const step: number = props.counter?.step || 1;
  const currentValue: number =
    inputValue.value || inputValue.value === 0
      ? parseInt(inputValue.value as string, 10)
      : parseInt(props.value as string, 10) || 0;
  const allowSum: boolean = operation.value > 0 && step + currentValue <= Number.MAX_SAFE_INTEGER;
  const allowSub: boolean = operation.value < 0 && currentValue - step >= (props.counter?.min || 0);

  if (allowSum || allowSub) {
    emit(GamComponentsEmits.UPDATE_VALUE, currentValue + operation.value * step);
  }
};

const checkSelectAll = (event: KeyboardEvent) => {
  return (event.ctrlKey || event.metaKey) && event.key === 'a';
};

const validateInput = (event: KeyboardEvent) => {
  // eslint-disable-next-line no-useless-escape
  if (props.isUsername && event.key.replace(/[^a-z0-9_\.]/g, '').length === 0) {
    event.stopPropagation();
    event.preventDefault();
    return;
  }

  if (checkSelectAll(event)) return;

  if (!lockInput.value || event.key === Key.BACKSPACE) {
    lockInput.value = false;

    if (!isNumber()) return;

    if (!isFinite(parseInt(event.key, 10))) {
      if (event.key?.length === 1) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        return;
      }
    }
  } else {
    event.stopPropagation();
    event.preventDefault();
  }
};

const isNumber = () => {
  return props.type === GamInputValueType.NUMBER;
};

const updateInput = (event: Event) => {
  const target = event?.target as HTMLInputElement;
  if (!target.value) return;

  let newValue = target.value;

  if (isNumber()) {
    newValue = String(parseInt(target.value.toString(), 10));
  }

  emit(GamComponentsEmits.UPDATE_VALUE, newValue);
};

const clearInput = () => {
  props.onClickClear && props.onClickClear(inputValue);
  emit(GamComponentsEmits.UPDATE, '');
  emit(GamComponentsEmits.UPDATE_VALUE, '');
  emit(GamComponentsEmits.CLEAR);
};

const handleClear = () => {
  clearInput();
  props.onClickClear?.(inputValue);
};

const isInputActive = (): boolean => {
  const valueEntered = inputValue.value?.toString();
  return isActive.value || !!valueEntered?.length;
};

const getContainerClasses = computed(() => ({
  'is-active': isActive.value,
  'is-disabled': props.isDisabled || props.isLoading,
  'is-centered': props.isCentered,
  'is-error': props.isError,
  'read-only': props.readOnly,
  'has-left-icon': !!props.leftIcon && !props.rightIcon && !props.canClear,
  'has-right-icon': (!!props.rightIcon || !!props.canClear) && !props.leftIcon,
  'has-both-icons': !!props.leftIcon && (!!props.rightIcon || !!props.canClear),
  counter: props.counter,
}));

const getContentClasses = computed(() => ({
  active: isInputActive(),
}));

const getLeftIcon = computed((): GamIconType | undefined => {
  if (props.leftIcon && !(props.counter && props.readOnly)) {
    return getIconValues(props.leftIcon);
  }
  return undefined;
});

const getRightIcon = computed((): GamIconType | undefined => {
  if (props.rightIcon && !(props.counter && props.readOnly)) {
    return getIconValues(props.rightIcon);
  }
  return undefined;
});

const inputWidth = computed((): StyleValue => {
  const value = inputValue.value?.toString();
  return {
    width: props.suffix ? `${(value?.length || 0) * 9}px` : 'inherit',
  };
});

watch(inputValue, (value, oldValue) => {
  if (props.readOnly) return;
  lockInput.value = !!(value?.toString().length && props.isEmoji);

  let newValue = value;

  if (props.isLowerCase) {
    newValue = String(newValue).toLocaleLowerCase();
  }

  if (isNumber() && !props.counter) {
    const maxValue = props.max ? parseInt(props.max.toString(), 10) : Math.max();
    const previewValue = parseInt(newValue?.toString() || '', 10);

    if (previewValue > maxValue) {
      inputValue.value = oldValue;
      newValue = oldValue;
    }
  }
  newValue = newValue && isNumber() ? parseInt(newValue.toString(), 10) : newValue;

  emit(GamComponentsEmits.UPDATE, newValue);
  emit(GamComponentsEmits.UPDATE_VALUE, newValue);
});

watch(
  () => props.value,
  () => {
    if (props.value || props.value === 0) {
      if (props.isEmoji) return;
      inputValue.value = setInputValue();
    } else {
      inputValue.value = props.value;
    }
  },
);

watch(
  () => props.isLoading,
  () => {
    lockInput.value = props.isLoading;
  },
);

defineExpose({
  isActive,
});

onMounted(() => {
  props.onMounted && input.value && props.onMounted(input.value);
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  height: 50px;

  .error-message {
    position: absolute;
    top: 52px;
  }

  .gam-input-container {
    width: inherit;
    // display: flex;
    // align-items: center;
    // padding: var(--spacing-md) calc(var(--spacing-l) + var(--spacing-s));
    // gap: 12px;
    border-radius: var(--radius-large);
    height: 47px !important;
    cursor: text;
    border: 1px solid var(--color-white-5);
    transition: border var(--transition);
    position: relative;
    @extend .gam-special-border !optional;

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0;
      inset: -1px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .input-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 47px;
      pointer-events: none;
    }

    &.has-left-icon,
    &.has-both-icons {
      .input-content {
        left: 18px;
      }
    }

    &.has-right-icon,
    &.has-both-icons {
      .input-content {
        right: 18px;
      }
    }

    .input-content {
      // width: 100%;
      transition: inherit;
      // display: grid;
      // align-items: center;
      // padding: var(--spacing-md) calc(var(--spacing-l) + var(--spacing-s));

      &.active {
        .label {
          opacity: 1;
        }

        .input-area {
          .input-suffix {
            display: block;
          }
        }

        .placeholder {
          opacity: 0;
          // margin-bottom: 3px;
        }
      }

      .label {
        // position: relative;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.36px;
        text-transform: uppercase;
        color: var(--color-white-30);
        transition:
          opacity var(--transition-fast),
          top var(--transition-fast);
        transition-delay: 128ms;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        top: 8px;
        left: 22px;
        pointer-events: none;
      }

      .placeholder {
        color: var(--color-white-70);
        transition:
          opacity var(--transition-fast),
          margin-bottom var(--transition-fast);
        transition-delay: 128ms;
        position: absolute;
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        left: calc(var(--spacing-l) + var(--spacing-s));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        pointer-events: none;
      }

      // .input-area {
      //   width: inherit;
      //   display: flex;
      //   align-items: center;
      //   gap: 4px;

      .input {
        font-size: 16px;
        font-weight: 300;
        color: var(--color-white-100);
        padding: 20px calc(var(--spacing-l) + var(--spacing-s)) 8px;
        position: absolute;
        align-self: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        pointer-events: all;
      }

      .input-suffix {
        display: none;
        align-self: center;
        min-width: max-content;
      }

      .input-flag {
        width: max-content;
        height: 16px;
      }
      // }
    }

    .icon {
      display: block;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;

      x &.icon-close {
        cursor: pointer;
      }
    }

    .icon-left {
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span,
      svg {
        width: 32px;
        height: 32px;
      }
    }

    .icon-right {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
      cursor: pointer;
    }

    &.is-active {
      &:before {
        opacity: 1;
      }

      .input-content {
        .input {
          &::placeholder {
            display: none;
            color: transparent;
          }
        }
      }
    }

    &.is-disabled {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.3;

      &:before {
        content: none;
      }
    }

    &.is-error {
      border-color: var(--color-red) !important;

      &:before {
        content: none;
      }
    }

    &.counter {
      padding: var(--spacing-l);

      .input-area {
        justify-content: center;
      }

      .input {
        text-align: center;
        padding-top: 5px;

        &::placeholder {
          color: var(--color-white-30);
        }
      }

      .icon {
        cursor: pointer;
      }

      &.is-active {
        .input {
          color: var(--color-white-100);
        }
      }
    }

    &.read-only {
      cursor: default;

      .label,
      .placeholder {
        transition: none !important;
      }

      .input {
        color: var(--color-white-100);
        transition: none !important;
      }
    }
  }
}
</style>
