import type { BaseClubDto } from '@/core/data/club/club.interface';
import type { GeoLocation } from '@/core/data/location/location.interface';
import type { BaseUserDto, BaseUserInfo, UserDto, ValidateUserDto } from '@/core/data/user/user.interface';
import { UserRepository } from '@/core/data/user/user.repository';
import FormHelper from '@/core/helpers/form.helper';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import type { Coords } from '@/stores/auth.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { NotificationTopics } from '@/views/composables/constants/gam.constants';
import type { ProfileImagesForm, ProfileUserForm } from '@/views/composables/models/form.interface';

export class UserService {
  private readonly repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async getLocation(): Promise<Result<Coords>> {
    try {
      const response = await this.repository.getLocation();

      if (!response?.data?.latitude || !response?.data?.longitude) return { error: generalError() };

      return { data: response.data };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateLocation(location: GeoLocation): Promise<Result<GeoLocation>> {
    try {
      const response = await this.repository.updateLocation(location);

      if (!response?.data?.lat || !response?.data?.lng) return { error: generalError() };

      return { data: response.data };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getUsers(listId: GamListId, location?: GeoLocation): Promise<Result<BaseUserDto[]>> {
    try {
      const response = await this.repository.getUsers(listId, location);
      return response?.data
        ? {
            data: response.data?.filter?.((u) => u.username && u.rating && typeof u.distance === 'number'),
            cursor: response.cursor,
          }
        : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getUser(id: string): Promise<Result<UserDto>> {
    try {
      const response = await this.repository.getUser(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getUserByName(username: string): Promise<Result<UserDto>> {
    try {
      const response = await this.repository.getUserByName(username);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getUserInfo(refresh?: boolean): Promise<Result<BaseUserInfo>> {
    try {
      const response = await this.repository.getUserInfo(refresh);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getUserClubs(refresh?: boolean): Promise<Result<BaseClubDto[]>> {
    try {
      const response = await this.repository.getUserClubs(refresh);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateUser(profileData: ProfileUserForm): Promise<Result<boolean>> {
    try {
      const response = await this.repository.updateProfile({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        username: profileData.username,
        emoji: profileData.emoji || undefined,
        aboutMe: profileData.aboutMe || undefined,
        birthDate: profileData.date?.toISOString(),
        profileImage: FormHelper.parseImageFile(profileData.profileImage),
        chessboardImage: FormHelper.parseImageFile(profileData.chessboardImage),
      });
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateImages(profileData: ProfileImagesForm): Promise<Result<boolean>> {
    try {
      const response = await this.repository.updateImages({
        profileImage: FormHelper.parseImageFile(profileData.profileImage),
        chessboardImage: FormHelper.parseImageFile(profileData.chessboardImage),
      });
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async validateUsername(username: string): Promise<Result<ValidateUserDto>> {
    try {
      const response = await this.repository.validateUsername(username);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async storeNotificationToken(token: string, topics: NotificationTopics[] = []): Promise<Result<boolean>> {
    try {
      const response = await this.repository.storeNotificationToken(token, topics);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async toggleFollow(id: string, isFollowing?: boolean): Promise<Result<boolean>> {
    try {
      const response = await this.repository.toggleFollow(id, isFollowing);
      return response ? { data: true } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async toggleBlock(id: string, isBlocked?: boolean): Promise<Result<boolean>> {
    try {
      const response = await this.repository.toggleBlock(id, isBlocked);
      return response ? { data: true } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
