<template>
  <div v-if="isPinpointingLocation" class="pinpointing-header">
    <span class="address">{{ localize('locations.add.cancel.message') }}</span>

    <RouterLink :to="{ ...route, query: { ...route.query, side_panel: undefined, mobile_step: undefined } }">
      <GamIcon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" /> />
    </RouterLink>
  </div>

  <div v-if="isPinpointingLocation" class="pinpointing-footer">
    <div class="address">
      {{ gamForm?.form.name }}
    </div>

    <GamButton :size="GamButtonSize.SMALL" :variant="GamButtonVariant.SECONDARY" @click="handleContinueFromPinpoint">
      Continue
    </GamButton>
  </div>

  <gam-side-panel v-bind="getAddLocSidePanel" @clear="clearAddMode">
    <LocationForm
      v-if="locationData"
      ref="locationForm"
      :key="`edit-location-${instanceId}`"
      :form-id="formId"
      :location="locationData"
    />
  </gam-side-panel>

  <GamDialog ref="successDialog" :hide-back="true" :background="DialogBackgrounds.ADD_LOCATION">
    <SimpleContentDialog v-bind="addSuccess()" />
  </GamDialog>
</template>

<script setup lang="ts">
import type { AdminDetailLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { gambits, localize } from '@/core/gambits';
import { useFormStore } from '@/stores/form.store';
import { useMapStore } from '@/stores/map.store';
import { useMarkerStore } from '@/stores/marker.store';
import SimpleContentDialog from '@/views/components/gam-dialog/dialog-contents/SimpleContentDialog.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import { DialogBackgrounds } from '@/views/composables/constants/dialog.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { SimpleContentDialogType } from '@/views/composables/models/dialog.interface';
import type { LocationFormProps, LocationValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { v4 as uuid4 } from 'uuid';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { onBeforeRouteUpdate, RouterLink, useRoute, useRouter } from 'vue-router';
import LocationForm from '../forms/LocationForm.vue';
import { isLocationValid } from '../forms/validateLocation';

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const formId = FormId.EDIT_LOCATION;
const panelId = GamSidePanelId.LOCATION_FORM;

const formStore = useFormStore<LocationFormProps, LocationValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const mapStore = useMapStore();
const mapPinTableStore = useMarkerStore(GamListId.MARKERS)();

const locationData = ref<AdminDetailLocationDto>();

const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);
const locationForm = ref<InstanceType<typeof LocationForm>>();

const successDialog = ref<InstanceType<typeof GamDialog>>();

const instanceId = ref<string>('');

const isPinpointingLocation = computed(
  () => route.query.mobile_step === 'pick_coordinate' && route.query.side_panel === 'edit_location',
);

const handleContinueFromPinpoint = () => {
  router.push({ ...route, query: { ...route.query, mobile_step: undefined } });
};

const isInvalid = ref<boolean>(true);
watch(
  gamForm,
  (gamForm) => {
    isInvalid.value = !isLocationValid(gamForm);
  },
  { deep: true },
);

const addSuccess = (): SimpleContentDialogType => {
  return {
    title: 'dialog.edit.location.title',
    message: 'dialog.edit.location.message',
    action: {
      label: 'dialog.edit.location.button',
      onClick: () => {
        successDialog.value?.close();
      },
    },
  };
};

const clearAddMode = () => {
  mapStore.clearAddMode();
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'location_detail',
      mobile_step: undefined,
    },
  });
};

const closeAddLocation = () => {
  mapStore.clearAddMode();

  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

const router = useRouter();
const route = useRoute();

watch(
  () => [route.query.side_panel, route.query.location],
  ([side_panel, location]) => {
    if (String(side_panel) === 'edit_location' && (typeof location === 'string' || Array.isArray(location)))
      getLocation(String(location));
  },
);

onBeforeMount(() => {
  const { side_panel, location } = route.query;

  if (String(side_panel) === 'edit_location' && (typeof location === 'string' || Array.isArray(location)))
    getLocation(String(location));
});

const getLocation = async (id: string) => {
  const location = await gambits.locationService.getLocation(id);

  if (location.error || !location.data) return;

  // @ts-ignore
  locationData.value = location.data;
};

onBeforeRouteUpdate((to, from) => {
  if (to.query.side_panel === 'edit_location' && from.query.side_panel !== 'edit_location') {
    instanceId.value = uuid4();
    isInvalid.value = true;
  }
});

function getUpdatePayload(form: LocationFormProps): Partial<AdminDetailLocationDto> {
  const payload: Partial<LocationFormProps> = {
    ...form,
  };

  if (!form.locationTypes.includes(LocationType.CHESS_TABLE)) {
    payload.numberOfChessboards = 0;
  }

  if (!form.locationTypes.includes(LocationType.GIANT_CHESS)) {
    payload.numberOfGiantChessboards = 0;
  }

  return {
    id: String(route.query.location),
    ...payload,
  };
}

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.address = undefined;
  form.about = undefined;
  form.chessSet = false;
  form.coordinates = undefined;
  form.locationTypes = [];
  form.name = '';
  form.numberOfChessboards = undefined;
  form.numberOfGiantChessboards = undefined;
  form.photos = [];
  form.pinpoint = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'edit_location' && newPanel.side_panel === 'edit_location') {
    clearForm();
  }
});

const getAddLocSidePanel = computed((): GamSidePanelType => {
  return {
    isOpened: route.query.side_panel === 'edit_location' && route.query.mobile_step !== 'pick_coordinate',
    isComplementary: true,
    isModal: false,
    id: panelId,
    direction: GamCollapseDirection.LEFT,
    option: GamSidePanelOption.EDIT,
    preventOpenState: true,
    onExpand: () => {
      // Respond to location expansion.
    },
    header: {
      title: 'locations.edit.title.label',
      icon: GamIconName.LOCATION_EMPTY,
    },
    footer: {
      isColumn: true,
      action: {
        label: 'locations.edit.form.action.button',
        isDisabled: isInvalid.value || isLoading.value,
        isLoading: isLoading.value,
        message: errorMessage.value,
        onClick: async () => {
          if (!gamForm.value?.form) return;

          isLoading.value = true;

          const payload = getUpdatePayload(gamForm.value.form);

          // @ts-ignore
          const result = await gambits.adminService.updateLocation(payload);

          if (result.data) {
            const { latitude, longitude } = mapPinTableStore.getLocationParam() || {};
            latitude && longitude && mapPinTableStore.addUniqueItems({ lat: latitude, lng: longitude });
            // @ts-ignore
            closeAddLocation();
            clearForm();
            emits(GamComponentsEmits.SUCCESS);
            // TODO: Replace the stale location in the tableStore instead
            // setTimeout(() => window.location.reload(), 666);
          } else if (result.error) {
            errorMessage.value = {
              label: result.error.message,
              variant: GamButtonMessageVariant.ERROR,
            };
          }

          isLoading.value = false;
        },
      },
      clear: {
        label: 'locations.add.form.clear.button',
        isDisabled: isLoading.value,
        onClick: clearAddMode,
      },
    },
  };
});
</script>

<style lang="scss" scoped>
#location-form {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}

.pinpointing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-l) var(--spacing-xl);

  position: absolute;
  z-index: 801;
  top: 0;

  background: linear-gradient(0deg, rgba(#181716, 0) 0%, rgba(#181716, 0.15) 9.5%, rgba(#181716, 0.8) 28%, #181716 58%);

  a {
    padding: var(--spacing-l);
  }
}

.pinpointing-footer {
  position: absolute;
  z-index: 802;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(#181716, 0) 0%,
    rgba(#181716, 0.15) 9.5%,
    rgba(#181716, 0.8) 28%,
    #181716 58%
  );
  transition:
    opacity var(--transition-router),
    bottom var(--transition);
  background-color: transparent;
  border: 0;
  position: fixed;
  min-height: 78px;
  padding: var(--spacing-xl);
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
