<template>
  <div class="tear-off" @click="navigateToGambit">
    <div class="title">{{ props.gambit.title || `Unnamed gambit` }}</div>
    <GamGambitDate v-bind="getGambitDate" />
    <div class="gambit-tags-wrapper">
      <GamGambitTypes :gambit="gambit" :is-list="false" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import router from '@/router';
import { GamGambitDateSize } from '@/views/composables/constants/components/gamGambit.constants';
import type { GambitTearOffType, GamGambitDateType } from '@/views/composables/models/components/GamGambit';
import { computed } from 'vue';
import GamGambitDate from './GamGambitDate.vue';
import GamGambitTypes from './GamGambitTypes.vue';

const props = defineProps<GambitTearOffType>();

const getGambitDate = computed((): GamGambitDateType => {
  return {
    start: props.gambit?.start,
    end: props.gambit?.end,
    size: GamGambitDateSize.MEDIUM,
  };
});

const navigateToGambit = () => {
  if (!props.gambit) return;

  router.push({ name: RouterNameType.GAMBIT, params: { id: props.gambit.id } });
};
</script>

<style lang="scss" scoped>
.tear-off {
  padding: 1rem;
  width: 8.5rem;
  min-height: 220px;

  border-radius: 16px;
  border: 1px solid #ff6da2;
  background: radial-gradient(70.67% 100% at 0% 0%, rgba(255, 109, 162, 0.1) 0%, rgba(255, 97, 205, 0) 100%),
    var(--Gambit-Dark-Dark-600, #242322);

  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  &:hover {
    border-radius: 16px;
    border: 1px solid #ff6da2;
    background: radial-gradient(108.06% 152.91% at 0% 0%, rgba(255, 109, 162, 0.3) 0%, rgba(255, 97, 205, 0) 100%),
      var(--Gambit-Dark-Dark-600, #242322);

    /* DarkMedium_Shadow */
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.4);
  }

  .title {
    flex-grow: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
    word-break: break-word;
    hyphens: auto;
  }

  .gambit-tags-wrapper {
    width: 100%;
    overflow: hidden;
  }
}
</style>
