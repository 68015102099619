<template>
  <div class="gam-scroll-items" :class="getClasses" :style="getStyleVars">
    <ScrollOverflow ref="scrollbar" class="scroll-items-row" is-horizontal>
      <slot />
    </ScrollOverflow>
  </div>
</template>

<script setup lang="ts">
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamScrollItemsType } from '@/views/composables/models/components/GamScrollItems';
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import ScrollOverflow from './ScrollOverflow.vue';

const scrollbar = ref<typeof ScrollOverflow>();
const holdTimeout = ref<NodeJS.Timeout | null>(null);
const isDrag = ref<boolean>(false);
const startX = ref<number>(0);
const scrollX = ref<number>(0);

const props = withDefaults(defineProps<GamScrollItemsType>(), {
  gap: 4,
  spacing: 8,
  defaultBg: 'var(--color-dark-600)',
  selectedBg: 'var(--color-dark-500)',
  hoveredBg: 'var(--color-dark-500)',
});
const emits = defineEmits([GamComponentsEmits.UPDATE]);

onMounted(() => {
  if (props.preventDrag) return;
  setTimeout(() => {
    if (!scrollbar.value?.ps?.element) return;
    scrollbar.value.ps.element.addEventListener('mousedown', slideMouseDown);
  });
});

const slideMouseDown = (event: MouseEvent) => {
  holdTimeout.value = setTimeout(() => {
    if (!scrollbar.value?.ps) return;
    isDrag.value = true;
    startX.value = event.pageX - scrollbar.value.ps.element.offsetLeft;
    scrollX.value = scrollbar.value.ps.element.scrollLeft;
    props.parent?.addEventListener('mouseup', slideMouseLeave, { once: true });
    props.parent?.addEventListener('mousemove', slideMouseMove);
    emits(GamComponentsEmits.UPDATE, true);
  }, 200);
};

const slideMouseEnter = () => {
  isDrag.value = false;
  emits(GamComponentsEmits.UPDATE, false);
};

const slideMouseLeave = (event: MouseEvent) => {
  event.stopPropagation();
  document.removeEventListener('mousemove', slideMouseMove);
  if (holdTimeout.value) clearTimeout(holdTimeout.value);
  setTimeout(() => {
    isDrag.value = false;
    emits(GamComponentsEmits.UPDATE, false);
  }, 200);
};

const slideMouseMove = (event: MouseEvent) => {
  if (!scrollbar.value?.ps || !isDrag.value) return;
  event.preventDefault();
  const x = event.pageX - scrollbar.value.ps.element.offsetLeft;
  const walk = (x - startX.value) * 2;
  scrollbar.value.ps.element.scrollLeft = scrollX.value - walk;
  updateScrollbar();
};

const updateScrollbar = () => {
  nextTick(() => {
    if (scrollbar.value?.ps) {
      scrollbar.value.ps.update();
    }
  });
};

const getClasses = computed(() => {
  return {
    'no-scroll': props.hideRail,
    selected: props.selected,
    hovered: props.hovered,
    drag: !props.preventDrag,
    dragging: isDrag.value,
  };
});

const getStyleVars = computed(() => {
  return {
    '--spacing-x': `${props.spacing}px`,
    '--items-gap': `${props.gap}px`,
    '--default-bg-l': props.defaultBgLeft || props.defaultBg,
    '--default-bg-r': props.defaultBgRight || props.defaultBg,
    '--selected-bg-l': props.selectedBgLeft || props.selectedBg,
    '--selected-bg-r': props.selectedBgRight || props.selectedBg,
    '--hovered-bg-l': props.hoveredBgLeft || props.hoveredBg,
    '--hovered-bg-r': props.hoveredBgRight || props.hoveredBg,
  };
});

onUnmounted(() => {
  if (props.preventDrag) return;
  if (!scrollbar.value?.ps?.element) return;
  scrollbar.value.ps.element.removeEventListener('mousedown', slideMouseDown);
  document.removeEventListener('mousemove', slideMouseMove);
  scrollbar.value.ps.element.removeEventListener('mouseenter', slideMouseEnter);
  scrollbar.value.ps.element.removeEventListener('mouseleave', slideMouseLeave);
  scrollbar.value.ps.element.removeEventListener('mouseup', slideMouseLeave);
});
</script>

<style lang="scss">
.gam-scroll-items {
  width: calc(100% + (var(--spacing-x) * 2));
  position: relative;
  left: calc(var(--spacing-x) * -1);
  overflow: hidden;

  &.drag {
    cursor: grab;
  }

  &.dragging {
    cursor: grabbing;
  }

  &:after,
  &:before {
    content: '';
    height: 100%;
    width: var(--spacing-x);
    position: absolute;
    top: 0;
    z-index: 2;
    pointer-events: none;
  }

  &:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--default-bg-r) 50%, var(--default-bg-r));
    right: 0;
  }

  &:before {
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0, var(--default-bg-l) 50%, var(--default-bg-l));
    left: 0;
  }

  &.selected {
    &:after {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0,
        var(--selected-bg-r) 50%,
        var(--selected-bg-r)
      ) !important;
    }

    &:before {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0,
        var(--selected-bg-l) 50%,
        var(--selected-bg-l)
      ) !important;
    }
  }

  &.hovered {
    &:after {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0,
        var(--hovered-bg-r) 50%,
        var(--hovered-bg-r)
      ) !important;
    }

    &:before {
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 0, var(--hovered-bg-l) 50%, var(--hovered-bg-l)) !important;
    }
  }

  &.no-scroll {
    .scroll-items-row {
      padding: 0 var(--spacing-x);
    }
  }

  .scroll-items-row {
    display: flex;
    align-items: center;
    gap: var(--items-gap);
    align-self: stretch;
    min-width: 0;
    padding: 0 var(--spacing-x) 10px;

    span {
      color: var(--color-white-30);
    }

    .distance {
      font-size: 14px;
      color: var(--color-white-70);
      font-weight: 400;
      white-space: nowrap;

      * {
        color: inherit;
      }
    }
  }
}
</style>
