export type RatingKey = 'rating' | RatingLichessKey | RatingChessKey;

export type RatingLichessKey =
  | 'fideRating'
  | 'lichessComBlitzRating'
  | 'lichessComRapidRating'
  | 'lichessComBulletRating'
  | 'lichessComClassicalRating';

export type RatingChessKey =
  | 'chessComBlitzRating'
  | 'chessComRapidRating'
  | 'chessComBulletRating'
  | 'chessComClassicalRating';

export enum RatingType {
  FIDE = 'fide',
  LICHESS = 'lichess',
  CHESSCOM = 'chesscom',
  GAMBIT = 'gambit',
}

export enum RatingValueType {
  FIDE = 'fide',
  RAPID = 'rapid',
  CLASSICAL = 'classical',
  BLITZ = 'blitz',
  BULLET = 'bullet',
}
