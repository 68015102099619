<template>
  <div class="gam-filter-item-range">
    <gam-slider v-if="range" v-bind="range" ref="rangeSlider" />
  </div>
</template>

<script setup lang="ts">
import GamSlider from '@/views/components/GamSlider.vue';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamFilterType } from '@/views/composables/models/components/GamFilter';
import { whenever } from '@vueuse/core';
import { computed, ref } from 'vue';

defineProps<GamFilterType>();
const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);
const rangeSlider = ref();

const sliderValues = computed(() => {
  return rangeSlider.value?.sliderValues;
});

whenever(sliderValues, (values) => {
  emit(GamComponentsEmits.UPDATE_VALUE, values);
});
</script>

<style scoped lang="scss">
.gam-filter-item-range {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
</style>
