<template>
  <div class="profile-links" :class="{ 'is-mobile': isMobile }">
    <div class="profile-links-wrapper">
      <gam-nav-item-link v-for="link in profileLinks" :key="`plink-${link.position}`" v-bind="getLinkItem(link)" />
    </div>
    <div class="additional-actions">
      <gam-button v-bind="getLogout" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type UIMyProfileLinkItem } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import NavigationHelper from '@/core/helpers/navigation.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamNavItemLink from '@/views/components/gam-nav/GamNavItemLink.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamNavSize, GamNavVariant } from '@/views/composables/constants/components/gamNav.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamNavItemLinkType } from '@/views/composables/models/components/GamNav';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const profileLinks = gambits.configService.getMyProfileLinks();
const router = useRouter();

const getLinkItem = (link: UIMyProfileLinkItem): GamNavItemLinkType => {
  return {
    name: link.name,
    displayName: `links.profile.${link.name}`,
    variant: GamNavVariant.SECONDARY,
    size: GamNavSize.SMALL,
    showArrow: link.showArrow,
    onClick: async () => {
      await NavigationHelper.openLink(router, link);
    },
  };
};

const getLogout = computed((): GamButtonType => {
  return {
    size: GamButtonSize.LARGE,
    variant: GamButtonVariant.TERTIARY,
    label: 'profile.button.logout',
    center: true,
    isFullSize: true,
    onClick: async () => {
      await gambits.authService.logout();
    },
  };
});
</script>

<style scoped lang="scss">
.profile-links {
  display: flex;
  padding: 0 var(--spacing-xxl);
  gap: var(--spacing-md);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  &.is-mobile {
    padding: 0;

    .gam-nav-item-link.secondary.small {
      padding: var(--spacing-xs) 0;
    }
  }

  .profile-links-wrapper {
    align-self: stretch;
  }

  .additional-actions {
    align-self: stretch;
  }
}
</style>
