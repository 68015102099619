<template>
  <div :class="['detail-container', 'player', { 'is-mobile': isMobile }]">
    <GamPlayerDetailHeader v-if="playerDetails" :player="playerDetails" />

    <div class="metrics">
      <div class="metric-group">
        <GamCard
          v-if="playerDetails"
          title="player.details.general.friends.card.followers"
          :value="String(playerDetails.followers)"
        />

        <GamCard v-if="playerDetails" title="Following users" :value="String(playerDetails.following)" />

        <GamCard v-if="playerDetails" title="Following clubs" :value="String(playerDetails.following)" />
      </div>

      <div class="metric-group">
        <GamCard v-if="playerDetails" title="Gambits created" :value="String(playerDetails.gambitsCreated)" />

        <GamCard v-if="playerDetails" title="Gambits joined" :value="String(playerDetails.gambitsAttended)" />

        <GamCard v-if="playerDetails" title="Check ins" :value="String(playerDetails.locationsCheckedIn)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamPlayerDetailHeader from '@/views/components/gam-player/GamPlayerDetailHeader.vue';
import GamCard from '@/views/components/GamCard.vue';
import { onMounted, ref } from 'vue';

interface PlayerDetailsProps {
  id?: string;
  username?: string;
}

const props = defineProps<PlayerDetailsProps>();

const playerDetails = ref<UserDto | null>(null);

const getPlayerDetail = async () => {
  if (!props.id && !props.username) return;

  let result;

  if (props.id) {
    result = await gambits.userService.getUser(props.id);
  } else if (props.username) result = await gambits.userService.getUserByName(props.username);

  if (!result?.data) return;

  playerDetails.value = result.data;
};

onMounted(() => {
  getPlayerDetail();
});
</script>

<style lang="scss" scoped>
.metrics {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  // gap: var(--spacing-md);
  gap: var(--spacing-md);
}

.metric-group {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: var(--spacing-md);
}

.right-side-panel,
.gam-form-content {
  width: 100%;
  height: calc(100% - 5rem);
}
</style>
