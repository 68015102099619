import type { PlayerRatingsDto } from '@/core/data/user/user.interface';
import PlayerHelper from '@/core/helpers/player.helper';
import type { GamChipType } from '@/views/composables/models/components/GamChip';

export default class ProfileHelper {
  static getProfileRatings(ratings?: PlayerRatingsDto[]): GamChipType[] | undefined {
    if (ratings?.length) {
      return ratings
        .map((rating: PlayerRatingsDto) => {
          return {
            label: rating.rating?.toString(),
            leftIcon: rating.type ? PlayerHelper.getPlayerRatingIcon(rating.type) : undefined,
          };
        })
        .filter((chip) => chip.label);
    }
  }
  static getProfileAvailability(availability?: string | null): GamChipType[] | undefined {
    if (availability === null) {
      return [
        {
          label: 'profile.my.availability.status.not',
        },
      ];
    }
  }
}
