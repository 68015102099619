<template>
  <div :class="['main-view clubs-view', { 'is-mobile': isMobile }]">
    <div class="list-wrapper">
      <GamSimpleListView
        id="clubs"
        :load-more="clubStore.fetchMore"
        :show-more="afterCursor"
        :is-empty="filteredItemList.length === 0"
        :no-data-info="noDataInfo"
      >
        <template #header>
          <ClubListHeader />
        </template>

        <template #list>
          <GamClubCard
            v-for="club in filteredItemList"
            v-bind="getClubCard(club)"
            :id="club.id"
            :key="club.id"
            @click="navigateToClub(club.username)"
          />
        </template>
      </GamSimpleListView>
    </div>

    <template v-if="authStore.isAuthenticated()">
      <AddClub />

      <EditClub v-if="clubDetails" :key="`edit-club-${clubDetails.id}`" :club="clubDetails" />

      <EditLocations
        v-if="clubDetails"
        :id="clubDetails.id"
        :key="`locations-club-${clubDetails.id}`"
        :club="clubDetails"
      />

      <EditCurators
        v-if="clubDetails"
        :id="clubDetails.id"
        :key="`curate-club-${clubDetails.id}`"
        :club="clubDetails"
      />
    </template>

    <SimpleDetailView v-bind="getDetailView">
      <template #header>
        <DetailHeader v-if="clubDetails" v-bind="getDetailHeader"></DetailHeader>
      </template>

      <ScrollOverflow v-if="clubDetails" class="detail-scroll" is-vertical>
        <div class="detail-container club">
          <GamClubDetail v-bind="getDetailProps" :key="clubDetails?.id"></GamClubDetail>
        </div>
      </ScrollOverflow>

      <PlaceholderSplash
        v-else-if="!isLoading && !route.params.username"
        button-label="Create a Club"
        :button-variant="GamButtonVariant.SECONDARY"
        :button-size="GamButtonSize.SMALL"
        :handle-create-click="handleCreateClick"
      >
        {{ randomPun }}
      </PlaceholderSplash>
    </SimpleDetailView>
  </div>
</template>

<script lang="ts" setup>
import type { BaseClubDto, DetailClubDto } from '@/core/data/club/club.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import type { GetTableParams } from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useClubStore } from '@/stores/club.store';
import { useFilterStore } from '@/stores/filter.store';
import { useNavigationStore } from '@/stores/navigation.store';
import DetailHeader from '@/views/components/detail-view/DetailHeader.vue';
import SimpleDetailView from '@/views/components/detail-view/SimpleDetailView.vue';
import GamClubCard from '@/views/components/gam-clubs/GamClubCard.vue';
import GamClubDetail from '@/views/components/gam-clubs/GamClubDetail.vue';
import GamSimpleListView from '@/views/components/gam-list-view/GamSimpleListView.vue';
import PlaceholderSplash from '@/views/components/PlaceholderSplash.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamClubCardCategory, GamClubCardSize } from '@/views/composables/constants/components/gamClub.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamClubCardType, GamClubDetailViewType } from '@/views/composables/models/components/GamClub';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ClubListHeader from './ClubListHeader.vue';
import AddClub from './side-panels/AddClub.vue';
import EditClub from './side-panels/EditClub.vue';
import EditCurators from './side-panels/EditCurators.vue';
import EditLocations from './side-panels/EditLocations.vue';

const PUNS = ['Playing over-the-board can lead to chilling with friends.', 'Is there even a better place to open up?'];

const randomPun = computed(() => PUNS[Math.floor(Math.random() * 2)]);

const noDataInfo = {
  id: GamListId.CLUBS,
  title: 'This list is empty',
  message: 'Clubs you follow or organize will appear here for quick access.',
  size: GamErrorInfoSize.SMALL,
  actionPrimary: {
    variant: GamButtonVariant.SLAB,
    size: GamButtonSize.SMALL,
    label: 'Follow some clubs',
    onClick: () => {
      router.push({
        name: RouterNameType.CLUBS,
        query: { side_panel: 'add_club' },
      });
    },
  },
};

const listId = GamListId.CLUBS;
const navigationStore = useNavigationStore();
const { isSwipeAware } = storeToRefs(navigationStore);
const filterStore = useFilterStore<GetTableParams>(GamListId.CLUBS)();
const { listTabItems } = storeToRefs(filterStore);

const clubStore = useClubStore();
const { clubs, afterCursor, isLoading } = storeToRefs(clubStore);

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

function handleCreateClick(e: MouseEvent) {
  e.preventDefault();

  router.push({ name: RouterNameType.LOCATIONS, query: { side_panel: 'add_gambit' } });
}

const filteredItemList = computed(() => {
  if (!Array.isArray(clubs.value)) {
    return [];
  }

  const activeTab = listTabItems.value?.find((t) => t.isActive);
  if (activeTab?.name === 'all' || activeTab?.name !== 'mine') {
    return clubs.value;
  }

  if (!user.value) {
    return clubs.value;
  }

  return clubs.value.filter((club) => {
    const isOwner = user.value?.id === club.ownerId;
    const isCurator = club.curators?.findIndex((c) => c.id === user.value?.id) > -1;

    const isFollower =
      [...(club.followers || [])].findIndex((c: { userId: string }) => c.userId === user.value?.id) > -1;

    return isOwner || isCurator || isFollower;
  });
});

const currentClubIndex = computed(() => {
  const username = route.params.username;

  if (!username || !Array.isArray(filteredItemList.value) || filteredItemList.value.length < 1) {
    // console.warn('Can not find club', username, filteredItemList.value);
    return -1;
  }

  const clubIndex = filteredItemList.value.findIndex((c) => c.username === username);

  if (clubIndex === -1) {
    // console.warn('Couldnt find club index', username, filteredItemList.value);
  }

  return clubIndex;
});

const navigateToClub = (username: string) => {
  router.replace({
    name: isMobile.value ? RouterNameType.CLUB : RouterNameType.CLUBS,
    query: { ...route.query, side_panel: undefined },
    params: { username },
  });
};

const clubDetails = ref<DetailClubDto | null>(null);

const getClubDetail = async (id: string): Promise<DetailClubDto | null> => {
  const clubsResult = await gambits.clubService.getClub(id);

  if (clubsResult?.data) {
    return clubsResult.data;
  }

  return null;
};

const getClubCard = (club: BaseClubDto): GamClubCardType => {
  return {
    club,
    selected: route.params.username === club.username,
    category: GamClubCardCategory.LIST,
  };
};

const getDetailHeader = computed(() => ({
  isSwipeAware: isSwipeAware.value,
  previousButton: {
    isDisabled: currentClubIndex.value <= 0,
    onClick: () => {
      router.push({
        name: RouterNameType.CLUBS,
        params: { username: filteredItemList.value[currentClubIndex.value - 1].username },
      });
    },
  },
  nextButton: {
    isDisabled: currentClubIndex.value + 1 === filteredItemList.value.length,
    onClick: () => {
      router.push({
        name: RouterNameType.CLUBS,
        params: { username: filteredItemList.value[currentClubIndex.value + 1].username },
      });
    },
  },
}));

const getDetailView = computed((): GamDetailType => {
  return { listId };
});

const getDetailProps = computed((): GamClubDetailViewType => {
  const { username } = route.params;
  const details = clubDetails.value;

  return {
    club: username && details?.username === username ? details : null,
    size: GamClubCardSize.LARGE,
    category: GamClubCardCategory.DETAIL,
  };
});

onMounted(async () => {
  clubStore.setCursor(null, null);
  await clubStore.fetchAll();
});

watch(
  () => ({ username: route.params.username, clubs: clubs.value }),
  async ({ username, clubs }) => {
    if (!username && clubs.length === 0) return;

    if (!username) {
      username = clubs[0].username;
      router.replace({ name: RouterNameType.CLUBS, params: { username } });
    }

    const club = clubs.find((c) => c.username === username);

    if (!club) return;

    clubDetails.value = await getClubDetail(club.id);
  },
);
</script>

<style scoped lang="scss">
.main-view {
  .list-wrapper {
    @media (max-width: 1180px) {
      width: 428px;
    }
  }
  .detail-scroll {
    width: 100%;
    height: 100svh;

    .detail-container {
      max-width: 596px;
      margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto var(--spacing-xxl);

      padding: 0 var(--spacing-xl) var(--spacing-xxl);

      @media (max-width: 1180px) {
        max-width: 512px;
        padding: 0 var(--spacing-l) var(--spacing-xxl);
      }
    }
  }
}

.frame-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 12px;
}
</style>
