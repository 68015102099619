<template>
  <div ref="playerCard" :class="['gam-player-card', getPlayerCardClasses, size]">
    <div v-if="!isNormal" class="player-image-wrapper">
      <gam-player-image v-bind="getPlayerImage" :class="[{ skeleton: !player }]" @expand="expandImage" />
    </div>
    <div class="player-content">
      <div class="data">
        <div class="data-player">
          <div :class="['username-wrapper', { skeleton: !player }]">
            <span class="username">{{ player?.username || 'Username of player' }}</span>
            <gam-icon v-if="player?.featured" class="featured" :name="GamIconSpecialName.BADGE_MATCH" size="14px" />
          </div>

          <div v-if="isLarge || isSmall" :class="['name-wrapper ', { skeleton: !player }]">
            <span v-if="player?.emoji" class="emoji">
              {{ player.emoji }}
            </span>

            <span v-else-if="player?.country" class="country">
              <gam-icon :name="player.country" :is-flag="true" size="14px" />
            </span>

            <div class="full-name">
              <span v-if="player">{{ `${player.firstName} ${player.lastName}` }}</span>
              <span v-else>Firstname and Lastname</span>
            </div>
          </div>
        </div>

        <div v-if="!isSmall && !isLarge" :class="['role ', { skeleton: !player }]">
          <div v-if="!player">
            <span>Player roles</span>
          </div>
          <div v-if="getPlayerTypes?.length || player?.title" class="role-wrapper">
            <span v-if="player?.title" class="titled">
              {{ localize(PlayerHelper.getPlayerTitleLocale(player.title, showFullName)) }}
            </span>
            <span v-if="getPlayerTypes">
              <span v-for="type in getPlayerTypes" :key="type" :class="type">
                {{ localize(PlayerHelper.getPlayerTypeLocale(type)) }}
              </span>
            </span>
          </div>
        </div>

        <div v-if="!isSmaller" class="info">
          <div :class="['location', { skeleton: !player }]">
            <span v-if="!player">Player status</span>
            <span v-else>
              {{ localizeWithValues('player.card.distance.label', [player.distance?.toString() || '0']) }}
            </span>
          </div>
          <div :class="['status', { skeleton: !player }, player?.status]">
            <span v-if="!player">Player status</span>
            <span v-else>
              {{ localize(PlayerHelper.getPlayerStatusLocale(player.status)) }}
            </span>
          </div>
        </div>
      </div>

      <div :class="['ratings-wrapper', { skeleton: !player }]">
        <div v-if="player?.ratings?.length" class="ratings">
          <gam-chip v-for="(rating, index) in getRatingList" v-bind="getRatingChip(rating)" :key="index" />
        </div>
        <span v-else-if="!isXSmall">
          <gam-chip v-bind="getRatingChip()" />
        </span>
      </div>
    </div>

    <gam-expand-image v-if="selectedImage" ref="imageExpand" v-bind="selectedImage" @close="clearSelected" />
  </div>
</template>

<script lang="ts" setup>
import { RatingType } from '@/core/data/rating/rating.type';
import { BlobSource } from '@/core/data/report/report.type';
import type { PlayerRatingsDto } from '@/core/data/user/user.interface';
import { PlayerType } from '@/core/data/user/user.type';
import { localize, localizeWithValues } from '@/core/gambits';
import PlayerHelper from '@/core/helpers/player.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import GamChip from '@/views/components/GamChip.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamPlayerImage from '@/views/components/gam-player/GamPlayerImage.vue';
import { GamChipSize } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconSpecialName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamPlayerCardSize, GamPlayerImageSize } from '@/views/composables/constants/components/gamPlayer.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamPlayerCardType, GamPlayerImageType } from '@/views/composables/models/components/GamPlayer';
import { computed, nextTick, ref } from 'vue';

const props = defineProps<GamPlayerCardType>();

const playerCard = ref<HTMLDivElement>();
const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const getRatingChip = (rating?: PlayerRatingsDto): GamChipType => {
  return {
    label: rating?.rating?.toString() || '(i) 1234',
    leftIcon: rating?.type ? PlayerHelper.getPlayerRatingIcon(rating.type) : undefined,
    size: isSmaller.value ? GamChipSize.X_SMALL : GamChipSize.NORMAL,
  };
};

const isTitled = (): boolean => {
  return !!props.player?.title;
};

const getPlayerTypes = computed((): PlayerType[] | undefined => {
  return props.player?.playerTypes?.filter((type) => type !== PlayerType.GAMBIT_MASTER);
});

const getPlayerImage = computed((): GamPlayerImageType => {
  return {
    size: isSmaller.value ? GamPlayerImageSize.XXS_CIRCULAR : GamPlayerImageSize.SMALL,
    imageBlob: props.player?.profileImageBlobHash,
    expand: !isSmaller.value,
  };
});

const getRatingList = computed((): PlayerRatingsDto[] => {
  const ratings = props.player?.ratings?.filter((r) => r.rating);
  if (!ratings) return [];
  switch (props.size) {
    case GamPlayerCardSize.NORMAL:
      return ratings.slice(0, 2);
    case GamPlayerCardSize.LARGE:
      return isMobile.value ? ratings.slice(0, 3) : ratings;
    default:
      return [ratings.find((r) => r.type === RatingType.GAMBIT) || ratings[0]];
  }
});

const isNormal = computed((): boolean => {
  return props.size === GamPlayerCardSize.NORMAL;
});

const isLarge = computed((): boolean => {
  return props.size === GamPlayerCardSize.LARGE;
});

const isSmall = computed((): boolean => {
  return props.size === GamPlayerCardSize.SMALL;
});

const isXSmall = computed((): boolean => {
  return props.size === GamPlayerCardSize.X_SMALL;
});

const getPlayerCardClasses = computed(() => {
  return { titled: isTitled(), selected: props.selected, 'is-mobile': isMobile.value, auto: props.autoSize };
});

const isSmaller = computed((): boolean => {
  return isSmall.value || isXSmall.value;
});

const showFullName = computed(() => {
  const typesLength: number = getPlayerTypes.value?.length || 0;
  return (isLarge.value || isNormal.value) && !typesLength;
});

const getHeight = computed(() => {
  return playerCard.value?.clientHeight;
});

const clearSelected = () => {
  selectedImage.value = null;
};

const expandImage = (blob: string) => {
  // TODO: Need the Image object to be able to send to expand for actions
  selectedImage.value = {
    blobSource: BlobSource.USER_PROFILE,
    selectedIndex: 0,
    images: [
      {
        id: '',
        imageBlobHash: blob,
        liked: false,
      },
    ],
    noFilter: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};

defineExpose({
  getHeight,
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-player-card {
  background-color: var(--color-dark-600);
  position: relative;
  width: 100%;
  height: 88px;
  max-height: 88px;
  padding: var(--spacing-md) var(--spacing-l);
  border-radius: var(--radius-normal);
  align-items: center;
  display: flex;
  flex-shrink: 0;
  transition:
    box-shadow var(--transition),
    background-color var(--transition);
  cursor: pointer;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-white-30);
    padding: 1px;
    opacity: 0;
  }

  &.auto {
    width: max-content !important;
  }

  &.small,
  &.x-small {
    width: 100%;
    background-color: var(--color-white-5);
    padding: var(--spacing-md) var(--spacing-l) var(--spacing-md) var(--spacing-md);
    height: fit-content;
    max-height: none;
    gap: var(--spacing-md);
    align-items: center;

    &:before {
      background: var(--color-white-10);
    }

    .player-image-wrapper {
      display: flex;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
    }

    .player-content {
      gap: var(--spacing-md);

      .data {
        flex-direction: row;
        align-items: center;
        flex-grow: 1;

        .data-player {
          gap: var(--spacing-xs);
          flex-grow: 1;
          flex-direction: row;

          .username-wrapper {
            padding: 0;

            .username {
              font-size: 14px;
              font-weight: 400;
              position: relative;
              height: 15px;

              &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 14px;
                width: 100%;
                height: 1px;
                background: var(--color-white-100);
                transition: var(--transition);
                opacity: 0;
              }
            }
          }

          .name-wrapper {
            .full-name {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }

      .ratings-wrapper {
        flex-shrink: 0;
      }
    }

    &:hover,
    &.selected {
      background-color: var(--color-white-10);
      box-shadow: none;

      .player-content {
        .data {
          .data-player {
            .username-wrapper {
              .username {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &:before {
        opacity: 1;
      }
    }
  }

  &.x-small {
    padding: var(--spacing-xxs) var(--spacing-xs) var(--spacing-xxs) var(--spacing-xxs) !important;
    background: var(--color-dark-50) !important;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    .player-image-wrapper {
      display: flex;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }

  &.large {
    background-color: var(--color-white-5);
    padding: var(--spacing-l);
    cursor: default;
    height: fit-content;
    max-height: none;
    gap: var(--spacing-l);
    align-items: flex-start;

    &.is-mobile {
      .player-content {
        .data {
          .data-player {
            .username-wrapper {
              .username {
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    &.titled {
      background: var(--color-radial-gradient-large);
    }

    .player-image-wrapper {
      width: max-content;
      height: max-content;
      display: flex;
    }

    .player-content {
      flex-direction: column;

      .data {
        gap: var(--spacing-xs);

        .data-player {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-md);

          .username-wrapper {
            .username {
              font-size: 24px;
              font-weight: 800;
            }
          }

          .name-wrapper {
            .full-name {
              font-size: 16px;
            }
          }
        }

        .role {
          font-weight: 300;
        }
      }

      .ratings-wrapper {
        .ratings {
          flex-direction: row;
          justify-content: flex-start;
        }
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.selected {
    background-color: var(--color-dark-500);

    &:before {
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--color-dark-500) !important;
    box-shadow: var(--shadow-card-hover);
  }

  &.titled {
    background: var(--color-radial-gradient);
    background-color: var(--color-dark-600);
  }

  .player-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: var(--spacing-md);
    min-width: 0;

    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-md);
      flex-grow: 1;
      min-width: 0;

      .data-player {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);
        min-width: 0;

        .username-wrapper {
          display: flex;
          gap: var(--spacing-xs);
          padding: 1px 0;

          .username {
            font-weight: 700;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .featured {
            position: relative;
            top: 0;
            display: inline-flex;
          }
        }

        .name-wrapper {
          display: flex;
          gap: var(--spacing-s);
          align-items: center;

          .full-name {
            font-size: 14px;
            font-weight: 700;
            color: var(--color-white-70);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .role {
        text-transform: uppercase;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 400;
        display: none;

        &:has(> .role-wrapper) {
          display: block;
        }

        .role-wrapper {
          display: flex;
          gap: var(--spacing-md);

          .titled {
            color: var(--color-pink);
          }

          .coach {
            color: var(--color-yellow);
          }

          .streamer {
            color: var(--color-white-70);
          }
        }
      }

      .info {
        display: inline-flex;
        color: var(--color-white-70);
        gap: var(--spacing-md);
        font-size: 14px;
        font-weight: 400;

        .status {
          display: flex;

          span {
            display: flex;
            align-items: center;

            &::before {
              content: '';
              width: 8px;
              height: 8px;
              margin-right: var(--spacing-xs);
              border-radius: 4px;
              background-color: var(--color-white-30);
            }

            &.playing {
              &::before {
                background-color: var(--color-pink);
              }
            }

            &.online {
              &::before {
                background-color: var(--color-green);
              }
            }
          }
        }
      }
    }

    .ratings-wrapper {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .ratings {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-xs);
        justify-content: center;
      }
    }
  }
}
</style>
