<template>
  <div class="gam-gambit-date" :class="size">
    <GamGambitDateItem v-bind="getGambitDateItem(false, start, end)" />
  </div>
</template>

<script setup lang="ts">
import GambitHelper from '@/core/helpers/gambit.helper';
import GamGambitDateItem from '@/views/components/gam-gambits/GamGambitDateItem.vue';
import type { GamGambitDateItemType, GamGambitDateType } from '@/views/composables/models/components/GamGambit';

const props = defineProps<GamGambitDateType>();

const getGambitDateItem = (isEnd: boolean, date?: string, end?: string | null): GamGambitDateItemType => {
  return {
    date,
    isEnd,
    size: props.size,
    type: date ? GambitHelper.getGambitsDateType(date, isEnd, end) : undefined,
  };
};
</script>

<style scoped lang="scss">
.gam-gambit-date {
  display: flex;
  align-items: flex-end;

  &.large {
    height: 100%;
    align-items: flex-start;

    .to-date {
      top: 16px;
    }
  }

  .to-date {
    position: relative;
    height: 100%;
    align-self: stretch;
    top: 8px;
  }
}
</style>
