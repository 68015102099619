<template>
  <div ref="detailsView" :class="['detail-wrapper', { 'is-absolute': showAbsolute }]">
    <div class="detail-view">
      <div v-if="showListCursors" class="detail-header">
        <gam-button v-bind="getPreviousButton()" @click="() => changeItem(FlowDirections.PREVIOUS)" />
        <gam-button v-bind="getNextButton()" @click="() => changeItem(FlowDirections.NEXT)" />

        <div v-if="swipeMessage" :class="['help-wrapper', { 'is-swipe-aware': isSwipeAware }]">
          <div class="first-line">
            <div>{{ localize(swipeMessage.firstLine) }}</div>
            <gam-icon :name="GamIconSpecialName.KEY_DOWN" />
            <gam-icon :name="GamIconSpecialName.KEY_UP" />
          </div>
          <div v-if="swipeMessage.secondLine" class="second-line">
            {{ localize(swipeMessage.secondLine) }}
          </div>
        </div>
      </div>

      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { useNavigationStore } from '@/stores/navigation.store';
import { useTableStore } from '@/stores/table.store';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSpecialName } from '@/views/composables/constants/components/gamIcon.constants';
import { FlowDirections, Key, KeyEvent } from '@/views/composables/constants/gam.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import { useDrag, type Vector2 } from '@vueuse/gesture';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useKeypress } from 'vue3-keypress';

const props = withDefaults(defineProps<GamDetailType>(), {
  clearSelection: false,
});

const navigationStore = useNavigationStore();
const { isSwipeAware } = storeToRefs(navigationStore);
const tableStore = useTableStore(props.listId)();
const { detailsOpened, selectedIndex, itemList, tableParams } = storeToRefs(tableStore);

const detailsView = ref<HTMLElement>();
const lockKey = ref<boolean>(false);
const emits = defineEmits([GamComponentsEmits.CLOSE]);

onMounted(async () => {
  if (props.isOpen) showDetails(true);
  if (detailsView.value) detailsView.value?.focus();
});

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      showDetails();
    }
  },
);

const showDetails = (onInit?: boolean) => {
  if (props.isOpen || detailsOpened.value) {
    detailsView.value?.classList.remove('hidden');
    setTimeout(() => {
      detailsView.value?.classList.add('opened');
    });
  } else {
    if (onInit) {
      detailsView.value?.classList.add('hidden');
    } else {
      detailsView.value?.classList.remove('opened');
      detailsView.value?.addEventListener('transitionend', () => detailsView.value?.classList.add('hidden'), {
        once: true,
      });
    }
  }
};

const getNextButton = (): GamButtonType => {
  const itemsLength = itemList.value?.length || 0;
  return {
    ...props.nextButton,
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    leftIcon: GamIconName.SMALL_ARROW_DOWN,
    isDisabled:
      props.nextButton?.isDisabled || selectedIndex.value + 1 >= itemsLength || itemsLength === 1 || lockKey.value,
  };
};
const getPreviousButton = (): GamButtonType => {
  return {
    ...props.previousButton,
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    rightIcon: GamIconName.SMALL_ARROW_UP,
    isDisabled: props.previousButton?.isDisabled || selectedIndex.value === 0,
  };
};

const changeItem = async (direction?: FlowDirections | MouseEvent): Promise<void> => {
  if (!direction) detailsOpened.value = false;
  const listLength = itemList.value?.length || 0;

  if (listLength === 0) return;

  if (direction === FlowDirections.PREVIOUS && selectedIndex.value - 1 >= 0) {
    tableStore.selectItem(selectedIndex.value - 1);
  } else if (itemList.value && direction === FlowDirections.NEXT && selectedIndex.value + 1 < listLength) {
    tableStore.selectItem(selectedIndex.value + 1);
  } else if (selectedIndex.value + 1 === listLength && tableParams.value.afterCursor) {
    lockKey.value = true;
    await tableStore.infiniteLoad();
    tableStore.selectItem(selectedIndex.value + 1);
    lockKey.value = false;
  } else if (props.clearSelection) {
    tableStore.setSelectedItem(null, 0);
  } else {
    emits(GamComponentsEmits.CLOSE);
  }

  navigationStore.setSwipeAware(true);
};

const swipeHandler = (swipe: Vector2) => {
  if (swipe[1] > 0) {
    changeItem(FlowDirections.PREVIOUS);
  } else if (swipe[1] < 0) {
    changeItem(FlowDirections.NEXT);
  } else if (swipe[0] > 0) {
    changeItem();
  }
};

watch(detailsOpened, () => {
  nextTick(() => {
    showDetails();
  });
});

watch(selectedIndex, (index) => {
  const listLength = itemList.value?.length || 0;
  lockKey.value = index + 1 === listLength;
});

useDrag(({ swipe }) => swipeHandler(swipe), {
  domTarget: detailsView,
});

useKeypress({
  keyEvent: KeyEvent.KEY_DOWN,
  keyBinds: [
    {
      keyCode: Key.DOWN,
      success: () => {
        if (!getNextButton().isDisabled) changeItem(FlowDirections.NEXT);
      },
    },
    {
      keyCode: Key.UP,
      success: () => {
        if (!getPreviousButton().isDisabled) changeItem(FlowDirections.PREVIOUS);
      },
    },
  ],
});
</script>

<style lang="scss" scoped>
.help-wrapper {
  opacity: 1;
}

.help-wrapper.is-swipe-aware {
  opacity: 0;
  transition: opacity 2s linear;
}

.scrollable-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-view .detail-wrapper .detail-view {
  max-width: unset;
  padding: unset;
}

.detail-header {
  position: absolute;
  width: 100%;
  padding: var(--spacing-xxl) var(--spacing-xxl) var(--spacing-menu);
  max-width: calc(596px + var(--spacing-xxl) * 2);
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  pointer-events: none;

  .gam-button {
    pointer-events: auto;
  }
}
</style>
