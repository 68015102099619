<template>
  <div
    v-if="(itemList && itemList?.length > 0) || authStore.isAuthenticated()"
    class="location-images-tab"
    :class="{ mobile: isMobile }"
  >
    <h4>Photos</h4>
    <hr />

    <gam-intersect v-bind="getIntersect" @infinite="tableStore.infiniteLoad()">
      <div class="photos-wrapper">
        <div v-if="featuredPhoto" class="featured-photo">
          <gam-image
            v-bind="getImageType(featuredPhoto)"
            :size="GamImageSize.DYNAMIC"
            :featured="true"
            @expand="expandImage"
          />
        </div>

        <div class="all-photos">
          <gam-image
            v-for="image in getPhotoList"
            :key="`loc-img-${image.id}`"
            v-bind="getImageType(image)"
            @expand="expandImage"
          />

          <gam-image
            v-if="authUser"
            :is-multi-add="true"
            :size="GamImageSize.DYNAMIC"
            :uploading="uploadingImage"
            @add="addImage"
          />
        </div>
      </div>
    </gam-intersect>

    <gam-expand-image
      v-if="selectedImage"
      ref="imageExpand"
      v-bind="selectedImage"
      @close="clearSelected"
      @update="toggleLike"
      @delete="showDeleteInfo"
    />

    <gam-notification
      v-if="deletingImage"
      ref="notification"
      v-bind="deleteImageNotification"
      @close="deletingImage = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { ImageDto } from '@/core/data/location/location.interface';
import { BlobSource } from '@/core/data/report/report.type';
import { gambits } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useTableStore } from '@/stores/table.store';
import GamNotification from '@/views/components/gam-notification/GamNotification.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamIntersect from '@/views/components/GamIntersect.vue';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { NotificationPosition, NotificationVariant } from '@/views/composables/constants/notification.constants';
import type { GamExpandImageType, GamImageType } from '@/views/composables/models/components/GamImage';
import type { GamIntersectType } from '@/views/composables/models/components/GamIntersect';
import type { GamLocationImagesType } from '@/views/composables/models/components/GamLocation';
import type { NotificationContentType, NotificationType } from '@/views/composables/models/notification.interface';
import { cloneDeep, isArray } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<GamLocationImagesType>();

const listId = GamListId.LOCATION_IMAGES;

const tableStore = useTableStore<ImageDto>(listId)();
const { tableParams, itemList } = storeToRefs(tableStore);

const featuredPhoto = ref<ImageDto>();
const selectedImage = ref<GamExpandImageType | null>();

const authStore = useAuthStore();
const authUser = authStore.user;

const uploadingImage = ref<boolean>(false);
const deletingImage = ref<boolean>(false);
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();
const notification = ref<InstanceType<typeof GamNotification>>();

const router = useRouter();
const route = useRoute();

onBeforeMount(() => {
  tableStore.setLoadCallback(() => {
    return gambits.locationService.getLocationImages(props.id);
  });
});

watch(
  () => props.id,
  async (newId) => {
    tableStore.setLoadCallback(() => {
      return gambits.locationService.getLocationImages(newId);
    });

    await getListData();
  },
);

onMounted(async () => {
  await getListData();
});

const getListData = async (): Promise<void> => {
  await tableStore.loadItems();
};

const addImage = async (hash: string) => {
  uploadingImage.value = true;
  const photo = await gambits.locationService.addLocationPhoto(props.id, hash);
  if (photo.data) {
    tableStore.addItem(photo.data);
    selectFeaturedPhoto();
  }
  uploadingImage.value = false;
};

const isUserAuthor = (index: number): boolean => {
  const imageHasOwner = typeof itemList.value?.[index]?.user?.id === 'string';
  const isSameOwnerId = authUser?.id === itemList.value?.[index]?.user?.id;
  return imageHasOwner && isSameOwnerId;
};

const expandImage = (id: string) => {
  const imageIndex = itemList.value?.findIndex((img) => img.id === id);
  if (imageIndex?.toString() && itemList.value?.[imageIndex]) {
    const allowDeletion = isUserAuthor(imageIndex);
    selectedImage.value = {
      blobSource: BlobSource.LOCATION,
      selectedIndex: imageIndex,
      images: itemList.value || [],
      closeAfterDelete: true,
      noFilter: true,
      confirmDelete: true,
      canDelete: allowDeletion,
      canReport: !allowDeletion,
    };
    nextTick(() => {
      imageExpand.value?.show();
    });
  }
};

const clearSelected = () => {
  selectedImage.value = null;
};

watch(selectedImage, () => {
  if (!selectedImage.value) {
    router.replace({
      path: route.path,
      query: { ...route.query, photo: undefined },
    });

    return;
  }

  const images = selectedImage.value.images;
  const index = selectedImage.value.selectedIndex;
  const photo = images[index].id;

  router.replace({
    path: route.path,
    query: { ...route.query, photo },
  });
});

const getImageType = (image: ImageDto): GamImageType => {
  return {
    id: image.id,
    image: image.imageBlobHash,
    size: GamImageSize.DYNAMIC,
    noFilter: true,
  };
};

const toggleLike = async (index: number) => {
  const image = itemList.value?.[index];

  if (image) {
    const numOfLikes: number = image?.numberOfLikes || 0;
    tableStore.updateItem({
      id: image.id,
      numberOfLikes: image.liked ? numOfLikes - 1 : numOfLikes + 1,
      liked: !image.liked,
    } as ImageDto);
    await gambits.locationService.toggleImageLike(image.id, image.liked);
  }
};

const getNotificationDelete = (): NotificationContentType => {
  return {
    title: 'locations.detail.image.delete.notification.title',
    message: 'locations.detail.image.delete.notification.message',
    actionLabel: 'locations.detail.image.delete.notification.action',
    afterAction: 'locations.detail.image.deleted.notification.title',
    action: deleteLocationImage,
    variant: NotificationVariant.INFO,
  };
};

const deleteLocationImage = async (): Promise<boolean> => {
  const selectedIndex = selectedImage.value?.selectedIndex.toString();
  const selected = GamHelper.getItem(selectedImage.value?.images, selectedImage.value?.selectedIndex);

  if (selected && selectedIndex) {
    const deleteImage = await gambits.locationService.deleteLocationImage(selected.id);

    if (deleteImage.data) {
      clearSelected();
      tableStore.removeItem(parseInt(selectedIndex, 10));
      selectFeaturedPhoto();
      return true;
    }
  }
  return false;
};

const showDeleteInfo = () => {
  deletingImage.value = true;

  nextTick(() => {
    notification.value?.show();
  });
};

const selectFeaturedPhoto = () => {
  featuredPhoto.value = itemList.value?.[Math.floor(itemList.value?.length * Math.random())];
};

const deleteImageNotification = computed((): NotificationType => {
  return {
    content: getNotificationDelete(),
    position: NotificationPosition.TOP,
  };
});

const getIntersect = computed((): GamIntersectType => {
  return {
    id: listId,
    showMore: !!tableParams.value.afterCursor,
    noData: !itemList.value,
  };
});

const getPhotoList = computed((): ImageDto[] => {
  const items = cloneDeep(itemList.value);

  if (!isArray(items)) return [];

  if (!featuredPhoto.value) return items;

  const featuredIndex = items.findIndex((img) => {
    return img.imageBlobHash === featuredPhoto.value?.imageBlobHash;
  });

  if (featuredIndex > -1) {
    items.splice(featuredIndex, 1);
  }

  return items;
});

watch(itemList, () => {
  selectFeaturedPhoto();
});

onUnmounted(() => {
  tableStore.resetList(true);
});
</script>

<style scoped lang="scss">
.location-images-tab {
  width: 100%;
  max-height: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;

  h4 {
    margin: 0;
  }

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background: var(--color-white-10);
    margin: var(--spacing-l) 0;
  }

  &.mobile {
    .photos-wrapper {
      padding: 0;
    }
  }

  .photos-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    height: 100%;

    .featured-photo {
      width: 100%;
    }

    .all-photos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-md);
      flex-wrap: wrap;
    }
  }
}
</style>
