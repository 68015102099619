import Bowser from 'bowser';
import throttle from 'lodash/throttle';
import { ref } from 'vue';

const queries = {
  sm: '(max-width: 640px)',
  md: '((min-width: 640px) and (max-width: 1024px))',
  air: '((min-width: 1024px) and (max-width: 1181px))',
  lg: '((min-width: 1181px) and (max-width: 1280px))',
  xl: '(min-width: 1280px)',
};

type Breakpoint = 'sm' | 'md' | 'air' | 'lg' | 'xl';

export const isMobile = ref<boolean>(); // sm
export const isTablet = ref<boolean>(); // md
export const isLargeTablet = ref<boolean>(); // air
export const isLaptop = ref<boolean>(); // lg
export const isDesktop = ref<boolean>(); // xl
export const isNavCollapsed = ref<boolean>(); // md, air
export const breakpoint = ref<Breakpoint>();

export const isSafari = ref<boolean>();
export const isTouchDevice = ref<boolean>();

// Responsive media

function determineMedia() {
  isMobile.value = window.matchMedia(queries.sm).matches;
  isTablet.value = window.matchMedia(queries.md).matches;
  isLargeTablet.value = window.matchMedia(queries.air).matches;
  isLaptop.value = window.matchMedia(queries.lg).matches;
  isDesktop.value = window.matchMedia(queries.xl).matches;

  isNavCollapsed.value =
    window.matchMedia(queries.md).matches ||
    window.matchMedia(queries.air).matches ||
    window.matchMedia(queries.lg).matches;

  breakpoint.value = isMobile.value
    ? 'sm'
    : isTablet.value
      ? 'md'
      : isLargeTablet.value
        ? 'air'
        : isLaptop.value
          ? 'lg'
          : 'xl';
}

determineMedia();

window.addEventListener('resize', throttle(determineMedia, 200));

// Touch devices

export function detectTouchDevice() {
  try {
    const clientInfo = Bowser.parse(window.navigator.userAgent);

    const { platform } = clientInfo || {};
    const { type } = platform || {};

    const isTouchDevice = type === 'mobile' || type === 'tablet';

    if (isTouchDevice) {
      const bodyEl = document.querySelector('body');
      bodyEl?.classList.add('touch-device');
    }

    return isTouchDevice;
  } catch (e) {
    throw new Error(`Couldnt detect client browser: ${e}`);
  }

  return false;
}

isTouchDevice.value = detectTouchDevice();

// Safari

export function detectSafari() {
  try {
    const clientInfo = Bowser.parse(window.navigator.userAgent);
    const { browser } = clientInfo || {};
    const { name } = browser || {};

    if (name?.toLowerCase() === 'safari') {
      const bodyEl = document.querySelector('body');
      bodyEl?.classList.add('safari-quirks');

      return true;
    }
  } catch (e) {
    throw new Error(`Couldnt detect client browser: ${e}`);
  }

  return false;
}

isSafari.value = detectSafari();
