import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { UserGeoData } from '@/core/data/location/location.interface';
import { gambits } from '@/core/gambits';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';
import { useRoute, useRouter, type RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import { useAuthStore, type Coords } from './auth.store';

export const FALLBACK_LOCATION = {
  lat: 0,
  lng: 0,
};

export const isMapLocationError = (route: RouteLocationNormalizedLoadedGeneric) => {
  const latQuery = route.query.lat;
  const lngQuery = route.query.lng;
  const isLatLngQueryDefined = latQuery !== undefined && lngQuery !== undefined;
  const currentLat = parseFloat(String(latQuery));
  const currentLng = parseFloat(String(lngQuery));
  const isCurrentLatLngZero = currentLat === 0 && currentLng === 0;

  return route.name === RouterNameType.LOCATIONS && isLatLngQueryDefined && isCurrentLatLngZero;
};

export const useGeolocationStore = defineStore(GamStoreId.GEOLOCATION, () => {
  const router = useRouter();
  const route = useRoute();

  const userLocation = ref<UserGeoData>();
  const userIpLocation = ref<Coords>();
  const isUserLocated = ref<boolean>(window.respondedToIp);
  const needsPreciseLocation = ref<boolean>(false);
  const isLocationPermissionGranted = ref<boolean>(false);
  const isLocationPermissionRequested = ref<boolean>(false);
  const shouldPanToChangedUserLocation = ref<boolean>(false);

  const setUserLocation = (geoData: UserGeoData) => {
    userLocation.value = geoData;
    gambits.geolocationService.saveGeoLocation(geoData);
    useAuthStore().isAuthenticated() && gambits.userService.updateLocation(geoData.location);
  };

  const setUserIpLocation = async () => {
    const result = await gambits.userService.getLocation();

    if (result.error || !result.data) return;
    // TODO: Determine country from client device.

    userIpLocation.value = result.data;

    const lat = result.data.latitude;
    const lng = result.data.longitude;

    setUserLocation({
      accuracy: 1,
      location: { lat, lng },
    });

    isUserLocated.value = true;

    if (!isMapLocationError(route)) return;

    nextTick(() => {
      router.push({ ...route, query: { ...route.query, lat, lng, zoom: 15 } });
      // @ts-ignore
      window.gMap?.map?.setCenter({ lat, lng });
    });
  };

  const setNeedsPreciseLocation = () => {
    needsPreciseLocation.value = true;
  };

  const setLocationPermissionGranted = (value: boolean) => {
    isLocationPermissionGranted.value = value;
  };

  const setLocationPermissionRequested = (value: boolean) => {
    isLocationPermissionRequested.value = value;
  };

  const setShouldPanToChangedUserLocation = (value: boolean) => {
    shouldPanToChangedUserLocation.value = value;
  };

  const setUserLocated = (value: boolean) => {
    isUserLocated.value = value;
  };

  const initLocationPermissionState = async () => {
    const result = await navigator.permissions.query({ name: 'geolocation' });

    if (result.state === 'granted') {
      isLocationPermissionGranted.value = true;
      return;
    }

    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
        if (permissionStatus.state === 'granted') {
          isLocationPermissionGranted.value = true;
        }
      };
    });
  };

  const initUserLocation = async () => {
    const lastLocation = gambits.geolocationService.getUserLocation();

    if (!lastLocation && isLocationPermissionGranted.value === false) {
      await setUserIpLocation();
      return;
    }

    if (!lastLocation && isLocationPermissionGranted.value === true) {
      shouldPanToChangedUserLocation.value = true;
      gambits.geolocationService.startTracking();
      return;
    }

    if (!lastLocation) return;

    userLocation.value = lastLocation;
    isUserLocated.value = true;

    if (route.query.lat && route.query.lng) return;

    router.push({
      ...route,
      query: { ...route.query, lat: userLocation.value.location.lat, lng: userLocation.value.location.lng, zoom: 15 },
    });
  };

  const init = async function () {
    await initLocationPermissionState();
    await initUserLocation();

    router.afterEach((to) => {
      if (isMapLocationError(to)) {
        setUserIpLocation();
      }
    });
  };

  init();

  return {
    userLocation,
    setUserLocation,
    userIpLocation,
    setUserIpLocation,
    needsPreciseLocation,
    setNeedsPreciseLocation,
    isLocationPermissionGranted,
    setLocationPermissionGranted,
    isLocationPermissionRequested,
    setLocationPermissionRequested,
    shouldPanToChangedUserLocation,
    setShouldPanToChangedUserLocation,
    isUserLocated,
    setUserLocated,
  };
});
