import type { UserDto } from '@/core/data/user/user.interface';
import { differenceInYears, parseISO } from 'date-fns';

export const getPlayerAge = (player: any): number | undefined => {
  const { birthDate } = player || {};
  if (!birthDate) return;

  const birthDateParsed = parseISO(birthDate);

  return differenceInYears(new Date(), birthDateParsed);
};

export const getHumanizedAge = (player: UserDto | any): string | undefined => {
  const { birthDate } = player || {};

  if (!birthDate) return;

  const birthDateParsed = parseISO(birthDate);

  const age = differenceInYears(new Date(), birthDateParsed);

  if (age < 20) return 'Late teens';
  if (age < 26) return 'Early 20’s';
  if (age < 30) return 'Late 20’s';
  if (age < 36) return 'Early 30’s';
  if (age < 40) return 'Late 30’s';
  if (age < 46) return 'Early 40’s';
  if (age < 50) return 'Late 40’s';
  if (age < 56) return 'Early 50’s';
  if (age < 60) return 'Late 50’s';
  if (age < 66) return 'Early 60’s';
  if (age < 70) return 'Late 60’s';
  if (age < 76) return 'Early 70’s';
  if (age < 80) return 'Late 70’s';
  if (age < 86) return 'Early 80’s';
  if (age < 90) return 'Late 80’s';
  if (age < 96) return 'Early 90’s';
  if (age < 100) return 'Late 90’s';

  return '100+ years';
};

export enum UnitSystem {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export const getHumanizedDistance = (
  player: UserDto | any,
  // units: UnitSystem = UnitSystem.METRIC,
): string | undefined => {
  const { distance } = player || {};

  if (!distance) return;

  const distanceInKm = distance / 1000;

  if (distanceInKm === 0) return 'Here';
  if (distanceInKm < 2) return 'Nearby';
  if (distanceInKm < 1500) return `${Math.floor(distanceInKm)} km away`;

  return 'Far away';
};
