<template>
  <div disabled-v-on-click-outside="closeDropdown" class="gam-input-search">
    <gam-input
      ref="searchInput"
      v-bind="getSearchInput"
      v-model="tableParams.search"
      :on-click-clear="props.onClickClear"
      @clear="clearInput"
      @close="closeDropdown"
      @click-message="onClickMessage"
    />

    <gam-dropdown v-if="getDropdown && isDropdownOpened" v-bind="getDropdown" @update="updateSelected" />
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import GamDropdown from '@/views/components/GamDropdown.vue';
import GamInput from '@/views/components/GamInput.vue';
import { GamDropdownCategory } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import type { GamDropdownItem, GamDropdownType } from '@/views/composables/models/components/GamDropdown';
import type { GamInputSearchType, GamInputType } from '@/views/composables/models/components/GamInput';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { GamListId } from '../composables/constants/components/gamIntersect.constants';

const props = defineProps<GamInputSearchType<GamDropdownItem>>();

const tableStore = useTableStore<GamDropdownItem>(props.id as GamListId)();
const { selectedItem, tableParams } = storeToRefs(tableStore);

const filterStore = useFilterStore(props.id as GamListId)();

const route = useRoute();

const searchInput = ref();
const loadedOnInit = ref<boolean>(false);
const tempSelectedItem = ref<GamDropdownItem | null>(null);

onBeforeMount(async () => {
  setDefaultItem();
  await setInitQuery();
  if (props.loadCallback) {
    tableStore.setLoadCallback(props.loadCallback);

    if (props.loadOnInit) {
      await initLoad();
    } else {
      tempSelectedItem.value = selectedItem.value;
      loadedOnInit.value = true;
    }
  }
});

const setInitQuery = async () => {
  const { search } = route.query;

  if (search || selectedItem.value) {
    await tableStore.setSearchQuery({
      query: typeof search == 'string' ? search : LocationHelper.getAddress(selectedItem.value),
      preventLoad: true,
    });
  }
};

const initLoad = async () => {
  setInitParams();
  await tableStore.loadItems({ loadOnInit: true, selectFirstItem: props.selectFirstItem });
  loadedOnInit.value = true;
};

const setInitParams = () => {
  if (props.loadOnInit && props.initParams && !isSearchEntered()) {
    filterStore.setFilters(props.initParams);
  } else {
    filterStore.setFilters();
  }
};

const isSearchEntered = (): boolean => {
  const inputValue = tableParams.value.search?.toString() || '';
  return inputValue.length > 0 && !isDefaultValue();
};

const setDefaultItem = (): void => {
  if (props.dropdown?.selected) {
    tableStore.setSelectedItem(props.dropdown.selected, 0);
  } else if (props.dropdown?.defaultItem) {
    tableStore.setSelectedItem(props.dropdown.defaultItem, -1);
  } else if (tempSelectedItem.value) {
    tableStore.setSelectedItem(tempSelectedItem.value, 0);
  }
};

const isDefaultSelected = (): boolean => {
  return !!props.dropdown?.defaultItem && selectedItem.value?.id === props.dropdown?.defaultItem?.id;
};

const isDefaultValue = (): boolean => {
  const isLength: boolean = !!tableParams.value.search && tableParams.value.search.toString().length > 1;
  return isLength && tableParams.value.search === localize(props.dropdown?.defaultItem?.label || '');
};

const closeDropdown = () => {
  if (searchInput.value && props.dropdown) {
    searchInput.value.isActive = false;
  }
};

const clearInput = () => {
  if (props.input.canClear) {
    tableStore.setSearchQuery({ query: '' });
    tableStore.setSelectedItem(null, 0);
    tempSelectedItem.value = null;
  }
};

const isDropdownOpened = computed((): boolean => {
  const isInputActive = searchInput.value?.isActive;
  return !!props.dropdown && ((isInputActive && isSearchEntered()) || (props.loadOnInit && isInputActive));
});

const getSearchInput = computed((): GamInputType => {
  return {
    ...props.input,
    name: GamInputName.SEARCH,
    value: props.input.value || tableParams.value.search,
    isSearch: props.isSearch,
    isDropdownOpened: props.dropdown && isDropdownOpened.value,
    flagIcon: selectedItem.value?.flagIcon,
    onMounted: props.onMounted,
  };
});

const getDropdown = computed((): GamDropdownType | undefined => {
  if (!props.dropdown) return;
  return {
    ...props.dropdown,
    opened: isDropdownOpened.value,
  };
});

const updateSelected = async (selected: boolean) => {
  if (props.dropdown && selected) {
    tempSelectedItem.value = selectedItem.value;
    await tableStore.setSearchQuery({ query: LocationHelper.getAddress(selectedItem.value), preventLoad: true });
    closeDropdown();
  }
};

// TODO: This gets called when blurring a cleared input
// TODO: Check if it affects the iPad / iOS search clearing bug
watch(selectedItem, async (selected: GamDropdownItem | null) => {
  if (props.selectCallback) {
    props.selectCallback(selected);
  }
  await updateSelected(!!selected);
});

watch(isDropdownOpened, async (opened: boolean) => {
  if (props.dropdown) {
    if (opened) {
      await nextTick(async () => {
        if (props.loadOnInit && !loadedOnInit.value) {
          await initLoad();
        } else {
          tableStore.setLoaded();
          loadedOnInit.value = true;
        }
      });
    } else if (loadedOnInit.value) {
      if (selectedItem.value) {
        if (isDefaultSelected()) {
          const query = localize(props.dropdown?.defaultItem?.label || '');
          await tableStore.setSearchQuery({ query, preventLoad: true });
        } else if (props.dropdown?.category === GamDropdownCategory.DEFAULT) {
          await tableStore.setSearchQuery({ query: '', preventLoad: true });
        }
      }
    }
  }
});

watch(
  () => tableParams.value.search,
  debounce(async (query) => {
    if (isSearchEntered() && loadedOnInit.value) {
      props.onQueryChanged && props.onQueryChanged(query);

      setInitParams();
      await tableStore.loadItems({ loadOnInit: true, selectFirstItem: LocationHelper.isValidDDFormat(query) });
    } else if (!isSearchEntered()) {
      props.onQueryChanged && props.onQueryChanged(undefined);
      tableStore.resetList(true);
    }
  }, 400),
);

watch(
  () => searchInput.value?.isActive,
  (value) => {
    if (!value) {
      setDefaultItem();
    }
  },
);

onUnmounted(async () => {
  tempSelectedItem.value = null;
});
</script>

<style scoped lang="scss">
.gam-input-search {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
}
</style>
