<template>
  <gam-side-panel v-bind="editGambitSidePanel">
    <div class="edit-gambit" :class="{ 'is-mobile': isMobile }">
      <GambitFormComponent
        v-if="gambit && !gambit.parentId"
        ref="gambitForm"
        :key="gambit.id"
        :form-id="formId"
        :gambit="gambit"
      />

      <RecurringGambitForm
        v-if="gambit && gambit.parentId"
        ref="gambitForm"
        :key="gambit.id"
        :form-id="formId"
        :gambit="gambit"
      />
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import { type BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import { GambitCategory, GambitType } from '@/core/data/gambit/gambit.type';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useFormStore } from '@/stores/form.store';
import { useTableStore } from '@/stores/table.store';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamButtonMessageVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { getGambitSidePanel } from '@/views/composables/constants/main/gambit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GambitDetailDto } from '@/views/composables/models/components/GamGambit';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { GambitForm, GambitValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import GambitFormComponent from '../forms/GambitForm.vue';
import RecurringGambitForm from '../forms/RecurringGambitForm.vue';

const formId = FormId.EDIT_GAMBIT;
const panelId = GamSidePanelId.GAMBIT_FORM;

interface Props {
  gambit: GambitDetailDto;
}
const props = defineProps<Props>();

const formStore = useFormStore<GambitForm, GambitValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const gambitTableStore = useTableStore<BaseGambitDto>(GamListId.GAMBITS)();

const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);
const gambitForm = ref<InstanceType<typeof GambitFormComponent>>();

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const isInvalid = ref<boolean>(true);
watch(
  () => gambitForm.value?.isInvalid,
  (isFormInvalid) => {
    if (typeof isFormInvalid !== 'boolean') {
      return;
    }

    isInvalid.value = isFormInvalid;
  },
);

const clearEditMode = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

const closeEditGambit = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      reload: 'true',
      side_panel: undefined,
    },
  });
};

const router = useRouter();
const route = useRoute();

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.address = undefined;
  form.bringMyBoard = false;
  form.clubId = undefined;
  form.description = undefined;
  form.end = undefined;
  form.gambitCategory = GambitCategory.CASUAL;
  form.gambitType = [GambitType.ANY];
  form.locationId = undefined;
  form.locationInputValue = undefined;
  form.locationPoint = undefined;
  form.mapBoxId = undefined;
  form.pinpoint = undefined;
  form.private = false;
  form.recurrences = undefined;
  form.repeat = undefined;
  form.streamed = false;
  form.timed = false;
  form.title = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'edit_gambit' && newPanel.side_panel === 'edit_gambit') {
    clearForm();
  }
});

const editGambitSidePanel = computed((): GamSidePanelType => {
  return getGambitSidePanel({
    panelId,
    isOpened: route.query.side_panel === 'edit_gambit',
    errorMessage: errorMessage.value,
    title: 'gambit.edit.title.label',
    icon: GamIconName.GAMBIT_EMPTY,
    submitLabel: 'gambit.edit.form.action.button',
    onSubmit: async () => {
      if (gamForm.value?.form) {
        errorMessage.value = null;
        isLoading.value = true;

        const options = gamForm.value.form.recurrences ? { recurrences: true } : undefined;

        const { form } = gamForm.value;

        const payload = {
          ...form,
          streamed: form.gambitType.includes(GambitType.STREAMED),
          timed: form.gambitType.includes(GambitType.TIMED),
          gambitType: form.gambitType.filter((t) => !['streamed', 'timed'].includes(t)),
        };

        const result = await gambits.gambitService.updateGambit(props.gambit.id, payload, options);

        if (result.error) {
          errorMessage.value = {
            label: result.error.message,
            variant: GamButtonMessageVariant.ERROR,
          };
        } else {
          const gambitResult = await gambits.gambitService.getGambit(props.gambit.id);
          if (gambitResult.data) {
            gambitTableStore.updateItem(gambitResult.data);
          }
          emits(GamComponentsEmits.SUCCESS);
          closeEditGambit();
          clearForm();
        }

        isLoading.value = false;
      }
    },
    cancelLabel: 'gambit.edit.form.clear.button',
    onCancel: clearEditMode,
    isActionDisabled: isInvalid.value || isLoading.value,
    isLoading: isLoading.value,
  });
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.edit-gambit {
  position: relative;

  &.is-mobile {
    width: 100% !important;
    border: 0;
  }
}

#gambit-form {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}
</style>
