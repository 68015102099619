<template>
  <div class="gam-slider" :class="{ disabled: disabled }">
    <div v-if="isSetRange" class="slider-input">
      <gam-input v-bind="minInput" class="slider-input-item" :style="getInputWidth" @update="setMinValue" />
      <gam-input
        v-if="Array.isArray(value)"
        v-bind="maxInput"
        class="slider-input-item"
        :style="getInputWidth"
        @update="setMaxValue"
      />
    </div>
    <div :class="['gam-slide-container', { range: isSetRange }]">
      <slider v-bind="getSliderData" @slide="slide" @start="sliding = true" @end="sliding = false" />
    </div>
    <div v-if="showRangeScale" ref="rangeContainer" class="range-numbers-container">
      <div :class="['range-numbers', { sliding }]">
        <span
          v-for="rNumber in getRangeNumbers()"
          :key="rNumber.value"
          :class="{ active: rNumber.active }"
          @click="rNumber.onClick"
        >
          {{ rNumber.value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import GamInput from '@/views/components/GamInput.vue';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamSliderRangeType, GamSliderType, SliderProps } from '@/views/composables/models/components/GamSlider';
import Slider from '@vueform/slider';
import { computed, ref, type StyleValue } from 'vue';

const props = withDefaults(defineProps<GamSliderType>(), {
  value: 0,
  step: 1,
});
const sliderValues = ref<number | number[]>(props.value);
const sliding = ref<boolean>(false);
const rangeContainer = ref<HTMLElement>();

const getSliderValues = (): number | number[] => {
  if (Array.isArray(sliderValues.value)) {
    if (props.min?.isReadyOnly) {
      return sliderValues.value[1];
    } else if (props.max?.isReadyOnly) {
      return sliderValues.value[0];
    }
    return sliderValues.value;
  } else {
    return sliderValues.value;
  }
};

const getValue = (index: number): number => {
  if (index === 0 && props.min?.isReadyOnly) return props.min?.value || 0;
  if (index === 1 && props.max?.isReadyOnly) return props.max?.value || 0;

  if (Array.isArray(sliderValues.value)) {
    if (sliderValues.value[index] >= (props.max?.value || 0) && props.isUnlimited) {
      return props.max?.value ? props.max.value : 0;
    }
    return sliderValues.value[index];
  }
  return sliderValues.value;
};

const setMinValue = (value: number) => {
  if (props.min?.isReadyOnly) return;
  const parsedValue = isFinite(value) ? value : 0;
  if (Array.isArray(sliderValues.value)) sliderValues.value[0] = parsedValue;
  else sliderValues.value = parsedValue;
};

const setMaxValue = (value: number) => {
  if (props.max?.isReadyOnly) return;
  const parsedValue = isFinite(value) ? value : 0;
  if (Array.isArray(sliderValues.value)) sliderValues.value[1] = parsedValue;
  else sliderValues.value = parsedValue;
};

const slide = (value: number | number[]) => {
  sliderValues.value = value;
};

const isRangeNumberActive = (value: number): boolean => {
  if (Array.isArray(props.value) && value <= getValue(1) && value >= getValue(0)) {
    return true;
  } else if (value === getValue(0)) {
    return true;
  }
  return false;
};

const getRangeNumbers = (): GamSliderRangeType[] => {
  const rangeNumbers: GamSliderRangeType[] = [];

  for (let i = props.min?.value || 0; i <= (props.max?.value || 0); i += props.step) {
    rangeNumbers.push({
      value: i,
      active: isRangeNumberActive(i),
      onClick: () => {
        sliderValues.value = i;
      },
    });
  }
  return rangeNumbers;
};

const getBaseInputProps = (index: number): GamInputType => {
  return {
    name: GamInputName.SLIDER,
    value: getValue(index),
    type: GamInputValueType.NUMBER,
    isDisabled: props.disabled,
  };
};

const getMaxSuffix = (): string | undefined => {
  if (getValue(1) >= (props.max?.value || 0) && props.isUnlimited) {
    return '+';
  }
  return props.suffix;
};

const minInput = computed((): GamInputType => {
  return {
    ...getBaseInputProps(0),
    name: GamInputName.SLIDER_MIN,
    placeholder: 'players.filters.distance.range.min',
    min: props.min?.value,
    max: Array.isArray(props.value) ? getValue(1) : props.max?.value,
    suffix: props.suffix,
    isDisabled: props.disabled || props.min?.isReadyOnly,
  };
});

const maxInput = computed((): GamInputType => {
  return {
    ...getBaseInputProps(1),
    name: GamInputName.SLIDER_MAX,
    placeholder: 'players.filters.distance.range.max',
    min: getValue(0),
    max: props.max?.value,
    suffix: getMaxSuffix(),
    isDisabled: props.disabled || props.max?.isReadyOnly,
  };
});

const getSliderData = computed((): SliderProps => {
  return {
    min: props.min?.value,
    max: props.max?.value,
    value: getSliderValues(),
    step: props.step,
  };
});

const getInputWidth = computed((): StyleValue => {
  const maxLength: number = props.max?.value.toString()?.length || 0;
  const width: number = maxLength >= 3 ? maxLength * 10 + 66 : 96;
  return {
    width: `${width}px`,
    maxWidth: `${width}px`,
  };
});

defineExpose({
  sliderValues,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

$seek-head-size: 24px;
$track-height: 8px;
$tooltip-distance: 4px;

.gam-slider {
  display: grid;
  gap: 8px;
  width: 100%;

  &.disabled {
    opacity: 0.3;
  }

  .gam-slide-container {
    margin: 0 calc($seek-head-size / 2);

    .slider-target,
    .slider-target * {
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      touch-action: none;
      user-select: none;
    }

    .slider-target {
      position: relative;

      .slider-base,
      .slider-connects {
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 1;
      }

      .slider-connects {
        overflow: hidden;
        z-index: 0;
      }
    }

    .slider-connect,
    .slider-origin {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: 0 0;
      width: 100%;
      z-index: 1;
    }

    .slider-txt-dir-rtl {
      .slider-horizontal {
        .slider-origin {
          left: 0;
          right: auto;
        }
      }
    }

    .slider-handle {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
    }

    .slider-touch-area {
      height: 100%;
      width: 100%;
    }

    .slider-state-tap {
      .slider-connect,
      .slider-origin {
        transition: transform var(--transition);
      }
    }

    .slider-state-drag * {
      cursor: inherit !important;
    }

    .slider-horizontal {
      height: $track-height;

      .slider-origin {
        height: 0;
      }

      .slider-handle {
        height: $seek-head-size;
        right: calc($seek-head-size / 2 * -1);
        top: calc(($seek-head-size - $track-height) / 2 * -1);
        width: $seek-head-size;
      }
    }

    .slider-txt-dir-rtl {
      .slider-horizontal {
        .slider-handle {
          left: calc($seek-head-size / 2 * -1);
          right: auto;
        }
      }
    }

    .slider-base {
      background: var(--color-linear-gradient-30);
      border-radius: calc($track-height / 2);
    }

    .slider-base,
    .slider-connects {
      border-radius: calc($track-height / 2);
    }

    .slider-connect {
      cursor: pointer;
      background: var(--color-linear-gradient);
    }

    .slider-draggable {
      cursor: ew-resize;
    }

    .slider-handle {
      background: var(--color-white-100);
      border-radius: $seek-head-size;
      box-shadow:
        var(--shadow-xs) var(--shadow-dark-6),
        var(--shadow-md) var(--shadow-dark-10);
      cursor: grab;
      height: $seek-head-size;
      width: $seek-head-size;
      @extend .gam-special-border !optional;

      &:before {
        padding: 2px;
        background: var(--color-linear-gradient);
      }

      &:focus {
        outline: none;
      }
    }

    .slider-active {
      cursor: grabbing;
    }

    [disabled] {
      .slider-target {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &.slider-handle,
      &.slider-target {
        cursor: not-allowed;
      }
    }

    .slider-tooltip {
      display: none !important;
    }
  }

  .slider-input {
    display: grid;
    grid-template-columns: auto max-content;
    width: 100%;
  }

  .range-numbers-container {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 8px;

    .range-numbers {
      width: 100%;
      position: relative;
      display: flex;
      padding: 0 4px;
      flex: auto;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      left: 0;

      &.sliding {
        span {
          transition: none;
        }
      }

      span {
        font-size: 14px;
        color: var(--color-white-30);
        text-align: center;
        width: 16px;
        flex-shrink: 0;
        cursor: pointer;

        &.active {
          color: var(--color-white-100);
        }

        &:hover {
          color: var(--color-white-50);
        }
      }
    }

    &:before {
      content: '';
      width: 10%;
      overflow: hidden;
      height: 15px;
    }
  }
}
</style>
