import type { AdminStatsDto } from '@/core/data/admin/admin.interface';
import type { AdminDetailLocationDto } from '@/core/data/location/location.interface';
import type { AdminReportDto } from '@/core/data/report/report.interface';
import type { AdminUserDto } from '@/core/data/user/user.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import type { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { AxiosResponse } from 'axios';
import type { HttpClient } from '../http/httpClient';
import type { GamResponse, GetTableParams } from '../http/httpClient.interface';
import { adminLocationFields, adminUserFields, detailsLocationFields } from './constants/api.fields.constant';
import { AdminEndpoint } from './constants/api.url.constant';

export class AdminApi {
  private readonly httpClient: HttpClient;
  private readonly defaultSortBy = '-createdAt';

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getGenericParams(id: GamListId): GetTableParams {
    const tableStore = useTableStore(id)();
    const filterStore = useFilterStore<GetTableParams>(id)();

    return {
      ...tableStore.tableParams,
      ...filterStore.getFilters(),
    };
  }

  async getUsers(listId: GamListId): Promise<AxiosResponse<GamResponse<AdminUserDto[]>>> {
    return this.httpClient.get(AdminEndpoint.USERS, {
      config: {
        params: {
          ...this.getGenericParams(listId),
          fields: adminUserFields.join(','),
          sortBy: this.defaultSortBy,
        },
      },
    });
  }

  async getLocations(listId: GamListId): Promise<AxiosResponse<GamResponse<AdminDetailLocationDto[]>>> {
    return this.httpClient.get(AdminEndpoint.LOCATIONS, {
      config: {
        params: {
          ...this.getGenericParams(listId),
          fields: adminLocationFields.join(','),
          sortBy: this.defaultSortBy,
        },
      },
    });
  }

  async getLocationById(id: string): Promise<AxiosResponse<GamResponse<AdminDetailLocationDto>>> {
    return this.httpClient.get(AdminEndpoint.LOCATION, {
      urlParams: {
        id,
      },
      config: {
        params: {
          fields: detailsLocationFields.join(','),
        },
      },
    });
  }

  async verifyLocation(id: string, verified: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    return this.httpClient.put(AdminEndpoint.LOCATION, {
      urlParams: {
        id,
      },
      data: {
        verified,
      },
    });
  }

  async updateLocation(location: AdminDetailLocationDto): Promise<AxiosResponse<GamResponse<void>>> {
    return this.httpClient.put(AdminEndpoint.LOCATION, {
      urlParams: {
        id: location.id,
      },
      data: {
        ...location,
      },
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async deleteLocation(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return this.httpClient.delete(AdminEndpoint.LOCATION, {
      urlParams: {
        id,
      },
    });
  }

  async deleteUser(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return this.httpClient.delete(AdminEndpoint.USER, {
      urlParams: {
        id,
      },
    });
  }

  async redactUser(id: string, payload: any): Promise<AxiosResponse<GamResponse<void>>> {
    return this.httpClient.patch(AdminEndpoint.USER_REDACT, {
      urlParams: {
        id,
      },
      data: payload,
    });
  }

  async fetchStats(): Promise<AxiosResponse<GamResponse<AdminStatsDto>>> {
    return this.httpClient.get(AdminEndpoint.STATS);
  }

  async getLocationReports(listId: GamListId): Promise<AxiosResponse<GamResponse<AdminReportDto[]>>> {
    return this.httpClient.get(AdminEndpoint.REPORT_LOCATIONS, {
      config: {
        params: {
          ...this.getGenericParams(listId),
        },
      },
    });
  }

  async getLocationCommentReports(listId: GamListId): Promise<AxiosResponse<GamResponse<AdminReportDto[]>>> {
    return this.httpClient.get(AdminEndpoint.REPORT_LOCATION_COMMENTS, {
      config: {
        params: {
          ...this.getGenericParams(listId),
        },
      },
    });
  }

  async getBlobReports(listId: GamListId): Promise<AxiosResponse<GamResponse<AdminReportDto[]>>> {
    return this.httpClient.get(AdminEndpoint.REPORT_BLOBS, {
      config: {
        params: {
          ...this.getGenericParams(listId),
        },
      },
    });
  }
}
