<template>
  <div :class="['gam-select-container', getContainerClasses]">
    <div class="gam-select-content">
      <div v-if="label" class="label">
        {{ localize(label) }}
      </div>

      <select ref="selectElement" class="gam-select" :value="modelValue" @change="onChange">
        <option v-if="placeholder" value="" disabled selected>{{ localize(placeholder) }}</option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { computed, ref } from 'vue';
import { GamComponentsEmits } from '../composables/constants/main/emit.constants';

type Option = {
  value: string | number;
  label: string;
};

interface Props {
  label?: string;
  modelValue?: string | number;
  options?: Option[];
  placeholder?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

const props = defineProps<Props>();

const selectElement = ref<HTMLSelectElement>();
const isActive = ref<boolean>(false);

const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);

function onChange(event: Event) {
  const target = event.target as HTMLSelectElement;
  emit(GamComponentsEmits.UPDATE_VALUE, target.value);
}

const getContainerClasses = computed(() => ({
  'is-placeholder': !props.modelValue,
  'is-active': isActive.value,
  'is-disabled': props.isDisabled || props.isLoading,
  'is-error': props.isError,
}));
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-select-container {
  display: flex;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-l);
  border-radius: var(--radius-large);
  height: auto;
  cursor: text;
  border: 1px solid var(--color-white-5);
  transition: border var(--transition);
  position: relative;
  gap: 16px;

  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-linear-gradient);
    opacity: 0;
    inset: -1px;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.gam-select-content {
  width: 100%;
  position: relative;
  transition: inherit;
  display: grid;
  align-items: center;
}

.gam-select {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Hanken Grotesk', sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  color: var(--color-white-100);
  background-color: var(--color-dark-70);
}

.is-placeholder .gam-select {
  color: var(--color-white-70);
}

.label {
  width: inherit;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: var(--color-white-30);
  transition: all var(--transition-fast);
  top: 0;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
</style>
