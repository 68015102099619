export enum GamDropdownSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum GamDropdownCategory {
  DEFAULT = 'default',
  LOCATIONS = 'locations',
  PLAYERS = 'players',
}
