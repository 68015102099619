<template>
  <div class="notification-content" :class="{ mobile: isMobile }">
    <div v-if="icon && !showAfterMessage" class="content-icon">
      <gam-icon :name="icon" />
    </div>
    <div class="content-container">
      <div v-if="showAfterMessage && afterAction" class="info-wrapper">
        <div class="info-title" v-html="localize(afterAction)" />
      </div>
      <div v-else class="info-wrapper">
        <div v-if="title" class="info-title" v-html="localize(title)" />
        <div v-if="message" class="info-description" v-html="localize(message)" />
      </div>
      <div v-if="action && !showAfterMessage" class="button-group">
        <gam-button v-bind="getCancelButton" />
        <gam-button v-bind="getActionButton" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { NotificationContentType } from '@/views/composables/models/notification.interface';
import { computed, ref } from 'vue';

const props = defineProps<NotificationContentType>();
const emits = defineEmits([GamComponentsEmits.CLOSE]);

const isLoading = ref<boolean>(false);
const showAfterMessage = ref<boolean>(false);

const getActionButton = computed((): GamButtonType => {
  return {
    label: props.actionLabel || 'notification.general.action.button',
    variant: GamButtonVariant.SECONDARY,
    size: isMobile.value ? GamButtonSize.SMALL : GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
    isLoading: isLoading.value,
    onClick: async () => {
      if (props.action) {
        isLoading.value = true;
        const response = await props.action();
        if (response && props.afterAction) {
          showAfterMessage.value = true;
          setTimeout(() => {
            emits(GamComponentsEmits.CLOSE);
          }, 5000);
        } else {
          emits(GamComponentsEmits.CLOSE);
        }
        isLoading.value = false;
      }
    },
  };
});

const getCancelButton = computed((): GamButtonType => {
  return {
    label: 'notification.general.cancel.button',
    variant: GamButtonVariant.TERTIARY,
    size: isMobile.value ? GamButtonSize.SMALL : GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
    onClick: () => {
      emits(GamComponentsEmits.CLOSE);
    },
  };
});
</script>

<style lang="scss">
.notification-content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-menu);
  flex: 1 0 0;

  &.mobile {
    .content-container {
      .info-wrapper {
        gap: var(--spacing-md);

        .info-title {
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
        }

        .info-description {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-menu);
    align-self: stretch;

    .info-wrapper {
      display: flex;
      padding-right: var(--spacing-menu);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-l);
      align-self: stretch;

      .info-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        color: var(--color-white-100);
        align-self: stretch;
      }

      .info-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 110%;
        color: var(--color-white-70);
        z-index: 2;

        span {
          color: var(--color-white-100);
        }
      }
    }

    .button-group {
      display: flex;
      align-items: flex-start;
      gap: var(--spacing-md);
      align-self: stretch;
    }
  }
}
</style>
