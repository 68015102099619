<template>
  <div class="placeholder-splash">
    <div class="copy">
      <p><slot /></p>
      <GamButton :variant="buttonVariant" :size="buttonSize" @click="handleCreateClick">{{ buttonLabel }}</GamButton>
    </div>
    <img v-if="activeImage === 1" src="/images/editorial/frame_1.jpg" />
    <img v-if="activeImage === 2" src="/images/editorial/frame_2.jpg" />
    <img v-if="activeImage === 3" src="/images/editorial/frame_3.jpg" />
  </div>
</template>

<script setup lang="ts">
import type { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { computed } from 'vue';
import GamButton from './GamButton.vue';

defineProps<{
  buttonVariant: GamButtonVariant;
  buttonSize: GamButtonSize;
  buttonLabel: string;
  handleCreateClick: (e: MouseEvent) => void;
}>();

const activeImage = computed(() => Math.ceil(Math.random() * 3));
</script>

<style lang="scss" scoped>
.placeholder-splash {
  width: 100%;
  height: 100%;
  position: relative;

  .copy {
    font-family: 'Hanken Grotesk', sans-serif;
    font-optical-sizing: auto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: center;

    position: absolute;
    left: 50%;
    top: 50%;
    width: 36em;
    max-width: calc(100% - 8em);
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    gap: var(--spacing-l);
    margin-bottom: var(--spacing-xxl);

    p {
      font-size: 3.5rem;
      font-weight: 100;
      font-variant: small-caps;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;

      @media (max-width: 1280px) {
        font-size: 3rem;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 100%;
    opacity: 10%;
    pointer-events: none;
    filter: saturate(0);
  }
}
</style>
