<template>
  <GamDialog ref="dialog">
    <div class="offline-dialog" :class="{ mobile: isMobile }">
      <h2 class="title">Connection problem</h2>
      <div class="message">
        <p>
          The map &amp; search features require a persistent connection to function. Please make sure you're connected
          to the internet &amp; reload the app if needed.
        </p>
      </div>
    </div>
  </GamDialog>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import GamDialog from './components/gam-dialog/GamDialog.vue';

const appStore = useAppStore();
const { isOnline } = storeToRefs(appStore);
const route = useRoute();

const dialog = ref<InstanceType<typeof GamDialog>>();

onMounted(() => {
  dialog.value?.show();
});

watch(isOnline, (isOnline, wasOnline) => {
  if (!wasOnline && isOnline) {
    setTimeout(() => (window.location.href = cleanRoute.value), 5000);
  }
});

const cleanRoute = computed(() => {
  const newQuery = { ...route.query };

  if (route.query.location) {
    newQuery.side_panel = 'location_detail';
  } else {
    delete newQuery.side_panel;
  }

  // @ts-ignore
  const searchParams = new URLSearchParams(newQuery).toString();

  return route.path + (searchParams ? `?${searchParams}` : '');
});

// const getActionButton = computed((): GamButtonType => {
//   return {
//     label: 'Reload now',
//     variant: GamButtonVariant.SECONDARY,
//     size: GamButtonSize.ACTIONS,
//     isFullSize: true,
//     center: true,
//     isDisabled: !isOnline.value,
//     onClick: async () => {
//       window.location.href = cleanRoute.value;
//     },
//   };
// });
</script>

<style lang="scss" scoped>
.offline-dialog {
  display: flex;
  position: relative;
  width: calc(335px - 2 * var(--spacing-menu));
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-md);
  text-align: center;

  &.mobile {
    width: 100%;
    min-width: 0;
  }

  h2.title {
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;
    padding-bottom: var(--spacing-md);
    flex: 1 0 0;
    margin-bottom: 0;
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--spacing-md);
    align-self: stretch;
  }

  p {
    line-height: 1.4;
    // color: var(--color-white-50);
    opacity: 0.7;
  }

  .button-group {
    display: flex;
    padding: var(--spacing-l) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
  }
}
</style>
