<template>
  <div class="list-header">
    <div class="tabs-row">
      <gam-button v-bind="getCreateClubButton" />
      <gam-tab v-bind="getTabItems" @update="onTabItemClick" />
    </div>

    <div v-if="false" class="club-filtering">
      <gam-input-search v-bind="getSearchInput" />
      <div class="club-filter">
        <gam-button v-bind="getFilterButton" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TabItemType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import type { GetTableParams } from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useFilterStore } from '@/stores/filter.store';
import GamButton from '@/views/components/GamButton.vue';
import GamInputSearch from '@/views/components/GamInputSearch.vue';
import GamTab from '@/views/components/gam-tab/GamTab.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamTabSize } from '@/views/composables/constants/components/gamTab.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputSearchType } from '@/views/composables/models/components/GamInput';
import type { GamTabType } from '@/views/composables/models/components/GamTab';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const filterStore = useFilterStore<GetTableParams>(GamListId.CLUBS)();
const { listTabItems } = storeToRefs(filterStore);

onBeforeMount(() => {
  filterStore.setListTabItems(gambits.configService.getClubViewTabItems());
});

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const onTabItemClick = (name: TabItemType) => {
  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  filterStore.setActiveTab(name);
};

const getCreateClubButton = computed((): GamButtonType => {
  return {
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.PRIMARY,
    label: isMobile.value ? 'Create' : 'clubs.general.button.label',
    onClick: () => {
      if (!authStore.isAuthenticated()) {
        router.push('/auth');
        return;
      }

      router.push({
        ...route,
        query: {
          side_panel: 'add_club',
        },
      });
    },
  };
});

const getTabItems = computed((): GamTabType => {
  return {
    tabs: listTabItems.value,
    background: true,
    size: GamTabSize.SMALL,
  };
});

const getSearchInput = computed((): GamInputSearchType<BaseUserDto> => {
  return {
    id: GamListId.PLAYERS,
    input: {
      name: GamInputName.SEARCH,
      placeholder: 'clubs.general.search.placeholder',
      leftIcon: GamIconName.SEARCH,
    },
    selectFirstItem: !isMobile.value,
  };
});

const getFilterButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.FILTER,
    isIconOnly: true,
    size: GamButtonSize.LARGE,
    variant: GamButtonVariant.TERTIARY,
    isIconWhite: true,
  };
});
</script>

<style scoped lang="scss">
.list-header {
  .tabs-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: stretch;
    gap: var(--spacing-menu);

    .gam-button {
      flex-shrink: 0;
    }
  }

  .club-filtering {
    display: flex;
    align-self: stretch;
    gap: var(--spacing-xs);
  }
}
</style>
