<template>
  <div :class="['player-profile-image', { missing: !imageBlob }]">
    <div v-touch:tap="expandImage" class="image-container" @click="expandImage">
      <div v-if="imageBlob" class="image">
        <img loading="lazy" :src="getImageSrc()" alt="image" @load="loaded" @loadstart="loadingImage" />
      </div>
      <div v-else class="missing-icon">
        <gam-icon v-bind="getMissingIcon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gambits } from '@/core/gambits';
import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamPlayerImageSize } from '@/views/composables/constants/components/gamPlayer.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamPlayerImageType } from '@/views/composables/models/components/GamPlayer';
import { computed, ref } from 'vue';

const imgLoading = ref<boolean>(true);
const props = withDefaults(defineProps<GamPlayerImageType>(), {
  size: GamPlayerImageSize.LARGE,
  expand: true,
});

const emit = defineEmits([GamComponentsEmits.EXPAND]);

const getImageSrc = (): string => {
  const url = `${gambits.configService.getServiceRoutingHost()}${AppEndpoint.BLOB}`;
  return url.replace(new RegExp('{blobHash}', 'g'), props.imageBlob || '');
};

const loadingImage = () => {
  imgLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    imgLoading.value = false;
  });
};

const expandImage = () => {
  if (!props.imageBlob) return;

  // TODO: Pass the id of the image not the imageBlob
  emit(GamComponentsEmits.EXPAND, props.imageBlob);
};

const getMissingIcon = computed((): GamIconType => {
  return {
    name: GamIconName.PAWN_EMPTY,
    size: '36px',
  };
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.player-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: border var(--transition);
  overflow: hidden;

  background: var(--color-dark-700);
  @extend .gam-special-border !optional;

  width: 100%;
  height: 100%;

  &:before {
    background: var(--color-white-100);
    padding: 3px;
    opacity: 0;
    z-index: 100;
  }

  .image-container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      width: inherit;
      height: inherit;

      &.skeleton {
        border-radius: 0 !important;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        filter: grayscale(100%);
      }
    }
  }

  &:not(.missing):hover {
    cursor: pointer;
    &:before {
      opacity: 1;
    }
  }

  .expand-icon {
    position: absolute;
    right: 6px;
    bottom: 6px;
  }
}
</style>
