<template>
  <div :class="['main-view locations-view', { 'is-mobile': isMobile, prevent: imageExpanded }]">
    <LocationSearch />

    <LocationDetail />

    <template v-if="authStore.isAuthenticated()">
      <AddLocation />

      <EditLocation v-if="route.query.side_panel === 'edit_location'" />

      <AddGambit />

      <AddClub />
    </template>

    <div v-if="isMobile && arePanelsClosed" class="mobile-map-actions">
      <MapActions />
      <MapModes />
    </div>
    <div v-if="!isMobile">
      <MapActions />
    </div>

    <div v-if="isUserLocated" class="map-wrapper" :class="{ 'is-absolute': isMobile }">
      <GamMap v-bind="getMapConfig" @update="updateSelectedLocation" />
    </div>

    <GeoLocation />
  </div>
</template>

<script lang="ts" setup>
import type { LocationGambitDto } from '@/core/data/gambit/gambit.interface';
import type { BaseLocationDto } from '@/core/data/location/location.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import GeoLocation from '@/GeoLocation.vue';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamMap from '@/views/components/gam-map/GamMap.vue';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamMapType } from '@/views/composables/models/components/GamMap';
import AddLocation from '@/views/main/locations/side-panels/AddLocation.vue';
import LocationSearch from '@/views/main/locations/side-panels/LocationsList.vue';
import MapActions from '@/views/main/locations/widgets/MapActions.vue';
import { storeToRefs } from 'pinia';
import { computed, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import AddClub from '../clubs/side-panels/AddClub.vue';
import AddGambit from '../gambits/side-panels/AddGambit.vue';
import EditLocation from './side-panels/EditLocation.vue';
import LocationDetail from './side-panels/LocationPanel.vue';
import MapModes from './widgets/MapModes.vue';

const listId = GamListId.LOCATIONS;

const appStore = useAppStore();
const { imageExpanded } = storeToRefs(appStore);

const mapStore = useMapStore();
const { selectedLocationId } = storeToRefs(mapStore);

const geolocationStore = useGeolocationStore();
const { isUserLocated } = storeToRefs(geolocationStore);

const tableStore = useTableStore<BaseLocationDto>(listId)();
const { selectedItem } = storeToRefs(tableStore);

const authStore = useAuthStore();

const route = useRoute();

const updateSelectedLocation = (location: LocationGambitDto) => {
  if (selectedLocationId.value === location.id) return;
};

const arePanelsClosed = computed(() => route.query.side_panel === undefined);

const getMapConfig = computed((): GamMapType => {
  return {
    selectedLocation: selectedItem.value,
  };
});

onUnmounted(async () => {
  appStore.setDrawerOpened(false);
});
</script>

<style scoped lang="scss">
.locations-view {
  .map-wrapper {
    z-index: 1;
  }

  .mobile-map-actions {
    position: absolute;
    padding-top: 8px;
    padding-left: 2rem;
    padding-right: 2.25rem;
    padding-bottom: 16px;
    bottom: 68px;
    width: 100%;
    z-index: 700;
    opacity: 1;
    transition: opacity var(--transition);
    background: linear-gradient(180deg, rgba(#1d1d1d, 0) 0%, rgba(#1d1d1d, 0.66) 100%);

    display: flex;
    align-items: center;
    justify-content: space-between;

    .gam-button {
      flex-shrink: 0;
    }
  }

  .map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: center;
    background: var(--color-linear-gradient-vertical) var(--color-dark-700);
    overflow: hidden;
    padding-left: 417px;

    &.is-absolute {
      position: absolute;
      background: none;
      top: 0;
      left: 0;
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss">
.locations-view {
  .map-actions {
    pointer-events: none;

    .gam-button {
      pointer-events: auto;
    }
  }
}
</style>
