<template>
  <div>
    <div class="auth-content">
      <div class="gambit-logo">
        <img src="/images/logo-gambits-auth.svg" :alt="localize('app.name.full')" />
      </div>
      <div class="auth-context">
        <span class="auth-title">{{ localize('auth.main.title') }}</span>
        <span class="auth-subtitle">{{ localize('auth.main.subtitle') }}</span>
      </div>
      <div v-if="showAuthMethods" class="auth-methods-group">
        <div v-for="methodGroup in authMethodGroups" :key="methodGroup.position" class="methods-row">
          <gam-button v-for="method in methodGroup.items" :key="method.type" v-bind="getLoginButton(method)" />
        </div>
      </div>
      <div class="auth-context">
        <span class="auth-description" v-html="localize('auth.privacy')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import GamButton from '@/views/components/GamButton.vue';
import { useAuth } from '@/views/composables/auth/useAuth';
import type { AuthScreenMethod } from '@/views/composables/auth/useAuth.interface';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import { onMounted } from 'vue';

const { showAuthMethods, authMethodGroups, signInWith } = useAuth({
  serverUrl: gambits.configService.getServiceUrl(),
  authScreenType: RouterAuthType.AUTH_SELECT,
});

onMounted(() => {
  gambits.authService.getFormData();
});

const getLoginButton = (method: AuthScreenMethod): GamButtonType => {
  return {
    variant: method.isPrimary ? GamButtonVariant.PRIMARY : GamButtonVariant.SECONDARY,
    size: GamButtonSize.LARGE,
    label: method.label,
    isIconOnly: !method.label && !!method.icon,
    leftIcon: method.icon,
    isFullSize: true,
    center: true,
    isDisabled: !method.active,
    isLoading: method.isLoading,
    message: method.errorMessage,
    onClick: () => {
      signInWith(method);
    },
  };
};
</script>

<style scoped lang="scss">
.auth-content {
  .gambit-logo {
    width: auto;
    height: 32px;
    max-height: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
