<template>
  <div v-if="gambit" class="gam-gambit-card" :class="getClass">
    <GamHighlight />

    <RouterLink :to="getGambitLinkProps" class="gambit-link" />

    <!-- <GamGambitDate v-bind="getGambitDate" /> -->

    <div class="timeframe">
      <!-- <span><gam-icon :name="GamIconName.TIME" /></span> -->

      <span class="day-date-text">
        <GamGambitDayTag :date="gambit.start" />

        <span>{{ format(gambit.start, 'd MMM') }}</span>

        <span>{{ format(gambit.start, 'HH:mm') }}</span>
      </span>

      <template v-if="gambit.end">
        <span>&ndash;</span>
        <span>{{ format(gambit.end, 'd MMM HH:mm') }}</span>
      </template>
    </div>

    <div class="title">
      <span>
        {{ gambit?.title || 'Unnamed gambit' }}
      </span>
    </div>

    <div class="location">
      <span> <GamIcon :name="getTypeIcon" /> </span>
      <span class="name">{{ location.name }}</span> <span class="address">{{ location.address }}</span>
    </div>

    <!-- <div class="rank"></div> -->

    <div v-if="gambit" class="gambit-tags-wrapper">
      <GamGambitSimpleTags :gambit="gambit" is-small is-horizontally-padded />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import GamGambitDayTag from '@/views/components/gam-gambits/GamGambitDayTag.vue';
import GamGambitSimpleTags from '@/views/components/gam-gambits/GamGambitSimpleTags.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamLocationCardType } from '@/views/composables/models/components/GamLocation';
import { format } from 'date-fns';
import { computed } from 'vue';
import GamHighlight from '../GamHighlight.vue';

const props = defineProps<GamLocationCardType & { gambit: BaseGambitDto }>();

const getClass = computed(() => {
  return {
    selected: props.selected,
    'is-list': true,
    'is-mobile': isMobile,
  };
});

const getGambitLinkProps = computed(() => {
  return {
    name: RouterNameType.GAMBIT,
    params: { id: props.gambit?.id },
  };
});

const getTypeIcon = computed((): GamIconName => {
  return LocationHelper.getLocationTypeIcon(
    props.location.locationTypes,
    props.location.private,
    props.location.favorite,
  );
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-gambit-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--spacing-s) + var(--spacing-xs));
  position: relative;
  border-radius: 16px;
  // overflow: hidden;
  background-color: #222;
  padding-bottom: 32px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 16px;
  text-decoration: none;

  // border: 1px solid var(--color-pink);
  background: radial-gradient(70.67% 100% at 0% 0%, rgba(255, 109, 162, 0.1) 0%, rgba(255, 97, 205, 0) 100%),
    var(--Gambit-Dark-Dark-600, #242322);

  &.selected {
    border: 1px solid #555;
    background-color: var(--color-dark-500);
  }

  &:hover {
    background-color: var(--color-dark-500);
    box-shadow: var(--shadow-card-hover);
  }

  .gambit-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title {
    // font-weight: bold;
    // display: flex;
    gap: 0.75rem;
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-weight: 300;
    font-size: 1.125rem;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      word-break: break-word;
      hyphens: auto;
      overflow: hidden;
      line-height: 1.25;
    }
  }

  .timeframe {
    display: flex;
    gap: 0.75rem;

    .day-date-text {
      display: flex;
      gap: 0.5rem;
      text-transform: uppercase;
    }
  }

  .location {
    display: flex;
    gap: 0.75rem;
    width: 100%;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;
    }

    .address {
      color: var(--color-white-30);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .gam-gambit-date {
    // position: absolute;
    z-index: 6;
    padding-left: 8px;
    top: 0;
    left: 0;
    bottom: calc(32px + 8px);
    padding-bottom: 20px;
    padding-left: 16px;
  }

  .gam-gambit-location {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    mask-image: linear-gradient(rgb(0 0 0 / 100%), transparent);
  }

  .gambit-tags-wrapper {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
}

.gambit-base-info {
  width: 100%;
}
</style>
