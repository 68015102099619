import { RouterAdminType, RouterAuthType, RouterNameType, StaticPageName } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import AuthImages from '@/views/auth/pages/AuthImages.vue';
import posthog from 'posthog-js';
import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router';

import AuthView from '@/views/auth/AuthView.vue';
import AuthMail from '@/views/auth/pages/AuthMail.vue';
import AuthOtp from '@/views/auth/pages/AuthOtp.vue';
import AuthRatings from '@/views/auth/pages/AuthRatings.vue';
import AuthRegister from '@/views/auth/pages/AuthRegistration.vue';
import AuthSelection from '@/views/auth/pages/AuthSelection.vue';
import ClubsView from '@/views/main/clubs/ClubsView.vue';
import ClubView from '@/views/main/clubs/ClubView.vue';
import GambitDetailView from '@/views/main/gambits/GambitDetailView.vue';
import GambitsView from '@/views/main/gambits/GambitsView.vue';
import LocationsView from '@/views/main/locations/LocationsView.vue';
import PlayersView from '@/views/main/players/PlayersView.vue';
import PlayerView from '@/views/main/players/PlayerView.vue';
import ProfileView from '@/views/main/profile/ProfileView.vue';
import StaticPage from '@/views/StaticPage.vue';

// const MainView = () => import('@/views/MainView.vue');
const AdminView = () => import('@/views/admin/AdminView.vue');
const AdminLocations = () => import('@/views/admin/AdminLocations.vue');
const AdminUsers = () => import('@/views/admin/AdminUsers.vue');
const AdminReports = () => import('@/views/admin/AdminReports.vue');

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
  routes: [
    {
      path: '/player/:username?',
      name: RouterNameType.PLAYER,
      component: PlayerView,
    },
    {
      path: '/players/:username?',
      name: RouterNameType.PLAYERS,
      component: PlayersView,
    },
    {
      path: '/club/:username?',
      name: RouterNameType.CLUB,
      component: ClubView,
    },
    {
      path: '/clubs/:username?',
      name: RouterNameType.CLUBS,
      component: ClubsView,
    },
    {
      path: '/gambit/:id',
      name: RouterNameType.GAMBIT,
      component: GambitDetailView,
    },
    {
      path: '/gambits/:id?',
      name: RouterNameType.GAMBITS,
      component: GambitsView,
    },
    // {
    //   path: '/feed',
    //   name: RouterNameType.FEED,
    //   component: MainView,
    // },
    {
      path: '/',
      name: RouterNameType.LOCATIONS,
      component: LocationsView,
    },
    // {
    //   path: '/#more',
    //   name: RouterNameType.MORE,
    //   component: MainView,
    // },
    // {
    //   path: '/messages',
    //   name: RouterNameType.MESSAGES,
    //   component: MainView,
    // },
    // {
    //   path: '/notifications',
    //   name: RouterNameType.NOTIFICATIONS,
    //   component: MainView,
    // },
    // {
    //   path: '/results',
    //   name: RouterNameType.RESULTS,
    //   component: MainView,
    // },
    {
      path: '/profile',
      name: RouterNameType.PROFILE,
      component: ProfileView,
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/auth',
      name: RouterAuthType.AUTH,
      component: AuthView,
      children: [
        {
          path: '',
          name: RouterAuthType.AUTH_SELECT,
          component: AuthSelection,
        },
        {
          path: 'email',
          name: RouterAuthType.AUTH_EMAIL,
          component: AuthMail,
        },
        {
          path: 'otp',
          name: RouterAuthType.AUTH_OTP,
          component: AuthOtp,
        },
        {
          path: 'register',
          name: RouterAuthType.AUTH_REGISTRATION,
          component: AuthRegister,
        },
        {
          path: 'ratings',
          name: RouterAuthType.AUTH_RATINGS,
          component: AuthRatings,
          beforeEnter: (to, from, next) => {
            next();
          },
        },
        {
          path: 'profile-images',
          name: RouterAuthType.AUTH_PROFILE_IMAGES,
          component: AuthImages,
          beforeEnter: (to, from, next) => {
            next();
          },
        },
      ],
    },
    {
      path: '/terms-and-conditions',
      name: StaticPageName.TERMS,
      component: StaticPage,
    },
    {
      path: '/privacy',
      name: StaticPageName.PRIVACY_POLICY,
      component: StaticPage,
    },
    {
      path: '/imprint',
      name: StaticPageName.IMPRINT,
      component: StaticPage,
    },
    {
      path: '/contact',
      name: StaticPageName.CONTACT,
      component: StaticPage,
    },
    {
      path: '/admin',
      name: RouterNameType.ADMIN,
      component: AdminView,
      redirect: { name: RouterAdminType.USERS },
      children: [
        {
          path: 'locations',
          name: RouterAdminType.LOCATIONS,
          component: AdminLocations,
        },
        {
          path: 'users',
          name: RouterAdminType.USERS,
          component: AdminUsers,
        },
        {
          path: 'reports',
          name: RouterAdminType.REPORTS,
          component: AdminReports,
        },
      ],
    },
    {
      path: '/@:username',
      name: RouterNameType.USERNAME,
      component: ClubView,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = gambits.authService.isAuthenticated();
  const isOnboarded = gambits.authService.isActive();
  const isAdmin = gambits.authService.isAdmin();
  const toName = to?.name?.toString() || '';
  const isAuthRoute = Object.values(RouterAuthType).includes(toName as RouterAuthType);
  const isAdminRoute = Object.values(RouterAdminType).includes(toName as RouterAdminType);
  const isRestrictedRoute = [RouterNameType.PROFILE, RouterNameType.PLAYER, RouterNameType.PLAYERS].includes(
    toName as RouterNameType,
  );

  // Restrict access to admin routes
  if (isAdminRoute && !isAdmin) {
    next({ name: RouterNameType.LOCATIONS });
    return;
  }

  // Ensure onboarding is completed
  if (isLoggedIn && !isOnboarded && !isAuthRoute) {
    next({ name: RouterAuthType.AUTH_REGISTRATION });
    return;
  }

  // Ensure user is logged in for restricted screens
  if (!isLoggedIn && isRestrictedRoute) {
    next({ name: RouterAuthType.AUTH_SELECT });
    return;
  }

  next();
});

// Analytics integration
router.afterEach((to, from, failure) => {
  const url = window.origin + to.fullPath;

  if (isNavigationFailure(failure)) {
    posthog.capture('$pageview', {
      $current_url: url,
      error: 'Navigation Failure',
      status: 404,
    });

    return;
  }

  posthog.capture('$pageview', { $current_url: url });
});

export default router;
