<template>
  <OfflineView v-if="!isOnline" />

  <div v-if="showApp" id="gambits-app">
    <gam-nav v-if="(!isMobile && !isAuthScreen) || (isMobile && !isInModalInteraction && !isAuthScreen)" />

    <main :id="id" v-loading="{ state: isLoading, id }">
      <gam-cookie-notice v-if="showCookieNotice" @update="checkCookies" />

      <router-view v-slot="{ Component }">
        <template v-if="Component">
          <component :is="Component" class="component-wrapper height-100" />
        </template>
      </router-view>
    </main>
  </div>
</template>

<script setup lang="ts">
import type { CookiesConfig } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useNavigationStore } from '@/stores/navigation.store';
import { AppViewsId } from '@/views/composables/constants/gam.constants';
import { useHead, useSeoMeta } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { bootstrap } from 'vue-gtag';
import { useRoute } from 'vue-router';
import { isMobile } from './core/helpers/ui.helper';
import { useAppStore } from './stores/app.store';
import GamNav from './views/components/gam-nav/GamNav.vue';
import GamCookieNotice from './views/components/gam-notification/GamCookieNotice.vue';
import OfflineView from './views/OfflineView.vue';

const id: string = AppViewsId.MAIN;
const showApp = ref<boolean>(false);
const language = ref<string>('');

const appStore = useAppStore();
const { isOnline } = storeToRefs(appStore);

const authStore = useAuthStore();
const navStore = useNavigationStore();
const { isLoading } = storeToRefs(navStore);
const cookies = ref<CookiesConfig | null>(null);

const route = useRoute();
const isInModalInteraction = computed(() => route.query.side_panel !== undefined);
const isAuthScreen = computed(() => route.path.includes('/auth'));

onMounted(async () => {
  try {
    // useGeolocationStore().setUserIpLocation();
    cookies.value = gambits.configService.getCookies();
    language.value = await gambits.localizationService.setTranslations();
    await gambits.filterService.setAppFilters();
  } finally {
    await initApp();
  }

  authStore.authorizeUser(gambits.authService.getUser(), gambits.authService.isAuthenticated());
});

useHead(gambits.getPageMeta());
useSeoMeta(gambits.useSeoMeta());

const initApp = async (): Promise<void> => {
  return new Promise((resolve) => {
    initAnalytics();
    showApp.value = true;
    resolve();
  });
};

const showCookieNotice = computed(() => {
  return cookies.value === null;
});

const initAnalytics = () => {
  if (cookies.value?.performance) {
    setTimeout(async () => {
      await bootstrap();
    });
  }
};

const checkCookies = (setCookies: CookiesConfig) => {
  cookies.value = setCookies;
  initAnalytics();
};

onUnmounted(() => {
  gambits.geolocationService.clearPositionWatcher();
});
</script>

<style scoped lang="scss">
#gambits-app {
  display: flex;
  flex-direction: row;
  height: 100svh;
  overflow: hidden;

  #gam-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    transform: scale(1);

    .component-wrapper {
      padding: 0;
      flex-grow: 1;
      flex-shrink: 0 !important;
    }
  }

  .git-hash {
    position: absolute;
    top: 10px;
    right: 20px;
    color: var(--color-white-10);
    z-index: 99999999;
    pointer-events: none;
    display: none;
  }
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
</style>
