<template>
  <div class="auth-registration">
    <div v-if="isAuthLoading" class="auth-loading">
      <GamLoader />
    </div>
    <div class="auth-content">
      <div class="auth-context left">
        <span class="auth-title">{{ localize('auth.page.registration.title') }}</span>
        <span class="auth-subtitle">{{ localize('auth.page.registration.subtitle') }}</span>
      </div>
      <div class="auth-content-wrapper">
        <div v-if="gamForm" class="auth-form images">
          <div class="image">
            <div v-if="false" class="label">
              {{ localize('auth.page.registration.form.profile.label') }}
            </div>

            <gam-image
              v-model="gamForm.form.profileImage"
              :image="gamForm.form.profileImage"
              :add="true"
              :can-delete="true"
              :size="GamImageSize.MEDIUM"
              @expand="expandImage"
            />
          </div>

          <div v-if="false" class="image">
            <div class="label">
              {{ localize('auth.page.registration.form.chessboard.label') }}
            </div>

            <!-- <gam-image
              v-if="gamForm"
              v-model="gamForm.form.chessboardImage"
              :image="gamForm.form.chessboardImage"
              :add="true"
              :can-delete="true"
              :size="GamImageSize.MEDIUM"
              @expand="expandImage"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="auth-methods-group">
      <div class="methods-row">
        <gam-button v-bind="getContinueButton" />
      </div>
    </div>

    <gam-expand-image
      v-if="selectedImage"
      ref="imageExpand"
      v-bind="selectedImage"
      @close="clearSelected"
      @clear="deleteImage"
    />
  </div>
</template>

<script setup lang="ts">
import type { RegisterFormData } from '@/core/data/auth/auth.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { BlobSource } from '@/core/data/report/report.type';
import { gambits, localize } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import { useMapStore } from '@/stores/map.store';
import GamButton from '@/views/components/GamButton.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamLoader from '@/views/components/GamLoader.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type {
  ProfileImagesForm,
  ProfileImagesValidator,
  ProfileUserForm,
} from '@/views/composables/models/form.interface';
import { useVuelidate } from '@vuelidate/core';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

const formId = FormId.AUTH_PROFILE_IMAGES;
const isAuthLoading = ref<boolean>(true);
const isLoading = ref<boolean>(false);

const formStore = useFormStore<ProfileImagesForm, ProfileImagesValidator>(formId)();
const { gamForm } = storeToRefs(formStore);
const router = useRouter();

const mapStore = useMapStore();
const { currentLocation, currentZoom } = storeToRefs(mapStore);

const savedData = ref<RegisterFormData | null>(gambits.authService.getFormData());
const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

const getForm = reactive({
  profileImage: savedData.value?.profileImage || null,
  chessboardImage: savedData.value?.chessboardImage || null,
});

const getRules = {
  profileImage: () => true,
  chessboardImage: () => true,
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
  await getAuthUser();
  authStore.fetchUserInfo(true);
});

const v$ = useVuelidate(getRules, getForm);

const getAuthUser = async (): Promise<void> => {
  await authStore.fetchUserInfo(true);
  isAuthLoading.value = false;

  if (gamForm.value?.form) {
    gamForm.value.form.profileImage = gamForm.value.form.profileImage || userDetails.value?.profileImageBlobHash || '';
    gamForm.value.form.chessboardImage = userDetails.value?.chessBoardImageBlobHash;
  }
};

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  return v$.value.$invalid;
});

const expandImage = (hash: string) => {
  selectedImage.value = {
    selectedIndex: 0,
    blobSource: BlobSource.USER_PROFILE,
    images: [
      {
        id: hash,
        imageBlobHash: hash,
        liked: false,
      },
    ],
    canDelete: true,
    closeAfterDelete: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};

const clearSelected = () => {
  selectedImage.value = null;
};

const deleteImage = (hash: string) => {
  if (gamForm.value?.form.profileImage === hash) {
    gamForm.value.form.profileImage = null;
  } else if (gamForm.value?.form.chessboardImage === hash) {
    gamForm.value.form.chessboardImage = null;
  }
};

const getContinueButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    label: 'auth.page.registration.continue.button.label',
    isFullSize: true,
    center: true,
    isDisabled: isInvalid.value,
    isLoading: isLoading.value,
    onClick: async () => {
      if (!gamForm.value?.form || !userDetails.value) {
        return;
      }

      // TODO: Build payload with types without nullable fields
      const payload = { ...userDetails.value, ...gamForm.value?.form } as ProfileUserForm;

      isLoading.value = true;

      await gambits.userService.updateUser(payload);

      await router.push({
        name: RouterNameType.LOCATIONS,
        query: { ...currentLocation.value, zoom: currentZoom.value },
      });
      isLoading.value = false;
    },
  };
});
</script>

<style scoped lang="scss">
.auth-registration {
  .auth-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-dark-30);
    z-index: 600;
  }

  .auth-content {
    gap: var(--spacing-menu);

    .auth-content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: var(--spacing-menu) !important;

      .auth-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl);
        align-self: stretch;

        .form-row {
          width: 100%;
          display: flex;
          gap: var(--spacing-md);

          .emoji {
            width: 80px;
          }
        }

        &.images {
          flex-direction: row;
          align-items: center;
          justify-content: center;

          @media (min-width: 640px) {
            margin-bottom: var(--spacing-menu);
          }

          .image {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-md);

            .label {
              font-size: 14px;
              font-weight: 400;
              color: var(--color-white-70);
              line-height: 110%;
            }
          }
        }
      }
    }
  }

  .input-with-support {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;

    .gam-support-text {
      position: absolute;
      top: 3.75rem;
      padding-left: 1.5rem;
      // max-width: 12rem;
      width: 20rem;
    }
  }

  .input-with-support {
    padding-bottom: 1rem;
  }
}
</style>
