import type { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import type { GamPinObject, UserGeoData } from '@/core/data/location/location.interface';
import { StorageKeyType } from '@/core/data/storage/storage.interface';
import type { StorageRepository } from '@/core/data/storage/storage.repository';
import { LocationApi } from '@/core/network/api/location/location.api';
// import { createLogger } from '@/core/helpers/logger.helper';

// const logger = createLogger('GeolocationRepository');

export class GeolocationRepository {
  private readonly httpAPI: LocationApi;
  private readonly storage: StorageRepository;
  private readonly config: ClientConfigRepository;
  private pinElements: { [key: string]: GamPinObject } = {};

  constructor(httpAPI: LocationApi, storage: StorageRepository, config: ClientConfigRepository) {
    this.httpAPI = httpAPI;
    this.storage = storage;
    this.config = config;
  }

  getGeoData(): UserGeoData | null {
    return this.storage.get<UserGeoData>(StorageKeyType.GeoData);
  }

  getGeoDataRefresh(): number | null {
    return this.storage.get<number>(StorageKeyType.GeoDataRefresh);
  }

  setGeoDataRefresh(): void {
    this.storage.set<number>(StorageKeyType.GeoDataRefresh, Date.now());
  }

  getDefaultGeoData(): UserGeoData {
    return this.config.parseDefaultGeoData();
  }

  getUserLocation(): UserGeoData | null {
    return this.getGeoData();
  }

  setUserLocation(geoLocation: UserGeoData): void {
    this.storage.set<UserGeoData>(StorageKeyType.GeoData, geoLocation);
    this.setGeoDataRefresh();
  }
}
