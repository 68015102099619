<template>
  <div v-if="gamForm" class="club-form">
    <div class="club-wrapper">
      <gam-input v-bind="computedName" v-model="gamForm.form.name" />
    </div>

    <div class="club-wrapper">
      <gam-input v-bind="username" v-model="gamForm.form.username" @update="checkUsername" />
    </div>

    <div class="additional-info section">
      <div class="section-title">
        {{ localize('clubs.add.form.section.info.title') }}
      </div>

      <gam-textarea
        v-model="gamForm.form.description"
        placeholder="clubs.add.form.description.placeholder"
        :value="gamForm.form.description"
      />

      <div class="form-row">
        <div class="label">{{ localize('clubs.add.form.clubType.label') }}</div>

        <div class="club-type-row">
          <GamChip
            v-for="(item, index) in clubTypeOptions"
            :key="`f-item-${index}`"
            ref="filterItem"
            :label="item.label"
            :is-active="gamForm.form.clubType.includes(item.value)"
            :is-upper-case="true"
            @click="() => changeClubType(item)"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="image">
          <div class="label">
            {{ localize('clubs.add.form.cover.label') }}
          </div>

          <gam-image
            v-model="gamForm.form.profileImage"
            :image="gamForm.form.profileImage"
            :add="true"
            :size="GamImageSize.MEDIUM"
            @expand="expandImage"
          />
        </div>
      </div>

      <gam-expand-image
        v-if="selectedImage"
        ref="imageExpand"
        v-bind="selectedImage"
        @close="clearSelected"
        @clear="deleteImage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AdminDetailClubDto } from '@/core/data/club/club.interface';
import { ClubType } from '@/core/data/club/club.type';
import { BlobSource } from '@/core/data/report/report.type';
import { localize } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import FormHelper from '@/core/helpers/form.helper';
import { useFormStore } from '@/stores/form.store';
import GamChip from '@/views/components/GamChip.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import type { ClubFormType } from '@/views/composables/models/components/GamClub';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { ClubForm, ClubValidator } from '@/views/composables/models/form.interface';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, reactive, ref } from 'vue';

const props = defineProps<ClubFormType>();

const formStore = useFormStore<ClubForm, ClubValidator>(props.formId)();
const { gamForm, usernameField } = storeToRefs(formStore);

const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const usernameValidator = FormHelper.usernameValidator;

const formFromProps = (properties: ClubFormType) => {
  return {
    name: properties.club?.name || '',
    username: properties.club?.username || '',
    clubType: properties.club?.clubType || ClubType.CASUAL,
    description: properties.club?.description || '',
    photos: properties.club?.image ? [properties.club?.image.imageBlobHash] : [],
    profileImage: properties.club?.profileImageBlobHash || null,
  };
};

const getForm = reactive(formFromProps(props));

const getRules = {
  name: { required },
  username: { required, usernameValidator },
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
});

const v$ = useVuelidate(getRules, getForm);

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  return v$.value.$invalid;
});

const changeClubType = (clubType: any) => {
  if (!clubType.value || !gamForm.value) {
    return;
  }

  gamForm.value.form.clubType = clubType.value;
};

const expandImage = (hash: string) => {
  selectedImage.value = {
    selectedIndex: 0,
    blobSource: BlobSource.LOCATION,
    images: [
      {
        id: hash,
        imageBlobHash: hash,
        liked: false,
      },
    ],
    canDelete: true,
    closeAfterDelete: true,
    noFilter: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};

const clearSelected = () => {
  selectedImage.value = null;
};

const deleteImage = (hash: string) => {
  if (!gamForm.value) return;
  GamHelper.removeItem(gamForm.value.form.photos, hash);
};

const checkUsername = debounce(() => {
  nextTick(() => {
    if (props.club?.username && props.club?.username === gamForm.value?.form.username) {
      formStore.setUsernameValidated(true);
      formStore.setUsernameError(null);
    } else {
      FormHelper.checkUsername(props.formId, gamForm.value?.form.username || '', v$);
    }
  });
}, 400);

const computedName = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamForm.value?.form.name,
    placeholder: 'clubs.add.form.name.placeholder',
  };
});

const username = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_USERNAME,
    value: gamForm.value?.form.username,
    placeholder: 'auth.page.registration.form.username.placeholder',
    isLoading: usernameField.value.isLoading,
    isError: usernameField.value.isValidated === false,
    message: usernameField.value.error,
    rightIcon: usernameField.value.isValidated ? GamIconName.CHECK : undefined,
    maxLength: 15,
    isPrimary: true,
    isLowerCase: true,
  };
});

const setFormValue = (value: Partial<AdminDetailClubDto>) => {
  const form = {
    ...(formStore.gamForm?.form ?? {}),
    ...value,
  } as ClubForm;
  formStore.setForm({ form, rules: getRules });
};

const clubTypeOptions = [
  { value: ClubType.CASUAL, label: 'clubs.general.clubType.casual' },
  { value: ClubType.PROFESSIONAL, label: 'clubs.general.clubType.professional' },
];

defineExpose({
  isInvalid,
  setFormValue,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.club-form {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--spacing-md);

  .form-row,
  .form-row .image {
    & > .label {
      margin-bottom: var(--spacing-l);
    }
  }

  .panel-header {
    display: flex;
    padding: var(--spacing-md) 0;
    align-items: center;
    gap: var(--spacing-md);
    align-self: stretch;

    .header-icon {
      display: flex;
      padding: var(--spacing-xs);
      justify-content: center;
      align-items: center;
    }

    .header-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .club-type-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .club-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--spacing-menu);
  }

  .section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-md);

    .section-title {
      padding: var(--spacing-l) 0;
      font-size: 16px;
      font-weight: 700;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-white-5);
        display: block;
        position: absolute;
        bottom: 0;
      }
    }

    &.additional-info {
      gap: var(--spacing-l);

      .photos-row-container {
        width: calc(100% + 36px);
        position: relative;
        left: -18px;

        &:after,
        &:before {
          content: '';
          height: 100%;
          width: 18px;
          position: absolute;
          top: 0;
          z-index: 10;
          pointer-events: none;
        }

        &:after {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--color-dark-700) 50%, var(--color-dark-700));
          right: 0;
        }

        &:before {
          background: linear-gradient(to left, rgba(0, 0, 0, 0) 0, var(--color-dark-700) 50%, var(--color-dark-700));
          left: 0;
        }

        .photos-row {
          display: flex;
          gap: var(--spacing-md);
          padding: 0 18px 10px;
        }
      }
    }
  }
}
</style>
