<template>
  <div class="location-wrapper location-create-fields">
    <GamInput v-bind="getNameInput" v-model="formModel.name" />

    <GamSimpleLocationSearch v-bind="getSimpleSearchInput" :key="formModel.name" />
  </div>
</template>

<script setup lang="ts">
import type { AdminDetailLocationDto, GeoLocation } from '@/core/data/location/location.interface';
import { MapLocationSource } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import type { Result } from '@/core/network/http/httpClient.interface';
import { useMapStore } from '@/stores/map.store';
import GamInput from '@/views/components/GamInput.vue';
import GamSimpleLocationSearch from '@/views/components/GamSimpleLocationSearch.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamInputType, GamSimpleInputSearchType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

export interface LocationCreateFormModel {
  name: string;
  address?: string;
  coordinates?: GeoLocation | undefined;
  pinpoint?: GeoLocation | undefined;
}

interface LocationCreateFieldProps {
  searchListId: GamListId;
  dropdownlistId: GamListId;
  setFormValue: (value: Partial<AdminDetailLocationDto>) => void;
  formModel: LocationCreateFormModel;
}

const props = defineProps<LocationCreateFieldProps>();

const mapStore = useMapStore();
const { currentLocation } = storeToRefs(mapStore);

const lastSelectedResult = ref<GamDropdownItem>();

const getNameInput = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: props.formModel.name,
    placeholder: 'locations.add.form.name.placeholder',
  };
});

const handleClearLocation = () => {
  props.setFormValue({
    name: undefined,
    address: undefined,
    coordinates: undefined,
    // @ts-ignore
    locationPoint: undefined,
    locationInputValue: undefined,
    pinpoint: undefined,
  });
};

const getSimpleSearchInput = computed((): GamSimpleInputSearchType<GamDropdownItem> => {
  return {
    id: props.searchListId,
    onClickClear: handleClearLocation,
    input: {
      canClear: true,
      name: GamInputName.LOCATION,
      placeholder: 'locations.add.form.search.choose',
      leftIcon: GamIconName.LOCATION_EMPTY,
      value: props.formModel.address,
    },
    dropdown: {
      id: props.dropdownlistId,
    },
    loadCallback: async (query: string): Promise<Result<GamDropdownItem[]>> => {
      const result = await gambits.locationService.searchLocationsByQuery(query, {
        geo: currentLocation.value,
        mapbox: true,
      });

      return {
        ...result,
        data: LocationHelper.mapSearchLocations(result.data),
      };
    },
    selectCallback: (locationData: GamDropdownItem | null) => {
      if (!locationData) {
        return;
      }

      if (locationData.source === MapLocationSource.INTERNAL) {
      } else if (
        locationData.source === MapLocationSource.EXTERNAL ||
        locationData.source === MapLocationSource.GOOGLE
      ) {
        lastSelectedResult.value = locationData;

        props.formModel.name = locationData.label;

        if (locationData.id.indexOf('poi') > -1) {
          props.formModel.address = `${locationData.subLabel}`;
        } else {
          props.formModel.address = `${locationData.label}, ${locationData.subLabel}`;
        }

        const coordinates = {
          lat: parseFloat(String(locationData.coordinates.lat)),
          lng: parseFloat(String(locationData.coordinates.lng)),
        };

        if (
          typeof coordinates.lat !== 'number' ||
          Number.isNaN(coordinates.lat) ||
          typeof coordinates.lng !== 'number' ||
          Number.isNaN(coordinates.lng)
        ) {
          // eslint-disable-next-line no-console
          console.error('Could not determine location coordinates from SimpleLocationSearch.selectCallback');
          return;
        }

        props.formModel.coordinates = coordinates;
        props.formModel.pinpoint = coordinates;

        // TODO: Share the map instance link through the mapStore.
        // @ts-ignore
        window.gMap?.map?.panTo(coordinates);
      }
    },
  };
});
</script>

<style lang="scss" scoped>
.location-create-fields {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--spacing-l);
}
</style>
