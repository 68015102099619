<template>
  <div class="gam-rating-unknown">
    <div class="message-wrapper">
      {{ localize('app.component.ratings.item.unknown.description') }}
    </div>
    <gam-slider v-bind="getRatingSlider" ref="ratingSlider" />
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { useRatingStore } from '@/stores/rating.store';
import GamSlider from '@/views/components/GamSlider.vue';
import type { GamSliderType } from '@/views/composables/models/components/GamSlider';
import { computed, ref, watch } from 'vue';

const ratingStore = useRatingStore();
const ratingSlider = ref();
const getRatingSlider = computed((): GamSliderType => {
  return {
    min: {
      value: 0,
    },
    max: {
      value: 10,
    },
    showRangeScale: true,
  };
});

const sliderValues = computed(() => {
  return ratingSlider.value?.sliderValues;
});

watch(sliderValues, (value: number) => {
  ratingStore.setByUnknownFactor(value);
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-rating-unknown {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);

  .message-wrapper {
    font-size: 14px;
    line-height: 110%;
    font-weight: 400;
    color: var(--color-white-70);
    padding: var(--spacing-md) 0;
  }
}
</style>
