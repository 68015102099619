<template>
  <div class="my-profile" :class="{ 'is-mobile': isMobile }">
    <div class="my-profile-title">
      <span v-if="userDetails?.username" class="title">@{{ userDetails?.username }}</span>
      <span v-else class="title">Loading...</span>
    </div>

    <my-profile-sections />
  </div>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import MyProfileSections from '@/views/main/profile/my-profile/MyProfileSections.vue';
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';

const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

onBeforeMount(async () => {
  if (userDetails) return;

  await authStore.fetchUserInfo();
});
</script>

<style scoped lang="scss">
.my-profile {
  display: flex;
  padding: 0 var(--spacing-xxl);
  gap: var(--spacing-menu);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  &.is-mobile {
    padding: 0;
  }

  .my-profile-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
