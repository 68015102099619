<template>
  <div :class="['missing-info-container', size]">
    <div v-if="loadIcon" class="missing-loader">
      <gam-loader v-bind="getLoader"></gam-loader>
    </div>
    <div class="missing-info">
      <div class="missing-info-title">
        {{ localize(title) }}
      </div>
      <div v-if="message" class="missing-info-message" v-html="localize(message)"></div>
    </div>
    <div v-if="actionPrimary || actionSecondary" class="missing-action-buttons">
      <gam-button v-if="actionPrimary" v-bind="getActionPrimary" @click="actionPrimary?.onClick"></gam-button>
      <gam-button v-if="actionSecondary" v-bind="getActionSecondary" @click="actionSecondary?.onClick"></gam-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import GamButton from '@/views/components/GamButton.vue';
import GamLoader from '@/views/components/GamLoader.vue';
import { GamButtonSize } from '@/views/composables/constants/components/gamButton.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamLoaderColor, GamLoaderSize } from '@/views/composables/constants/components/gamLoader.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamErrorInfoType } from '@/views/composables/models/components/GamErrorInfo';
import type { GamLoaderType } from '@/views/composables/models/components/GamLoader';
import { computed } from 'vue';

const props = defineProps<GamErrorInfoType>();

const getButtonSize = (): GamButtonSize => {
  return props.size === GamErrorInfoSize.SMALL ? GamButtonSize.SMALL : GamButtonSize.LARGE;
};

const parseLoaderSize = (): GamLoaderSize => {
  switch (props.size) {
    case GamErrorInfoSize.LARGE:
      return GamLoaderSize.LARGE;
    case GamErrorInfoSize.MEDIUM:
      return GamLoaderSize.MEDIUM;
    default:
      return GamLoaderSize.SMALL;
  }
};

const getLoader = computed((): GamLoaderType => {
  return {
    color: GamLoaderColor.GRADIENT,
    size: parseLoaderSize(),
    spinIcon: false,
    icon: props.loadIcon,
  };
});

const getActionPrimary = computed((): GamButtonType => {
  return {
    ...props.actionPrimary,
    size: getButtonSize(),
  };
});

const getActionSecondary = computed((): GamButtonType => {
  return {
    ...props.actionSecondary,
    size: getButtonSize(),
  };
});
</script>

<style scoped lang="scss">
.missing-info-container {
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--spacing-l) 0;
  flex-grow: 1;
  margin-bottom: var(--spacing-xxl);

  &.small {
    gap: var(--spacing-md);

    .missing-info {
      padding: var(--spacing-md) 0;
      gap: var(--spacing-md);

      .missing-info-title {
        font-size: 18px;
        font-weight: 700;
        padding: 1px 0;
      }

      .missing-info-message {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .missing-action-buttons {
      padding: var(--spacing-md) 0;
    }
  }

  &.medium {
    gap: var(--spacing-l);

    .missing-info {
      padding: var(--spacing-l) 0;
      gap: var(--spacing-l);

      .missing-info-title {
        font-size: 24px;
        font-weight: 800;
      }

      .missing-info-message {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  &.large {
    gap: var(--spacing-menu);

    .missing-info {
      padding: var(--spacing-menu) 0;
      gap: var(--spacing-menu);

      .missing-info-title {
        font-size: 32px;
        font-weight: 800;
      }

      .missing-info-message {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .missing-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .missing-info-title {
      font-size: 14px;
      font-weight: 700;
    }

    .missing-info-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      width: 16em;
      color: var(--color-white-30);
    }
  }

  .missing-action-buttons {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-l) 0;
    gap: 12px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
}
</style>
