<template>
  <div ref="playerCard" :class="['player-detail-header', { titled: isTitled(), 'is-mobile': isMobile }]">
    <GamHighlight :class="['highlight', { 'is-mobile': isMobile }]" />

    <GamPlayerProfileImage class="image" :image-blob="props.player.profileImageBlobHash" @expand="expandImage" />
    <gam-expand-image v-if="selectedImage" ref="imageExpand" v-bind="selectedImage" @close="clearSelected" />

    <img class="checker-terrain" src="/images/checker-terrain.jpg" />

    <div class="names-titles">
      <div class="username">
        <span class="pill">@{{ player.username }}</span>

        <span v-if="player.emoji" class="emoji">
          {{ player.emoji }}
        </span>

        <gam-icon v-if="player.featured" class="featured" :name="GamIconSpecialName.BADGE_MATCH" size="14px" />
      </div>

      <div class="name-roles">
        <div class="name">{{ getName }}</div>

        <div class="role">
          <span v-if="player.title" class="titled">
            {{ localize(PlayerHelper.getPlayerTitleLocale(player.title, false)) }}
          </span>

          <span v-if="getPlayerTypes">
            <span v-for="type in getPlayerTypes" :key="type" :class="type">
              {{ localize(PlayerHelper.getPlayerTypeLocale(type)) }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="ratings-actions">
      <div v-if="player.ratings?.length" class="ratings">
        <gam-chip v-for="(rating, index) in getRatingList" v-bind="getRatingChip(rating)" :key="index" />
      </div>

      <div v-if="user?.id !== player.id" class="follow">
        <GamButton v-bind="getFollowButtonProps" />
      </div>

      <div v-else class="edit">
        <GamButton v-bind="getEditButtonProps" />
      </div>
    </div>

    <div class="meta">
      <div v-if="player.birthDate" class="age">{{ getHumanizedAge(player) }}</div>
      <div v-if="player.distance" class="age">{{ getHumanizedDistance(player) }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { RatingType } from '@/core/data/rating/rating.type';
import { BlobSource } from '@/core/data/report/report.type';
import type { PlayerRatingsDto } from '@/core/data/user/user.interface';
import { PlayerType } from '@/core/data/user/user.type';
import { gambits, localize } from '@/core/gambits';
import PlayerHelper from '@/core/helpers/player.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import GamChip from '@/views/components/GamChip.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSpecialName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamPlayerCardSize } from '@/views/composables/constants/components/gamPlayer.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamPlayerCardType } from '@/views/composables/models/components/GamPlayer';
import { storeToRefs } from 'pinia';
import { nextTick } from 'process';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import GamButton from '../GamButton.vue';
import GamHighlight from '../GamHighlight.vue';
import GamPlayerProfileImage from './GamPlayerProfileImage.vue';
import { getHumanizedAge, getHumanizedDistance } from './helpers';

const props = withDefaults(defineProps<GamPlayerCardType>(), {
  size: GamPlayerCardSize.LARGE,
});

const router = useRouter();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const playerCard = ref<HTMLDivElement>();
const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const getRatingChip = (rating?: PlayerRatingsDto): GamChipType => {
  return {
    label: rating?.rating?.toString() || '(i) 1234',
    leftIcon: rating?.type ? PlayerHelper.getPlayerRatingIcon(rating.type) : undefined,
    // size: GamChipSize.NORMAL,
  };
};

const getName = computed(() => {
  const { firstName, lastName } = props.player;

  const name = `${firstName} ${lastName}`;

  return name.replace(/\b\w/g, (c) => c.toUpperCase());
});

const isTitled = (): boolean => {
  return !!props.player.title;
};

const isFollowed = computed(() => {
  // @ts-ignore
  return props.player.isFollowed;
});

// const listId = GamListId.PLAYERS;
// const tableStore = useTableStore<BaseUserDto>(listId)();

const followUser = () => {
  if (!props.player) return;

  gambits.userService.toggleFollow(props.player.id, false);

  // @ts-ignore
  props.player.isFollowed = true;
  // @ts-ignore
  props.player.followers += 1;

  // tableStore.updateItems((el) => {
  //   if (el.id !== props.player.id) return el;

  //   return { ...el, followers: el.followers + 1 };
  // });
};

const unfollowUser = () => {
  if (!props.player) return;

  gambits.userService.toggleFollow(props.player.id, true);

  // @ts-ignore
  props.player.isFollowed = false;
  // @ts-ignore
  props.player.followers -= 1;
};

const getFollowButtonProps = computed(
  (): GamButtonType => ({
    size: GamButtonSize.X_SMALL,
    label: isFollowed.value ? 'Following' : 'Follow',
    variant: isFollowed.value ? GamButtonVariant.SECONDARY : GamButtonVariant.PRIMARY,
    leftIcon: isFollowed.value ? GamIconName.HEART : GamIconName.HEART_EMPTY,
    onClick: () => (isFollowed.value ? unfollowUser() : followUser()),
  }),
);

const getEditButtonProps = computed(
  (): GamButtonType => ({
    size: GamButtonSize.X_SMALL,
    label: 'Edit',
    variant: GamButtonVariant.SECONDARY,
    leftIcon: GamIconName.EDIT,
    onClick: () => {
      router.push({ name: RouterNameType.PROFILE, query: { side_panel: 'general' } });
    },
  }),
);

const getPlayerTypes = computed((): PlayerType[] | undefined => {
  return props.player.playerTypes?.filter((type) => type !== PlayerType.GAMBIT_MASTER);
});

const getRatingList = computed((): PlayerRatingsDto[] => {
  const ratings = props.player.ratings?.filter((r) => r.rating);
  if (!ratings) return [];
  switch (props.size) {
    case GamPlayerCardSize.NORMAL:
      return ratings.slice(0, 2);
    case GamPlayerCardSize.LARGE:
      return isMobile.value ? ratings.slice(0, 3) : ratings;
    default:
      return [ratings.find((r) => r.type === RatingType.GAMBIT) || ratings[0]];
  }
});

const clearSelected = () => {
  selectedImage.value = null;
};

const expandImage = (blob: string) => {
  // TODO: Need the Image object to be able to send to expand for actions
  selectedImage.value = {
    blobSource: BlobSource.USER_PROFILE,
    selectedIndex: 0,
    images: [
      {
        id: '',
        imageBlobHash: blob,
        liked: false,
      },
    ],
    noFilter: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.player-detail-header {
  padding: var(--spacing-l) var(--spacing-l);
  padding-top: calc(var(--spacing-xxl) + var(--spacing-xl));
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--spacing-l);

  border-radius: var(--radius-small);
  background-color: var(--color-dark-500);
  background: radial-gradient(70.67% 100% at 0% 0%, rgba(255, 109, 162, 0.1) 0%, rgba(255, 97, 205, 0) 100%),
    var(--color-dark-500);

  position: relative;
}

.checker-terrain {
  position: absolute;
  border-radius: var(--radius-small);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: 0.33;
  object-fit: cover;
  z-index: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--color-dark-600);
  border-radius: 100%;
  z-index: 2;
}

.player-detail-header.is-mobile .image {
  width: 96px;
  height: 96px;
}

.player-detail-header:not(.is-mobile) .image {
  width: 128px;
  height: 128px;
}

.names-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-md);
  position: relative;
  z-index: 3;
}

.username {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  font-size: 16px;
  font-weight: bold;
  color: var(--color-pink);
  letter-spacing: 0.5px;

  .pill {
    background-color: rgba(0, 0, 0, 0.33);
    padding: var(--spacing-xs) var(--spacing-md);
    border-radius: var(--radius-small);
    margin-left: -0.25rem;
  }
}

.name {
  font-size: 32px;
  font-weight: 700;
  display: flex;
  gap: 0.33em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  overflow: hidden;
  line-height: 1.25;
}

.meta {
  position: absolute;
  top: var(--spacing-l);
  right: calc(var(--spacing-l) + var(--spacing-xs));
  color: var(--color-white-50);
  opacity: 0.5;
}

.ratings-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  z-index: 4;
}

.ratings {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

.follow,
.edit {
  width: 7.5rem;
  flex-shrink: 0;
  align-self: flex-end;

  .gam-button {
    width: 100%;
  }
}

.edit {
  width: 6rem;
}
</style>
