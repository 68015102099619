import { AuthRepository } from '@/core/data/auth/auth.repository';
import { JwtRepository } from '@/core/data/auth/jwt.repository';
import { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import { UIConfigRepository } from '@/core/data/config/uiConfig.repository';
import { FilterRepository } from '@/core/data/filter/filter.repository';
import { GambitRepository } from '@/core/data/gambit/gambit.repository';
import { ImageRepository } from '@/core/data/image/image.repository';
import { LocalizationRepository } from '@/core/data/localization/localization.repository';
import type { ObjectType } from '@/core/data/localization/localization.type';
import { LocationRepository } from '@/core/data/location/location.repository';
import { RatingRepository } from '@/core/data/rating/rating.repository';
import { ReportRepository } from '@/core/data/report/report.repository';
import { StorageType } from '@/core/data/storage/storage.interface';
import { StorageRepository } from '@/core/data/storage/storage.repository';
import { UserRepository } from '@/core/data/user/user.repository';
import { AuthService } from '@/core/domain/auth/auth.service';
import { JwtService } from '@/core/domain/auth/jwt.service';
import { ConfigService } from '@/core/domain/config.service';
import { FilterService } from '@/core/domain/filter.service';
import { GambitService } from '@/core/domain/gambit/gambit.service';
import { HomeViewService } from '@/core/domain/homeView.service';
import { ImageService } from '@/core/domain/image.service';
import { LocalizationService } from '@/core/domain/localization.service';
import { GeolocationService } from '@/core/domain/location/geolocation.service';
import { LocationService } from '@/core/domain/location/location.service';
import { RatingService } from '@/core/domain/rating/rating.service';
import { ReportService } from '@/core/domain/report.service';
import { StaticService } from '@/core/domain/static/static.service';
import { UserService } from '@/core/domain/user/user.service';
import { AuthApi } from '@/core/network/api/auth.api';
import { FiltersApi } from '@/core/network/api/filters.api';
import { GambitApi } from '@/core/network/api/gambit/gambit.api';
import { ImageApi } from '@/core/network/api/image.api';
import { LocationApi } from '@/core/network/api/location/location.api';
import { RatingApi } from '@/core/network/api/rating/rating.api';
import { ReportApi } from '@/core/network/api/report.api';
import { UserApi } from '@/core/network/api/user/user.api';
import { HttpClient } from '@/core/network/http/httpClient';
import type { Head, UseSeoMetaInput } from '@unhead/vue';
import { ClubRepository } from './data/club/club.repository';
import { AdminService } from './domain/admin.service';
import { ClubService } from './domain/club/club.service';
import { AdminApi } from './network/api/admin.api';
import { ClubApi } from './network/api/club/club.api';

import { GeolocationRepository } from './data/location/geolocation.repository';
import data from './gambits.json';

class Gambits {
  private readonly httpClient: HttpClient;
  public readonly configService: ConfigService;
  public readonly staticService: StaticService;
  public readonly localizationService: LocalizationService;
  public readonly authService: AuthService;
  public readonly adminService: AdminService;

  public readonly homeViewService: HomeViewService;

  public readonly userService: UserService;
  public readonly locationService: LocationService;
  public readonly gambitService: GambitService;
  public readonly filterService: FilterService;
  public readonly imageService: ImageService;
  public readonly ratingService: RatingService;
  public readonly geolocationService: GeolocationService;
  public readonly reportService: ReportService;
  public readonly clubService: ClubService;

  constructor() {
    // Local config repository
    const clientConfigRepository = new ClientConfigRepository();
    const spices = clientConfigRepository.parseServiceSpices();

    const localStorage = new StorageRepository(window.localStorage, StorageType.Local, spices);
    const sessionStorage = new StorageRepository(window.sessionStorage, StorageType.Session, spices);

    // Config service
    const uiConfigRepository = new UIConfigRepository(localStorage);
    this.configService = new ConfigService(clientConfigRepository, uiConfigRepository);

    // Create an instance of HttpClient object
    const jwtRepository = new JwtRepository(localStorage);
    const jwtService = new JwtService(jwtRepository, this.configService.getServiceRoutingHost());
    this.httpClient = new HttpClient(jwtService, this.configService.getServiceRoutingHost());

    // Admin service
    const adminApi = new AdminApi(this.httpClient);
    this.adminService = new AdminService(adminApi);

    // Localization service
    const localizationRepository = new LocalizationRepository();
    this.localizationService = new LocalizationService(localizationRepository, this.configService);

    // User service
    const userApi = new UserApi(this.httpClient);
    const userRepository = new UserRepository(userApi, sessionStorage);
    this.userService = new UserService(userRepository);

    // Auth service
    const authApi = new AuthApi(this.httpClient);
    const authRepository = new AuthRepository(jwtRepository, userRepository, authApi, localStorage, sessionStorage);
    this.authService = new AuthService(authRepository, this.configService);

    this.staticService = new StaticService();

    // HomeView service
    const uiConfig = this.configService.getUIConfig();
    this.homeViewService = new HomeViewService(uiConfig);

    // Location service
    const locationApi = new LocationApi(this.httpClient);
    const locationRepository = new LocationRepository(
      locationApi,
      localStorage,
      clientConfigRepository,
      this.localizationService,
    );
    this.locationService = new LocationService(locationRepository);

    // Club service
    const clubApi = new ClubApi(this.httpClient);
    const clubRepository = new ClubRepository(clubApi);
    this.clubService = new ClubService(clubRepository);

    // Gambit service
    const gambitApi = new GambitApi(this.httpClient);
    const gambitRepository = new GambitRepository(gambitApi);
    this.gambitService = new GambitService(gambitRepository);

    const filterApi = new FiltersApi(this.httpClient);
    const filterRepository = new FilterRepository(filterApi, sessionStorage);
    this.filterService = new FilterService(filterRepository);

    const imageApi = new ImageApi(this.httpClient);
    const imageRepository = new ImageRepository(imageApi);
    this.imageService = new ImageService(imageRepository, this.configService);

    const ratingApi = new RatingApi(this.httpClient);
    const ratingRepository = new RatingRepository(ratingApi, sessionStorage);
    this.ratingService = new RatingService(ratingRepository);

    const reportApi = new ReportApi(this.httpClient);
    const reportRepository = new ReportRepository(reportApi);
    this.reportService = new ReportService(reportRepository);

    const geolocationRepository = new GeolocationRepository(locationApi, localStorage, clientConfigRepository);
    this.geolocationService = new GeolocationService(geolocationRepository);
  }

  getPageMeta(): Head {
    return {
      meta: [
        {
          name: 'keywords',
          content: data.keywords,
        },
      ],
    };
  }

  useSeoMeta(): UseSeoMetaInput {
    return {
      title: data.title,
      description: data.description,
      ogType: 'website',
      ogTitle: data.title,
      ogDescription: data.description,
      ogImage: { url: '/images/og-gambit.png', type: 'image/png' },
    };
  }
}

const gambits = new Gambits();

const localize = (translationKey: string): string => {
  const translation = gambits.localizationService.localize(translationKey);

  if (translation === null) return '';
  if (translation === undefined) return translationKey;

  return translation;
};

const localizeWithValues = (translationKey: string, values: unknown[]): string => {
  return gambits.localizationService.localizeWithValues(translationKey, values) || translationKey;
};

const localizeType = (type: ObjectType, translationKey: string): string => {
  return gambits.localizationService.localizeType(type, translationKey) || translationKey || '';
};

export { gambits, localize, localizeType, localizeWithValues };
