<template>
  <div class="auth-ratings">
    <div class="auth-content">
      <div class="auth-context left">
        <span class="auth-title">
          {{ localize('auth.page.ratings.title') }}
        </span>
      </div>
      <form class="auth-content-wrapper" @submit="handleSubmit">
        <div class="slot-content">
          <gam-ratings :is-auth="true" />
        </div>
        <input type="submit" class="hidden" />
      </form>
      <div class="auth-methods-group">
        <div class="methods-row">
          <gam-button v-bind="getContinueButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useRatingStore } from '@/stores/rating.store';
import GamButton from '@/views/components/GamButton.vue';
import GamRatings from '@/views/components/gam-ratings/GamRatings.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

const ratingStore = useRatingStore();
const authStore = useAuthStore();
// const { userDetails } = storeToRefs(authStore);
const { ratings } = storeToRefs(ratingStore);
const isSubmitting = ref<boolean>(false);
const router = useRouter();

onBeforeMount(async () => {
  await authStore.fetchUserInfo(true);
});

async function handleSubmit(event: Event) {
  event.preventDefault();

  if (isSubmitting.value) return;

  isSubmitting.value = true;

  const result = await gambits.authService.submitRatings(ratings.value);

  if (result.data) {
    await router.push({ name: RouterAuthType.AUTH_PROFILE_IMAGES });
    // await authStore.authorizeUser(result.data, true);
  }

  isSubmitting.value = false;
}

const getContinueButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    label: 'auth.page.ratings.continue.button.label',
    isFullSize: true,
    center: true,
    isDisabled: !ratingStore.isRatingAdded() || isSubmitting.value,
    onClick: handleSubmit,
  };
});
</script>

<style scoped lang="scss">
.auth-ratings {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing-menu);

  .auth-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .slot-content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
