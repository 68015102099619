<template>
  <div ref="gamDropdown" :class="['gam-dropdown', getClasses]">
    <div class="gam-dropdown-container" :style="getDropdownStyle">
      <gam-intersect v-bind="getDropdownIntersect" ref="intersect" @infinite="tableStore.infiniteLoad()">
        <div v-if="numOfItems === 0 && !isLoading" class="no-data-info">
          <div class="message">
            <div class="message-icon">
              <gam-icon :name="GamIconName.SEARCH" />
            </div>
            <div class="message-text" v-html="getNoDataText"></div>
          </div>
        </div>
        <div v-else-if="isPlayerDropdown()" class="gam-dropdown-content" :style="getDropdownContentStyle">
          <gam-player-card
            v-for="(player, index) in dropdownItems"
            v-bind="getPlayerCard(player)"
            ref="itemRef"
            :key="`${index}-${player.id}`"
            v-touch:tap="setSelectedItem(index)"
            :selected="selectedItem?.id === player.id"
            @click="setSelectedItem(index)"
          />
        </div>
        <div v-else class="gam-dropdown-content" :style="getDropdownContentStyle">
          <gam-button v-if="defaultItem" v-bind="getButtonItem(defaultItem, -1)" :class="getDefaultClass" />
          <gam-button
            v-for="(item, index) in dropdownItems"
            v-bind="getButtonItem(item, index)"
            ref="itemRef"
            :key="`${index}-${item.id}`"
          />
        </div>
      </gam-intersect>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { localizeWithValues } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useTableStore } from '@/stores/table.store';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamIntersect from '@/views/components/GamIntersect.vue';
import GamPlayerCard from '@/views/components/gam-player/GamPlayerCard.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamDropdownCategory, GamDropdownSize } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamPlayerCardSize } from '@/views/composables/constants/components/gamPlayer.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamDropdownItem, GamDropdownType } from '@/views/composables/models/components/GamDropdown';
import type { GamIntersectType } from '@/views/composables/models/components/GamIntersect';
import type { GamPlayerCardExpose, GamPlayerCardType } from '@/views/composables/models/components/GamPlayer';
import { whenever } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, type StyleValue } from 'vue';
import type { GamListId } from '../composables/constants/components/gamIntersect.constants';

const props = withDefaults(defineProps<GamDropdownType>(), {
  maxItems: 4,
  itemSpacing: 8,
});
const emits = defineEmits([GamComponentsEmits.UPDATE]);

const tableStore = useTableStore<any>(props.id as GamListId)();
const { itemList, tableParams, selectedItem, isLoading } = storeToRefs(tableStore);
const dropdownItems = ref<any[] | null>(null);

const gamDropdown = ref<HTMLDivElement>();
const intersect = ref();
const itemRef = ref<GamPlayerCardExpose[]>();
const numOfItems = ref<number | null>(null);

onMounted(() => {
  dropdownItems.value = itemList.value;
  tableStore.setLoaded();
});

const getStaticHeight = (): number => {
  const staticHeight = props.size === GamDropdownSize.SMALL ? 34 : 52;
  return props.category === GamDropdownCategory.PLAYERS ? 47 : staticHeight;
};

const getNumOfItems = (): number => {
  if (tableParams.value.search?.length) {
    if (dropdownItems.value?.length && props.defaultItem) {
      return dropdownItems.value.length + 1;
    }
    return dropdownItems.value?.length || 0;
  } else if (props.defaultItem) {
    return (dropdownItems.value?.length || 0) + 1;
  } else if (!isLocationDropdown()) {
    return dropdownItems.value?.length || 0;
  }
  return 0;
};

const getDropdownMaxHeight = (): number => {
  if (getNumOfItems() === 0) return 0;
  const itemHeight: number = itemRef.value?.[0]?.getHeight || getStaticHeight();
  const spacingHeight = props.itemSpacing * (props.maxItems - 1);

  return itemHeight * props.maxItems + spacingHeight + 18;
};
const getDropdownHeight = (): number => {
  if (getNumOfItems() === 0) return 0;
  const itemHeight: number = itemRef.value?.[0]?.getHeight || getStaticHeight();
  const maxItems = getNumOfItems() < props.maxItems ? getNumOfItems() : props.maxItems;
  const spacingHeight = props.itemSpacing * (maxItems - 1);

  return itemHeight * maxItems + spacingHeight + 18;
};

const getPlayerCard = (player: BaseUserDto): GamPlayerCardType => {
  return {
    player,
    size: GamPlayerCardSize.SMALL,
  };
};

const getButtonItem = (item: GamDropdownItem, index: number): GamButtonType => {
  return {
    label: item.label,
    subLabel: item.subLabel,
    leftIcon: item.icon,
    size: props.size === GamDropdownSize.SMALL ? GamButtonSize.ACTIONS : GamButtonSize.LARGE,
    variant: GamButtonVariant.TERTIARY,
    isIconWhite: !item.chessLocation, // TODO: map to relevant data
    isFullSize: item.isFullSize,
    flagIcon: item.flagIcon,
    onClick: () => {
      if (props.defaultItem && index < 0) {
        setMyLocation();
      } else {
        setSelectedItem(index);
      }
    },
  };
};

const isPlayerDropdown = (): boolean => {
  return props.category === GamDropdownCategory.PLAYERS;
};

const isLocationDropdown = (): boolean => {
  return props.category === GamDropdownCategory.LOCATIONS;
};

const setMyLocation = () => {
  const myLocation = props.defaultItem;
  tableStore.setSelectedItem(myLocation, -1);
};

const setSelectedItem = (index: number) => {
  tableStore.selectItem(index);
  emits(GamComponentsEmits.UPDATE, true);
};

const setNumItems = (): number | null => {
  if (!isLocationDropdown()) {
    return dropdownItems.value?.length || 0;
  } else {
    return getNumOfItems();
  }
};

const getDropdownIntersect = computed((): GamIntersectType => {
  return {
    id: props.id as GamListId,
    showMore: !!tableParams.value.afterCursor,
    noData: false,
    dropdown: true,
    widthSpacing: 8,
  };
});

const getDropdownStyle = computed((): StyleValue => {
  const height = getDropdownHeight();
  const maxHeight = getDropdownMaxHeight();

  return {
    height: height ? `${height}px` : 'auto',
    maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
  };
});

const getDropdownContentStyle = computed((): StyleValue => {
  return {
    gap: !isLocationDropdown() ? `${props.itemSpacing}px` : '0',
    padding: isMobile.value ? '0 10px 0 0' : '0 10px 0 8px',
  };
});

const getClasses = computed(() => {
  return {
    'no-data': numOfItems.value === 0,
    opened: dropdownItems.value,
    'is-mobile': isMobile.value,
    default: props.category === GamDropdownCategory.DEFAULT,
    reversed: props.reversed,
  };
});

const getDefaultClass = computed(() => {
  return {
    default: getNumOfItems() > 1,
  };
});

const getNoDataText = computed((): string => {
  if (!tableParams.value.search) return '';
  return localizeWithValues('app.component.search.missing', [tableParams.value.search]);
});

whenever(itemList, () => {
  dropdownItems.value = itemList.value;
  numOfItems.value = setNumItems();
});

onUnmounted(() => {
  if (!tableParams.value.search?.length && props.category === GamDropdownCategory.PLAYERS) {
    tableStore.resetList();
  }
});
</script>

<style scoped lang="scss">
.gam-dropdown {
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 56px;
  z-index: 800;
  overflow: hidden;
  pointer-events: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: var(--radius-small);
  border: 1px solid var(--color-white-10);

  &.opened {
    pointer-events: auto;
  }

  &.reversed {
    bottom: 56px;
    top: auto;
  }

  &.no-data {
    background-color: var(--gambit-white-white-10, rgba(255, 255, 255, 0.1));
  }

  &.default {
    .gam-dropdown-container {
      min-height: auto;
    }
  }

  .gam-dropdown-container {
    width: 100%;
    height: auto;
    min-height: 70px;
    background-color: var(--color-dark-30);
    // padding: var(--spacing-md) 0;

    .gam-dropdown-content {
      height: 100%;
      flex-direction: column;
      align-items: center;
      display: flex;
      min-width: 0;
      overflow: hidden;

      .default {
        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-white-10);
          position: absolute;
          bottom: 0;
        }
      }
    }

    .no-data-info {
      padding: var(--spacing-md);
      display: flex;
      align-items: center;
      flex-direction: column;

      .message {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--spacing-l);
        padding: var(--spacing-l);
        align-self: stretch;

        .message-text {
          font-size: 16px;
          font-weight: 300;
          flex: 1 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
        }
      }
    }
  }
}
</style>
