<template>
  <dialog ref="dialog" class="gam-notification" @close="hideDialog">
    <transition name="fade">
      <div
        v-if="visible"
        ref="expandImage"
        class="gam-expanded-frame"
        :class="{ drawer: drawerOpened, mobile: isMobile }"
        @dragover="stopEvent"
      >
        <div :class="['gam-expand-image', format]">
          <div
            v-on-click-outside="closeModal"
            class="image-wrapper"
            :class="{ skeleton: imgLoading, 'no-filter': noFilter }"
          >
            <img loading="lazy" :src="imageSource" alt="image" @load="loaded" @loadstart="loadingImage" />

            <div class="image-console">
              <div v-if="images.length > 1" class="image-selector">
                <span
                  v-for="(image, index) in images"
                  :key="image.id"
                  class="dot-select"
                  :class="{ selected: index === selectedImageIndex }"
                  @click="selectImage(index)"
                />
              </div>
              <div class="more-wrapper">
                <gam-more v-bind="getMoreItems" />
              </div>
            </div>

            <div class="image-info">
              <gam-button v-bind="likedInfo" />

              <gam-player-card
                v-if="images[selectedImageIndex].user"
                :size="GamPlayerCardSize.X_SMALL"
                :auto-size="true"
                :player="images[selectedImageIndex].user!"
              />
            </div>

            <div v-if="close" class="close-button">
              <gam-button v-bind="getCloseButton" />
            </div>

            <div v-if="images[selectedImageIndex].createdAt" class="time-ago">
              <gam-chip :left-icon="GamIconName.TIME" :label="timeAgo" />
            </div>
          </div>
        </div>

        <gam-dialog v-if="canReport" ref="reportDialog">
          <reporting-dialog
            :id="images[selectedImageIndex].imageBlobHash"
            :type="ReportType.BLOB"
            :blob-source="blobSource"
            @close="reportDialog?.close()"
          />
        </gam-dialog>
      </div>
    </transition>
  </dialog>
</template>

<script lang="ts" setup>
import { ReportType } from '@/core/data/report/report.type';
import { gambits } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import ReportingDialog from '@/views/components/gam-dialog/dialog-contents/ReportingDialog.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import GamMore from '@/views/components/gam-nav/gam-more/GamMore.vue';
import GamPlayerCard from '@/views/components/gam-player/GamPlayerCard.vue';
import GamButton from '@/views/components/GamButton.vue';
import GamChip from '@/views/components/GamChip.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamImageFormat } from '@/views/composables/constants/components/gamImage.constants';
import { GamMorePosition } from '@/views/composables/constants/components/gamMore.constants';
import { GamPlayerCardSize } from '@/views/composables/constants/components/gamPlayer.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamMoreType } from '@/views/composables/models/components/GamMore';
import { vOnClickOutside } from '@vueuse/components';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

const props = withDefaults(defineProps<GamExpandImageType>(), {
  format: GamImageFormat.VERTICAL,
  close: true,
  showAuthor: false,
  closeAfterDelete: true,
});
const emits = defineEmits([
  GamComponentsEmits.CLOSE,
  GamComponentsEmits.CLEAR,
  GamComponentsEmits.UPDATE,
  GamComponentsEmits.DELETE,
  GamComponentsEmits.UPDATE_VALUE,
]);
const dialog = ref<HTMLDialogElement>();
const visible = ref<boolean>(false);
const appStore = useAppStore();
const { drawerOpened } = storeToRefs(appStore);
const expandImage = ref();
const reportDialog = ref<InstanceType<typeof GamDialog>>();
const imgLoading = ref<boolean>(true);
const timeAgo = ref<string>();
const selectedImageIndex = ref<number>(props.selectedIndex || 0);
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

onMounted(() => {
  const createdDate = props.images[selectedImageIndex.value].createdAt;

  if (createdDate) {
    timeAgo.value = GamHelper.getLocaleTimeAgo(new Date(createdDate));
  }
});

const getCloseButton = computed((): GamButtonType => {
  return {
    size: GamButtonSize.ACTIONS,
    variant: GamButtonVariant.PRIMARY,
    isIconOnly: true,
    leftIcon: GamIconName.X_CLOSE,
    onClick: () => {
      closeModal();
    },
  };
});

const likedInfo = computed((): GamButtonType => {
  const isLiked = props.images[selectedImageIndex.value].liked;

  return {
    variant: isLiked ? GamButtonVariant.PRIMARY : GamButtonVariant.SLAB,
    size: GamButtonSize.ACTIONS,
    label: props.images[selectedImageIndex.value].numberOfLikes?.toString() || '0',
    leftIcon: isLiked ? GamIconName.HEART : GamIconName.HEART_EMPTY,
    isIconOnly: !isLiked,
    onClick: () => {
      emits(GamComponentsEmits.UPDATE, props.selectedIndex);
    },
  };
});

const selectImage = (index: number): void => {
  selectedImageIndex.value = index;
  emits(GamComponentsEmits.UPDATE_VALUE, index);
};

const deletePhoto = () => {
  emits(GamComponentsEmits.CLEAR, props.images[selectedImageIndex.value].imageBlobHash);

  if (props.closeAfterDelete) {
    closeModal();
  }
};

const canDeletePhoto = () => {
  if (props.confirmDelete) {
    emits(GamComponentsEmits.DELETE);
  } else {
    deletePhoto();
  }
};

const showModal = () => {
  dialog.value?.showModal();
  appStore.setImageExpanded(true);
  appStore.setDialogOpened(true);
  visible.value = true;
};

const closeModal = (): void => {
  if (reportDialog.value?.visible) return;
  dialog.value?.close();
};

const stopEvent = (event: Event) => {
  event.stopPropagation();
  event.preventDefault();
};

const hideDialog = (): void => {
  appStore.setImageExpanded(false);
  appStore.setDialogOpened(false);
  visible.value = false;
  emits(GamComponentsEmits.CLOSE);
};

const loadingImage = () => {
  imgLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    imgLoading.value = false;
  });
};

const getMoreItems = computed((): GamMoreType => {
  const items = [];

  if (props.canDelete || user.value?.isAdmin) {
    items.push({
      label: 'app.more.delete',
      onClick: () => {
        canDeletePhoto();
      },
    });
  } else {
    items.push({
      label: 'app.more.report',
      onClick: () => {
        reportDialog.value?.show();
      },
    });
  }
  return {
    items,
    size: GamButtonSize.ACTIONS,
    position: GamMorePosition.LEFT_BELOW,
    icon: GamIconName.DOTS_V,
    variant: GamButtonVariant.SLAB,
  };
});

const imageSource = computed(() => {
  return gambits.imageService.getImageSrc(props.images[selectedImageIndex.value].imageBlobHash);
});

defineExpose({
  show: showModal,
  close: closeModal,
  visible,
});
</script>

<style lang="scss" scoped>
.gam-expanded-frame {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-dark-70);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  pointer-events: all !important;
  cursor: default;
  -webkit-transform: translateZ(0);

  &.drawer {
    border-radius: 36px 0 0 36px;
  }

  .gam-expand-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    max-width: calc(100% - 16vw);
    max-height: calc(100dvh - 16vh);

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: var(--radius-normal) !important;
      box-shadow: var(--shadow-backdrop) var(--shadow-pink-15);
      max-height: inherit;
      position: relative;
      background: var(--color-dark-700) !important;

      img {
        max-width: 100%;
        max-height: inherit;
        height: auto;
        width: auto;
        border-radius: inherit;
        min-width: 250px;
        min-height: 250px;
        filter: grayscale(100%);
      }

      &.no-filter {
        img {
          filter: none;
        }
      }

      .image-console,
      .image-info {
        display: flex;
        width: 100%;
        position: absolute;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
        pointer-events: all !important;
      }

      .image-info {
        bottom: 0;
        align-self: stretch;
        justify-content: space-between;
      }

      .image-console {
        justify-content: center;

        .image-selector {
          display: flex;
          gap: 8px;
          width: max-content;
          height: auto;
          align-self: center;

          .dot-select {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: var(--color-white-30);
            cursor: pointer;

            &.selected {
              background-color: var(--color-white-100);
            }
          }
        }

        .more-wrapper {
          position: absolute;
          right: 10px;
        }
      }

      .close-button {
        position: absolute;
        bottom: -16px;
        z-index: 10;
      }

      .time-ago {
        position: absolute;
        right: 0;
        bottom: -36px;
      }
    }
  }
}
</style>
