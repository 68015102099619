<template>
  <div v-if="player" ref="playerCard" :class="['gam-player-result-row', getPlayerCardClasses]">
    <div class="username-name-title">
      <div class="username">
        <span>@{{ player?.username || 'unknown' }}</span>
        <gam-icon v-if="player?.featured" class="featured" :name="GamIconSpecialName.BADGE_MATCH" size="14px" />
      </div>

      <div class="name">
        <span class="first-name">{{ player.firstName }}</span> <span class="last-name">{{ player.lastName }}</span>
      </div>
    </div>

    <div class="rating-age-distance">
      <GamChip
        v-if="player.rating"
        :label="String(player.rating)"
        :left-icon="GamIconName.G_CROWN"
        :size="GamChipSize.SMALL"
      />

      <div v-if="player.birthDate && typeof player?.distance === 'number'" class="age-and-distance">
        <span>{{ getHumanizedAge(player as UserDto) }},</span>
        <span>{{ getHumanizedDistance(player) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { UserDto } from '@/core/data/user/user.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import GamChip from '@/views/components/GamChip.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamChipSize } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconName, GamIconSpecialName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamPlayerCardType } from '@/views/composables/models/components/GamPlayer';
import { computed, ref, watch } from 'vue';
import { getHumanizedAge, getHumanizedDistance } from './helpers';

const props = defineProps<GamPlayerCardType>();

const playerCard = ref<HTMLDivElement>();

const isTitled = (): boolean => {
  return !!props.player?.title;
};

const getPlayerCardClasses = computed(() => {
  return { titled: isTitled(), selected: props.selected, 'is-mobile': isMobile.value, auto: props.autoSize };
});

const getHeight = computed(() => {
  return playerCard.value?.clientHeight;
});

watch(props, ({ selected }) => {
  if (!selected) return;

  playerCard.value?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
});

defineExpose({
  getHeight,
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-player-result-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-md);
  flex-wrap: nowrap;
  overflow: hidden;

  min-height: 5rem;
  width: 100%;
  padding: var(--spacing-md) var(--spacing-l);

  border-radius: var(--radius-small);
  background-color: var(--color-dark-600);
  transition:
    box-shadow var(--transition),
    background-color var(--transition);

  cursor: pointer;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-white-30);
    padding: 1px;
    opacity: 0;
  }

  &.selected {
    background-color: var(--color-dark-500);

    &:before {
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--color-dark-500) !important;
    box-shadow: var(--shadow-card-hover);
  }

  &.titled {
    background: var(--color-radial-gradient);
    background-color: var(--color-dark-600);
  }
}

.username-name-title {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  flex-grow: 1;
  overflow: hidden;
}

.username {
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.name {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-white-30);

  span {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .first-name {
    flex-shrink: 0;
  }

  .role {
    text-transform: uppercase;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .role-titled {
    color: var(--color-pink);
  }

  .role-coach {
    color: var(--color-yellow);
  }

  .role-streamer {
    color: var(--color-white-70);
  }

  .ratings {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    justify-content: center;
  }
}

.rating-age-distance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex-shrink: 1;
  gap: var(--spacing-s);
  font-size: small;
  color: var(--color-white-30);

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-align: right;
    line-height: 1.5;
    gap: var(--spacing-s);
  }

  .age-and-distance span {
    white-space: pre;
  }
}

.age-and-rating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-md);
}
</style>
