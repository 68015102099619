<template>
  <div
    class="gam-map-filters-item"
    :class="{ 'is-selected': isSelected, 'is-disabled': isDisabled, 'is-mobile': isMobile }"
    @mouseover="handleMouseOver"
    @click="toggleItem"
  >
    <div class="selected-check">
      <gam-icon :name="GamIconName.CHECK" />
    </div>

    <div class="map-filter-item-pin">
      <gam-map-pin v-bind="getMapPin" />
    </div>

    <div class="map-filter-item-label">
      {{ type && localizeType(ObjectType.LOCATION, type) }}
      <span v-if="subLabel">⋅ {{ localize(subLabel) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ObjectType } from '@/core/data/localization/localization.type';
import { localize, localizeType } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamMapPin from '@/views/components/gam-map/GamMapPin.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamMapPinVariant } from '@/views/composables/constants/components/gamMap.constants';
import type { GamMapFilterItemType, GamMapPinType } from '@/views/composables/models/components/GamMap';
import { computed, ref } from 'vue';

const props = defineProps<GamMapFilterItemType>();
const isHovered = ref<boolean>(false);

const handleMouseOver = () => {
  isHovered.value = !props.isDisabled && true;
  document.addEventListener(
    'mouseout',
    () => {
      isHovered.value = false;
    },
    { once: true },
  );
};

const toggleItem = () => {
  !props.isDisabled && props.callback(props.type);
};

const getMapPin = computed((): GamMapPinType => {
  return {
    location: props.type,
    isHovered: isHovered.value && !props.isDisabled,
    isSelected: props.isSelected && !props.isDisabled,
    isDisabled: props.isDisabled,
    variant: GamMapPinVariant.DEFAULT,
    preventPointer: true,
  };
});
</script>

<style lang="scss" scoped>
.gam-map-filters-item {
  display: inline-flex;
  padding: 2px var(--spacing-lg) 2px 2px;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  align-self: stretch;
  flex-direction: column;
  width: 50%;
  position: relative;
  // margin-bottom: 1.5rem;

  .selected-check {
    display: none;
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.is-selected {
    .selected-check {
      opacity: 1;
    }

    .gam-map-pin.default .gam-map-pin-container {
      color: var(--color-pink);
    }
  }

  &:not(.is-disabled, .is-mobile):hover,
  &.is-selected {
    .map-filter-item-label {
      color: var(--color-pink);
    }
  }

  .selected-check {
    opacity: 0;
    transition: opacity var(--transition);
    position: absolute;
  }

  .map-filter-item-pin {
    max-width: 42px;
    max-height: 42px;
  }

  .map-filter-item-label {
    color: var(--color-white-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    span {
      color: var(--color-white-30);
      margin-left: 0.25rem;
    }
  }
}
</style>
