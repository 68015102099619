<template>
  <div :class="['gam-simple-input', containerClasses]">
    <label v-if="String(inputValue).length > 0 || isFocussed">{{ label }}</label>
    <input
      ref="input"
      v-bind="$attrs"
      :value="modelValue"
      type="text"
      @input="onChange"
      @focus="() => (isFocussed = true)"
      @blur="() => (isFocussed = false)"
    />
  </div>
</template>

<script lang="ts" setup>
import { isSafari } from '@/core/helpers/ui.helper';
import { computed, ref, useAttrs } from 'vue';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  label?: string;
  modelValue?: string | number | null;
}

defineProps<Props>();
const attrs = useAttrs();

const input = ref<HTMLInputElement>();
const inputValue = ref<string>('');
const isFocussed = ref<boolean>(false);

const emit = defineEmits(['update:modelValue']);

const containerClasses = computed(() => ({
  'is-focussed': isFocussed.value,
  'is-safari': isSafari.value,
  'is-placeholder': String(inputValue.value).length === 0,
}));

const onChange = (event: Event) => {
  const target = event.target as HTMLInputElement;

  let value = target.value;
  inputValue.value = value;

  if (attrs.type === 'number') {
    value = value.replace(/\D/g, '');
    const newValue = parseInt(value, 10).toString();
    if (typeof newValue === 'number') value = newValue;
  }

  target.value = value;
  emit('update:modelValue', value);
};
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-simple-input {
  border: 1px solid var(--color-white-5);
  border-radius: 25px;
  min-height: 47px;
  position: relative;
  transition: border-color 200ms;

  &:hover {
    border-color: var(--color-pink);
  }

  label {
    position: absolute;
    top: 8px;
    left: 20px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    color: var(--color-white-30);
  }

  input {
    border: none;
    outline: none;
    background: transparent;
    color: var(--color-white);
    border-radius: 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-top: 12px;
    box-sizing: border-box;

    &::placeholder {
      color: var(--color-white-70);
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &.is-placeholder {
    input {
      padding-top: 0;
    }
    input:focus {
      padding-top: 12px;
    }
  }
}
</style>
