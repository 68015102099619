import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamCollapseDirection } from '@/views/composables/constants/components/gamSidePanel.constants';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { ClubSectionSidePanelData } from '@/views/composables/models/main/ClubView';

export const getClubSidePanel = (data: ClubSectionSidePanelData): GamSidePanelType => {
  return {
    id: data.panelId,
    isOpened: data.isOpened,
    header: data.title
      ? {
          title: data.title,
          icon: data.icon,
        }
      : undefined,
    errorMessage: data.errorMessage,
    footer: {
      action: data.onSubmit
        ? {
            label: data.submitLabel,
            isFullSize: true,
            center: true,
            onClick: data.onSubmit,
            message: data.errorMessage,
            isDisabled: data.isActionDisabled,
            isLoading: data.isLoading,
          }
        : undefined,
      clear: data.onCancel
        ? {
            variant: GamButtonVariant.TERTIARY,
            size: GamButtonSize.LARGE,
            label: data.cancelLabel,
            isFullSize: true,
            center: true,
            onClick: data.onCancel,
          }
        : undefined,
    },
    direction: GamCollapseDirection.RIGHT,
  };
};
