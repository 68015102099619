export enum GamImageSize {
  MINI = 'mini',
  SMALL = 'small',
  CARD = 'card',
  MEDIUM = 'medium',
  LARGE = 'large',
  AUTO = 'auto',
  DYNAMIC = 'dynamic',
}

export enum GamImageFormat {
  VERTICAL = 'vertical',
  SQUARE = 'square',
}
