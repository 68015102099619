import type { UpdateProfileFormData, UserImagesFormData } from '@/core/data/auth/auth.interface';
import { StorageKeyType } from '@/core/data/storage/storage.interface';
import type { StorageRepository } from '@/core/data/storage/storage.repository';
import type { BaseUserDto, BaseUserInfo, UserDto, ValidateUserDto } from '@/core/data/user/user.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import { UserApi } from '@/core/network/api/user/user.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HandleApiError, HttpError } from '@/core/network/http/httpError';
import type { Coords } from '@/stores/auth.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { NotificationTopics } from '@/views/composables/constants/gam.constants';
import type { BaseClubDto } from '../club/club.interface';
import type { GeoLocation } from '../location/location.interface';

const logger = createLogger('UserRepository');

export class UserRepository {
  private readonly httpAPI: UserApi;
  private readonly session: StorageRepository;

  constructor(httpAPI: UserApi, session: StorageRepository) {
    this.httpAPI = httpAPI;
    this.session = session;
  }

  async getLocation(): Promise<GamResponse<Coords> | null> {
    // const savedIpLocation = this.session.get(StorageKeyType.IPLocation) as Coords;

    // if (savedIpLocation?.latitude && savedIpLocation?.longitude) {
    //   return { data: savedIpLocation };
    // }

    try {
      const response = await this.httpAPI.getLocation();
      if (response.data?.data) {
        const { latitude, longitude } = response.data.data;
        // this.session.set(StorageKeyType.IPLocation, {
        //   latitude: parseFloat(latitude),
        //   longitude: parseFloat(longitude),
        // });
        return { data: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) } };
      } else {
        return null;
      }
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async updateLocation(location: GeoLocation): Promise<GamResponse<GeoLocation> | null> {
    try {
      const response = await this.httpAPI.updateLocation(location);
      if (response.data?.data) {
        const { lat, lng } = response.data.data;
        return { data: { lat, lng } };
      } else {
        return null;
      }
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getUsers(listId: GamListId, location?: GeoLocation): Promise<GamResponse<BaseUserDto[]> | null> {
    try {
      const response = await this.httpAPI.getUsers(listId, location);
      return response.data?.data ? { data: response.data.data, cursor: response.data.cursor } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getUser(id: string): Promise<GamResponse<UserDto> | null> {
    try {
      const response = await this.httpAPI.getUser(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getUserByName(username: string): Promise<GamResponse<UserDto> | null> {
    try {
      const response = await this.httpAPI.getUserByName(username);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getUserInfo(refresh?: boolean): Promise<GamResponse<BaseUserInfo> | null> {
    try {
      // const savedUser = this.session.get<BaseUserInfo>(StorageKeyType.UserInfo);
      const savedUser = undefined;
      if (savedUser && !refresh) return { data: savedUser };
      const response = await this.httpAPI.getUserInfo();
      if (response.data?.data) {
        this.session.set<BaseUserInfo>(StorageKeyType.UserInfo, response.data.data);
        return { data: response.data.data };
      }
      return null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getUserClubs(refresh?: boolean): Promise<GamResponse<BaseClubDto[]> | null> {
    try {
      const savedClubs = this.session.get<BaseClubDto[]>(StorageKeyType.UserClubs);
      if (savedClubs && !refresh) return { data: savedClubs };
      const response = await this.httpAPI.getUserClubs();
      if (response.data?.data) {
        this.session.set<BaseClubDto[]>(StorageKeyType.UserInfo, response.data.data);
        return { data: response.data.data };
      }
      return null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async updateProfile(profile: UpdateProfileFormData): Promise<GamResponse<boolean>> {
    try {
      const response = await this.httpAPI.updateProfile(profile);
      if (response.data?.data) {
        this.session.set<BaseUserInfo>(StorageKeyType.UserInfo, response.data.data);
        return { data: true };
      }
      return { data: false };
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async updateImages(images: UserImagesFormData): Promise<GamResponse<boolean>> {
    try {
      const response = await this.httpAPI.updateImages(images);
      if (response.data?.data) {
        this.session.set<BaseUserInfo>(StorageKeyType.UserInfo, response.data.data);
        return { data: true };
      }
      return { data: false };
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async validateUsername(id: string): Promise<GamResponse<ValidateUserDto> | null> {
    try {
      const response = await this.httpAPI.validateUsername(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async storeNotificationToken(token: string, topics: NotificationTopics[] = []): Promise<GamResponse<boolean>> {
    try {
      const response = await this.httpAPI.storeNotificationToken(token, topics);
      if (response.data?.data) {
        return { data: true };
      }
      return { data: false };
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async toggleFollow(id: string, isFollowing?: boolean): Promise<boolean> {
    try {
      const response = await this.httpAPI.toggleFollow(id, isFollowing);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async toggleBlock(id: string, isBLocked?: boolean): Promise<boolean> {
    try {
      const response = await this.httpAPI.toggleBlock(id, isBLocked);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }
}
