<template>
  <div v-if="detail" class="location-about-tab">
    <div class="header">
      <h4 class="title">
        {{ localize('locations.detail.about.title') }}
      </h4>
      <hr />
    </div>

    <div class="location-author">
      <div v-if="!detail.user" class="author">{{ 'Suggested on' }}</div>
      <div
        v-else
        class="author"
        v-html="localizeWithValues('locations.detail.about.suggested', [detail.user.username])"
      />
      <div class="date">
        {{ getDate() }}
      </div>
    </div>

    <div class="additional-info">
      <div
        v-if="detail.locationTypes.includes(LocationType.CHESS_TABLE) && detail.numberOfChessboards"
        class="info-item"
      >
        <span class="label">
          {{ localize('locations.detail.about.info.numOfChessTables') }}
        </span>
        <span class="value">
          {{ detail.numberOfChessboards }}
        </span>
      </div>
      <div
        v-if="detail.locationTypes.includes(LocationType.GIANT_CHESS) && detail.numberOfGiantChessboards"
        class="info-item"
      >
        <span class="label">
          {{ localize('locations.detail.about.info.numOfGiantChess') }}
        </span>
        <span class="value">
          {{ detail.numberOfGiantChessboards }}
        </span>
      </div>
      <div class="info-item">
        <span class="label">
          {{
            localize(
              detail.chessSet ? 'locations.detail.about.info.chessSets' : 'locations.detail.about.info.noChessSets',
            )
          }}
        </span>
        <span :class="['value', { yes: detail.chessSet, no: !detail.chessSet }]">
          <GamIcon :name="detail.chessSet ? GamIconName.CHECK : GamIconName.X_CLOSE" />
        </span>
      </div>
    </div>

    <div v-if="detail.about" class="location-info">
      <div class="about">
        <span>{{ detail.about }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DetailLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { localize, localizeWithValues } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';

interface Props {
  detail: DetailLocationDto;
}

const { detail } = defineProps<Props>();

const getDate = (): string => {
  return GamHelper.getDate(detail.createdAt);
};
</script>

<style lang="scss">
.location-about-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  gap: var(--spacing-l);
  width: 100%;

  .header {
    width: 100%;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 110%;
      color: var(--color-white-100);
    }

    h4 {
      margin: 0;
    }

    hr {
      width: 100%;
      border-color: transparent;
      margin: var(--spacing-l) 0;
      margin-bottom: 0;
      border: none;
      height: 1px;
      background: var(--color-white-10);
    }
  }

  .location-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--spacing-md);
    width: 100%;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 110%;
      color: var(--color-white-100);
    }

    .about {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-white-70);
      width: 100%;
      border: 1px solid var(--color-white-5);
      border-radius: var(--radius-x-small);
      padding: var(--spacing-md) var(--spacing-l);
      min-height: 24px;
    }
  }

  .location-author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    min-height: 24px;

    .author {
      font-size: 14px;
      font-weight: 700;
      line-height: 110%;
      color: var(--color-white-70);
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;

      // span {
      //   background: var(--color-linear-gradient);
      //   background-clip: text;
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      // }
    }

    .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 110%;
      color: var(--color-white-30);
      flex-shrink: 0;
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-l);

    .info-item {
      display: flex;
      flex-direction: row-reverse;
      height: 24px;
      gap: var(--spacing-l);
      align-items: center;
      color: var(--color-white-100);
      align-self: stretch;

      .value.yes path {
        fill: lime !important;
      }

      .value.no path {
        fill: red !important;
      }

      .label {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 300;
        line-height: 110%; /* 17.6px */
        flex: 1 0 0;
      }

      .value {
        min-width: 18px;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 110%;
      }
    }
  }
}
</style>
