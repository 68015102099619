<template>
  <GamSidePanel
    :id="GamSidePanelId.LOCATION_DETAIL"
    :direction="GamCollapseDirection.LEFT"
    :option="GamSidePanelOption.LIST"
    :is-opened="isOpened"
    :footer="getSidePanelFooter"
    @close="handleClose"
  >
    <LocationDetail :location="location" @back="handleBack">
      <template #navigation>
        <div class="back-button" @click="handleBack"><GamIcon :name="GamIconName.ARROW_LEFT" /></div>

        <div class="tags-wrapper">
          <gam-icon v-if="location" v-bind="getLocationIcon" />

          <div class="location-type-label" :class="{ skeleton: !location }">
            <span>{{ getTypeLabel }}</span>
          </div>

          <GamChip v-if="location?.ratingCount" v-bind="getRatingTag" />
        </div>

        <div v-if="false" class="close-button" @click="handleClose">
          <GamIcon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" />
        </div>

        <GamButton
          v-if="location?.visitors.findIndex((u: any) => u.id === user?.id) === -1"
          class="check-in"
          :size="GamButtonSize.X_SMALL"
          :variant="GamButtonVariant.PRIMARY"
          @click="handleCheckIn"
        >
          Check in
        </GamButton>

        <GamButton
          v-if="(location?.visitors || []).findIndex((u: any) => u.id === user?.id) > -1"
          class="check-out"
          :size="GamButtonSize.X_SMALL"
          :variant="GamButtonVariant.SECONDARY"
          @click="handleCheckOut"
        >
          Check out
        </GamButton>
      </template>
    </LocationDetail>
  </GamSidePanel>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { ObjectType } from '@/core/data/localization/localization.type';
import type { DetailLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { gambits, localizeType } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import GamButton from '@/views/components/GamButton.vue';
import GamChip from '@/views/components/GamChip.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamSidePanelFooter } from '@/views/composables/models/components/GamSidePanel';
import LocationDetail from '@/views/main/locations/details/LocationDetail.vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const router = useRouter();
const route = useRoute();

const handleCheckIn = async () => {
  if (!locationId.value) return;

  await gambits.locationService.checkIn(String(locationId.value));

  fetchLocation();
};

const handleCheckOut = async () => {
  if (!locationId.value) return;

  await gambits.locationService.checkOut(String(locationId.value));

  fetchLocation();
};

const handleBack = () => {
  router.replace({
    path: route.path,
    query: { ...route.query, side_panel: 'search', location: undefined },
  });
};

const handleClose = () => {
  router.replace({
    path: route.path,
    query: { ...route.query, side_panel: undefined, location: undefined },
  });
};

const getLocationIcon = computed((): GamIconType => {
  const locationType = location.value?.locationTypes?.[0] || LocationType.CHESS_FRIENDLY;
  return {
    name: LocationHelper.getLocationTypeIcon([locationType], location.value?.private),
  };
});

const getTypeLabel = computed((): string => {
  return localizeType(ObjectType.LOCATION, location.value?.locationTypes[0] || LocationType.CHESS_FRIENDLY);
});

const getRatingTag = computed((): GamChipType => {
  if (!location.value?.ratingCount) return {};
  const ratingLabel = `${location.value?.rating || 0} <span>(${location.value?.ratingCount || 0})</span>`;
  return LocationHelper.getBaseLocationTag(ratingLabel, !!isMobile.value, GamIconName.STAR);
});

const getSidePanelFooter = computed(
  (): GamSidePanelFooter => ({
    isColumn: true,
    action: {
      label: 'Create Gambit',
      size: GamButtonSize.LARGE,
      onClick: () => {
        if (!authStore.isAuthenticated()) {
          router.push('/auth');
          return;
        }

        router.push({
          name: RouterNameType.LOCATIONS,
          query: {
            side_panel: 'add_gambit',
            location: location.value?.id,
            locationName: location.value?.name,
          },
        });
      },
    },
  }),
);

const isOpened = computed(() => {
  const { side_panel, location } = route.query;
  return side_panel === 'location_detail' && !!location;
});

const location = ref<DetailLocationDto>();

const locationId = computed(() => route.query.location);

const fetchLocation = async () => {
  if (typeof locationId.value !== 'string') {
    return;
  }

  const response = await gambits.locationService.getLocation(locationId.value);

  if (response.data) {
    location.value = response.data;
  } else {
    location.value = undefined;
  }
};

watch(
  () => route.query,
  (nextQuery, prevQuery) => {
    if (String(nextQuery.reload) === 'true' && !prevQuery.reload) {
      router.replace({ ...route, query: { ...route.query, reload: undefined } });
      fetchLocation();
    }
  },
);

onMounted(() => {
  fetchLocation();
});

watch(
  () => locationId.value,
  () => fetchLocation(),
);
</script>

<style lang="scss" scoped>
.back-button,
.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 51px;
  cursor: pointer;
}
.tags-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  align-self: stretch;
  min-width: 0;
  position: relative;

  .location-type-label {
    display: flex;
    min-height: 18px;
    align-items: center;

    span {
      color: var(--color-white-70);
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
</style>
