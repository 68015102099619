<template>
  <div class="gam-location-detail-card" :class="getClass">
    <div class="location-base-info">
      <div class="more-wrapper">
        <gam-more v-bind="getMoreItems" />
      </div>

      <div class="location-title" :class="{ skeleton: !location }">
        <span class="location-name">{{ location?.name || 'Unnamed location' }}</span>
      </div>
    </div>

    <div v-if="location.locationTypes?.length > 1" class="location-types">
      <div v-for="(type, index) in location.locationTypes" :key="`loc-type-${index}`" class="type">
        <GamIcon v-bind="getLocationIcon(type)" />
        <span>{{ localizeType(ObjectType.LOCATION, type) }}</span>
      </div>
    </div>

    <div class="location-address">
      <gam-button v-if="authStore.isAuthenticated()" v-bind="getFavoriteButton" :class="{ skeleton: !location }" />

      <div class="address">{{ location.address }}</div>
    </div>

    <gam-dialog v-if="location" ref="dialog">
      <reporting-dialog :id="location.id" :type="ReportType.LOCATION" @close="closeReport" />
    </gam-dialog>
  </div>
</template>

<script setup lang="ts">
import { ObjectType } from '@/core/data/localization/localization.type';
import type { BaseLocationDto, DetailLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { ReportType } from '@/core/data/report/report.type';
import { gambits, localizeType } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useMarkerStore } from '@/stores/marker.store';
import { useTableStore } from '@/stores/table.store';
import ReportingDialog from '@/views/components/gam-dialog/dialog-contents/ReportingDialog.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import GamMore from '@/views/components/gam-nav/gam-more/GamMore.vue';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamMorePosition } from '@/views/composables/constants/components/gamMore.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamMoreType } from '@/views/composables/models/components/GamMore';
import { useClipboard } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  location: DetailLocationDto;
}

const props = defineProps<Props>();

const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

const dialog = ref<InstanceType<typeof GamDialog>>();
const coordinates = ref();
const { copy } = useClipboard();

const closeReport = () => {
  dialog.value?.close();
};

const getLocationIcon = (locationType: LocationType): GamIconType => {
  return {
    name: LocationHelper.getLocationTypeIcon([locationType], props.location.private),
  };
};

const getCoordString = (round?: boolean): string => {
  if (round) {
    const coords = LocationHelper.roundLocation(props.location.coordinates, 4);
    return `${coords.lat}, ${coords.lng}`;
  }
  return `${props.location.coordinates.lat}, ${props.location.coordinates.lng}`;
};

const isFavorite = ref<boolean>(!!props.location.favorite);

const getFavoriteButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    isIconOnly: true,
    leftIcon: isFavorite.value === true ? GamIconName.HEART : GamIconName.HEART_EMPTY,
    onClick: async () => {
      if (!props.location) return;

      const newFavorite = !isFavorite.value;

      const updatedPin = useMarkerStore(GamListId.MARKERS)().updateItem({
        id: props.location.id,
        favorite: newFavorite,
      });

      if (updatedPin) {
        gambits.locationService.updateMarker(updatedPin, props.location.id);
      }

      useTableStore<BaseLocationDto>(GamListId.LOCATIONS)().updateItem({
        id: props.location.id,
        favorite: newFavorite,
      });

      await gambits.locationService.toggleFavorite(props.location.id, newFavorite);

      isFavorite.value = newFavorite;
    },
  };
});

const getClass = computed(() => {
  return {
    'is-mobile': isMobile.value,
  };
});

const router = useRouter();
const route = useRoute();

const getMoreItems = computed((): GamMoreType => {
  const items = [
    {
      label: getCoordString(true),
      onClick: () => {
        coordinates.value = getCoordString();
        copy(coordinates.value);
      },
    },
    {
      label: 'app.more.share.location',
      onClick: () => {
        copy(window.location.href);
      },
    },
    {
      label: 'app.more.navigate',
      onClick: () => {
        LocationHelper.redirectToGoogleMaps(props.location.coordinates);
      },
    },
    {
      label: 'app.more.report',
      onClick: () => {
        if (!authStore.isAuthenticated()) {
          router.push('/auth');
          return;
        }

        dialog.value?.show();
      },
    },
  ];

  if (userDetails.value?.isAdmin) {
    items.push({
      label: 'app.more.edit',
      onClick: () => {
        router.push({ ...route, query: { ...route.query, side_panel: 'edit_location', location: props.location.id } });
        return;
      },
    });
  }

  return {
    items,
    size: GamButtonSize.X_SMALL,
    position: GamMorePosition.LEFT_BELOW,
  };
});
</script>

<style lang="scss">
.location-panel .location-info {
  .location-types {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-white-70);

    .type {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    .gam-map-pin,
    .gam-map-pin > div {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.gam-location-detail-card {
  display: flex;
  width: 100%;
  min-width: 0;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-l);
  padding: var(--spacing-l);
  border-radius: var(--radius-normal);
  background-color: var(--color-dark-600);
  //overflow: hidden;

  .location-base-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--spacing-xl);
    position: relative;
    min-width: 0;

    .more-wrapper {
      position: absolute;
      right: 0;
      z-index: 2;
    }

    .location-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
      position: relative;

      .location-name {
        font-size: 24px;
        font-weight: 800;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 133%;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 36px);
        text-wrap-style: pretty;
      }
    }

    .tags-wrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
      min-width: 0;

      &:after {
        content: '';
        height: 20px;
        width: 24px;
        position: absolute;
        bottom: 0;
        z-index: 10;
        pointer-events: none;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--color-dark-600) 50%, var(--color-dark-600));
        right: -16px;
      }

      .location-type-label {
        display: flex;
        min-height: 18px;
        align-items: center;

        span {
          color: var(--color-white-70);
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }
  }

  .location-address {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-md);
    align-self: stretch;
    min-width: 0;
    word-break: break-all;

    .address {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0 0.25rem;
    }
  }
}
</style>
