import type { LichessRatings, UserRatings } from '@/core/data/rating/rating.interface';
import type { RatingKey } from '@/core/data/rating/rating.type';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRatingStore = defineStore(GamStoreId.RATING, () => {
  const ratings = ref<UserRatings>({});
  const lichessRatings = ref<LichessRatings>({});
  const unknownFactor: number = 150;
  const maxRating = ref(4000);

  const isRatingAdded = (): boolean => {
    let ratingAdded = false;
    for (const key in ratings.value) {
      if (ratings.value[key as RatingKey] !== undefined) {
        const keyValue = ratings.value[key as RatingKey];
        if (keyValue && keyValue.toString().length && parseInt(keyValue.toString(), 10) <= maxRating.value) {
          ratingAdded = true;
          break;
        }
      }
    }
    return ratingAdded;
  };

  // Get from provider
  const isLichessConnected = (): boolean => {
    const r = lichessRatings.value;
    return !!(
      r.lichessComBlitzRating ||
      r.lichessComRapidRating ||
      r.lichessComBulletRating ||
      r.lichessComClassicalRating
    );
  };

  const parseValue = (value?: number | string): string => {
    return value ? value.toString() : '';
  };

  const setLichessRatings = (r: LichessRatings) => {
    lichessRatings.value = {
      lichessComBlitzRating: parseValue(r.lichessComBlitzRating),
      lichessComRapidRating: parseValue(r.lichessComRapidRating),
      lichessComBulletRating: parseValue(r.lichessComBulletRating),
      lichessComClassicalRating: parseValue(r.lichessComClassicalRating),
      fideRating: parseValue(r.fideRating),
      title: r.title,
    };
    updateLichessRatings();
  };

  const updateLichessRatings = () => {
    ratings.value = {
      ...ratings.value,
      ...lichessRatings.value,
    };
  };

  const setOfflineRating = (rating?: number) => {
    ratings.value.rating = rating;
  };

  const setByUnknownFactor = (value: number) => {
    if (value <= 0) {
      setOfflineRating();
    } else {
      setOfflineRating(value * unknownFactor);
    }
  };

  const setByRatingKey = (key: RatingKey, rating?: string | number) => {
    ratings.value[key] = rating ? parseInt(rating.toString(), 10) : undefined;
  };

  const deleteByRatingKey = (key: RatingKey) => {
    if (key in ratings.value) {
      delete ratings.value[key];
    }
  };

  return {
    ratings,
    maxRating,
    setLichessRatings,
    setOfflineRating,
    setByUnknownFactor,
    setByRatingKey,
    deleteByRatingKey,
    isRatingAdded,
    isLichessConnected,
    updateLichessRatings,
  };
});
