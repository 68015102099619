<template>
  <div class="gambit-organizer-dropdown">
    <div :key="modelValue" class="selected">
      <GamDisplayImage v-if="selectedOrganizer?.image" :hash="selectedOrganizer.image" :size="GamImageSize.SMALL" />
      <div v-else class="image-placeholder"></div>

      <div class="title">{{ selectedOrganizer?.label }}</div>
    </div>

    <select :value="modelValue" placeholder="Select Organizer" @change="onChange">
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { GamImageSize } from '../composables/constants/components/gamImage.constants';
import { GamComponentsEmits } from '../composables/constants/main/emit.constants';
import GamDisplayImage from './GamDisplayImage.vue';

interface Props {
  modelValue: string | undefined;
  options: { value: string; label: string; image?: string }[];
}

const props = defineProps<Props>();

const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE, GamComponentsEmits.UPDATE]);

function onChange(event: Event) {
  const selectedOrganizer = (event.target as HTMLSelectElement).value;

  emit(GamComponentsEmits.UPDATE_VALUE, selectedOrganizer);
  emit(GamComponentsEmits.UPDATE, selectedOrganizer);
}

const selectedOrganizer = computed(() => {
  const organizer = props.options.find((o) => o.value === props.modelValue);
  return organizer;
});
</script>

<style lang="scss" scoped>
.gambit-organizer-dropdown {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &:hover {
    .selected {
      background-color: var(--color-dark-500);
    }
  }

  .selected {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    background-color: var(--color-dark-600);
    border-radius: var(--radius-small);
    overflow: hidden;

    .gam-display-image,
    .image-placeholder {
      width: calc(96px * 0.75);
      height: calc(80px * 0.75);
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
