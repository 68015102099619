export enum GamClubCardSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum GamClubCardCategory {
  LIST = 'list',
  DETAIL = 'detail',
}

export enum GamClubDateSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}
