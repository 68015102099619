import type { FiltersDto } from '@/core/data/filter/filter.interface';
import type { FilterRepository } from '@/core/data/filter/filter.repository';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import { storeToRefs } from 'pinia';
import GeolocationHelper from '../helpers/geolocation.helper';

export class FilterService {
  private repository: FilterRepository;

  constructor(repository: FilterRepository) {
    this.repository = repository;
  }

  async setAppFilters(): Promise<Result<boolean>> {
    try {
      const response = await this.repository.setAppFilters();
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  getAppFilters(): FiltersDto | null {
    return this.repository.getAppFilters();
  }

  saveFiltersToStorage<T>(listId: GamListId, filters?: T | null): void {
    this.repository.saveFiltersToStorage(filters, `${listId}-filters`);
  }

  saveSelectedTableLocation(listId: GamListId, selectionId?: GamListId): void {
    const tableStore = useTableStore(listId)();
    const currentLocation = GeolocationHelper.getMyLocationOption();

    if (selectionId) {
      const tableStoreSelection = useTableStore<GamDropdownItem>(selectionId)();
      const { selectedItem } = storeToRefs(tableStoreSelection);
      const { userLocation } = storeToRefs(useGeolocationStore());
      this.repository.saveFiltersToStorage(selectedItem.value, listId);
      const coordinates = selectedItem.value?.coordinates || userLocation.value?.location;
      coordinates && tableStore.setLocationParam(coordinates);
    } else {
      tableStore.setLocationParam(currentLocation?.coordinates);
      this.repository.saveFiltersToStorage(currentLocation, listId);
    }
  }

  saveTableLocation(listId: GamListId, location?: GamDropdownItem | null): void {
    const tableStore = useTableStore(listId)();

    if (location?.coordinates) {
      tableStore.setLocationParam(location.coordinates);
      this.repository.saveFiltersToStorage(location, listId);
    } else {
      const currentLocation = GeolocationHelper.getMyLocationOption();
      tableStore.setLocationParam(currentLocation?.coordinates);
      this.repository.removeFiltersToStorage(listId);
    }
  }

  getSavedFilters<T>(listId: GamListId): T | null {
    return this.repository.getFiltersFromStorage<T>(`${listId}-filters`);
  }

  getSavedTableLocation(listId: GamListId): GamDropdownItem | null {
    return this.repository.getFiltersFromStorage(listId);
  }
}
