<template>
  <div class="list-header" :class="{ mobile: isMobile }">
    <div class="tabs-row">
      <div class="location-search">
        <GamSimpleLocationSearch v-bind="getSimpleSearchInput" @click-clear="onClickClear" />
        <div v-if="isMobile" class="location-result-burger" @click="handleRevealResults">
          <GamIcon :name="GamIconName.HAMBURGER" :size="GamIconSize.LARGE" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gambits } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import type { Result } from '@/core/network/http/httpClient.interface';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import GamIcon from '@/views/components/GamIcon.vue';
import GamSimpleLocationSearch from '@/views/components/GamSimpleLocationSearch.vue';
import { GamDropdownCategory, GamDropdownSize } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamSimpleInputSearchType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const mapStore = useMapStore();
const { seekingLocation } = storeToRefs(mapStore);
const geolocationStore = useGeolocationStore();
const { isLocationPermissionGranted } = storeToRefs(geolocationStore);

const handleRevealResults = () => {
  router.replace({
    path: route.path,
    query: { ...route.query, side_panel: 'search' },
  });
};

const onQueryChanged = (query: string | undefined) => {
  router.replace({
    path: route.path,
    query: { ...route.query, search: query },
  });
};

const onClickClear = (prevValue: any) => {
  if (!prevValue) {
    router.replace({
      path: route.path,
      query: { ...route.query, side_panel: undefined },
    });
  }
};

const onSearchMounted = (el: HTMLInputElement) => {
  window.focusSearch = () => {
    if (!el) return;
    el.focus();
  };
};

const onSelectedResult = (loc: GamDropdownItem | null) => {
  if (loc === null) return;

  if (loc.source === 'internal') {
    router.push({ ...route, query: { ...route.query, side_panel: 'location_detail', location: loc.id } });
    // @ts-ignore
    window.gMap?.map.setZoom(17);
    // @ts-ignore
    window.gMap?.map.panTo(loc.coordinates);
  }

  if (loc.source === 'external') {
    // @ts-ignore
    window.gMap?.map.setZoom(13);
    // @ts-ignore
    window.gMap?.map.panTo(loc.coordinates);
  }
};

const searchId = GamListId.LOCATION_SEARCH;

const getSimpleSearchInput = computed((): GamSimpleInputSearchType<GamDropdownItem> => {
  return {
    id: searchId,
    isSearch: true,
    input: {
      name: GamInputName.SEARCH,
      placeholder: 'locations.general.search.placeholder',
      leftIcon: GamIconName.SEARCH,
      canClear: true,
    },
    dropdown: {
      id: searchId,
      category: GamDropdownCategory.LOCATIONS,
      size: GamDropdownSize.LARGE,
      itemSpacing: 0,
      reversed: isMobile.value && seekingLocation.value,
    },
    selectCallback: onSelectedResult,
    loadCallback: async (query: string): Promise<Result<GamDropdownItem[]>> => {
      const result = await gambits.locationService.searchLocationsByQuery(query, {
        mapbox: true,
        internal: true,
      });

      return {
        ...result,
        data: LocationHelper.mapSearchLocations(result.data),
      };
    },
    onQueryChanged,
    onMounted: onSearchMounted,
    onFocus: () => {
      if (isLocationPermissionGranted.value) return;

      geolocationStore.setLocationPermissionRequested(true);
    },
  };
});
</script>

<style scoped lang="scss">
.list-header {
  &.mobile {
    width: 100%;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l);
  }

  .location-search {
    width: 100%;
    position: relative;
  }

  .tabs-row {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: var(--spacing-menu);
  }

  .filtering {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-xs);
    transition: height var(--transition);
    position: relative;

    .filter-actions {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: var(--spacing-md);

      .switch {
        width: max-content;
      }
    }

    .search-input {
      width: 100%;
      top: 0;
      position: relative;
    }
  }
}

.location-result-burger {
  position: absolute;
  top: 14px;
  right: 48px;
  z-index: 1024;

  &,
  .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: white;
    }
  }
}
</style>
