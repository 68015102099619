import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamRatingItemType } from '@/views/composables/models/components/GamRatings';

export enum GamRatingPlatform {
  CHESS_COM = 'chessCom',
  LICHESS = 'lichess',
  GAMBITS = 'gambits',
  UNKNOWN = 'unknown',
  OFFLINE = 'offline',
}

const getGambitRating = (): GamRatingItemType => {
  return {
    platform: GamRatingPlatform.GAMBITS,
    isOpened: true,
  };
};

const getAuthRatings = (offlineOpenState = false): GamRatingItemType[] => {
  return [
    {
      platform: GamRatingPlatform.OFFLINE,
      accordion: true,
      isOpened: offlineOpenState,
      isAuth: true,
    },
    {
      platform: GamRatingPlatform.UNKNOWN,
      accordion: true,
      isOpened: false,
      isAuth: true,
    },
  ];
};

export const ratingItems = (connect?: (platform: GamRatingPlatform) => void, isAuth?: boolean): GamRatingItemType[] => {
  const ratingItems: GamRatingItemType[] = isAuth ? getAuthRatings(true) : [getGambitRating()];

  ratingItems.push(
    {
      platform: GamRatingPlatform.LICHESS,
      icon: GamIconName.LICHESS,
      accordion: !!isAuth,
      isOpened: !isAuth,
      connect,
      isAuth,
    },
    {
      platform: GamRatingPlatform.CHESS_COM,
      icon: GamIconName.CHESS_COM,
      accordion: !!isAuth,
      isOpened: !isAuth,
      isAuth,
    },
  );

  return ratingItems;
};
