<template>
  <span v-if="processedSlotContent !== ''" class="gam-support-text">
    {{ processedSlotContent }}
  </span>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { computed } from 'vue';

interface SupportTextProps {
  label: string;
}

const props = defineProps<SupportTextProps>();

const slots = defineSlots<{
  default?: () => any;
}>();

const processedSlotContent = computed(() => {
  if (slots.default) {
    const slotContent = slots.default();
    const textContent = slotContent.map((node: any) => node.children).join('');
    return localize(textContent);
  }

  if (props.label) {
    return localize(props.label);
  }

  return '';
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-support-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-white-30);
  line-height: 110%;
}
</style>
