<template>
  <div disabled-v-on-click-outside="closeDropdown" class="gam-input-search">
    <GamInput
      ref="searchInput"
      v-bind="getSearchInput"
      v-model="query"
      :on-click-clear="handleClear"
      @clear="clearInput"
      @close="closeDropdown"
      @click-message="onClickMessage"
      @focus="onFocus"
    />

    <GamSimpleDropdown v-if="getDropdown && isDropdownOpened" v-bind="getDropdown" @update="updateSelected" />
  </div>
</template>

<script setup lang="ts">
import { MapLocationSource } from '@/core/data/location/location.type';
import { gambits, localize } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { GamDropdownCategory } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import type { GamDropdownItem, GamSimpleDropdownType } from '@/views/composables/models/components/GamDropdown';
import type { GamInputType, GamSimpleInputSearchType } from '@/views/composables/models/components/GamInput';
import { debounce } from 'lodash';
import { computed, nextTick, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import GamInput from './GamInput.vue';
import GamSimpleDropdown from './GamSimpleDropdown.vue';

const props = defineProps<GamSimpleInputSearchType<GamDropdownItem>>();

const query = ref<string>('');
const items = ref<GamDropdownItem[]>([]);
const infiniteLoad = () => {};

const searchInput = ref();
const loadedOnInit = ref<boolean>(false);
const tempSelectedItem = ref<GamDropdownItem | null>(null);
const selectedItem = ref<GamDropdownItem | null>(null);
const isLoaded = ref<boolean>(false);

onBeforeMount(async () => {
  setDefaultItem();

  if (props.loadCallback) {
    if (props.loadOnInit) {
      await initLoad();
    } else {
      tempSelectedItem.value = selectedItem.value;
      loadedOnInit.value = true;
    }
  }
});

const initLoad = async () => {
  loadedOnInit.value = true;
};

const isSearchEntered = (): boolean => {
  const inputValue = query.value.toString() || '';
  return inputValue.length > 0 && !isDefaultValue();
};

const setDefaultItem = (): void => {
  if (props.dropdown?.selected) {
    selectedItem.value = props.dropdown.selected;
  } else if (props.dropdown?.defaultItem) {
    selectedItem.value = props.dropdown.defaultItem;
  } else if (tempSelectedItem.value) {
    selectedItem.value = tempSelectedItem.value;
  }
};

const isDefaultSelected = (): boolean => {
  return !!props.dropdown?.defaultItem && selectedItem.value?.id === props.dropdown?.defaultItem?.id;
};

const isDefaultValue = (): boolean => {
  return query.value === localize(props.dropdown?.defaultItem?.label || '');
};

const closeDropdown = () => {
  if (searchInput.value && props.dropdown) {
    searchInput.value.isActive = false;
  }
};

const clearInput = () => {
  if (props.input.canClear) {
    query.value = '';
    tempSelectedItem.value = null;
  }
};

const handleClear = () => {
  props.onClickClear?.();
  searchInput.value?.focus?.();
};

const isDropdownOpened = computed((): boolean => {
  const isInputActive = searchInput.value?.isActive;
  return !!props.dropdown && ((isInputActive && isSearchEntered()) || (props.loadOnInit && isInputActive));
});

const getSearchInput = computed((): GamInputType => {
  return {
    ...props.input,
    name: GamInputName.SEARCH,
    value: props.input.value || query.value,
    isSearch: props.isSearch,
    isDropdownOpened: props.dropdown && isDropdownOpened.value,
    flagIcon: selectedItem.value?.flagIcon,
    onMounted: props.onMounted,
  };
});

const getDropdown = computed((): GamSimpleDropdownType | undefined => {
  if (!props.dropdown) return;
  return {
    ...props.dropdown,
    query: query.value,
    items: items.value,
    infiniteLoad,
    isOpen: isDropdownOpened.value,
    maxItems: 5,
  };
});

const updateSelected = async (result?: GamDropdownItem | null) => {
  if (!result) return;

  if (result.source !== MapLocationSource.GOOGLE) {
    tempSelectedItem.value = result;
    query.value = result.label;
    closeDropdown();

    return;
  }

  const { data: place } =
    (await gambits.locationService.fetchGooglePlaceDetails({
      placeId: result.id,
      description: result.label,
      mainText: result.label,
      secondaryText: result.subLabel!,
    })) || {};

  if (!place) {
    tempSelectedItem.value = result;
    query.value = result.label;
    closeDropdown();

    return;
  }

  tempSelectedItem.value = {
    ...result,
    ...place,
    // @ts-ignore
    coordinates: { lat: place.coordinates?.lat.toString(), lng: place.coordinates?.lng.toString() },
  };
  query.value = result.label;
  closeDropdown();

  if (!place.coordinates) {
    return;
  }

  if (!place.viewport) {
    // @ts-ignore
    window.gMap?.map?.panTo(place.coordinates);
    return;
  }

  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(place.viewport.southwest.lat, place.viewport.southwest.lng),
    new google.maps.LatLng(place.viewport.northeast.lat, place.viewport.northeast.lng),
  );

  // @ts-ignore
  window.gMap?.map?.fitBounds(bounds);
};

// TODO: This gets called when blurring a cleared input
watch(selectedItem, async (selected: GamDropdownItem | null) => {
  if (props.selectCallback) {
    props.selectCallback(selected);
  }
  await updateSelected(selected);
});

watch(isDropdownOpened, async (opened: boolean) => {
  if (props.dropdown) {
    if (opened) {
      await nextTick(async () => {
        if (props.loadOnInit && !loadedOnInit.value) {
          await initLoad();
        } else {
          isLoaded.value = true;
          loadedOnInit.value = true;
        }
      });
    } else if (loadedOnInit.value) {
      if (selectedItem.value) {
        if (isDefaultSelected()) {
          const newQuery = localize(props.dropdown?.defaultItem?.label || '');
          query.value = newQuery;
        } else if (props.dropdown?.category === GamDropdownCategory.DEFAULT) {
          query.value = '';
        }
      }
    }
  }
});

type LoadItemsOptions = {
  query: string;
  selectFirstItem: boolean;
};

async function loadItems({ query }: LoadItemsOptions) {
  const results = await props.loadCallback?.(query);

  if (!results?.data) {
    items.value = [];

    return;
  }

  items.value = results.data;
}

watch(
  () => query.value,
  debounce(async (query) => {
    if (isSearchEntered() && loadedOnInit.value) {
      props.onQueryChanged && props.onQueryChanged(query);

      loadItems({ query, selectFirstItem: LocationHelper.isValidDDFormat(query) });
    } else if (!isSearchEntered()) {
      props.onQueryChanged && props.onQueryChanged(undefined);
    }
  }, 400),
);

watch(
  () => searchInput.value?.isActive,
  (value) => {
    if (!value) {
      setDefaultItem();
    }
  },
);

onUnmounted(async () => {
  tempSelectedItem.value = null;
});
</script>

<style scoped lang="scss">
.gam-input-search {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
}

.gam-simple-input {
  width: 100%;
}
</style>
