export enum FormId {
  GENERAL_PROFILE = 'general-profile',
  AUTH_REGISTRATION = 'auth-registration',
  AUTH_PROFILE_IMAGES = 'auth-profile-images',
  ADD_LOCATION = 'add-location',
  EDIT_LOCATION = 'edit-location',
  ADD_COMMENT = 'add-comment',
  ADD_GAMBIT = 'add-gambit',
  EDIT_GAMBIT = 'edit-gambit',
  ADD_CLUB = 'add-club',
  EDIT_CLUB = 'edit-club',
  EDIT_CLUB_CURATORS = 'edit-club-curators',
  ADD_REPORT = 'add-report',
}
