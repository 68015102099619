<template>
  <div :class="mapActionsClasses">
    <gam-map-filters
      v-if="false && !isAddingData"
      :show-events="true"
      :list-id="GamListId.LOCATIONS"
      @add="addLocationMobileAction"
    />

    <div :class="['action-wrapper', { add: isAddingData }]">
      <div v-if="!isMobile && isAddingLocation" class="help-label">
        {{ localize('locations.add.cancel.message') }}
      </div>

      <gam-button
        v-if="!isAddingData"
        :variant="GamButtonVariant.SECONDARY"
        :size="GamButtonSize.SMALL"
        :center="true"
        label="Create ▾"
        @click="toggleCreateMenu"
      />

      <transition name="fade" mode="out-in">
        <div v-if="isCreateMenuOpen && !isAddingData" class="gam-create-menu-container">
          <gam-button
            :variant="GamButtonVariant.TERTIARY"
            :size="GamButtonSize.SMALL"
            :center="true"
            label="Location"
            @click="addLocationAction"
          />

          <gam-button
            :variant="GamButtonVariant.TERTIARY"
            :size="GamButtonSize.SMALL"
            :center="true"
            label="Gambit"
            @click="addGambitAction"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MapLocationDto } from '@/core/data/location/location.interface';
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamMapFilters from '@/views/components/gam-map/GamMapFilters.vue';
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const mapStore = useMapStore();
const { seekingLocation } = storeToRefs(mapStore);
const authStore = useAuthStore();
const locationsTableStore = useTableStore<MapLocationDto>(GamListId.LOCATIONS);

const tableStore = useTableStore<MapLocationDto>(GamListId.ADD_LOCATION)();
const { selectedItem } = storeToRefs(tableStore);

const router = useRouter();
const route = useRoute();

const isCreateMenuOpen = ref(false);
function toggleCreateMenu() {
  if (!authStore.isAuthenticated()) {
    router.push('/auth');

    return;
  }

  isCreateMenuOpen.value = !isCreateMenuOpen.value;
}

const isAddingData = computed(() => {
  const { side_panel } = route.query;

  return ['edit_location', 'add_location', 'add_gambit', 'add_club'].includes(String(side_panel));
});

const isAddingLocation = computed(() => {
  const { side_panel } = route.query;

  return ['add_location', 'edit_location'].includes(String(side_panel));
});

const addLocationAction = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'add_location',
      mobile_step: isMobile.value ? 'pick_coordinate' : undefined,
      initialize_with_current_coords: 'true',
    },
  });
};

const addGambitAction = () => {
  isCreateMenuOpen.value = false;

  const {
    query: { location: locationId },
  } = route;

  const store = locationsTableStore();
  const item = store.itemList?.find((i) => i.id === locationId);

  if (item && !isMobile.value) {
    router.push({
      ...route,
      query: {
        ...route.query,
        side_panel: 'add_gambit',
        mobile_step: isMobile.value ? 'pick_coordinate' : undefined,
        location: item.id,
        locationName: item.name,
      },
    });

    return;
  }

  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'add_gambit',
      mobile_step: isMobile.value ? 'pick_coordinate' : undefined,
      initialize_with_current_coords: 'true',
    },
  });
};

const addLocationMobileAction = () => {
  if (selectedItem.value) {
    mapStore.setSeekingLocation(!seekingLocation.value);
  } else if (isAddingData.value) {
    mapStore.setAddMode(false);
    mapStore.setSeekingLocation(false);
  } else {
    mapStore.setSeekingLocation(true);
    mapStore.setAddMode(true);
  }
};

const mapActionsClasses = computed(() => {
  return [
    'map-actions',
    {
      'map-actions-mobile': isMobile.value,
      'map-actions-desktop': !isMobile.value,
      'add-mode': isAddingData.value,
      'map-actions-mobile-guest': authStore.isAuthenticated(),
    },
  ];
});
</script>

<style scoped lang="scss">
.is-mobile .map-actions-mobile:not(.map-actions-mobile-guest) {
  transform: translateY(-1rem);
}

.map-actions {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;

  .action-wrapper {
    display: flex;
    gap: var(--spacing-md);
    align-items: center;
    z-index: 10;
    pointer-events: all;

    .help-label {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .gam-create-menu-container {
    position: absolute;
    display: flex;
    padding: var(--spacing-s) var(--spacing-md);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: var(--color-dark-70);
    border: 1px solid var(--color-white-5);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: var(--radius-normal);
    top: 80px;
    right: var(--spacing-l) !important;

    &.is-mobile {
      bottom: 42px;
      left: 0;
      top: auto;
    }
  }
}

.slide-loc-enter-active,
.slide-loc-leave-active {
  transition: all var(--transition);
}

.slide-loc-enter-from,
.slide-loc-leave-to {
  transition: all var(--transition);
  opacity: 0;
  bottom: -250px !important;
}

.map-actions-mobile {
  position: unset;
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 700;

  .gam-create-menu-container {
    bottom: 3.5rem;
    left: 1.75rem;
    top: unset;
    width: 8rem;
    height: auto;
  }

  .help-label {
    padding: 20px;
  }
}

.map-actions-desktop {
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  padding: 37px 23px var(--spacing-menu);
  justify-content: flex-end;
  align-items: center;
  min-height: 112px;
  z-index: 2;
  gap: var(--spacing-l);
  pointer-events: none;

  &:before {
    content: '';
    background: var(--color-gradient-overlay);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity var(--transition);
    z-index: 1;
    pointer-events: none;
  }

  &.add-mode {
    &:before {
      opacity: 1;
    }
  }
}
</style>
