import type { GeoLocation, MapLocationDto } from '@/core/data/location/location.interface';
import type { GetCoords, GetTableParams, LoadItemsData, Result } from '@/core/network/http/httpClient.interface';
import { useIntersectStore } from '@/stores/intersect.store';
import type { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { debounce } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface ItemWithId {
  id: string;
}

export let incomingMarkers: MapLocationDto[] = [];
export let remainingMarkers: MapLocationDto[] = [];
export let outgoingMarkers: MapLocationDto[] = [];
export let currentMarkers: MapLocationDto[] = [];

export const useMarkerStore = (listId: GamListId) =>
  defineStore(`${GamStoreId.MAP}-${listId}`, () => {
    const intersectStore = useIntersectStore();
    const isLoading = ref<boolean>(false);

    const lastUpdated = ref<number>(new Date().getTime());
    const selectedItem = ref<MapLocationDto | null>(null);
    const tableParams = ref<GetTableParams>({});
    const coordinates = ref<GetCoords>();
    const loadCallback = ref<((listId: GamListId, center?: GeoLocation) => Promise<Result<MapLocationDto[]>>) | null>(
      null,
    );

    const setLoadCallback = (callback: (listId: GamListId) => Promise<Result<MapLocationDto[]>>) => {
      loadCallback.value = callback;
    };

    const setLoadingState = (state: boolean) => {
      isLoading.value = state;
    };

    const setSearchQuery = async (data?: LoadItemsData): Promise<void> => {
      tableParams.value.search = data?.query;
      if (data?.query !== undefined && !data?.preventLoad) {
        await loadItems(data);
      }
    };

    const setLocationParam = (location: GeoLocation): void => {
      if (location) {
        coordinates.value = {
          latitude: location.lat || undefined,
          longitude: location.lng || undefined,
        };
        // } else {
        //   const { userLocation } = storeToRefs(useGeolocationStore());
        //   coordinates.value = {
        //     latitude: userLocation.value?.location.lat,
        //     longitude: userLocation.value?.location.lng,
        //   };
      }
    };

    const getLocationParam = (): GetCoords | undefined => {
      return coordinates.value;
    };

    const populateList = (items: MapLocationDto[]): void => {
      if (tableParams.value.afterCursor) {
        currentMarkers = currentMarkers.concat(items);
      } else {
        currentMarkers = items;
      }
    };

    const setList = async (
      center: GeoLocation,
      resetListAfterLoad?: boolean,
      selectFirstItem?: boolean,
    ): Promise<void> => {
      setLoadingState(true);

      const result = await loadCallback.value?.(listId, center);

      if (result?.data) {
        if (resetListAfterLoad) resetList();

        populateList(result.data);

        if (!tableParams.value.afterCursor && selectFirstItem) setSelectedItem(result.data[0]);
      }

      setLoaded();

      setLoadingState(false);
    };

    const setSelectedItem = (item: MapLocationDto | null): void => {
      selectedItem.value = item;
    };

    const loadItems = async (data?: LoadItemsData): Promise<void> => {
      if (!data?.center) return;
      if (!data?.loadOnInit) resetList(data?.resetSelected);
      await setList(data.center, data?.loadOnInit, data?.selectFirstItem);
    };

    const replaceItems = async (items: MapLocationDto[]): Promise<void> => {
      currentMarkers = items;

      setLoaded();

      setLoadingState(false);
    };

    const addUniqueItems = debounce(
      async (center: GeoLocation): Promise<void> => {
        setLoadingState(true);

        const results = await loadCallback.value?.(listId, center);
        if (!results?.data?.length) {
          return;
        }

        if (currentMarkers.length === 0) {
          currentMarkers = results.data || [];
          incomingMarkers = results.data || [];
          lastUpdated.value = new Date().getTime();

          return;
        }

        incomingMarkers = [];
        results.data?.forEach((newItem) => {
          if (currentMarkers.findIndex((oldItem) => newItem.id === (oldItem as ItemWithId).id) === -1) {
            incomingMarkers.push(newItem);
          }
        });

        outgoingMarkers = [];
        remainingMarkers = [];
        currentMarkers.forEach((oldItem) => {
          if (results.data?.findIndex((newItem) => (oldItem as ItemWithId).id === newItem.id) === -1) {
            outgoingMarkers.push(oldItem);
          } else {
            remainingMarkers.push(oldItem);
          }
        });

        currentMarkers = [...remainingMarkers, ...incomingMarkers];
        lastUpdated.value = new Date().getTime();

        setLoaded();
        setLoadingState(false);
      },
      128,
      { trailing: true },
    );

    const resetList = (resetSelected?: boolean): void => {
      populateList([]);
      if (resetSelected) setSelectedItem(null);
    };

    const setLoaded = () => {
      intersectStore.setLoadState(listId, true);
    };

    const updateItem = (update: Partial<MapLocationDto>): MapLocationDto | undefined => {
      const item = currentMarkers.find((item) => item.id === update.id);
      if (!item) {
        return;
      }

      Object.entries(update).forEach(([key, value]) => {
        // @ts-ignore
        item[key as keyof MapLocationDto] = value;
      });

      return item;
    };

    const addItem = (item: MapLocationDto) => {
      currentMarkers.push(item);
    };

    const removeItem = (index: number) => {
      currentMarkers.splice(index, 1);
    };

    return {
      isLoading,
      lastUpdated,
      selectedItem,
      tableParams,
      setLoadingState,
      setSearchQuery,
      setList,
      setSelectedItem,
      resetList,
      replaceItems,
      loadItems,
      setLoadCallback,
      setLocationParam,
      getLocationParam,
      setLoaded,
      addUniqueItems,
      addItem,
      removeItem,
      updateItem,
    };
  });
