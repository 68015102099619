<template>
  <div class="gam-static-page" :class="{ mobile: isMobile }">
    <div class="static-wrapper">
      <div class="page-header">
        <div class="page-title">
          {{ getTitle() }}
        </div>
        <gam-button v-bind="getCloseButton()" />
      </div>
      <ScrollOverflow class="frame-wrapper" is-vertical>
        <div v-if="htmlContent" class="gam-frame" v-html="htmlContent" />
      </ScrollOverflow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gambits, localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import { onMounted, onUpdated, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ScrollOverflow from './components/ScrollOverflow.vue';

const router = useRouter();
const route = useRoute();
const baseUrl = 'static/pages/';

const htmlContent = ref<string | null>(null);

onMounted(async () => {
  await loadStaticPage();
});

onUpdated(async () => {
  await loadStaticPage();
});

const loadStaticPage = async () => {
  const routeName = route.name?.toString();
  htmlContent.value = await gambits.staticService.loadHtmlContent(`${baseUrl}${routeName}.html`);
};

const getTitle = () => {
  const routeName = route.name?.toString();
  return localize(`static.pages.${routeName}.title`);
};

const getCloseButton = (): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    label: 'static.pages.header.button',
    onClick: () => {
      router.back();
    },
  };
};
</script>

<style lang="scss">
.gam-static-page {
  display: flex;
  height: 100vh;
  padding: var(--spacing-xxl);
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;

  &.mobile {
    padding: var(--spacing-mobile-wrapper);

    .static-wrapper {
      padding-bottom: var(--spacing-mobile-bottom);

      .frame-wrapper {
        margin: 0 auto;
      }
    }
  }

  .static-wrapper {
    display: flex;
    width: calc(100% + var(--spacing-menu));
    max-width: 600px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-menu);
    position: relative;
    margin: 0 auto;
    left: -12px;

    .page-header {
      display: flex;
      padding: 0 12px var(--spacing-md);
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;

      .page-title {
        flex: 1 0 0;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .frame-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      padding: 0 12px;

      .gam-frame {
        .content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          .section-updated {
            display: flex;
            padding: 8px 16px 8px 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 28px;
            background: linear-gradient(81deg, rgba(255, 109, 162, 0.3) 19.88%, rgba(245, 76, 228, 0.3) 70.74%);

            .updated-time {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: #fff;
              text-overflow: ellipsis;
              font-size: 14px;
              font-weight: 400;
              line-height: 100%; /* 14px */
            }
          }

          .section {
            display: flex;
            padding: 16px 0;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            .title {
              font-size: 24px;
              font-weight: 800;
              align-self: stretch;
              line-height: 1;
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 16px;

              span {
                color: var(--color-white-70);
                text-align: justify;
              }
            }
          }

          .sub-section {
            display: flex;
            padding: 8px 0;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .title {
              font-size: 18px;
              font-weight: 700;
              align-self: stretch;
              line-height: 1;
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 14px;

              span {
                color: var(--color-white-70);
                text-align: justify;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
