<template>
  <div class="gam-gambit-card" :class="getClass">
    <div class="gambit-base-info">
      <gam-button v-if="isMobile" class="back-button" v-bind="getBackLink" />

      <div class="gambit-time-location">
        <div v-if="isOrganizer || isCreator" class="gam-owner">
          <GamGambitOwner
            :is-list="isList()"
            :is-detail="isDetail()"
            :is-corner="isMobile"
            :is-creator="isCreator && !isOrganizer"
            :is-organizer="isOrganizer"
          />
        </div>

        <GamMore
          v-if="!isOrganizer && !isCreator"
          class="gam-more"
          v-bind="getMoreItems"
          style="--button-full-width: 170px"
        />

        <gam-gambit-date v-if="false && !isMobile" v-bind="getGambitDate" />

        <router-link :to="getMapLinkParams">
          <GamGambitLocation
            v-bind="getGambitLocation"
            :key="`gambit-${props.gambit?.locationImageBlobHash}`"
            :class="{ skeleton: !gambit }"
          />
        </router-link>
      </div>

      <div v-if="gambit" class="gambit-title-heading">
        <h1>
          <router-link :to="{ name: RouterNameType.GAMBIT, params: { id: gambit.id } }">
            {{ getTitle(gambit) }}
          </router-link>
        </h1>

        <GamButton
          v-if="!gambit.isAttending"
          class="join-leave"
          :size="GamButtonSize.X_SMALL"
          :variant="GamButtonVariant.PRIMARY"
          :left-icon="GamIconName.HEART_EMPTY"
          @click="attendGambit"
        >
          {{ localize('Join') }}
        </GamButton>

        <GamButton
          v-if="gambit.isAttending"
          class="join-leave"
          :size="GamButtonSize.X_SMALL"
          :variant="GamButtonVariant.SECONDARY"
          :left-icon="GamIconName.HEART"
          @click="leaveGambit"
        >
          {{ localize(new Date(gambit.start) < new Date() ? 'Went' : 'Going') }}
        </GamButton>
      </div>
    </div>

    <div class="gambit-details">
      <div
        v-if="gambit?.description"
        :class="['gambit-description', { isDescriptionExpanded, 'is-mobile': isMobile }]"
        @click="isDescriptionExpanded = !isDescriptionExpanded"
      >
        <div class="truncation">{{ gambit?.description }}</div>
      </div>

      <div v-if="gambit?.location" :class="['location-card', { 'is-mobile': isMobile }]" @click="navigateToLocation">
        <div class="gradient-surface"></div>
        <div class="content">
          <div class="geo-info">
            <div class="data">
              <h2>
                <GamIcon name="location" /> <span>{{ gambit.location.name }}</span>
              </h2>
              <p>{{ gambit.location.address }}</p>
            </div>
            <div class="title">Location</div>
          </div>

          <div class="type-info">
            <div class="icons">
              <GamIcon
                :is-dark="false"
                :is-circle="true"
                :is-filled="false"
                :is-padded="true"
                :name="LocationHelper.getLocationTypeIcon(gambit.location.locationTypes)"
                :size="GamIconSize.X_LARGE"
              />
            </div>

            <div class="title">
              {{ getPrimaryLocationLabel(gambit.location) }}
            </div>
          </div>
        </div>
      </div>

      <RouterLink
        v-else-if="gambit?.address"
        :to="getMapLinkParams"
        class="address-card"
        @click="() => router.push(getMapLinkParams)"
      >
        <div class="name">
          <GamIcon :name="GamIconName.LOCATION" /> <span class="text-label">{{ gambit.address }}</span>
        </div>
      </RouterLink>

      <GamGambitTime v-if="gambit?.start" :start="gambit?.start" :end="gambit?.end" />

      <div class="gambit-tags-wrapper">
        <ScrollOverflow v-if="gambit" is-horizontal>
          <GamGambitSimpleTags :gambit="gambit" :is-list="isList()" />
        </ScrollOverflow>
      </div>

      <div v-if="getAttendees.length > 0" class="attendees">
        <GamAttendeeRow
          v-for="(attendee, a) in getAttendees"
          :key="`attendee-${a}`"
          :player="attendee"
          :is-organizer="attendee.id === gambit?.ownerId"
        />
      </div>

      <GamClubCard v-if="gambit?.club" :club="gambit.club" :class="['club-card', { 'is-mobile': isMobile }]" />

      <div class="secondary-actions">
        <GamButton
          label="Share"
          :variant="GamButtonVariant.SECONDARY"
          :size="GamButtonSize.SMALL"
          @click="() => copyToClipboard()"
        />

        <GamButton
          label="Navigate"
          :variant="GamButtonVariant.SECONDARY"
          :size="GamButtonSize.SMALL"
          full-size
          @click="() => redirectToGoogleMaps()"
        />

        <GamMore v-if="isCreator || isOrganizer" v-bind="getMoreItems" style="--button-full-width: 170px" />
      </div>
    </div>
  </div>

  <GamDialog v-if="gambit" ref="dialog">
    <ReportingDialog :id="gambit.id" :type="ReportType.GAMBIT" @close="closeReport" />
  </GamDialog>
</template>

<script setup lang="ts">
import type { BaseClubDto } from '@/core/data/club/club.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { GambitRecurrenceOptions } from '@/core/data/gambit/gambit.interface';
import type { BasicLocationInfo } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { ReportType } from '@/core/data/report/report.type';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits, localize } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import GamGambitDate from '@/views/components/gam-gambits/GamGambitDate.vue';
import GamGambitLocation from '@/views/components/gam-gambits/GamGambitLocation.vue';
import GamGambitOwner from '@/views/components/gam-gambits/GamGambitOwner.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamGambitCardCategory, GamGambitDateSize } from '@/views/composables/constants/components/gamGambit.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamMorePosition } from '@/views/composables/constants/components/gamMore.constants';
import type {
  GamGambitDateType,
  GamGambitDetailViewType,
  GamGambitLocationType,
} from '@/views/composables/models/components/GamGambit';
import type { GamMoreItem, GamMoreType } from '@/views/composables/models/components/GamMore';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import GamClubCard from '../gam-clubs/GamClubCard.vue';
import ReportingDialog from '../gam-dialog/dialog-contents/ReportingDialog.vue';
import GamDialog from '../gam-dialog/GamDialog.vue';
import GamMore from '../gam-nav/gam-more/GamMore.vue';
import GamAttendeeRow from '../gam-player/GamAttendeeRow.vue';
import GamButton from '../GamButton.vue';
import GamIcon from '../GamIcon.vue';
import ScrollOverflow from '../ScrollOverflow.vue';
import GamGambitSimpleTags from './GamGambitSimpleTags.vue';
import GamGambitTime from './GamGambitTime.vue';

const isDescriptionExpanded = ref(false);

const props = defineProps<GamGambitDetailViewType>();

const { userLocation } = storeToRefs(useGeolocationStore());

const dialog = ref<InstanceType<typeof GamDialog>>();

const appStore = useAppStore();
const router = useRouter();
const route = useRoute();

const getTitle = (options: { title?: string; club?: BaseClubDto; user?: BaseUserDto }) => {
  const { title, club, user } = options;

  if (title) {
    return title;
  }

  if (club) {
    return `${club.username}’s Gambit`;
  }

  if (user) {
    return `${user.username}’s Gambit`;
  }

  return 'Gambit';
};

const closeReport = () => {
  dialog.value?.close();
};

const getPrimaryLocationLabel = (location: BasicLocationInfo): string => {
  if (!Array.isArray(location.locationTypes)) {
    return localize(`app.types.location.${LocationType.CHESS_FRIENDLY}`);
  }

  return localize(`app.types.location.${location.locationTypes[0]}`);
};

const attendGambit = async () => {
  if (!props.gambit) return;

  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  await gambits.gambitService.createAttendance(props.gambit.id);

  router.push({ ...route, query: { ...route.query, reload: 'true' } });
};

const leaveGambit = async () => {
  if (!props.gambit) return;

  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  await gambits.gambitService.deleteAttendance(props.gambit.id);

  router.push({ ...route, query: { ...route.query, reload: 'true' } });
};

const isActualDetailView = computed(() => {
  return route.name === RouterNameType.GAMBIT;
});

const getAttendees = computed(() => {
  if (!props.gambit || !Array.isArray(props.gambit.attendees)) {
    return [];
  }

  return props.gambit.attendees;
});

const getBackLink = computed(() => ({
  class: 'back-link',
  href: `/${RouterNameType.GAMBITS}`,
  size: GamButtonSize.ACTIONS,
  variant: GamButtonVariant.TERTIARY,
  leftIcon: GamIconName.ARROW_LEFT,
  onClick: (e: MouseEvent) => {
    e.preventDefault();

    const { previousPath } = appStore;

    if (appStore.isFirstVisit() || (previousPath && previousPath.indexOf(route.fullPath) > -1)) {
      router.push({ name: RouterNameType.GAMBITS });
      return;
    }

    if (appStore.previousPath) {
      router.push(appStore.previousPath);
      return;
    }

    router.back();
  },
}));

const isList = (): boolean => {
  return props.category === GamGambitCardCategory.LIST;
};

const isDetail = (): boolean => {
  return props.category === GamGambitCardCategory.DETAIL;
};

const authStore = useAuthStore();
const { user, userDetails } = storeToRefs(authStore);

const getMapLinkParams = computed(() => {
  let query = {
    zoom: 18,
    location: isMobile.value ? undefined : props.gambit?.locationId,
    side_panel: !isMobile.value && props.gambit?.locationId ? 'location_detail' : undefined,
  };

  if (props.gambit?.location) {
    query = { ...query, ...props.gambit?.location?.coordinates };
  }

  if (props.gambit?.locationPoint) {
    query = { ...query, ...props.gambit?.locationPoint };
  }

  return { name: RouterNameType.LOCATIONS, query };
});

const getLocationLinkParams = computed(() => {
  let query = {
    zoom: 18,
    location: props.gambit?.locationId,
    side_panel: props.gambit?.locationId ? 'location_detail' : undefined,
  };

  if (props.gambit?.location) {
    query = { ...query, ...props.gambit?.location?.coordinates };
  }

  if (props.gambit?.locationPoint) {
    query = { ...query, ...props.gambit?.locationPoint };
  }

  return { name: RouterNameType.LOCATIONS, query };
});

const isGambitOwner = computed(() => {
  return isCreator.value || isOrganizer.value;
});

const deleteGambit = async (options?: GambitRecurrenceOptions) => {
  if (!props.gambit) return;

  if (!confirm('Are you sure? Deleting the gambit is not undoable.')) return;

  // TODO: Workaround to refresh tableStore by navigating back to the /gambits route
  if (route.name !== RouterNameType.GAMBIT) {
    router.push({ name: RouterNameType.GAMBIT, params: { id: props.gambit.id } });
  }

  await gambits.gambitService.deleteGambit(props.gambit.id, options);

  setTimeout(() => router.push({ name: RouterNameType.GAMBITS }));
};

const copyToClipboard = () => {
  const url = `${window.location.protocol}//${window.location.host}/gambit/${props.gambit?.id}`;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert('URL copied to clipboard!');
    })
    .catch(() => {
      alert(`Failed to copy url: ${url}`);
    });
};

const redirectToGoogleMaps = () => {
  let lat, lng;

  if (props.gambit?.locationPoint) {
    lat = props.gambit.locationPoint.lat;
    lng = props.gambit.locationPoint.lng;
  }

  if (props.gambit?.location?.coordinates) {
    lat = props.gambit.location.coordinates.lat;
    lng = props.gambit.location.coordinates.lng;
  }

  const userLat = userLocation.value?.location.lat;
  const userLng = userLocation.value?.location.lng;

  if (!lat || !lng || !userLat || !userLng) {
    alert('Could not navigate to loction');
    return;
  }

  const url = `https://www.google.com/maps/dir/${userLat},${userLng}/${lat},${lng}`;
  window.open(url, '_blank');
};

const isCreator = computed(() => {
  const ownerId = props.gambit?.ownerId;
  if (typeof ownerId !== 'string') return false;
  const userId = user.value?.id;
  if (typeof userId !== 'string') return false;

  return ownerId === userId;
});

const isOrganizer = computed(() => {
  return props.gambit?.canEdit;
});

const getMoreItems = computed((): GamMoreType => {
  const items: GamMoreItem[] = [];

  const isAdmin = userDetails.value?.isAdmin;

  if (isGambitOwner.value || isAdmin) {
    items.push({
      label: 'app.more.edit',
      onClick: () => {
        router.push({
          ...route,
          query: {
            ...route.query,
            side_panel: 'edit_gambit',
          },
        });
      },
    });

    items.push({
      label: 'app.more.delete',
      onClick: () => {
        deleteGambit();
      },
    });

    if (props.gambit?.parentId) {
      items.push({
        label: 'app.more.deleteAll',
        onClick: () => {
          deleteGambit({ recurrences: true });
        },
      });
    }
  } else {
    items.push({
      label: 'app.more.report',
      onClick: () => {
        if (!authStore.isAuthenticated()) {
          router.push('/auth');
          return;
        }

        dialog.value?.show();
      },
    });
  }

  return {
    items,
    size: GamButtonSize.X_SMALL,
    position: isGambitOwner.value ? GamMorePosition.RIGHT_ABOVE : GamMorePosition.LEFT_BELOW,
  };
});

const getGambitDate = computed((): GamGambitDateType => {
  return {
    start: props.gambit?.start,
    end: props.gambit?.end,
    size: isList() ? GamGambitDateSize.MEDIUM : GamGambitDateSize.LARGE,
  };
});

const getGambitLocation = computed((): GamGambitLocationType => {
  return {
    isList: isList(),
    shouldHover: !isList(),
    distance: props.gambit?.distance,
    locationImageHash: props.gambit?.locationImageBlobHash,
  };
});

const getClass = computed(() => {
  return {
    selected: props.selected,
    'is-list': isList(),
    'is-detail': isDetail(),
    'is-mobile': isMobile.value,
    'is-actual-detail': isActualDetailView.value,
  };
});

const navigateToLocation = () => {
  router.push(getLocationLinkParams.value);
};
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.debug {
  padding: 2rem;
}

.is-detail,
.is-list {
  .gambit-base-info {
    position: relative;

    @media (min-width: 640px) {
      padding-top: 23px;
    }
  }

  .back-button {
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 2;
  }
}

.gambit-title-heading {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  h1 {
    font-weight: 600;
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    text-align: left;
    word-break: break-word;
    hyphens: auto;
    margin: 0;

    a {
      text-decoration: none;
      background: var(--color-linear-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .join-leave {
    width: 6rem;
    flex-shrink: 0;
  }
}

.location-card {
  width: 100%;
  padding: 16px calc(var(--spacing-l) + var(--spacing-s));

  overflow: hidden;

  cursor: pointer;

  position: relative;

  &:not(.is-mobile) {
    height: 98px;
  }

  &.is-mobile {
    height: 80px;
  }

  .gradient-surface {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(#f54ce4, 0.8), rgba(#ff6da2, 0.8));
    transition: opacity linear 96ms;
    opacity: 0.5;
  }

  &:hover {
    .gradient-surface {
      opacity: 0.75;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
  }

  .title {
    font-size: 14px;
    opacity: 0.7;
  }

  .type-info,
  .geo-info {
    display: flex;
    flex-direction: column;
  }

  .geo-info {
    flex-grow: 1;

    .data {
      padding: 8px 0;
      flex-grow: 1;

      h2,
      p {
        margin: 0;
        padding: 0;
      }

      h2 {
        display: flex;
        gap: 4px;
        font-size: 16px;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        opacity: 0.7;
        letter-spacing: 0.03em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        text-align: left;
        word-break: break-word;
      }
    }
  }

  &:not(.is-mobile) .type-info {
    width: 100px;
  }

  .type-info {
    width: 4rem;
  }

  .type-info {
    .icons {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      text-align: center;
    }
  }
}

a.address-card {
  padding: 0;
  background: var(--linear-gradient);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 1;

  text-decoration: none;
  color: var(--color-white-100);
  flex-grow: 1;
  border-radius: var(--radius-small);
  border: 1px solid var(--color-pink-border-70);
  padding: 18px calc(var(--spacing-l) + var(--spacing-s)) 20px;
  gap: 0.5rem;

  overflow: hidden;
  flex-basis: 1;

  &:hover {
    background-color: var(--color-dark-600);
    border-color: var(--color-pink-border-100);
  }

  &.loc-count-3 {
    width: calc(100% / 3 - 1rem);
  }

  &.loc-count-2 {
    width: calc(100% / 2 - 0.5rem);
  }

  &.loc-count-1 {
    width: calc(100% / 1);
  }

  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
    overflow: hidden;
  }

  .text-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .address {
    color: var(--color-white-30);
  }
}

.gambit-details {
  width: 100%;
}

.attendees {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--spacing-md);
}

.gambit-tags-wrapper {
  width: 100%;

  .ScrollOverflow {
    width: calc(100% + 2 * 16px);
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .title-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-transform: uppercase;
    font-size: 12px;
    color: white;
    opacity: 0.7;
  }
}

.secondary-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.gam-gambit-card {
  display: flex;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;

  .location-card {
    border-radius: 18px;
  }

  &.is-detail,
  &.is-list {
    .gambit-time-location {
      border-radius: var(--radius-small);
      cursor: pointer;
      width: 100%;
      height: 216px;

      a {
        width: 100%;
        height: 100%;
      }
    }

    &.is-actual-detail:not(.is-mobile) {
      margin-top: -3rem;
    }

    .gambit-description {
      width: 100%;

      padding: 18px 20px 20px 20px;
      transition: background-color var(--transition);
      cursor: pointer;

      &:hover {
        background-color: var(--color-dark-500);
      }

      .truncation {
        width: 100%;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap-style: balance;
      }

      &.isDescriptionExpanded {
        .truncation {
          display: block;
        }
      }
    }
  }

  .gambit-details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: var(--spacing-l);
  }

  .gambit-description {
    order: 1;

    &:not(.is-mobile) {
      margin-top: calc(var(--spacing-l) * -0.25);
    }

    &.is-mobile {
      margin-top: calc(var(--spacing-l) * 0);
    }
  }

  .location-card,
  .address-card {
    order: 2;
  }

  .club-card {
    order: 3;

    &:not(.is-mobile) {
      margin-top: -1rem;
    }

    &.is-mobile {
      margin-top: 0;
    }
  }

  .gambit-tags-wrapper {
    order: 4;
  }

  .attendees {
    order: 5;
  }

  .secondary-actions {
    order: 7;
  }

  &.is-mobile {
    gap: 0 !important;

    .gambit-description {
      width: calc(100% + var(--spacing-l) * 2);
      // margin-top: calc(var(--spacing-l) * -1);
      margin-left: calc(var(--spacing-l) * -1);
      margin-right: calc(var(--spacing-l) * -1);
      background-color: var(--color-dark-600);
    }

    .gambit-tags-wrapper {
      .title-label {
        display: none;
      }
    }

    .location-card,
    .address-card {
      .title {
        opacity: 0;
        display: none;
      }
    }

    .secondary-actions {
      margin-top: 0;
    }

    .gambit-address-info {
      width: 100%;
    }

    .gam-gambit-location {
      border-radius: 0 !important;
    }

    .gambit-details {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
      margin-top: var(--spacing-l);
    }
  }

  &:not(.is-mobile) {
    .location-card,
    .address-card {
      border-radius: 18px;
      margin-bottom: var(--spacing-l);
    }

    .gam-gambit-card {
      gap: var(--spacing-l);
    }

    .gambit-details {
      width: 100%;
    }

    .gambit-description {
      border-radius: 18px;
      border: 1px solid var(--color-dark-500);
    }

    .gambit-mobile-time {
      margin-bottom: var(--spacing-md);
    }
  }

  .gambit-base-info {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: var(--spacing-l);
    min-width: 0;

    .gambit-time-location {
      position: relative;
      display: flex;
      align-self: stretch;
      align-items: flex-start;
      min-width: 0;

      .gam-owner {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
      }

      .gam-more {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 100;
      }
    }

    .gambit-tags-wrapper {
      width: inherit;
      min-width: 0;
    }
  }
}
</style>
