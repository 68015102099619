export enum GambitType {
  ANY = 'any',
  BLITZ = 'blitz',
  CLASSIC = 'classic',
  RAPID = 'rapid',
  BULLET = 'bullet',
  TIMED = 'timed',
  STREAMED = 'streamed',
  FREESTYLE = 'freestyle',
  BUGHOUSE = 'bughouse',
  HAND_AND_BRAIN = 'hand_and_brain',
  STIMUL = 'stimul',
}

export enum GambitCategory {
  CASUAL = 'casual',
  TOURNAMENT = 'tournament',
  SOCIALISING = 'event',
  LESSON = 'lesson',
}

export enum GambitInvitationType {
  CHALLENGE = 'challenge',
  REQUEST = 'request',
  SPECTATOR = 'spectator',
}

export enum GambitRepeat {
  NONE = 'none',
  EVERY_DAY = 'every_day',
  EVERY_WEEK = 'every_week',
  EVERY_MONTH = 'every_month',
}
