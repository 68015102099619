<template>
  <div>
    <div class="auth-content">
      <div class="auth-context left">
        <span class="auth-title">{{ localize('auth.page.email.title') }}</span>
        <span class="auth-subtitle">{{ localize('auth.page.email.subtitle') }}</span>
      </div>

      <form @submit="handleSubmit">
        <gam-input v-bind="getEmailInput" v-model="form.email" />
      </form>

      <transition name="dropdown" mode="out-in">
        <GamButtonMessage v-if="errorMessage" v-bind="errorMessage" />
      </transition>

      <div class="auth-context">
        <span class="auth-description" v-html="localize('auth.privacy')" />
      </div>

      <div class="auth-methods-group">
        <div class="methods-row">
          <gam-button v-bind="getContinueButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import GamButton from '@/views/components/GamButton.vue';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamInput from '@/views/components/GamInput.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import type { GamButtonMessageType, GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import useValidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const errorMessage = ref<GamButtonMessageType | null>(null);
const isLoading = ref<boolean>(false);
const form = reactive({
  email: '',
});
const validators = computed((): { email: any } => {
  return {
    email: { required, email },
  };
});
const v$ = useValidate(validators, form);

const submitForm = async () => {
  isLoading.value = true;
  const result = await gambits.authService.authWithEmail(form.email);
  if (result.data) {
    await router.push({ name: RouterAuthType.AUTH_OTP });
  } else if (result.error) {
    errorMessage.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }
  isLoading.value = false;
};

const handleSubmit = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  submitForm();
};

const getEmailInput = computed((): GamInputType => {
  return {
    name: GamInputName.EMAIL,
    leftIcon: GamIconName.ENVELOPE,
    type: GamInputValueType.EMAIL,
    placeholder: localize('auth.page.email.input.address.placeholder'),
    value: form.email,
    autocomplete: 'email',
  };
});

const getContinueButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    label: 'auth.page.email.continue.button.label',
    isFullSize: true,
    center: true,
    isDisabled: v$.value.email.$invalid,
    isLoading: isLoading.value,
    message: errorMessage.value,
    onClick: submitForm,
  };
});
</script>

<style scoped lang="scss">
form {
  width: 100%;
}
</style>
