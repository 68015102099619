<template>
  <div v-if="intersectList[id]" ref="intersectRoot" :class="['gam-intersect', { 'is-mobile': isMobile }]">
    <transition name="intersect" mode="out-in">
      <div v-if="noData" class="gam-no-data-wrapper">
        <gam-error-info v-if="noDataInfo" v-bind="noDataInfo" />
      </div>

      <ScrollOverflow
        v-else-if="itemList && !noData"
        ref="scrollbar"
        :class="['gam-intersect-wrapper', { 'scroll-spacing': scrollSpacing, dropdown: dropdown }]"
        is-vertical
      >
        <div class="slot-content" :style="getIntersectStyle">
          <slot />
        </div>

        <div v-if="showMore" ref="intersectTarget" :class="['intersect-element', { 'show-more': showMore }]">
          <gam-loader v-if="showMore" />
        </div>
        <GamErrorInfo v-else-if="endInfo" v-bind="endInfo" />
      </ScrollOverflow>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useIntersectStore } from '@/stores/intersect.store';
import { useTableStore } from '@/stores/table.store';
import GamErrorInfo from '@/views/components/GamErrorInfo.vue';
import GamLoader from '@/views/components/GamLoader.vue';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamIntersectType } from '@/views/composables/models/components/GamIntersect';
import { useIntersectionObserver } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, watch, type StyleValue } from 'vue';
import ScrollOverflow from './ScrollOverflow.vue';

const scrollbar = ref<typeof ScrollOverflow>();

const props = withDefaults(defineProps<GamIntersectType>(), {
  showMore: true,
  noData: false,
  threshold: 0.5,
  rootMargin: '200px 0px 0px',
  widthSpacing: 20,
  itemsGap: 8,
  spacerPadding: isMobile.value ? 130 : 168,
  footerPadding: isMobile.value ? 20 : 42,
});

const intersectStore = useIntersectStore();
const { intersectList } = storeToRefs(intersectStore);
const tableStore = useTableStore(props.id)();
const { itemList, tableParams } = storeToRefs(tableStore);

watch(
  () => tableParams.value.afterCursor,
  (cursor) => {
    if (!scrollbar.value) return;
    if (cursor !== null) return;
    scrollbar.value.resetScroll();
  },
);

const intersectRoot = ref<HTMLElement>();
const intersectTarget = ref<HTMLElement>();
const emits = defineEmits([GamComponentsEmits.INFINITE]);

onMounted(() => {
  intersectStore.addIntersect(props.id, scrollbar.value, props.isLoaded);
});

const onIntersection = (intersectionObserver: IntersectionObserverEntry[]): void => {
  const { isIntersecting } = intersectionObserver[0];
  if (isIntersecting) {
    emits(GamComponentsEmits.INFINITE);
  }
};

const getIntersectStyle = computed((): StyleValue => {
  return {
    gap: `${props.itemsGap}px`,
  };
});

const { stop } = useIntersectionObserver(intersectTarget, onIntersection, {
  root: intersectRoot,
  threshold: props.threshold,
  rootMargin: props.rootMargin,
});

onUnmounted(() => {
  stop();
  intersectStore.removeIntersect(props.id);
});
</script>

<style lang="scss">
.gam-intersect {
  width: inherit;
  height: 100%;

  .missing-info-container {
    width: 100%;
  }

  .slot-content {
    scroll-behavior: smooth;
  }

  &.is-mobile {
    .gam-no-data-wrapper {
      padding: var(--spacing-mobile-top) var(--spacing-mobile-wrapper) var(--spacing-mobile-bottom);
    }

    .gam-intersect-wrapper {
      padding: 0;
    }
  }

  .gam-no-data-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: var(--spacing-desktop-top) var(--spacing-xxl) var(--spacing-desktop-bottom);
    position: absolute;
    top: 0;
  }

  .intersect-loader-wrapper,
  .geo-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
    background: var(--color-dark-70);

    &.dropdown {
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      background: var(--color-dark-30);
    }
  }

  .gam-intersect-wrapper {
    width: calc(100%);
    height: 100%;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;

    .slot-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    &.ps {
      &.dropdown {
        .intersect-loader-wrapper {
          &.show-more {
            padding-bottom: var(--spacing-l);
          }
        }
      }
    }

    .intersect-element {
      width: 100%;
      display: flex;
      justify-content: center;

      &.show-more {
        padding-bottom: var(--spacing-xxl);
      }
    }
  }
}
</style>
