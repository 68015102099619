<template>
  <div :class="['main-view players-view', { 'is-mobile': isMobile }]">
    <div class="list-wrapper player">
      <gam-list-view v-bind="getPlayerList">
        <template #header>
          <players-list-header />
        </template>

        <template #list>
          <GamPlayerResultRow
            v-for="player in itemList"
            v-bind="getPlayerCard(player)"
            :id="player.id"
            :key="player.id"
            @click="navigateToPlayer(player.username)"
          />
        </template>
      </gam-list-view>
    </div>

    <gam-detail-view v-bind="getDetailView" :open="true">
      <scroll-overflow v-if="selectedItem" class="detail-scroll is-vertical">
        <PlayersDetail :id="selectedItem.id" :key="`player-id-${selectedItem.id}`" />
      </scroll-overflow>
    </gam-detail-view>

    <PlayerFilters class="side-filters" />
  </div>
</template>

<script lang="ts" setup>
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import ListHelper from '@/core/helpers/list.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { FALLBACK_LOCATION } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import GamDetailView from '@/views/components/detail-view/GamDetailView.vue';
import GamListView from '@/views/components/gam-list-view/GamListView.vue';
import GamPlayerResultRow from '@/views/components/gam-player/GamPlayerResultRow.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamPlayerCardSize } from '@/views/composables/constants/components/gamPlayer.constants';
import type { GamListType } from '@/views/composables/models/components/GamList';
import type { GamPlayerCardType } from '@/views/composables/models/components/GamPlayer';
import PlayersListHeader from '@/views/main/players/PlayersListHeader.vue';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PlayersDetail from './PlayersDetail.vue';
import PlayerFilters from './side-panels/PlayerFilters.vue';

const listId = GamListId.PLAYERS;
const tableStore = useTableStore<BaseUserDto>(listId)();
const { selectedItem, itemList } = storeToRefs(tableStore);
// const { userLocation } = storeToRefs(useGeolocationStore());
// const { selectedItem, tableParams, itemList } = storeToRefs(tableStore);
// const filterStore = useFilterStore<GetPlayerTableFilters>(listId)();

const route = useRoute();
const router = useRouter();

const mapStore = useMapStore();
const { currentLocation, currentZoom } = storeToRefs(mapStore);

// const currentPlayerSection = ref<TabItemType>(TabItemType.GENERAL);

onBeforeMount(async () => {
  // const locationItem = gambits.filterService.getSavedTableLocation(listId);
  // const coords = locationItem?.coordinates || userLocation.value?.location;
  // coords && tableStore.setLocationParam(coords);

  tableStore.setLocationParam(currentLocation.value);

  // filterStore.setFilters({
  //   sortBy: GamFilterSortPlayer.DISTANCE,
  // });
});

const getPlayerCard = (player: BaseUserDto): GamPlayerCardType => {
  return {
    player,
    selected: selectedItem.value?.id === player.id,
    size: GamPlayerCardSize.NORMAL,
  };
};

const navigateToPlayer = (username: string) => {
  if (!isMobile.value) {
    router.replace({
      name: RouterNameType.PLAYERS,
      query: { ...route.query },
      params: { username },
    });

    return;
  }

  router.replace({
    name: RouterNameType.PLAYER,
    query: { ...route.query },
    params: { username },
  });
};

const getDetailView = computed(() => {
  return {
    listId,
    // showListCursors: !!playerDetails.value,
    showListCursors: false,
    swipeMessage: {
      firstLine: 'player.details.header.description.one',
      secondLine: 'player.details.header.description.two',
    },
    nextButton: { isDisabled: !selectedItem.value, label: 'player.details.header.button.next' },
    previousButton: { isDisabled: !selectedItem.value, label: 'player.details.header.button.previous' },
  };
});

const getPlayerList = computed((): GamListType<BaseUserDto> => {
  return {
    listId,
    endInfo: {
      id: listId,
      title: 'No more players in this area',
      message:
        'Showing results near' +
        ' ' +
        (currentLocation.value.lat !== 0
          ? `${currentLocation.value.lat.toFixed(1)}°N, ${currentLocation.value.lng.toFixed(1)}°E`
          : 'you') +
        ' — ' +
        'the area you chose on the map.',
      size: GamErrorInfoSize.SMALL,
      actionPrimary: {
        variant: GamButtonVariant.SLAB,
        size: GamButtonSize.SMALL,
        label: 'Change coordinates',
        onClick: () => {
          router.push({
            name: RouterNameType.LOCATIONS,
            query: { ...currentLocation.value, zoom: Number(currentZoom.value) - 1 },
          });
        },
      },
    },
    noDataInfo: ListHelper.getListNoData({ id: listId, clearFilters: true }),
    scrollSpacing: true,
    isHorizontallyPadded: true,
    spacerPadding: 150,
    loadCallback: (listId) => {
      const location = currentLocation.value ? currentLocation.value : FALLBACK_LOCATION;
      return gambits.userService.getUsers(listId, location);
    },
  };
});

watch(selectedItem, async () => {
  // playerDetails.value = await getPlayerDetail();
  await router.replace({
    name: RouterNameType.PLAYERS,
    query: route.query,
    params: { username: selectedItem.value?.username },
  });
});

watch(
  () => ({ username: route.params.username, players: itemList.value }),
  async ({ username, players }) => {
    if (!username && !players?.length) return;

    if (!username) {
      username = players?.[0].username || '';
      await router.replace({ name: RouterNameType.PLAYERS, params: { username } });
    }

    const index = players?.findIndex((p) => p.username === username);
    if (index === undefined || index < 0) return;
    if (!isMobile.value) tableStore.selectItem(index);
  },
);

onUnmounted(() => {
  tableStore.resetList(true);
});
</script>

<style scoped lang="scss">
.detail-scroll {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.detail-container {
  max-width: 596px;
  margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto 0;

  @media (max-width: 1180px) {
    max-width: 512px;
  }

  &.is-mobile {
    padding: 0 var(--spacing-l);
  }
}

.player-tab-selector {
  display: flex;
  justify-self: center;
  margin: 0 auto;
  padding: var(--spacing-xxl) 0;
  flex-grow: 1;
  justify-content: flex-end;

  .tab-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}
</style>

<style lang="scss">
.main-view.players-view .list-content {
  gap: var(--spacing-md) !important;
}
</style>
