<template>
  <gam-side-panel v-bind="editCuratorsSidepanel">
    <div class="edit-curators" :class="{ 'is-mobile': isMobile }">
      <p>Organizers can edit information about the club on club pages, and manage the club's Gambits.</p>

      <div v-if="clubData" class="current">
        <p>Current users with organizer privileges:</p>

        <div v-if="clubData.owner" class="curator">
          @{{ clubData.owner?.username }}

          <GamButton
            label="Owner"
            :is-disabled="true"
            :size="GamButtonSize.X_SMALL"
            :variant="GamButtonVariant.SECONDARY"
          />
        </div>

        <div v-for="(curator, index) in clubData?.curators" :key="`cur-${index}`" class="curator">
          @{{ curator.username }}

          <GamButton
            label="Remove"
            :is-disabled="isRemoving"
            :size="GamButtonSize.X_SMALL"
            :variant="GamButtonVariant.SECONDARY"
            @click="removeCurator(curator.username)"
          />
        </div>
      </div>

      <div class="add">
        <p>Add a user to organizers:</p>

        <form @submit="handleAddCurator">
          <GamInput v-bind="computedAddUsername" v-model="addUsername" />

          <GamButton v-bind="getAddButton()" @click="handleAddCurator" />
        </form>

        <div v-if="addError" class="form-errors">
          <transition name="dropdown" mode="out-in">
            <GamButtonMessage v-bind="addError" />
          </transition>
        </div>
      </div>
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import type { DetailClubDto } from '@/core/data/club/club.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { getClubSidePanel } from '@/views/composables/constants/main/club.constants';
import type { GamButtonMessageType, GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  id: string;
  club: DetailClubDto;
}
const props = defineProps<Props>();

const router = useRouter();
const route = useRoute();

const isLoading = ref<boolean>(false);
const loadError = ref<GamButtonMessageType | null>(null);
const isRemoving = ref<boolean>(false);
const removeError = ref<GamButtonMessageType | null>(null);
const isAdding = ref<boolean>(false);
const addError = ref<GamButtonMessageType | null>(null);

function resetForm() {
  isLoading.value = false;
  loadError.value = null;
  isAdding.value = false;
  addError.value = null;
  isRemoving.value = false;
  removeError.value = null;
  addUsername.value = '';
}

// Club data

const clubData = ref<DetailClubDto | null>(props.club);

const getClub = async () => {
  isLoading.value = true;
  loadError.value = null;

  const result = await gambits.clubService.getClub(props.id);

  isLoading.value = false;

  if (result.error) {
    loadError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }

  if (!result.data) return;

  // @ts-ignore TODO: Harmonize types
  clubData.value = result.data;
};

watch(
  () => [props.id, route.query.side_panel],
  () => {
    if (route.query.side_panel !== 'edit_club_curators') {
      return;
    }

    getClub();
  },
);

const addUsername = ref<string>('');
const computedAddUsername = computed((): GamInputType => {
  return {
    name: 'username',
    value: addUsername.value,
    placeholder: '@username',
    isRequired: true,
  };
});

const getAddButton = (): GamButtonType => ({
  label: 'Add',
  isDisabled: isAdding.value || addUsername.value.trim().length === 0,
  size: GamButtonSize.LARGE,
  variant: GamButtonVariant.SECONDARY,
});

const handleAddCurator = async (e?: any) => {
  e?.preventDefault?.();

  addError.value = null;
  isAdding.value = true;

  const result = await gambits.clubService.addCurator(props.id, addUsername.value.replace('@', ''));

  isAdding.value = false;

  if (result?.error) {
    addError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };

    return;
  }

  addUsername.value = '';

  getClub();
};

watch(addUsername, () => {
  addError.value = null;
});

async function removeCurator(username: string) {
  if (!window.confirm(`Remove @${username} from @${clubData.value?.username} curators?`)) {
    return;
  }

  removeError.value = null;
  isRemoving.value = true;

  const result = await gambits.clubService.removeCurator(props.id, username);

  isRemoving.value = false;

  if (result?.error) {
    removeError.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };

    return;
  }

  getClub();
}

const editCuratorsSidepanel = computed((): GamSidePanelType => {
  return getClubSidePanel({
    panelId: GamSidePanelId.CLUB_FORM,
    isOpened: route.query.side_panel === 'edit_club_curators',
    title: 'Club organizers',
    icon: GamIconName.MUTUAL_EMPTY,
    errorMessage: loadError.value || removeError.value,
    isLoading: isLoading.value,
    onCancel: closeEditCurators,
  });
});

function closeEditCurators() {
  resetForm();

  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
}
</script>

<style lang="scss" scoped>
.edit-curators {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.is-mobile {
    width: 100% !important;
    border: 0;
  }
}

p {
  color: var(--color-white-70);
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;
}

.current {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 1rem;
}

.curator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .gam-button {
    width: 6rem;
  }
}

.add {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 1rem;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    gap: 1rem;

    .gam-button {
      width: 6rem;
      flex-shrink: 0;
    }
  }
}
</style>
