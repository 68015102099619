import { localizeWithValues } from '@/core/gambits';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import { GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamErrorInfoPayload, GamErrorInfoType } from '@/views/composables/models/components/GamErrorInfo';
import { storeToRefs } from 'pinia';

export default class ListHelper {
  static getListEnd(payload: GamErrorInfoPayload): GamErrorInfoType {
    const filterStore = useFilterStore(payload.id)();
    const isFilter: boolean = filterStore.isFiltersApplied();

    return {
      id: payload.id,
      title: payload.title || 'app.component.list.end.title',
      message: isFilter ? payload.message || 'app.component.list.end.description' : undefined,
      size: GamErrorInfoSize.SMALL,
      actionSecondary:
        isFilter && payload.clearFilters
          ? {
              variant: GamButtonVariant.SECONDARY,
              label: 'app.component.list.end.button.label',
              onClick: () => {
                useFilterStore(payload.id)().setFilters();
              },
            }
          : undefined,
    };
  }

  static getListNoData(payload: GamErrorInfoPayload): GamErrorInfoType {
    const { tableParams } = storeToRefs(useTableStore(payload.id)());
    const filterStore = useFilterStore(payload.id)();
    const isFilter: boolean = filterStore.isFiltersApplied();

    if (tableParams.value.search?.length) {
      return {
        id: payload.id,
        title: payload.title || 'app.error.title',
        message: localizeWithValues(payload.message || 'app.component.list.notFound.description', [
          tableParams.value.search || '',
        ]),
        size: GamErrorInfoSize.MEDIUM,
        loadIcon: GamIconName.SEARCH,
      };
    } else {
      return {
        id: payload.id,
        title: payload.title || 'app.error.title',
        message: isFilter
          ? payload.message || 'app.component.list.noItems.description'
          : 'app.component.list.noItems.init',
        size: GamErrorInfoSize.MEDIUM,
        actionSecondary:
          isFilter && payload.clearFilters
            ? {
                variant: GamButtonVariant.PRIMARY,
                label: 'app.component.list.noItems.button.clear',
                onClick: () => {
                  useFilterStore(payload.id)().setFilters();
                },
              }
            : undefined,
      };
    }
  }
}
