<template>
  <div v-if="gamForm" class="location-form">
    <LocationCreateFields
      :search-list-id="GamListId.LOCATION_FORM"
      :dropdownlist-id="listId"
      :name-input="getNameInput"
      :set-form-value="setFormValue"
      :form-model="gamForm.form"
    />

    <ChangeLocationCoords />

    <div class="location-types section">
      <div class="section-title">
        {{ localize('locations.add.form.section.type.title') }}
      </div>

      <gam-map-filters-list
        v-bind="getLocTypeList"
        v-model="gamForm.form.locationTypes"
        :value="gamForm.form.locationTypes"
      />

      <gam-counter
        v-if="isChessTableSelected"
        v-bind="getChessTableCounter"
        v-model="gamForm.form.numberOfChessboards"
      />

      <gam-counter
        v-if="isGiantChessSelected"
        v-bind="getGiantChessCounter"
        v-model="gamForm.form.numberOfGiantChessboards"
      />

      <gam-switch v-bind="getSwitch()" v-model:checked="gamForm.form.chessSet" />
    </div>

    <div class="additional-info section">
      <gam-textarea
        v-model="gamForm.form.about"
        placeholder="locations.add.form.section.type.about"
        :value="gamForm.form.about"
      />

      <gam-scroll-items v-bind="getGamScrollItemType">
        <gam-image :is-multi-add="true" :size="GamImageSize.MEDIUM" @add="addImage" />
        <gam-image
          v-for="(image, index) in gamForm.form.photos"
          :key="`loc-img-${index}`"
          v-model="gamForm.form.photos[index]"
          :image="gamForm.form.photos[index]"
          :size="GamImageSize.MEDIUM"
          :no-filter="true"
          @expand="expandImage"
        />
      </gam-scroll-items>
    </div>

    <gam-expand-image
      v-if="selectedImage"
      ref="imageExpand"
      v-bind="selectedImage"
      @close="clearSelected"
      @clear="deleteImage"
    />
  </div>
</template>

<script setup lang="ts">
import type { AdminDetailLocationDto, GeoLocation, SearchLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { BlobSource } from '@/core/data/report/report.type';
import { gambits, localize, localizeWithValues } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import { useFormStore } from '@/stores/form.store';
import GamMapFiltersList from '@/views/components/gam-map/GamMapFiltersList.vue';
import GamCounter from '@/views/components/GamCounter.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamScrollItems from '@/views/components/GamScrollItems.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamCounterType } from '@/views/composables/models/components/GamCounter';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamLocationFormType } from '@/views/composables/models/components/GamLocation';
import type { GamMapFiltersListType } from '@/views/composables/models/components/GamMap';
import type { GamScrollItemsType } from '@/views/composables/models/components/GamScrollItems';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { LocationFormProps, LocationValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, reactive, ref, watch } from 'vue';
import ChangeLocationCoords from './ChangeLocationCoords.vue';
import LocationCreateFields from './LocationCreateFields.vue';

const props = defineProps<GamLocationFormType>();

const listId = GamListId.LOCATION_FORM;

const formStore = useFormStore<LocationFormProps, LocationValidator>(props.formId)();
const { gamForm } = storeToRefs(formStore);

const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const lastSelectedResult = ref<GamDropdownItem>();

const formFromProps = (props: GamLocationFormType) => {
  return {
    name: props.location?.name || '',
    address: props.location?.address || '',
    locationTypes: props.location?.locationTypes || [],
    coordinates: props.location?.coordinates,
    chessSet: props.location?.chessSet || false,
    numberOfChessboards: props.location?.numberOfChessboards || null,
    numberOfGiantChessboards: props.location?.numberOfGiantChessboards || null,
    about: props.location?.about || '',
    photos: props.location?.image ? [props.location?.image.imageBlobHash] : [],
  };
};

function getForm(props: GamLocationFormType) {
  return reactive(formFromProps(props));
}

const reactiveForm = ref();
const v$ = ref();

onBeforeMount(() => {
  reactiveForm.value = getForm(props);
  formStore.setForm({ form: reactiveForm.value });
});
watch(
  () => props.instanceId,
  () => {
    reactiveForm.value = getForm(props);
    formStore.setForm({ form: reactiveForm.value });
  },
);

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;

  return v$.value.value.$invalid;
});

const expandImage = (hash: string) => {
  selectedImage.value = {
    selectedIndex: 0,
    blobSource: BlobSource.LOCATION,
    images: [
      {
        id: hash,
        imageBlobHash: hash,
        liked: false,
      },
    ],
    canDelete: true,
    closeAfterDelete: true,
    noFilter: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};

const addImage = (hash: string) => {
  if (!gamForm.value) return;
  gamForm.value.form.photos.push(hash);
};

const clearSelected = () => {
  selectedImage.value = null;
};

const deleteImage = (hash: string) => {
  if (!gamForm.value) return;
  GamHelper.removeItem(gamForm.value.form.photos, hash);
};

const getSwitch = (): GamSwitchType => {
  return {
    label: 'locations.add.form.section.info.chessSets',
    name: '',
    checked: gamForm.value?.form.chessSet,
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.RIGHT,
  };
};

const getNearbyAddress = (humanizeLabel = true): GamButtonMessageType | undefined => {
  if (lastSelectedResult.value?.nearbyPlaceAddress) {
    let label = lastSelectedResult.value.nearbyPlaceAddress;
    if (humanizeLabel) {
      label = localizeWithValues('locations.add.form.search.nearest.address', [label]);
    }
    return {
      label,
    };
  }
};

const handleUseNearbyLocation = () => {
  if (!gamForm.value) return;
  if (!lastSelectedResult.value?.nearbyPlaceAddress) return;

  gamForm.value.form.address = lastSelectedResult.value.nearbyPlaceAddress;
  gamForm.value.form.name = lastSelectedResult.value.label;
  if (lastSelectedResult.value.coordinates) {
    gamForm.value.form.coordinates = lastSelectedResult.value.coordinates;
  }
  lastSelectedResult.value.nearbyPlaceAddress = undefined;
};

const AUTO_CHOOSE_SUGGESTED_LOCATION = false;

watch(lastSelectedResult, (item) => {
  if (AUTO_CHOOSE_SUGGESTED_LOCATION && (item as SearchLocationDto)?.accuracy === 'rooftop') {
    handleUseNearbyLocation();
  }
});

const getNameInput = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamForm.value?.form.name,
    placeholder: 'locations.add.form.name.placeholder',
  };
});

const isChessTableSelected = computed((): boolean => {
  return !!gamForm.value?.form.locationTypes.includes(LocationType.CHESS_TABLE);
});

const isGiantChessSelected = computed((): boolean => {
  return !!gamForm.value?.form.locationTypes.includes(LocationType.GIANT_CHESS);
});

const getChessTableCounter = computed((): GamCounterType => {
  return {
    label: 'locations.add.form.section.info.numOfChessTables',
    value: gamForm.value?.form.numberOfChessboards || 1,
    input: {
      min: 1,
    },
  };
});

const getGiantChessCounter = computed((): GamCounterType => {
  return {
    label: 'locations.add.form.section.info.numOfGiantChess',
    value: gamForm.value?.form.numberOfGiantChessboards || 1,
    input: {
      min: 1,
    },
  };
});

const getLocTypeList = computed((): GamMapFiltersListType => {
  return {
    listId,
    value: gamForm.value?.form.locationTypes,
    isMultiSelect: true,
    isAddMode: true,
  };
});

const getGamScrollItemType = computed((): GamScrollItemsType => {
  return {
    defaultBg: 'var(--color-dark-700)',
    gap: 8,
    spacing: 18,
    preventDrag: true,
  };
});

watch(isChessTableSelected, (value) => {
  if (!gamForm.value || value) return;
  gamForm.value.form.numberOfChessboards = null;
});

watch(isGiantChessSelected, (value) => {
  if (!gamForm.value || value) return;
  gamForm.value.form.numberOfGiantChessboards = null;
});

const setFormValue = (value: Partial<AdminDetailLocationDto>) => {
  const form = {
    ...(formStore.gamForm?.form ?? {}),
    ...value,
  } as LocationFormProps;
  formStore.setForm({ form });
};

watch(
  () => formStore.gamForm?.form.pinpoint,
  async (newPoint) => {
    if (!newPoint) return;
    const { lat, lng } = newPoint as unknown as GeoLocation;

    const { coordinates } = formStore.gamForm?.form || {};
    if (newPoint.lat === coordinates?.lat && newPoint.lng === coordinates.lng) return;

    const result = await gambits.locationService.reverseGeocode(lat, lng);
    const loc = (result?.data as any) || {};

    if (!loc || !gamForm.value || !gamForm.value.form.address === loc.address) return;

    gamForm.value.form.address = loc.address;
    gamForm.value.form.name = loc.name;
    gamForm.value.form.coordinates = loc.coordinates;

    // @ts-ignore
    const map = window.gMap.map;

    // @ts-ignore
    if (!map || !newPoint.lat || !newPoint.lng) return;

    // @ts-ignore
    map.panTo(newPoint);
  },
);

defineExpose({
  isInvalid,
  setFormValue,
  getNearbyAddress,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.location-form {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--spacing-l);

  .panel-header {
    display: flex;
    padding: var(--spacing-md) 0;
    align-items: center;
    gap: var(--spacing-md);
    align-self: stretch;

    .header-icon {
      display: flex;
      padding: var(--spacing-xs);
      justify-content: center;
      align-items: center;
    }

    .header-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .location-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--spacing-l);
  }

  .section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-l);

    .section-title {
      padding: var(--spacing-l) 0;
      font-size: 16px;
      font-weight: 700;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-white-5);
        display: block;
        position: absolute;
        bottom: 0;
      }
    }

    &.additional-info {
      padding-top: var(--spacing-l);
      gap: var(--spacing-l);

      .photos-row-container {
        width: calc(100% + 36px);
        position: relative;
        left: -18px;

        &:after,
        &:before {
          content: '';
          height: 100%;
          width: 18px;
          position: absolute;
          top: 0;
          z-index: 10;
          pointer-events: none;
        }

        &:after {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--color-dark-700) 50%, var(--color-dark-700));
          right: 0;
        }

        &:before {
          background: linear-gradient(to left, rgba(0, 0, 0, 0) 0, var(--color-dark-700) 50%, var(--color-dark-700));
          left: 0;
        }

        .photos-row {
          display: flex;
          gap: var(--spacing-md);
          padding: 0 18px 10px;
        }
      }
    }
  }

  .gam-input .gam-input-container.counter {
    padding: var(--spacing-xs) var(--spacing-l);
  }

  .gam-counter .gam-counter-container .input-count {
    min-width: 100px;
  }
}
</style>
