import type { AuthUserDto } from '@/core/data/auth/auth.interface';
import type { LoginMethodType } from '@/core/data/auth/auth.type';
import { type BaseClubDto } from '@/core/data/club/club.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserInfo } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import posthog from 'posthog-js';
import { nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';

export type Coords = {
  latitude: number;
  longitude: number;
};

export const useAuthStore = defineStore(GamStoreId.AUTH, () => {
  const isUpdating = ref<boolean>(false);
  const user = ref<AuthUserDto | null>(null);
  const userDetails = ref<BaseUserInfo | null>(null);
  const userClubs = ref<BaseClubDto[] | null>(null);
  const authToken = ref<string | null>(null);
  const connecting = ref<LoginMethodType | null>();

  const router = useRouter();

  const isAuthenticated = () => {
    return gambits.authService.isAuthenticated();
  };

  const getUserDetails = () => {
    return userDetails.value;
  };

  const isAdmin = (): boolean => {
    return !!user.value?.isAdmin;
  };

  const setUser = (value: AuthUserDto | null) => {
    user.value = value;
  };

  const authorizeUser = async (value: AuthUserDto | null, authenticated: boolean) => {
    setUser(value);

    if (value && authenticated) {
      const { id, email, username } = value;
      posthog.identify(id, { email, username });
    }
  };

  const logoutUser = async () => {
    setUser(null);
    userDetails.value = null;
    posthog.reset();
    await nextTick(async () => {
      await router.push({ name: RouterNameType.LOCATIONS });
    });
  };

  const fetchUserInfo = async (refresh?: boolean): Promise<void> => {
    if (!isAuthenticated()) return;

    isUpdating.value = !!refresh;

    const userResult = await gambits.userService.getUserInfo(refresh);

    if (userResult?.data) {
      userDetails.value = userResult.data;
    }

    isUpdating.value = false;
  };

  const fetchUserClubs = async (refresh?: boolean): Promise<void> => {
    if (!isAuthenticated()) return;

    isUpdating.value = !!refresh;

    const userClubsResult = await gambits.userService.getUserClubs(refresh);

    if (userClubsResult?.data) {
      userClubs.value = userClubsResult.data;
    }

    isUpdating.value = false;
  };

  const setConnecting = (type: LoginMethodType | null) => {
    connecting.value = type;
  };

  return {
    isUpdating,
    authToken,
    user,
    userDetails,
    userClubs,
    connecting,
    setUser,
    fetchUserInfo,
    fetchUserClubs,
    authorizeUser,
    getUserDetails,
    logoutUser,
    isAuthenticated,

    isAdmin,
    setConnecting,
  };
});
