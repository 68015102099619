<template>
  <div class="gam-gambit-date-item" :class="[size, { 'is-mobile': isMobile, end: isEnd }]">
    <div class="date-time">
      <div class="gam-day" :class="{ skeleton: !date }">
        <div>{{ GamHelper.getDayOfDate(date) }}</div>
      </div>

      <div class="gam-time" :class="{ skeleton: !date }">
        <div v-html="getGamTime()" />
      </div>
    </div>

    <div class="date-type" :class="[type, { skeleton: !date }]">
      <span>{{ getTimeTag() || '' }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize, localizeWithValues } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { GamDateType } from '@/views/composables/constants/components/gamGambit.constants';
import type { GamGambitDateItemType } from '@/views/composables/models/components/GamGambit';

const props = defineProps<GamGambitDateItemType>();

const getTimeTag = () => {
  if (!props.date) return;
  if (props.type === GamDateType.DAY) {
    return GamHelper.getShortDayOfWeek(props.date);
  } else {
    return localize(`gambit.card.date.time.type.${props.type}`);
  }
};

const getGamTime = () => {
  const time = props.date ? GamHelper.getTimeOfDate(props.date) : '';
  const month = props.date ? GamHelper.getShortMonthOfDate(props.date) : '';

  return localizeWithValues('gambit.card.date.time', [month, time]);
};
</script>

<style lang="scss">
.gam-gambit-date-item {
  display: flex;
  width: max-content;
  min-width: 96px;
  height: inherit;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing-xs);
  border-radius: var(--radius-small) 0 0 0 !important;

  &.is-mobile {
    min-width: 72px;
  }

  &.end {
    align-items: flex-end;

    .date-time {
      align-items: flex-end;
      text-align: right;
    }
  }

  &.large {
    justify-content: space-between;
    flex-shrink: 0;

    .date-time {
      gap: 0;
      flex: 1 0 0;

      .gam-day {
        font-size: 48px;
      }

      .gam-time {
        font-size: 16px;
      }
    }

    .date-type {
      font-size: 16px;
    }
  }

  .date-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: -6px;
    align-self: stretch;

    .gam-day {
      font-size: 32px;
      font-weight: 300;
      color: var(--color-white-100);
    }

    .gam-time {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-white-70);

      div {
        line-height: 1.4;
      }

      span {
        color: var(--color-white-100);
      }
    }
  }

  .date-type {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-white-30);
    transition: color var(--transition-fast);
    text-transform: uppercase;
    line-height: 110%;

    &.soon,
    &.now {
      background: var(--color-linear-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.tomorrow,
    &.today {
      background: var(--color-linear-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
