<template>
  <dialog ref="dialog" class="gam-notification" @close="hideDialog">
    <transition name="fade">
      <div v-if="visible" class="gam-notification-modal" :class="[getClasses, content?.variant, position]">
        <div class="gam-notification-content">
          <div v-if="!preventClose" class="close-button">
            <gam-icon :name="GamIconName.X_CLOSE" @click="closeModal" />
          </div>
          <notification-content v-if="content" v-bind="content" @close="closeModal" />
        </div>
      </div>
    </transition>
  </dialog>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import NotificationContent from '@/views/components/gam-notification/NotificationContent.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { NotificationPosition } from '@/views/composables/constants/notification.constants';
import type { NotificationType } from '@/views/composables/models/notification.interface';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<NotificationType>(), {
  position: NotificationPosition.TOP,
});
const emits = defineEmits([GamComponentsEmits.CLOSE]);
const dialog = ref<HTMLDialogElement>();
const visible = ref(false);
const appStore = useAppStore();

const showModal = () => {
  dialog.value?.showModal();

  if (dialog.value) {
    dialog.value?.addEventListener('cancel', (event) => {
      if (props.preventClose) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }
  appStore.setDialogOpened(true);
  visible.value = true;
};

const closeModal = (): void => {
  if (!props.preventClose) {
    dialog.value?.close();
  }
};

const hideDialog = (): void => {
  appStore.setDialogOpened(false);
  visible.value = false;
  emits(GamComponentsEmits.CLOSE);
};

const getClasses = computed(() => {
  return {
    prevent: props.preventClose,
    mobile: isMobile.value,
  };
});

defineExpose({
  show: showModal,
  close: closeModal,
  visible,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-notification {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: none;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: none;
  border: 0;
  margin: 0 auto;
  padding: 0;
  transition: visibility var(--transition-loader);
  color: var(--color-white-100);
  pointer-events: all;

  &::backdrop {
    display: none;
  }

  .gam-notification-modal {
    display: flex;
    position: absolute;
    width: 100%;
    pointer-events: all;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.top {
      padding: 56px 48px 82px;
      background: linear-gradient(357deg, rgba(24, 23, 22, 0) 2.75%, rgba(24, 23, 22, 0.7) 97.24%);
      top: 0;

      .gam-notification-content {
        &:before {
          padding: 1px 0 0;
        }
      }
    }

    &.bottom {
      padding: 72px 48px 82px;
      background: linear-gradient(357deg, rgba(24, 23, 22, 0.4) 2.75%, rgba(24, 23, 22, 0) 92.77%);
      bottom: 0;

      .gam-notification-content {
        &:before {
          padding: 0 0 1px;
        }
      }
    }

    &.mobile {
      &.top {
        padding: 56px var(--spacing-md) 82px;
      }

      &.bottom {
        padding: 72px var(--spacing-md) 82px;
      }

      .gam-notification-content {
        width: 100%;
        padding: var(--spacing-menu);
      }
    }

    &.error {
      .gam-notification-content {
        background-color: var(--color-error-dark);
        box-shadow: var(--shadow-backdrop-panel-upper) var(--shadow-red-20);
      }

      &.top {
        .gam-notification-content {
          border-top: 1px solid var(--color-red);
        }
      }

      &.bottom {
        .gam-notification-content {
          border-bottom: 1px solid var(--color-red);
        }
      }
    }

    &.info {
      .gam-notification-content {
        @extend .gam-special-border !optional;

        &:before {
          background: var(--color-linear-gradient-70);
        }
      }
    }

    .gam-notification-content {
      position: relative;
      display: flex;
      pointer-events: auto;
      align-items: flex-start;
      justify-content: flex-end;
      flex-shrink: 0;
      padding: var(--spacing-xxl);
      border-radius: var(--radius-normal);
      background-color: var(--color-dark-700);
      box-shadow: var(--shadow-backdrop-panel-upper) var(--shadow-pink-40);
      z-index: 100;

      .close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
