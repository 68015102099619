<template>
  <gam-side-panel v-bind="generalSidePanel">
    <div v-if="isOpened" class="profile-general">
      <div v-if="userForm" class="general-sections">
        <div class="section-item">
          <div class="section-title">
            {{ localize('profile.my.general.section.account.title') }}
          </div>

          <div class="form-row">
            <gam-input v-bind="firstName" v-model="userForm.firstName" />
            <gam-input v-bind="lastName" v-model="userForm.lastName" />
          </div>

          <div class="form-row">
            <gam-input v-bind="userMail" v-model="userForm.email" />
          </div>

          <div class="form-row">
            <div class="input-with-support">
              <gam-input v-bind="username" v-model="userForm.username" @update="checkUsername" />
              <gam-support-text v-if="!usernameField.error" label="Letters, numbers, underscores, or dots." />
            </div>
            <gam-input v-bind="emoji" v-model="userForm.emoji" class="emoji" />
          </div>
        </div>

        <div class="section-item">
          <div class="section-title">
            {{ localize('profile.my.general.section.additional.title') }}
          </div>

          <div class="form-row">
            <gam-input-date v-bind="birthDate" v-model="userForm.date" />
          </div>

          <div class="form-row">
            <gam-textarea v-model="userForm.aboutMe" placeholder="about me" :value="userForm.aboutMe" />
          </div>
        </div>

        <div v-if="false" class="section-item">
          <div class="section-title">
            {{ localize('profile.my.general.section.type.title') }}
          </div>

          <div class="form-row">
            <gam-switch v-bind="getSwitch(PlayerType.COACH)" v-model:checked="switchCoachValue" class="switch" />
          </div>

          <div class="form-row">
            <gam-switch v-bind="getSwitch(PlayerType.STREAMER)" v-model:checked="switchStreamerValue" class="switch" />
          </div>
        </div>

        <div class="section-item">
          <div class="section-title">
            {{ localize('profile.my.general.section.photos.title') }}
          </div>

          <div class="form-row">
            <div class="image">
              <div class="label">
                {{ localize('auth.page.registration.form.profile.label') }}
              </div>

              <gam-image
                v-model="userForm.profileImage"
                :image="userForm.profileImage"
                :add="true"
                :size="GamImageSize.MEDIUM"
                @expand="expandImage"
              />
            </div>

            <div v-if="false" class="image">
              <div class="label">
                {{ localize('auth.page.registration.form.chessboard.label') }}
              </div>

              <gam-image
                v-model="userForm.chessboardImage"
                :image="userForm.chessboardImage"
                :add="true"
                :size="GamImageSize.MEDIUM"
                @expand="expandImage"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="personal-section">
        <div class="section-title">
          {{ localize('profile.my.general.section.personal.title') }}
        </div>

        <gam-nav-item-link v-bind="getDeleteAccountItem()" />
      </div>
    </div>

    <gam-expand-image
      v-if="selectedImage"
      ref="imageExpand"
      v-bind="selectedImage"
      @close="clearSelected"
      @clear="deleteImage"
    />

    <gam-dialog ref="dialog">
      <simple-content-dialog v-bind="deleteUserDialog()" />
    </gam-dialog>
  </gam-side-panel>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { BlobSource } from '@/core/data/report/report.type';
import { PlayerType } from '@/core/data/user/user.type';
import { gambits, localize } from '@/core/gambits';
import FormHelper from '@/core/helpers/form.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import SimpleContentDialog from '@/views/components/gam-dialog/dialog-contents/SimpleContentDialog.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import GamNavItemLink from '@/views/components/gam-nav/GamNavItemLink.vue';
import GamExpandImage from '@/views/components/GamExpandImage.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamInputDate from '@/views/components/GamInputDate.vue';
import GamSupportText from '@/views/components/GamSupportText.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamNavVariant } from '@/views/composables/constants/components/gamNav.constants';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { getProfileSidePanel } from '@/views/composables/constants/main/profile.constants';
import type { GamExpandImageType } from '@/views/composables/models/components/GamImage';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamNavItemLinkType } from '@/views/composables/models/components/GamNav';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { SimpleContentDialogType } from '@/views/composables/models/dialog.interface';
import type { ProfileUserForm, ProfileUserValidator } from '@/views/composables/models/form.interface';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const emits = defineEmits([GamComponentsEmits.UPDATE]);

const panelId = GamSidePanelId.PROFILE_GENERAL;
const formId = FormId.GENERAL_PROFILE;
const isLoading = ref<boolean>(false);

const emojiValidator = FormHelper.emojiValidator;
const usernameValidator = FormHelper.usernameValidator;
const formStore = useFormStore<ProfileUserForm, ProfileUserValidator>(formId)();
const { usernameField } = storeToRefs(formStore);

const authStore = useAuthStore();
const { userDetails, user } = storeToRefs(authStore);

const router = useRouter();
const route = useRoute();

/* Player type checkboxes */
const switchCoachValue = ref<boolean>(!!userDetails.value?.playerTypes?.includes(PlayerType.COACH));
const switchStreamerValue = ref<boolean>(!!userDetails.value?.playerTypes?.includes(PlayerType.STREAMER));

const selectedImage = ref<GamExpandImageType | null>();
const imageExpand = ref<InstanceType<typeof GamExpandImage>>();

const dialog = ref<InstanceType<typeof GamDialog>>();
const deleteUserLoading = ref<boolean>(false);

const getForm = reactive({
  firstName: userDetails.value?.firstName || '',
  lastName: userDetails.value?.lastName || '',
  username: userDetails.value?.username || '',
  email: user.value?.email || '',
  emoji: userDetails.value?.emoji || '',
  aboutMe: userDetails.value?.aboutMe || '',
  profileImage: userDetails.value?.profileImageBlobHash || null,
  chessboardImage: userDetails.value?.chessBoardImageBlobHash || null,
  playerTypes: userDetails.value?.playerTypes || [],
  date: userDetails.value?.birthDate ? new Date(userDetails.value?.birthDate) : undefined,
});

const userForm = reactive({
  firstName: userDetails.value?.firstName || '',
  lastName: userDetails.value?.lastName || '',
  username: userDetails.value?.username || '',
  email: user.value?.email || '',
  emoji: userDetails.value?.emoji || '',
  aboutMe: userDetails.value?.aboutMe || '',
  profileImage: userDetails.value?.profileImageBlobHash || null,
  chessboardImage: userDetails.value?.chessBoardImageBlobHash || null,
  playerTypes: userDetails.value?.playerTypes || [],
  date: userDetails.value?.birthDate ? new Date(userDetails.value?.birthDate) : undefined,
});

const dateValidator = (date: Date): boolean => {
  return FormHelper.isValidDate(date, gambits.configService.getAuthMinAge());
};

const getRules = {
  firstName: { required },
  lastName: { required },
  email: { required, email },
  username: { required, usernameValidator },
  emoji: { emojiValidator },
  date: { required, dateValidator },
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
  formStore.setUsernameValidated(true);
});

const v$ = useVuelidate(getRules, userForm);

const isInvalid = computed((): boolean => {
  return v$.value.$invalid || !usernameField.value.isValidated;
});

const checkUsername = debounce(() => {
  if (userForm.username === userDetails.value?.username) {
    formStore.setUsernameValidated(true);
    formStore.setUsernameError(null);

    return;
  }

  formStore.setUsernameValidated(null);
  FormHelper.checkUsername(formId, userForm.username || '', v$);

  nextTick(() => {
    if (userDetails.value?.username === userForm.username) {
      formStore.setUsernameValidated(true);
      formStore.setUsernameError(null);
    } else {
      formStore.setUsernameValidated(null);
      FormHelper.checkUsername(formId, userForm.username || '', v$);
    }
  });
}, 400);

const getDeleteAccountItem = (): GamNavItemLinkType => {
  return {
    name: '',
    displayName: 'profile.my.general.section.personal.delete.title',
    variant: GamNavVariant.SECONDARY,
    button: {
      label: 'profile.my.general.section.personal.delete.button',
      onClick: () => {
        dialog.value?.show();
      },
    },
  };
};

const expandImage = (hash: string) => {
  selectedImage.value = {
    selectedIndex: 0,
    blobSource: BlobSource.USER_PROFILE,
    images: [
      {
        id: hash,
        imageBlobHash: hash,
        liked: false,
      },
    ],
    canDelete: true,
    closeAfterDelete: true,
  };
  nextTick(() => {
    imageExpand.value?.show();
  });
};

const clearSelected = () => {
  selectedImage.value = null;
};

const deleteImage = (hash: string) => {
  if (userForm.profileImage === hash) {
    userForm.profileImage = null;
  } else if (userForm.chessboardImage === hash) {
    userForm.chessboardImage = null;
  }
};

const deleteUserDialog = (): SimpleContentDialogType => {
  return {
    title: 'dialog.user.delete.title',
    message: 'dialog.user.delete.message',

    action: {
      label: 'dialog.user.delete.button',
      isLoading: deleteUserLoading.value,
      onClick: async () => {
        deleteUserLoading.value = true;
        const deleteResult = await gambits.authService.delete();

        if (deleteResult.data) {
          await gambits.authService.logout();
        }
        deleteUserLoading.value = false;
      },
    },
  };
};

const firstName = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: userForm.firstName,
    placeholder: 'auth.page.registration.form.firstName.placeholder',
  };
});

const lastName = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_SURNAME,
    value: userForm.lastName,
    placeholder: 'auth.page.registration.form.lastName.placeholder',
  };
});

const userMail = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_EMAIL,
    value: userForm.email,
    placeholder: 'auth.page.registration.form.email.placeholder',
    isDisabled: true,
  };
});

const birthDate = computed(() => {
  return {
    name: GamInputName.GAM_DATETIME,
    value: userForm.date,
  };
});

const username = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_USERNAME,
    value: userForm.username,
    placeholder: 'auth.page.registration.form.username.placeholder',
    isLoading: usernameField.value.isLoading,
    isError: usernameField.value.isValidated === false,
    message: usernameField.value.error,
    rightIcon: usernameField.value.isValidated ? GamIconName.CHECK : undefined,
    maxLength: 42,
    isPrimary: true,
    isLowerCase: true,
  };
});

const emoji = computed((): GamInputType => {
  return {
    name: GamInputName.EMOJI,
    value: userForm.emoji,
    placeholder: 'auth.page.registration.form.emoji.placeholder',
    isEmoji: true,
  };
});

watch(
  () => route.query.side_panel,
  () => {
    if (route.name !== RouterNameType.PROFILE || route.query.side_panel !== 'general') {
      return;
    }

    authStore.fetchUserInfo(true);
  },
);

watch(
  () => userDetails.value,
  () => {
    const { value: details } = userDetails;

    if (!details) {
      return;
    }

    userForm.firstName = details.firstName || '';
    userForm.lastName = details.lastName || '';
    userForm.username = details.username || '';
    userForm.email = user.value?.email || '';
    userForm.emoji = details.emoji || '';
    userForm.aboutMe = details.aboutMe || '';
    userForm.profileImage = details.profileImageBlobHash || null;
    userForm.chessboardImage = details.chessBoardImageBlobHash || null;
    userForm.playerTypes = details.playerTypes || [];
    userForm.date = details.birthDate ? new Date(details.birthDate) : undefined;
  },
);

const isOpened = computed(() => {
  return route.query.side_panel === 'general';
});

const generalSidePanel = computed(() => {
  return {
    isOpened: isOpened.value,
    ...getProfileSidePanel({
      title: 'Your profile',
      panelId,
      onSubmit: saveProfile,
      onCancel: closeSave,
      isActionDisabled: isInvalid.value,
      isLoading: isLoading.value,
    }),
  };
});

const getSwitch = (type: PlayerType): GamSwitchType => {
  return {
    label: `profile.my.general.section.type.${type}.title`,
    name: '',
    supportText: `profile.my.general.section.type.${type}.description`,
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.LEFT,
  };
};

const saveProfile = async () => {
  isLoading.value = true;
  const result = await gambits.userService.updateUser(userForm);

  if (result.data) {
    emits(GamComponentsEmits.UPDATE);
    router.push({
      ...route,
      query: {
        ...route.query,
        side_panel: undefined,
      },
    });
  }
  isLoading.value = false;
};

const closeSave = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

watch(switchCoachValue, (value: boolean) => {
  if (userForm.playerTypes) {
    userForm.playerTypes = FormHelper.syncPlayerType(PlayerType.COACH, userForm.playerTypes, !value);
  }
});

watch(switchStreamerValue, (value: boolean) => {
  if (userForm.playerTypes) {
    userForm.playerTypes = FormHelper.syncPlayerType(PlayerType.STREAMER, userForm.playerTypes, !value);
  }
});
</script>

<style lang="scss">
#profile-general {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}
</style>

<style scoped lang="scss">
@use '@/ui/css/partial';

.input-with-support {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 15rem;
  min-height: 4rem;

  .gam-support-text {
    position: absolute;
    top: 3.75rem;
    padding-left: 1.5rem;
    // max-width: 12rem;
    width: 20rem;
  }
}

.input-with-support {
  padding-bottom: 1rem;
}

.profile-general {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-menu);

  .section-title {
    font-size: 16px;
    font-weight: 700;
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: var(--spacing-md);
  }

  .general-sections {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
    align-self: stretch;

    .section-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: var(--spacing-l);
      gap: var(--spacing-l);
      border-radius: var(--radius-small);
      min-width: 0;
      @extend .gam-special-border !optional;

      &:before {
        background: var(--color-dark-600);
      }
    }

    .form-row {
      width: 100%;
      display: flex;
      gap: var(--spacing-md);

      .emoji {
        width: 80px;
      }

      .image {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);

        .label {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-white-70);
          line-height: 110%;
        }
      }
    }
  }
}

.simple-content-dialog:not(.mobile) {
  width: 20rem;
}
</style>
