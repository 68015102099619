import './ui/css/style.scss';

import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import Vue3TouchEvents, { type Vue3TouchEventsOptions } from 'vue3-touch-events';

import { gambits } from '@/core/gambits';
import loadingDirective from '@/directives/loading.directive';
import tooltipDirective from '@/directives/tooltip.directive';
import * as Sentry from '@sentry/vue';
import { GesturePlugin } from '@vueuse/gesture';
import App from './App.vue';
import router from './router';

import { detectLoggingLevel } from '@/core/helpers/logger.helper';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import posthog from 'posthog-js';

detectLoggingLevel();

const app = createApp(App);
const head = createHead();
const sentryConfig = gambits.configService.getSentryConfig();

if (sentryConfig?.dsn) {
  Sentry.init({
    app,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    ...sentryConfig,
  });
}

app.use(head);
app.use(createPinia());
app.use(router);
app.use(GesturePlugin);
app.use(loadingDirective);
app.use(tooltipDirective);
app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
  disableClick: true,
});

app.use(
  VueGtag,
  {
    appName: 'Gambits',
    bootstrap: false,
    config: {
      id: import.meta.env.VITE_GTM_ID || '',
    },
  },
  router,
);

app.mount('#Gambits');

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;
if (typeof POSTHOG_API_KEY === 'string' && POSTHOG_API_KEY.length > 0) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always',
    capture_pageview: false,
    disable_surveys: true,
    enable_recording_console_log: true,
    secure_cookie: true,
  });
}

const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID;
const FIREBASE_VAPID_KEY = import.meta.env.VITE_FIREBASE_VAPID_KEY;

const firebaseConfigReady =
  FIREBASE_API_KEY &&
  FIREBASE_AUTH_DOMAIN &&
  FIREBASE_PROJECT_ID &&
  FIREBASE_STORAGE_BUCKET &&
  FIREBASE_MESSAGING_SENDER_ID &&
  FIREBASE_APP_ID &&
  FIREBASE_VAPID_KEY;

if (firebaseConfigReady) {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  const firebaseApp = initializeApp(firebaseConfig);

  const registerServiceWorker = async () => {
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    // eslint-disable-next-line no-console
    console.log('Service Worker registered with scope:', registration.scope);
    return registration;
  };

  window.allowNotifications = async () => {
    await registerServiceWorker(); // Ensure SW is registered

    const messaging = getMessaging(firebaseApp);
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') return;

    try {
      const currentToken = await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY });
      // eslint-disable-next-line no-console
      if (currentToken) console.info('Received the token', currentToken);
      return currentToken;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error retrieving token:', err);
    }
  };
}
