<template>
  <div :class="getAddPinClassName()" @click="setPosition">
    <div class="add-marker">
      <svg
        v-if="!isPinned"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        class="tracking-cursor"
      >
        <path d="M18 0L18 36" stroke="url(#paint0_linear_3600_117621)" stroke-width="2" />
        <path d="M36 18L0 18" stroke="url(#paint1_linear_3600_117621)" stroke-width="2" />
        <defs>
          <linearGradient
            id="paint0_linear_3600_117621"
            x1="18"
            y1="6.78559"
            x2="18"
            y2="6.78559"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF6DA2" />
            <stop offset="1" stop-color="#F54CE4" />
          </linearGradient>

          <linearGradient
            id="paint1_linear_3600_117621"
            x1="3.65914"
            y1="18"
            x2="3.65914"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF6DA2" />
            <stop offset="1" stop-color="#F54CE4" />
          </linearGradient>
        </defs>
      </svg>

      <div v-if="props.label" class="marker-info">
        <gam-chip v-bind="getMarkerChip" />
      </div>
    </div>

    <svg
      v-if="isPinned"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      class="pinned-to-map"
    >
      <path d="M18 0L18 36" stroke-width="2" />
      <path d="M36 18L0 18" stroke-width="2" />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { MapLocationSource } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamChip from '@/views/components/GamChip.vue';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamAddPinType } from '@/views/composables/models/components/GamMap';
import { storeToRefs } from 'pinia';
import { computed, onUnmounted, watch } from 'vue';

const mapStore = useMapStore();

const tableStore = useTableStore<GamDropdownItem>(GamListId.ADD_LOCATION)();
const { selectedItem } = storeToRefs(tableStore);

const props = defineProps<GamAddPinType>();
const emits = defineEmits([GamComponentsEmits.UPDATE]);

const getAddPinClassName = () => [
  'gam-add-pin',
  { 'is-pinned': props.isPinned, 'is-unclickable': props.isUnclickable, 'is-mobile': isMobile.value },
];

const setPosition = async () => {
  if (props.isDragging) {
    return;
  }

  if (props.pin) {
    const coords = props.pin.coordinates;
    emits(GamComponentsEmits.UPDATE, coords);
  }
};

const getMarkerChip = computed((): GamChipType => {
  return {
    size: GamChipSize.SMALL,
    variant: GamChipVariant.DEFAULT,
    isActive: true,
    label: props.label,
  };
});

watch(selectedItem, async () => {
  if (selectedItem.value?.id && selectedItem.value.source === MapLocationSource.EXTERNAL) {
    if (!selectedItem.value.coordinates) {
      const result = await gambits.locationService.locationPlace(selectedItem.value.id);
      if (result.data) {
        selectedItem.value.coordinates = result.data;
      }
    }
  }

  if (selectedItem.value?.coordinates) {
    emits(GamComponentsEmits.UPDATE, selectedItem.value.coordinates);
  }
});

onUnmounted(async () => {
  await tableStore.setSearchQuery({ preventLoad: true });
  mapStore.clearAddMode();
  emits(GamComponentsEmits.UPDATE, null);
});
</script>

<style lang="scss">
#g-map
  > div.mapdiv
  > div
  > div.gm-style
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div:nth-child(3)
  > div:nth-child(4) {
  position: relative;
  z-index: 501;
}
</style>

<style scoped lang="scss">
.gam-add-pin {
  display: block;
  z-index: 500;

  .add-marker {
    position: relative;

    .marker-info {
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }

  &.is-unclickable {
    pointer-events: none;
  }
}

.tracking-cursor {
  z-index: 2;
}
</style>

<style lang="scss">
@keyframes pulse {
  0% {
    stroke: black;
  }

  100% {
    stroke: white;
  }
}

@keyframes pulse-pink {
  0% {
    stroke: #ffffff;
  }

  50% {
    stroke: var(--color-pink);
  }

  100% {
    stroke: #000000;
  }
}

.pinned-to-map {
  z-index: 1;
  animation: pulse-pink 500ms linear alternate infinite;
}
</style>
