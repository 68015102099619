import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export const useAppStore = defineStore(GamStoreId.APP, () => {
  const router = useRouter();

  const previousPath = ref<string>();
  const dialogOpened = ref<boolean>(false);
  const drawerOpened = ref<boolean>(false);
  const imageExpanded = ref<boolean>(false);

  const isOnline = ref<boolean>(navigator.onLine);
  const wasOffline = ref<boolean>(!navigator.onLine);

  const setDialogOpened = (value: boolean) => {
    dialogOpened.value = value;
  };

  const setDrawerOpened = (value: boolean) => {
    drawerOpened.value = value;
  };

  const setImageExpanded = (value: boolean) => {
    imageExpanded.value = value;
  };

  window.addEventListener('online', () => {
    isOnline.value = true;
  });
  window.addEventListener('offline', () => {
    isOnline.value = false;
    wasOffline.value = true;
  });

  router.afterEach((to, from) => {
    previousPath.value = from.fullPath;
  });

  const isFirstVisit = () => {
    return previousPath.value === '/';
  };

  return {
    previousPath,
    dialogOpened,
    drawerOpened,
    imageExpanded,
    setDialogOpened,
    setDrawerOpened,
    setImageExpanded,
    isFirstVisit,
    isOnline,
    wasOffline,
  };
});
