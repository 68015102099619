import type { BaseClubDto, DetailClubDto } from '@/core/data/club/club.interface';
import type { GeoLocation } from '@/core/data/location/location.interface';
import { gambits } from '@/core/gambits';
import type { Result } from '@/core/network/http/httpClient.interface';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface IFetchOptions {
  id?: string;
  geo?: GeoLocation;
  filters?: any;
}

export const useClubStore = defineStore('clubs', () => {
  const isLoading = ref<boolean>(true);
  const isError = ref<boolean>(false);
  const clubs = ref<BaseClubDto[]>([]);
  const club = ref<DetailClubDto>();
  const afterCursor = ref<string | null>(null);
  const beforeCursor = ref<string | null>(null);

  const fetchAll = async (options?: IFetchOptions): Promise<void> => {
    isError.value = false;
    isLoading.value = true;

    const result = await gambits.clubService.getClubs(options?.geo, afterCursor.value);

    if (Array.isArray(result?.data)) {
      clubs.value = result.data;
      setCursor(result?.cursor?.beforeCursor, result?.cursor?.afterCursor);
    }

    if (!result?.data || result.error) {
      isError.value = true;
    }

    isLoading.value = false;
  };

  const fetchMore = async (options?: IFetchOptions): Promise<void> => {
    isError.value = false;
    isLoading.value = true;

    const result = await gambits.clubService.getClubs(options?.geo, afterCursor.value);

    if (Array.isArray(result?.data)) {
      clubs.value = clubs.value.concat(result.data);
      setCursor(result?.cursor?.beforeCursor, result?.cursor?.afterCursor);
    }

    if (!result?.data || result.error) {
      isError.value = true;
    }

    isLoading.value = false;
  };

  const fetchOne = async (params: { id?: string; username?: string }): Promise<Result<DetailClubDto> | undefined> => {
    isError.value = false;
    isLoading.value = true;

    let result;
    if (params.id) {
      result = await gambits.clubService.getClub(params.id);
    } else if (params.username) {
      result = await gambits.clubService.getClubByUsername(params.username);
    }

    if (result?.data) {
      club.value = result.data;
      if (clubs.value.find((c) => c.id === result.data.id)) {
        updateClub(result.data);
      } else {
        clubs.value.push(result.data);
      }
    } else {
      isError.value = true;
    }

    isLoading.value = false;

    return result;
  };

  const addClub = (club: BaseClubDto) => {
    clubs.value = [...clubs.value, club];
  };

  const removeClub = (id: string) => {
    clubs.value = clubs.value.filter((c) => c.id !== id);
  };

  const updateClub = (club: BaseClubDto) => {
    clubs.value = clubs.value.map((c) => (c.id === club.id ? club : c));
  };

  const setCursor = (newBeforeCursor?: string | null, newAfterCursor?: string | null): void => {
    if (newBeforeCursor === null) {
      beforeCursor.value = null;
    } else if (newBeforeCursor) {
      beforeCursor.value = newBeforeCursor;
    }
    if (newAfterCursor === null) {
      afterCursor.value = null;
    } else if (newAfterCursor) {
      afterCursor.value = newAfterCursor;
    }
  };

  return {
    clubs,
    club,
    isLoading,
    isError,
    beforeCursor,
    afterCursor,
    setCursor,
    fetchAll,
    fetchMore,
    fetchOne,
    addClub,
    removeClub,
    updateClub,
  };
});
