<template>
  <div class="gam-tab">
    <div :class="['gam-tab-container', getClasses, size]">
      <gam-tab-item v-for="tab in tabs" v-bind="getTab(tab)" :key="tab.position" @update="onTabItemClick" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { TabItemType } from '@/core/data/config/uiConfig.interface';
import GamTabItem from '@/views/components/gam-tab/GamTabItem.vue';
import { GamTabSize } from '@/views/composables/constants/components/gamTab.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamTabItemType, GamTabType } from '@/views/composables/models/components/GamTab';
import { computed } from 'vue';

const props = withDefaults(defineProps<GamTabType>(), {
  background: true,
  size: GamTabSize.SMALL,
});

const emit = defineEmits([GamComponentsEmits.UPDATE]);

const onTabItemClick = (name: TabItemType) => {
  emit(GamComponentsEmits.UPDATE, name);
};

const getClasses = computed(() => {
  return {
    background: props.background,
  };
});

const getTab = (tab: GamTabItemType): GamTabItemType => {
  return {
    ...tab,
    isDisabled: tab.isDisabled,
  };
};
</script>
<style scoped lang="scss">
@use '@/ui/css/partial';

$switch-size: 16px;
$radio-size: 8px;

.gam-tab {
  width: 100%;
  display: flex;

  .gam-tab-container {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    border-radius: var(--radius-x-large);
    gap: var(--spacing-xs);
    padding: var(--spacing-xs);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    &.background {
      background: var(--color-white-5);
    }

    &.medium {
      height: 38px;
    }

    &.large {
      height: 46px;
    }
  }
}
</style>
