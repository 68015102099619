<template>
  <div class="gam-message" :class="{ 'is-clickable': onClick }" @click="handleClick">
    <div class="gam-message-container" :class="variant">
      <div v-if="leftIcon" class="icon icon-left">
        <gam-icon :name="leftIcon" />
      </div>
      <div v-if="label" class="label">
        <span v-html="localize(label)"></span>
      </div>
      <div v-if="rightIcon" class="icon icon-right">
        <gam-icon :name="rightIcon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonMessageVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';

const props = withDefaults(defineProps<GamButtonMessageType>(), {
  style: GamButtonMessageVariant.DEFAULT,
  leftIcon: GamIconName.WARNING,
});

const handleClick = (e: MouseEvent) => {
  if (!props.onClick) return;

  e.preventDefault();
  e.stopPropagation();

  props.onClick(e);
};
</script>

<style lang="scss" scoped>
.gam-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;

  &.is-clickable {
    cursor: pointer;
  }

  .gam-message-container {
    display: inline-flex;
    background: var(--color-linear-gradient-30);
    padding: var(--spacing-xs) var(--spacing-md) var(--spacing-xs) var(--spacing-l);
    align-items: center;
    gap: var(--spacing-l);
    border-radius: var(--radius-normal);
    transition:
      opacity var(--transition),
      color var(--transition),
      background-color var(--transition);

    &.error {
      background: var(--color-red-30);
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-white-100);
      text-wrap-style: balance;

      span {
        display: -webkit-box;
        line-height: 110%;
      }
    }

    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
