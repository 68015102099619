<template>
  <div class="gam-rating-gambits">
    <GamSimpleInput v-bind="getRatingInput()" v-model="ratings.rating" />
    <div class="message-wrapper">
      <div class="description" v-html="localize('app.component.ratings.item.gambits.description')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { useRatingStore } from '@/stores/rating.store';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';
import GamSimpleInput from '../../GamSimpleInput.vue';

const ratingStore = useRatingStore();
const { maxRating, ratings } = storeToRefs(ratingStore);

const getRatingInput = (): GamInputType => {
  return {
    name: GamInputName.RATING_GAMBITS,
    placeholder: localize('app.component.ratings.input'),
    label: localize('app.component.ratings.input'),
    value: ratings.value.rating,
    type: GamInputValueType.NUMBER,
    max: maxRating.value,
  };
};
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-rating-gambits {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);

  .message-wrapper {
    .description {
      font-size: 14px;
      line-height: 110%;
      font-weight: 400;
      color: var(--color-white-70);

      span {
        font-weight: 700;
      }
    }
  }
}
</style>
