<template>
  <gam-side-panel v-bind="editClubSidePanel" @close="closeEditClub">
    <div class="edit-club" :class="{ 'is-mobile': isMobile }">
      <ClubFormComponent v-if="clubData" ref="clubForm" :key="props.club.id" :form-id="formId" :club="clubData" />
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import type { DetailClubDto } from '@/core/data/club/club.interface';
import { ClubType } from '@/core/data/club/club.type';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useClubStore } from '@/stores/club.store';
import { useFormStore } from '@/stores/form.store';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamButtonMessageVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { getClubSidePanel } from '@/views/composables/constants/main/club.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { ClubForm, ClubValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ClubFormComponent from '../forms/ClubForm.vue';

const formId = FormId.EDIT_CLUB;
const panelId = GamSidePanelId.CLUB_FORM;

interface Props {
  club: DetailClubDto;
}

const props = defineProps<Props>();

const clubData = ref<DetailClubDto | null>(null);

const formStore = useFormStore<ClubForm, ClubValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const clubStore = useClubStore();

const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);
const clubForm = ref<InstanceType<typeof ClubFormComponent>>();

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const isInvalid = ref<boolean>(true);

watch(
  () => clubForm.value?.isInvalid,
  () => {
    if (!clubForm.value) {
      return;
    }

    isInvalid.value = clubForm.value.isInvalid;
  },
);

const closeEditClub = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

const router = useRouter();
const route = useRoute();

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.name = '';
  form.username = '';
  form.clubType = ClubType.CASUAL;
  form.description = undefined;
  form.photos = [];
  form.profileImage = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'edit_club' && newPanel.side_panel === 'edit_club') {
    clearForm();
  }
});

const editClubSidePanel = computed((): GamSidePanelType => {
  return getClubSidePanel({
    panelId,
    isOpened: route.query.side_panel === 'edit_club',
    errorMessage: errorMessage.value,
    title: 'clubs.edit.title.label',
    icon: GamIconName.MUTUAL_EMPTY,
    submitLabel: 'club.edit.form.action.button',
    onSubmit: async () => {
      if (gamForm.value?.form) {
        isLoading.value = true;
        const result = await gambits.clubService.updateClub(props.club.id, gamForm.value.form);
        if (result.data) {
          clubStore.updateClub({ ...result.data, canEdit: true });
          emits(GamComponentsEmits.SUCCESS);
          closeEditClub();
          clearForm();
        } else if (result.error) {
          errorMessage.value = {
            label: result.error.message,
            variant: GamButtonMessageVariant.ERROR,
          };
        }
        isLoading.value = false;
      }
    },
    cancelLabel: 'club.edit.form.clear.button',
    onCancel: closeEditClub,
    isActionDisabled: isInvalid.value,
    isLoading: isLoading.value,
  });
});

watch(
  () => [props.club.id, route.query.side_panel],
  () => {
    route.query.side_panel === 'edit_club' && getClub();
  },
);

onBeforeMount(() => {
  route.query.side_panel === 'edit_club' && getClub();
});

const getClub = async () => {
  const club = await gambits.clubService.getClub(props.club.id);

  if (club.error || !club.data) return;

  clubData.value = club.data;
};
</script>

<style lang="scss" scoped>
.edit-club {
  position: relative;

  &.is-mobile {
    width: 100% !important;
    border: 0;
  }
}
</style>
