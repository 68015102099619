<template>
  <SearchAffordance v-if="!isMobile" @click="openSidebar" />

  <div v-if="isMobile && route.query.side_panel === undefined" :class="['mobile-search-header']">
    <LocationListHeader />
  </div>

  <gam-side-panel v-bind="getSidePanelData">
    <div :class="['list-wrapper', 'locations', { 'is-mobile': isMobile }]">
      <gam-list-view v-bind="getLocationList">
        <template #header>
          <LocationListHeader />
        </template>

        <template #list>
          <div class="card-stack">
            <GamGambitResultCard
              v-for="gambit in sortedGambits"
              :key="gambit.id"
              :location="gambit.location"
              :gambit="gambit"
            />

            <template v-for="location in itemList" :key="location.id">
              <GamLocationCard
                v-bind="getLocationCard(location)"
                :id="`l-card-${location.id}`"
                @update="updateSelectedLocation(location)"
              />
            </template>
          </div>
        </template>
      </gam-list-view>
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import type { GambitDto } from '@/core/data/gambit/gambit.interface';
import type { BaseLocationDto } from '@/core/data/location/location.interface';
import { gambits } from '@/core/gambits';
import ListHelper from '@/core/helpers/list.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamGambitResultCard from '@/views/components/gam-gambits/GamGambitResultCard.vue';
import GamListView from '@/views/components/gam-list-view/GamListView.vue';
import GamLocationCard from '@/views/components/gam-location/GamLocationCard.vue';
import SearchAffordance from '@/views/components/side-panel/GamSearchAffordance.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import type { GamListType } from '@/views/composables/models/components/GamList';
import type { GamLocationCardType } from '@/views/composables/models/components/GamLocation';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import { default as LocationListHeader } from '@/views/main/locations/widgets/LocationsHeader.vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, watch, type ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const listId = GamListId.LOCATIONS;
const panelId = GamSidePanelId.LOCATIONS;

const authStore = useAuthStore();
const mapStore = useMapStore();
const geolocationStore = useGeolocationStore();
const { isLocationPermissionGranted } = storeToRefs(geolocationStore);

const tableStore = useTableStore<BaseLocationDto>(listId)();
const { itemList } = storeToRefs(tableStore);

const router = useRouter();
const route = useRoute();

const openSidebar = () => {
  if (!isLocationPermissionGranted.value) {
    geolocationStore.setLocationPermissionRequested(true);
  }

  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'search',
    },
  });

  window.focusSearch?.();
};

watch(
  () => {
    return [route.query.lat, route.query.lng];
  },
  async () => {
    if (route.query.lat && route.query.lng) {
      const location = { lat: parseFloat(String(route.query.lat)), lng: parseFloat(String(route.query.lng)) };

      tableStore.setCursor(null);
      const items = await gambits.locationService.getLocations(listId, location);

      if (!items.data) {
        return;
      }
      tableStore.setCursor(items.cursor?.afterCursor);
      tableStore.replaceItems(items.data);
    }
  },
);

watch(
  () => [itemList.value, route.query.location],
  ([itemList, locationQuery]) => {
    if (!Array.isArray(itemList)) return;

    const locationId = locationQuery ? String(locationQuery) : null;

    const locationDto = itemList.find((loc) => loc !== null && (loc as BaseLocationDto).id === locationId);
    const locationIndex = itemList.findIndex((loc) => loc !== null && (loc as BaseLocationDto).id === locationId);

    if (!locationDto || !locationIndex) {
      return;
    }

    tableStore.setSelectedItem(locationDto as BaseLocationDto, locationIndex);
  },
);

const getLocationCard = (location: BaseLocationDto): GamLocationCardType => {
  return {
    location,
    isMobile: isMobile.value,
  };
};

const updateSelectedLocation = async (location: BaseLocationDto): Promise<void> => {
  if (authStore.isAuthenticated()) {
    mapStore.setSelectedLocation(location.id);
    // @ts-ignore
    window.gMap && window.gMap.map.panTo(location.coordinates);
    // @ts-ignore
    window.gMap && window.gMap.map.setZoom(18);
    router.replace({
      ...route,
      query: { ...route.query, side_panel: 'location_detail', location: location.id },
    });
  } else {
    await router.push({ name: RouterAuthType.AUTH_SELECT });
  }
};

onMounted(() => {
  const {
    query: { location },
  } = route;
  if (typeof location !== 'string') {
    return;
  }

  mapStore.setSelectedLocation(location);
});

const startDateComparator = (a: GambitDto, b: GambitDto) => {
  const aStart = new Date(a.start);
  const bStart = new Date(b.start);

  return aStart.getTime() - bStart.getTime();
};

type GambitWithLocation = GambitDto & { location: BaseLocationDto };

const sortedGambits: ComputedRef<GambitWithLocation[]> = computed(() => {
  const result: GambitWithLocation[] = [];

  if (!Array.isArray(itemList.value)) return [];

  itemList.value.forEach((location) => {
    const gambits = location.gambitIds?.data;
    if (!gambits || !Array.isArray(gambits)) return;

    const parentIds: string[] = [];

    gambits.forEach((gambit) => {
      if (typeof gambit.parentId === 'string' && parentIds.includes(gambit.parentId)) {
        return;
      }

      if (gambit.parentId) {
        parentIds.push(gambit.parentId);
      }

      result.push({ ...gambit, location });
    });
  });

  return result.sort(startDateComparator);
});

const isOpened = computed(() => {
  const { side_panel } = route.query;

  return side_panel === 'search' || (!isMobile.value && !side_panel);
});

const getSidePanelData = computed((): GamSidePanelType => {
  return {
    id: panelId,
    isOpened: isOpened.value,
    direction: GamCollapseDirection.LEFT,
    option: GamSidePanelOption.LIST,
    onCollapse: () => {
      router.push({
        ...route,
        query: {
          ...route.query,
          side_panel: undefined,
        },
      });
    },
  };
});

const getLocationList = computed((): GamListType<BaseLocationDto> => {
  return {
    listId,
    endInfo: ListHelper.getListEnd({ id: listId }),
    noDataInfo: {
      id: listId,
      title: 'No spots around here',
      message: 'Do you know of a chess-friendly place in this area?',
      size: GamErrorInfoSize.SMALL,
      actionPrimary: {
        variant: GamButtonVariant.SLAB,
        size: GamButtonSize.SMALL,

        label: 'Expand search',
        onClick: () => {
          if (isMobile.value) {
            router.push({ ...route, query: { ...route.query, side_panel: undefined } });
          }
          // @ts-ignore
          window.gMap.map.setZoom(window.gMap.map.getZoom() - isMobile.value ? 2 : 1);
        },
      },
    },
    scrollSpacing: true,
    selectFirst: false,
    spacerPadding: isMobile.value ? 108 : 108,
    loadCallback: (listId) => {
      if (route.query.lat && route.query.lng) {
        const location = { lat: parseFloat(String(route.query.lat)), lng: parseFloat(String(route.query.lng)) };
        return gambits.locationService.getLocations(listId, location);
      } else {
        return Promise.reject();
      }
    },
  };
});

onUnmounted(() => {
  tableStore.resetList(true);
  mapStore.setSelectedLocation(null);
});
</script>

<style lang="scss" scoped>
.search-affordance {
  position: absolute;
  top: 30px;
  left: 16px;
}

.card-stack {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}
</style>

<style lang="scss">
#location-view {
  .panel-body {
    height: 100%;
  }
}

.mobile-search-header {
  position: absolute;
  top: calc(var(--spacing-mobile-wrapper) + var(--spacing-l));
  left: var(--spacing-mobile-wrapper);
  right: var(--spacing-mobile-wrapper);
  z-index: 700;
  opacity: 1;
  transition: opacity var(--transition);

  .gam-input-container {
    background-color: rgba(#181716, 0.33);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.list-wrapper.locations {
  width: var(--side-panel-form-width);

  &.is-mobile {
    width: 100svw !important;

    .list-header {
      padding: 0 !important;
    }
  }

  &.is-absolute {
    .list-content {
      padding: 0 !important;
    }
  }

  .list-content {
    padding: 0 calc(var(--spacing-l) - 6px) 0 var(--spacing-l) !important;
  }
}
</style>
