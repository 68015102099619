<template>
  <div v-if="isMobile" class="location-form-coordinate-affordance">
    <div>
      {{ LocationHelper.toDMS(parseFloat(String(route.query.lat)), true) }},
      {{ LocationHelper.toDMS(parseFloat(String(route.query.lng)), false) }}
    </div>

    <router-link :to="{ ...route, query: { ...route.query, mobile_step: 'pick_coordinate' } }">Change</router-link>
  </div>
</template>

<script setup lang="ts">
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style lang="scss" scoped>
.location-form-coordinate-affordance {
  background: var(--color-dark-600);
  border-radius: 32px;
  padding-left: var(--spacing-l);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: -0.5px;
  gap: var(--spacing-md);
  width: 100%;
  margin-top: calc(var(--spacing-l) * -1 + 1px);

  div {
    line-height: 16px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: var(--color-white-100);
    border: 1px solid var(--color-white-30);
    border-radius: var(--radius-large);
    padding: var(--spacing-md) var(--spacing-l);
    font-size: 10px;
    transition: border-color var(--transition);
    letter-spacing: 0.5px;

    &:hover {
      border-color: var(--color-white-70);
    }
  }
}
</style>
