<template>
  <div class="map-filters-list" :class="{ add: isAddMode }">
    <gam-map-filter-item v-bind="getLocationTypeItem('chess_table' as LocationType)" />
    <gam-map-filter-item v-bind="getLocationTypeItem('giant_chess' as LocationType)" />
    <gam-map-filter-item v-bind="getLocationTypeItem('cafe_chess' as LocationType)" />
    <gam-map-filter-item v-bind="getLocationTypeItem('chess_friendly' as LocationType)" />
    <gam-map-filter-item v-bind="getLocationTypeItem('venue' as LocationType)" />

    <!-- TODO: Implement logic for Cafe / Club type specification -->

    <!--
    <gam-map-filter-item
    v-bind="getLocationTypeItem('club' as LocationType)"
    sub-label="Only for club locations"
    is-disabled
    />
    -->

    <!--
    TODO: This component is used as a form element as well as (temporarilly disabled)
    Map filtering dropdown. Split the current implementation out to a new component
    before restoring the code below.
    -->

    <!-- <gam-map-filter-item v-if="isAddMode || authStore.isAuthenticated()" v-bind="getMyLocation()" /> -->
    <!-- <gam-map-filter-item v-if="!isAddMode && authStore.isAuthenticated()" v-bind="getFavoriteLocation()" /> -->
  </div>
</template>

<script setup lang="ts">
import type { FilterAttribute } from '@/core/data/filter/filter.interface';
import { LocationType } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import type { GetLocationTableFilters } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import GamMapFilterItem from '@/views/components/gam-map/GamMapFilterItem.vue';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamMapFilterItemType, GamMapFiltersListType } from '@/views/composables/models/components/GamMap';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

const props = defineProps<GamMapFiltersListType>();
const emits = defineEmits([GamComponentsEmits.UPDATE_VALUE, GamComponentsEmits.CLOSE]);
const filterStore = useFilterStore<GetLocationTableFilters>(props.listId)();
const { filters } = storeToRefs(filterStore);

const locationFilters = ref<{ [key: string]: FilterAttribute }>();
const locationTypes = ref<LocationType[]>(filters.value?.locationTypes || []);

onMounted(() => {
  getLocationFilters();
  // Quite ugly, but since this is tightly coupled with a generic store, hard to get around now.
  if (props.value) {
    props.value.forEach((type: LocationType) => {
      toggleType(type);
    });
  }
});

const getLocationFilters = () => {
  // TODO: This is not initialized properly;

  const result = gambits.filterService.getAppFilters();
  if (result) {
    locationFilters.value = result.filters.locations;
  }
};

const toggleType = (type?: LocationType) => {
  if (!type) return;
  if (locationTypes.value.includes(type)) {
    if (props.isMultiSelect) {
      GamHelper.removeItem(locationTypes.value, type);
    } else {
      locationTypes.value = [];
    }
  } else {
    if (props.isMultiSelect) {
      locationTypes.value.push(type);
    } else {
      locationTypes.value = [type];
    }
  }
  if (props.isAddMode) {
    emits(GamComponentsEmits.UPDATE_VALUE, locationTypes.value);
  } else {
    filterStore.setFilters({
      ...filters.value,
      private: undefined,
      favorite: undefined,
      locationTypes: locationTypes.value,
    });
    emits(GamComponentsEmits.CLOSE);
  }
};

const getLocationTypeItem = (option: LocationType): GamMapFilterItemType => {
  return {
    type: option,
    isSelected: locationTypes.value.includes(option),
    callback: toggleType,
  };
};

// const getMyLocation = (): GamMapFilterItemType => {
//   return {
//     type: LocationType.HOME,
//     subLabel: props.isAddMode ? 'app.types.location.home.subLabel' : undefined,
//     isSelected: locationTypes.value.includes(LocationType.HOME) || !!filters.value?.private,
//     callback: (type?: LocationType) => {
//       if (props.isAddMode) {
//         toggleType(type);
//       } else {
//         locationTypes.value = [];
//         filterStore.setFilters({
//           ...filters.value,
//           locationTypes: undefined,
//           favorite: undefined,
//           private: !filters.value?.private,
//         });
//         emits(GamComponentsEmits.CLOSE);
//       }
//     },
//   };
// };

// const getFavoriteLocation = (): GamMapFilterItemType => {
//   return {
//     type: LocationType.FAVORITE,
//     isSelected: !!filters.value?.favorite,
//     callback: () => {
//       if (props.isAddMode) return;
//       locationTypes.value = [];
//       filterStore.setFilters({
//         ...filters.value,
//         locationTypes: undefined,
//         private: undefined,
//         favorite: !filters.value?.favorite,
//       });
//       emits(GamComponentsEmits.CLOSE);
//     },
//   };
// };
</script>

<style scoped lang="scss">
.map-filters-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
  align-self: stretch;

  &.add {
    padding-bottom: var(--spacing-l);
  }

  .gam-nav-item {
    width: 100%;
  }
}
</style>
