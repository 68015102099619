<template>
  <div :class="['player-image', { missing: !imageBlob }, size]">
    <div v-touch:tap="expandImage" class="image-container" @click="expandImage">
      <div v-if="imageBlob" class="image" :class="{ skeleton: imgLoading }">
        <img loading="lazy" :src="getImageSrc()" alt="image" @load="loaded" @loadstart="loadingImage" />
      </div>
      <div v-else class="missing-icon">
        <gam-icon v-bind="getMissingIcon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gambits } from '@/core/gambits';
import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamPlayerImageSize } from '@/views/composables/constants/components/gamPlayer.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamPlayerImageType } from '@/views/composables/models/components/GamPlayer';
import { computed, ref } from 'vue';

const imgLoading = ref<boolean>(true);
const props = withDefaults(defineProps<GamPlayerImageType>(), {
  size: GamPlayerImageSize.SMALL,
});
const emit = defineEmits([GamComponentsEmits.EXPAND]);

const getImageSrc = (): string => {
  const url = `${gambits.configService.getServiceRoutingHost()}${AppEndpoint.BLOB}`;
  return url.replace(new RegExp('{blobHash}', 'g'), props.imageBlob || '');
};

const getMissingIconSize = (): string => {
  switch (props.size) {
    case GamPlayerImageSize.LARGE:
      return '96px';
    case GamPlayerImageSize.MEDIUM:
      return '48px';
    case GamPlayerImageSize.SMALL:
      return '32px';
    default:
      return '16px';
  }
};

const loadingImage = () => {
  imgLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    imgLoading.value = false;
  });
};

const expandImage = () => {
  if (!props.expand || !props.imageBlob) return;

  // TODO: Pass the id of the image not the imageBlob
  emit(GamComponentsEmits.EXPAND, props.imageBlob);
};

const getMissingIcon = computed((): GamIconType => {
  return {
    name: GamIconName.PAWN,
    size: getMissingIconSize(),
  };
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.player-image {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: var(--radius-x-small) !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: border var(--transition);
  overflow: hidden;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-white-100);
    padding: 1px;
    opacity: 0;
    z-index: 100;
  }

  .image-container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      width: inherit;
      height: inherit;

      &.skeleton {
        border-radius: 0 !important;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        filter: grayscale(100%);
      }
    }
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.missing {
    background: var(--color-linear-gradient);
  }

  &.small {
    width: 60px;
    height: 75px;

    .expand-icon {
      right: 5px;
      bottom: 5px;
    }
  }

  &.medium {
    width: 100px;
    height: 125px;
  }

  &.large {
    width: 20px;
    height: 250px;
  }

  &.s-square {
    width: 56px;
    height: 56px;

    .expand-icon {
      right: 5px;
      bottom: 5px;
    }
  }

  &.xs-square {
    width: 32px;
    height: 32px;

    .expand-icon {
      right: 4px;
      bottom: 4px;
    }
  }

  &.xxs-circular {
    width: 100%;
    height: 100%;
    border-radius: 50% !important;

    .expand-icon {
      right: 3px;
      bottom: 3px;
    }
  }

  .expand-icon {
    position: absolute;
    right: 6px;
    bottom: 6px;
  }
}
</style>
