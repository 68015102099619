f
<template>
  <div class="gam-input-birth-date">
    <div v-if="label" class="label">
      {{ label }}
    </div>
    <div class="input-group">
      <GamSelect v-bind="getMonth" v-model="month" />
      <GamSimpleInput v-bind="getDay" v-model="day" />
      <GamSimpleInput v-bind="getYear" v-model="year" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import { computed, ref, watch } from 'vue';
import { GamComponentsEmits } from '../composables/constants/main/emit.constants';
import GamSelect from './GamSelect.vue';
import GamSimpleInput from './GamSimpleInput.vue';

interface Props {
  label?: string;
  modelValue?: Date;
  isDisabled?: boolean;
  isError?: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits([GamComponentsEmits.UPDATE_VALUE, GamComponentsEmits.UPDATE]);

const birthDate = props.modelValue ? new Date(props.modelValue) : undefined;

const day = ref<number | undefined>(birthDate ? birthDate.getDate() : undefined);
const month = ref<number | undefined>(birthDate ? birthDate.getMonth() + 1 : undefined);
const year = ref<number | undefined>(birthDate ? birthDate.getFullYear() : undefined);

watch(
  () => [year.value, month.value, day.value],
  ([year, month, day]) => {
    if (!year || !month || !day) return;

    const date = new Date();
    date.setFullYear(year);
    date.setMonth(parseInt(month.toString(), 10) - 1);
    date.setDate(day);
    date.setHours(12);
    date.setMinutes(0);

    emits(GamComponentsEmits.UPDATE, date);
    emits(GamComponentsEmits.UPDATE_VALUE, date);
  },
);

const getDay = computed(() => {
  return {
    name: GamInputName.GAM_BIRTH_DAY,
    placeholder: localize('auth.page.registration.form.date.day.placeholder'),
    label: localize('auth.page.registration.form.date.day.placeholder'),
    value: day.value,
    type: GamInputValueType.NUMBER,
  };
});

const getYear = computed(() => {
  return {
    name: GamInputName.GAM_BIRTH_YEAR,
    placeholder: localize('auth.page.registration.form.date.year.placeholder'),
    label: localize('auth.page.registration.form.date.year.placeholder'),

    value: year.value,
    type: GamInputValueType.NUMBER,
  };
});

const getMonth = computed(() => ({
  name: GamInputName.GAM_BIRTH_MONTH,
  label: 'auth.page.registration.form.date.month.placeholder',
  placeholder: 'auth.page.registration.form.date.month.placeholder',
  value: month.value,
  options: getMonthOptions(),
}));

const getMonthOptions = () => [
  { value: 1, label: localize('app.month.jan') },
  { value: 2, label: localize('app.month.feb') },
  { value: 3, label: localize('app.month.mar') },
  { value: 4, label: localize('app.month.apr') },
  { value: 5, label: localize('app.month.may') },
  { value: 6, label: localize('app.month.jun') },
  { value: 7, label: localize('app.month.jul') },
  { value: 8, label: localize('app.month.aug') },
  { value: 9, label: localize('app.month.sep') },
  { value: 10, label: localize('app.month.oct') },
  { value: 11, label: localize('app.month.nov') },
  { value: 12, label: localize('app.month.dec') },
];
</script>

<style scoped lang="scss">
.gam-input-birth-date {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-md);
  flex: 1 0 0;

  .label {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-white-70);
    line-height: 110%;
  }

  .input-group {
    display: grid;
    width: 100%;
    grid-template-columns: auto 80px 90px;
    grid-column-gap: 0.5rem;
  }
}
</style>
