import type { FormId } from '@/views/composables/constants/form.constants';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamForm, UsernameField } from '@/views/composables/models/form.interface';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

export const useFormStore = <T, V>(formId: FormId) =>
  defineStore(`${GamStoreId.FORM}-${formId}`, () => {
    const gamForm = ref<GamForm<T, V> | null>(null) as Ref<GamForm<T, V> | null>;
    const setForm = (form: GamForm<T, V>) => {
      gamForm.value = form;
    };

    const isValid = ref<boolean>(false);
    const setValid = (value: boolean) => {
      isValid.value = value;
    };

    // TODO: Decouple username logic from generic form store
    const usernameField = ref<UsernameField>({
      isValidated: true,
      isLoading: false,
      error: null,
    });

    const setUsernameValidated = (value: boolean | null) => {
      usernameField.value.isValidated = value;
    };

    const setUsernameLoading = (value: boolean) => {
      usernameField.value.isLoading = value;
    };

    const setUsernameError = (value: GamButtonMessageType | null) => {
      usernameField.value.error = value;
    };

    return {
      name: formId,
      usernameField,
      setUsernameValidated,
      setUsernameLoading,
      setUsernameError,
      gamForm,
      setForm,
      isValid,
      setValid,
    };
  });
