<template>
  <div class="my-profile-sections">
    <div v-for="(section, index) in getProfileSections" :key="`sec-${index}`" class="profile-section">
      <GamNavItemLink
        name="profile"
        display-name="profile.my.profile.title"
        :variant="GamNavVariant.SECONDARY"
        @click="() => router.push({ name: RouterNameType.PLAYER, params: { username: userDetails?.username } })"
        show-arrow
      />
      <gam-nav-item-link v-for="sectionItem in section" :key="`sec-i-${sectionItem.position}`" v-bind="sectionItem" />
    </div>

    <GeneralSection v-if="userDetails" @update="updateUserInfo" />
    <RatingsSection v-if="userDetails" @update="updateUserInfo" />
  </div>
</template>

<script setup lang="ts">
import { RouterNameType, type UIMyProfileSectionItem } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import ProfileHelper from '@/core/helpers/profile.helper';
import { useAuthStore } from '@/stores/auth.store';
import GamNavItemLink from '@/views/components/gam-nav/GamNavItemLink.vue';
import { GamNavMyProfileSection, GamNavVariant } from '@/views/composables/constants/components/gamNav.constants';
import { GamRatingPlatform } from '@/views/composables/constants/main/ratings.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamNavItemLinkType } from '@/views/composables/models/components/GamNav';
import { storeToRefs } from 'pinia';
import { computed, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import GeneralSection from './sections/GeneralSection.vue';
import RatingsSection from './sections/RatingsSection.vue';

const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);
const profileSections = gambits.configService.getMyProfileSections();

const checkOpenSection = () => {
  if (gambits.ratingService.getConnect() === GamRatingPlatform.LICHESS) {
    openRatings();
  }
};

const getProfileItem = (section: UIMyProfileSectionItem): GamNavItemLinkType => {
  return {
    name: section.name,
    displayName: `profile.my.${section.name}.title`,
    variant: GamNavVariant.SECONDARY,
    showArrow: true,
    statusChips: getSectionItemStatus(section.name),
    onClick: () => {
      openSettings(section.name);
    },
  };
};

const getSectionItemStatus = (section: GamNavMyProfileSection): GamChipType[] | undefined => {
  switch (section) {
    case GamNavMyProfileSection.RATINGS: {
      return ProfileHelper.getProfileRatings(userDetails.value?.ratings);
    }
    case GamNavMyProfileSection.AVAILABILITY: {
      return ProfileHelper.getProfileAvailability(userDetails.value?.preferredPlayingTime);
    }
    default: {
      return;
    }
  }
};

const openSettings = (section: GamNavMyProfileSection) => {
  switch (section) {
    case GamNavMyProfileSection.RATINGS:
      openRatings();
      break;
    case GamNavMyProfileSection.GENERAL:
      openGeneral();
      break;
    default:
      break;
  }
};

const getProfileSections = computed((): GamNavItemLinkType[][] => {
  if (!profileSections) return [];
  return profileSections.map((section) => {
    return section.map((sectionItem) => {
      return getProfileItem(sectionItem);
    });
  });
});

const router = useRouter();
const route = useRoute();

const openRatings = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'ratings',
    },
  });
};

const openGeneral = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: 'general',
    },
  });
};

const updateUserInfo = async () => {
  await authStore.fetchUserInfo();
};

watch(userDetails, () => {
  nextTick(() => {
    checkOpenSection();
  });
});
</script>

<style scoped lang="scss">
@use '@/ui/css/partial';

.my-profile-sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md);
  align-self: stretch;

  .profile-section {
    display: flex;
    padding: 0 var(--spacing-l);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--radius-small);
    @extend .gam-special-border !optional;

    &:before {
      background: var(--color-linear-gradient-70);
    }
  }
}
</style>
