import { gambits } from '@/core/gambits';
import { GamDropdownCategory } from '@/views/composables/constants/components/gamDropdown.constants';
import type { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterVariant } from '@/views/composables/constants/main/filter.constants';
import type { GamFilterTypeExpose } from '@/views/composables/models/components/GamFilter';
import { isNumber } from 'lodash';

export default class FilterHelper {
  private static mapDropdownValue(filterItem: GamFilterTypeExpose, listId: GamListId): void {
    const dropdown = filterItem.props.searchDropdown?.dropdown;

    switch (dropdown?.category) {
      case GamDropdownCategory.LOCATIONS:
        gambits.filterService.saveSelectedTableLocation(listId, dropdown.id as GamListId);
        break;
      default:
    }
  }

  private static mapRangeValues(filterItem: GamFilterTypeExpose): Record<string, string | undefined> {
    const isMinMaxReadyOnly = (): boolean => {
      return !!filterItem.props.range?.min?.isReadyOnly || !!filterItem.props.range?.max?.isReadyOnly;
    };
    const getLteValue = (): string | undefined => {
      const maxValue = filterItem.props.range?.max?.value || 0;
      if (filterItem.props.range?.isUnlimited && lteValue >= maxValue) {
        return undefined;
      }
      return lteValue?.toString();
    };

    const inputValue = filterItem.inputValue;
    const gteValue = isMinMaxReadyOnly() ? filterItem?.props.range?.min?.value || 0 : inputValue[0];
    const lteValue = isMinMaxReadyOnly() && isNumber(inputValue) ? inputValue : inputValue[1];
    const keyLte = `${filterItem.props.sortKey}Lte`;
    const keyGte = `${filterItem.props.sortKey}Gte`;

    return {
      [keyLte]: getLteValue(),
      [keyGte]: gteValue?.toString(),
    };
  }

  private static mapRadioValue(filterItem: GamFilterTypeExpose): Record<string, string> {
    const value = filterItem.inputValue as string;
    const key = `${filterItem.props.sortKey}`;

    return {
      [key]: value?.toString(),
    };
  }
  private static mapSwitchValue(filterItem: GamFilterTypeExpose): Record<string, boolean> {
    const value = filterItem.inputValue as boolean;
    const key = `${filterItem.props.sortKey}`;

    return {
      [key]: value,
    };
  }

  private static mapCheckboxValue(filterItem: GamFilterTypeExpose): Record<string, any> {
    const value = filterItem.inputValue as string[];
    const key = `${filterItem.props.sortKey}`;
    const allSelected = value?.length === filterItem.props.items?.length;
    const parsedValue = filterItem.props.isBoolean && value?.length ? value[0] : value;

    return {
      [key]: allSelected ? undefined : parsedValue,
    };
  }

  static getFilterValues = (filterItems: GamFilterTypeExpose[], listId: GamListId): Record<string, any> => {
    return filterItems
      .map((item) => {
        switch (item.props.variant) {
          case GamFilterVariant.DROPDOWN:
            this.mapDropdownValue(item, listId);
            return { undefined };
          case GamFilterVariant.RANGE:
            return this.mapRangeValues(item);
          case GamFilterVariant.RADIO:
            return this.mapRadioValue(item);
          case GamFilterVariant.SWITCH:
            return this.mapSwitchValue(item);
          case GamFilterVariant.CHECKBOX:
            return this.mapCheckboxValue(item);
          default:
            return {};
        }
      })
      .reduce((acc, value) => {
        for (const key in value) {
          if (value[key] !== undefined && value[key] !== null) {
            acc[key] = value[key];
          }
        }
        return acc;
      }, {});
  };
}
