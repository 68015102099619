<template>
  <div v-if="gamForm" class="gambit-form fields">
    <div class="fields">
      <fieldset class="fields">
        <div class="date-group">
          <div class="date-row">
            <Datepicker v-model="startTime" time-picker dark />
            <div v-if="!specifyEndTime">
              <div @click="showEndTime"><GamIcon :name="GamIconName.ARROW_RIGHT_END" /></div>
            </div>
          </div>

          <div v-if="specifyEndTime">
            <div class="date-row">
              <Datepicker v-model="endTime" time-picker dark />
              <div @click="hideEndTime"><GamIcon :name="GamIconName.TRASH" /></div>
            </div>
          </div>
        </div>

        <LocationSearch v-bind="getSearchInput" can-clear />
      </fieldset>

      <GamDetails title="Gambit Details (Optional)" class="fields" is-opened>
        <GamInput
          v-bind="computedTitle"
          v-model="gamForm.form.title"
          placeholder="Event title"
          @click="handleTouchedTitle"
        />

        <gam-textarea v-model="gamForm.form.description" placeholder="About Gambit" :value="gamForm.form.description" />
      </GamDetails>
    </div>

    <div>
      <gam-switch v-bind="getRecurrenceSwitch()" v-model:checked="gamForm.form.recurrences" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GambitCategory, GambitRepeat, GambitType } from '@/core/data/gambit/gambit.type';
import { localizeWithValues } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import { useTableStore } from '@/stores/table.store';
import GamDetails from '@/views/components/GamDetails.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GambitFormType } from '@/views/composables/models/components/GamGambit';
import type { BaseSearchType, GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { GambitForm, GambitValidator } from '@/views/composables/models/form.interface';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { add } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import LocationSearch from '../../locations/widgets/LocationSearch.vue';

const props = defineProps<GambitFormType>();
const { gambit } = props || {};

const listId = GamListId.GAMBIT;
const formStore = useFormStore<GambitForm, GambitValidator>(props.formId)();
const { gamForm } = storeToRefs(formStore);
const gambitStore = useTableStore<GamDropdownItem>(GamListId.GAMBIT)();
const { selectedItem } = storeToRefs(gambitStore);
const specifyEndTime = ref<boolean>(!!gambit?.end);
const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

const initialStartTime = gambit?.start
  ? { hours: new Date(gambit.start).getHours(), minutes: new Date(gambit.start).getMinutes() }
  : undefined;
const startTime = ref<{ hours: number; minutes: number } | undefined>(initialStartTime);
const initialEndTime = gambit?.end
  ? { hours: new Date(gambit.end).getHours(), minutes: new Date(gambit.end).getMinutes() }
  : initialStartTime
    ? { hours: initialStartTime.hours + 1, minutes: initialStartTime.minutes }
    : undefined;
const endTime = ref<{ hours: number; minutes: number } | undefined>(initialEndTime);

const formFromProps = (props: GambitFormType) => {
  let start = new Date();
  start.setMilliseconds(0);
  start.setMinutes(0);
  start = add(start, { hours: 1 });

  const repeatUntil = add(start, { months: 6 });

  const { gambit } = props || {};

  return {
    start: gambit?.start || start.toISOString(),
    end: gambit?.end || undefined,
    locationId: gambit?.locationId || undefined,
    address: gambit?.location?.address || gambit?.address || undefined,
    locationInputValue: gambit?.locationId ? gambit?.location?.name : gambit?.address || undefined,
    locationPoint: gambit?.locationPoint || undefined,
    bringMyBoard: gambit?.bringMyBoard || false,
    gambitType: gambit?.gambitType || [GambitType.ANY],
    gambitCategory: gambit?.gambitCategory || GambitCategory.CASUAL,
    title: gambit?.title || '',
    description: gambit?.description || '',
    clubId: gambit?.clubId || 'user',
    timed: gambit?.timed || false,
    streamed: gambit?.streamed || false,
    private: gambit?.private || false,
    repeat: gambit?.repeat || GambitRepeat.NONE,
    repeatUntil: gambit?.repeatUntil || repeatUntil.toISOString(),
    recurrences: false,
  };
};

const getForm = reactive(formFromProps(props));

const getRules = {
  start: { required },
  bringMyBoard: { required },
  gambitType: { required },
  gambitCategory: { required },
  streamed: { required },
  timed: { required },
  private: { required },
  title: { required },
};

const computedTitle = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamForm.value?.form.title,
    placeholder: 'gambit.add.form.title.placeholder',
  };
});

const showEndTime = () => {
  specifyEndTime.value = true;

  if (!gamForm.value) {
    return;
  }

  const startDate = new Date(gamForm.value.form.start);
  const endDate = add(startDate, { hours: 1 });
  gamForm.value.form.end = endDate.toISOString();
};

const hideEndTime = () => {
  specifyEndTime.value = false;

  if (!gamForm.value) {
    return;
  }

  gamForm.value.form.end = null;
};

watch(
  () => [startTime.value?.hours, startTime.value?.minutes, specifyEndTime.value],
  ([startHours, startMinutes, specifyEnd]) => {
    if (!gamForm.value?.form.start || typeof startHours !== 'number' || typeof startMinutes !== 'number') {
      return;
    }

    const startDate = new Date(gamForm.value?.form.start);
    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);
    gamForm.value.form.start = startDate.toISOString();

    if (!specifyEnd) {
      return;
    }

    const endDate = add(startDate, { hours: 1 });
    gamForm.value.form.end = endDate.toISOString();

    const newEndTime = {
      hours: endDate.getHours(),
      minutes: endDate.getMinutes(),
    };
    endTime.value = newEndTime;
  },
  { deep: true },
);

const getRecurrenceSwitch = (): GamSwitchType => {
  return {
    label: 'Apply to all future recurrences',
    name: '',
    checked: gamForm.value?.form.recurrences,
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.RIGHT,
  };
};

const getNearbyAddress = (humanizeLabel = true): GamButtonMessageType | undefined => {
  if (selectedItem.value?.nearbyPlaceAddress) {
    let label = selectedItem.value.nearbyPlaceAddress;
    if (humanizeLabel) {
      label = localizeWithValues('locations.add.form.search.nearest.address', [label]);
    }
    return {
      label,
    };
  }
  return undefined;
};

const getSearchInput = computed((): BaseSearchType => {
  return {
    id: props.formId,
    canClear: false,
    onClickClear: handleClearLocation,
    input: {
      name: GamInputName.LOCATION,
      placeholder: 'locations.add.form.search.choose',
      leftIcon: GamIconName.LOCATION_EMPTY,
      message: getNearbyAddress(),
      value: gamForm.value?.form.locationInputValue,
    },
    dropdown: {
      id: listId,
    },
    callback: (locationData: GamDropdownItem) => {
      if (!locationData) {
        return;
      }

      if (locationData.source === 'internal') {
        setFormValue({
          locationId: locationData.id,
          locationInputValue: locationData.label,
          locationPoint: undefined,
        });
      } else if (locationData.source === 'external') {
        setFormValue({
          locationId: undefined,
          mapBoxId: locationData.id,
          address: `${locationData.label}, ${locationData.subLabel}`,
          locationInputValue: `${locationData.label}, ${locationData.subLabel}`,
          locationPoint: locationData.coordinates,
        });
      }
    },
  };
});

const handleClearLocation = () => {
  setFormValue({
    locationId: undefined,
    mapBoxId: undefined,
    address: undefined,
    locationInputValue: undefined,
    locationPoint: undefined,
  });
};

const isDataLoaded = ref(false);

onBeforeMount(async () => {
  if (!userDetails.value) {
    await authStore.fetchUserInfo();
  }

  isDataLoaded.value = true;
});

const touchedTitle = ref<boolean>(false);
const handleTouchedTitle = () => {
  touchedTitle.value = true;
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
});

const v$ = useVuelidate(getRules, getForm);

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  const { form } = gamForm.value;
  const { locationId, mapBoxId, locationPoint, title } = form;
  if (!locationId && !mapBoxId && !locationPoint) return true;
  if (!title || (typeof title === 'string' && title.trim() === '')) return true;
  return v$.value.$invalid;
});

const setFormValue = (value: Partial<GambitForm>) => {
  const form = {
    ...(formStore.gamForm?.form ?? {}),
    ...value,
  } as GambitForm;
  formStore.setForm({ form, rules: getRules });
};

defineExpose({
  isInvalid,
});
</script>

<style lang="scss">
.gambit-form {
  .dp__theme_dark {
    --dp-primary-color: var(--color-pink);
    --dp-background-color: var(--color-dark-700);
    --dp-input-padding: 12px 30px 12px 12px;
    --dp-font-family: 'Hanken Grotesk', sans-serif;
  }

  .dp__input {
    border-radius: 24px;
  }
}
</style>
<style scoped lang="scss">
@use '@/ui/css/partial';

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

details.fields {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0;
}

.gambit-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 4rem;

  fieldset {
    border-radius: 24px;
    border: 1px solid var(--color-white-5);
    padding: 16px;
  }

  .date-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    justify-content: stretch;
  }

  .date-row {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: stretch;
  }

  .game-type-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;

    span {
      opacity: 0.7;
    }
  }

  h4 {
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: normal;
    opacity: 0.7;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }

  .gam-select-container {
    padding: var(--spacing-md) var(--spacing-l);
    border: 1px solid var(--color-white-5);
    border-radius: var(--radius-large);
    @extend .gam-special-border !optional;
    position: relative;
  }

  .gam-select-container:after {
    display: block;
    content: '';
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 15px;
    transform: rotate(45deg);
  }

  select {
    color: white;
    height: 33px;
  }

  .optional-details {
    border: 1px solid var(--color-white-10);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
    margin-top: var(--spacing-md);

    legend {
      padding: 0 var(--spacing-sm);
      font-weight: bold;
    }
  }
}
</style>
