<template>
  <span
    v-touch:tap="emitIconEvent"
    :class="[
      'svg-container',
      {
        [`${props.class}`]: !!props.class,
        'dark-color': isDark,
        'primary-color': isPrimary,
        circle: isCircle,
        filled: isFilled,
        padded: isPadded,
        error: isError,
        flag: isFlag,
      },
    ]"
    :style="{ fontSize: fontSizeValue, minWidth: fontSizeValue }"
    @click="emitIconEvent($event)"
  >
    <inline-svg
      v-if="svgSrc"
      :id="uuid"
      :src="`${svgSrc}`"
      :alt="`${props.name}`"
      @loaded="setIconSize($event)"
    ></inline-svg>
  </span>
</template>

<script lang="ts" setup>
import GamHelper from '@/core/helpers/app.helper';
import { GamIconSize, GamIconSpecialSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import { select, selectAll } from 'd3';
import { onMounted, onUpdated, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';

const props = withDefaults(defineProps<GamIconType>(), {
  size: GamIconSize.NORMAL,
});

const emit = defineEmits([GamComponentsEmits.CLICK]);

const id = props.id || 'icon';
const uuid = ref<string>(GamHelper.createId(id));
const svgIcon = ref<SVGElement>();
const svgSrc = ref<string>('');
const fontSizeValue = ref<string>('');

onMounted(() => {
  setIconProperties();
});

onUpdated(() => {
  setIconProperties();
});

const setIconProperties = (): void => {
  fontSizeValue.value = setFontSize();
  if (props.name) {
    svgSrc.value = getSource();
  }
};

const setFontSize = (): GamIconSize | GamIconSpecialSize | string => {
  return props.size || GamIconSize.NORMAL;
};

const getSource = (): string => {
  if (props.isFlag) {
    return new URL(`../../ui/icons/flags/${props.name}.svg`, import.meta.url).href;
  }
  return new URL(`../../ui/icons/${props.name}.svg`, import.meta.url).href;
};

const setIconSize = (svgElement: SVGElement): void => {
  svgIcon.value = svgElement;
  const svgElementViewBox = svgIcon.value?.getAttribute?.('viewBox');
  if (!svgElementViewBox) {
    return;
  }
  const viewBox: number[] | null = svgElementViewBox.split(' ').map((n) => Number(n));
  if (viewBox?.length) {
    const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);
    svgIcon.value.setAttribute('height', '1em');
    svgIcon.value.setAttribute('width', `${widthToHeight}em`);
    svgIcon.value.classList.add('svg-class');
  }
  setGradient();
};

const setGradient = (): void => {
  const gradientId: string = 'icon-primary-gradient';
  const idClass: string = `#${uuid.value}`;
  if (props.isPrimary && !props.isError) {
    if (!select(`${idClass} linearGradient`).size()) {
      const linearGradient = selectAll(idClass)
        .append('defs')
        .append('linearGradient')
        .attr('id', gradientId)
        .attr('gradientUnits', 'userSpaceOnUse');
      linearGradient.append('stop').attr('offset', '19.88%').attr('stop-color', '#FF6DA2');
      linearGradient.append('stop').attr('offset', '70.74%').attr('stop-color', '#F54CFF');
      svgIcon.value?.children[0].setAttribute('fill', `url(#${gradientId}`);
    }
  } else if (props.isDark) {
    svgIcon.value?.children[0]?.setAttribute('fill', '#181716');
  } else if (props.isError) {
    svgIcon.value?.children[0]?.setAttribute('fill', '#ff0000');
  } else {
    svgIcon.value?.children[0]?.setAttribute('fill', '#ffffff');
  }
};

const emitIconEvent = (event: Event): void => {
  emit(GamComponentsEmits.CLICK, event);
};

watch(
  () => props,
  () => {
    setFontSize();
    setGradient();
  },
  { deep: true },
);
</script>

<style lang="scss">
.svg-container {
  display: flex;

  &.circle {
    border-radius: 100px;
  }

  &.filled {
    background: white;
  }

  &.padded {
    padding: 0.5rem;
  }

  &.flag {
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
  }

  .svg-class {
    align-items: center;
    justify-content: center;
    max-height: 100%;
    width: 100%;
  }
}
</style>
