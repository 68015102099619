import type {
  AddLocationFormData,
  BaseLocationDto,
  CommentDto,
  DetailLocationDto,
  GeoLocation,
  GooglePlaceQuery,
  GooglePlaceResultDto,
  ImageDto,
  MapClusterData,
  MapLocationDto,
  SearchLocationDto,
  SearchLocationOptions,
} from '@/core/data/location/location.interface';
import { LocationRepository } from '@/core/data/location/location.repository';
import { LocationType, MapLocationType } from '@/core/data/location/location.type';
import GamHelper from '@/core/helpers/app.helper';
import FormHelper from '@/core/helpers/form.helper';
import LocationHelper from '@/core/helpers/location.helper';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { AddCommentForm, LocationFormProps } from '@/views/composables/models/form.interface';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

export class LocationService {
  private readonly repository: LocationRepository;
  private clusterer?: MarkerClusterer;
  private mapMarkers: AdvancedMarkerElement[];

  constructor(repository: LocationRepository) {
    this.repository = repository;
    this.mapMarkers = [];
  }

  async getLocations(
    listId: GamListId,
    geo: GeoLocation,
    includeTopLocation?: boolean,
  ): Promise<Result<BaseLocationDto[]>> {
    try {
      const response = await this.repository.getLocations(listId, geo, includeTopLocation);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getMapLocations(listId: GamListId, center: GeoLocation): Promise<Result<MapLocationDto[]>> {
    try {
      const response = await this.repository.getMapLocations(listId, center);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async searchLocations(listId: GamListId): Promise<Result<SearchLocationDto[]>> {
    try {
      const response = await this.repository.searchLocations(listId);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async searchLocationsByQuery(query?: string, options?: SearchLocationOptions): Promise<Result<SearchLocationDto[]>> {
    try {
      const response = await this.repository.searchLocationsByQuery(query, options);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async fetchGooglePlaceDetails(payload: GooglePlaceQuery): Promise<Result<GooglePlaceResultDto>> {
    try {
      const response = await this.repository.fetchGooglePlaceDetails(payload);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async searchLocationsByCoords(lat: number, lng: number): Promise<Result<SearchLocationDto[]>> {
    try {
      const response = await this.repository.searchLocationsByCoords(lat, lng);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async reverseGeocode(lat: number, lng: number): Promise<Result<SearchLocationDto>> {
    try {
      const response = await this.repository.reverseGeocode(lat, lng);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async locationPlace(mapBoxId: string): Promise<Result<GeoLocation>> {
    try {
      const response = await this.repository.getLocationPlace(mapBoxId);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getLocation(id: string): Promise<Result<DetailLocationDto>> {
    try {
      const response = await this.repository.getLocation(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getLocationImages(id: string): Promise<Result<ImageDto[]>> {
    try {
      const response = await this.repository.getLocationImages(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getLocationComments(id: string): Promise<Result<CommentDto[]>> {
    try {
      const response = await this.repository.getLocationComments(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  private mapLocationToPin(id: string, data: AddLocationFormData): MapLocationDto {
    return {
      id,
      type: MapLocationType.LOCATION,
      name: data.name,
      coordinates: {
        lat: data.coordinates?.lat || 0,
        lng: data.coordinates?.lng || 0,
      },
      locationTypes: data.locationTypes.map((type) => {
        return type.toString() as LocationType;
      }),
      private: data.private,
      favorite: false,
      gambit: null,
    };
  }

  async addNewLocation(formData: LocationFormProps): Promise<Result<MapLocationDto>> {
    try {
      const isPrivate = formData.locationTypes.includes(LocationType.HOME);
      const data: AddLocationFormData = {
        name: formData.name,
        address: formData.address || '',
        about: formData.about,
        private: isPrivate,
        coordinates: formData.coordinates,
        numberOfChessboards: formData.numberOfChessboards,
        numberOfGiantChessboards: formData.numberOfGiantChessboards,
        locationTypes: GamHelper.removeItem(formData.locationTypes, LocationType.HOME),
        images: formData.photos.map((photo) => FormHelper.parseImageFile(photo)),
        chessSet: formData.chessSet,
      };
      const response = await this.repository.addNewLocation(data);
      return response?.data ? { data: this.mapLocationToPin(response.data.id, data) } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  // async updateLocation(formData: LocationFormProps): Promise<Result<MapLocationDto>> {
  //   try {
  //     const isPrivate = formData.locationTypes.includes(LocationType.HOME);
  //     const data: AddLocationFormData = {
  //       name: formData.name,
  //       address: formData.address || '',
  //       about: formData.about,
  //       private: isPrivate,
  //       coordinates: formData.coordinates,
  //       numberOfChessboards: formData.numberOfChessboards,
  //       numberOfGiantChessboards: formData.numberOfGiantChessboards,
  //       locationTypes: GamHelper.removeItem(formData.locationTypes, LocationType.HOME),
  //       images: formData.photos.map((photo) => FormHelper.parseImageFile(photo)),
  //       chessSet: formData.chessSet,
  //     };
  //     const response = await this.repository.updateLocation(data);
  //     return response?.data ? { data: this.mapLocationToPin(response.data.id, data) } : { error: generalError() };
  //   } catch (error) {
  //     return {
  //       error: error as ApiError,
  //     };
  //   }
  // }

  getSelectedPinElement(id: string): HTMLDivElement {
    return this.repository.getSelectedPin(id)?.element;
  }

  private async setMapMarkers(data: MapClusterData): Promise<AdvancedMarkerElement[]> {
    const tempMarkers = await this.repository.getBaseMarkers(data);
    this.mapMarkers = [...this.mapMarkers, ...tempMarkers];
    return tempMarkers;
  }

  public async addItems(items: MapLocationDto[], onClickMarker: (item: MapLocationDto) => void) {
    const newMarkers = await this.repository.getBaseMarkers({ items, onClickMarker });
    this.mapMarkers = [...this.mapMarkers, ...newMarkers];
    this.clusterer?.addMarkers(newMarkers);
  }

  public async removeItems(items: MapLocationDto[]) {
    const staleMarkers: AdvancedMarkerElement[] = [];
    this.mapMarkers = this.mapMarkers.filter((marker) => {
      if (items.findIndex((item) => item.id === marker.id) > 0) {
        staleMarkers.push(marker);
        return false;
      }

      return true;
    });

    this.clusterer?.removeMarkers(staleMarkers);
  }

  async getMarkerCluster(data: MapClusterData): Promise<void> {
    // this.clusterer?.clearMarkers();
    // this.clusterer?.removeMarkers()

    // const currentItems = await this.setMapMarkers(data);
    if (this.clusterer) {
      return;
    }

    const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

    this.clusterer = new MarkerClusterer({
      map: data.map,
      algorithm: this.repository.getClusterAlgorithm(),
      markers: [],
      onClusterClick: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => {
        const bounds = cluster.bounds;

        if (cluster.bounds) {
          const center = bounds?.getCenter();
          const zoom = map.getZoom();

          if (!center || !zoom || !data.onClickMarker) return;

          event.stop();

          const item: MapLocationDto = {
            id: '',
            gambit: null,
            type: MapLocationType.CLUSTER,
            coordinates: { lat: center.lat(), lng: center.lng() },
            locationTypes: [],
          };

          data.onClickMarker(item);
        }
      },
      renderer: {
        render: ({ count, position }) => {
          return new AdvancedMarkerElement({
            position,
            content: this.repository.getClusterPin(count),
          });
        },
      },
    });
  }

  updateMarker(item: MapLocationDto, selected?: string): void {
    const selectedMarker = this.mapMarkers.find((marker) => {
      const content = marker.content as HTMLElement;
      return content?.id === item.id;
    });

    if (selectedMarker) {
      selectedMarker.content = this.repository.updateSelectedPin(item, selected).element;
    }
  }

  async toggleFavorite(id: string, favorite?: boolean): Promise<Result<boolean>> {
    try {
      const response = await this.repository.toggleFavorite(id, favorite);
      return response ? { data: true } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async toggleImageLike(id: string, like: boolean): Promise<Result<boolean>> {
    try {
      const response = await this.repository.toggleImageLike(id, like);
      return response ? { data: true } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  private async setAddedImage(image: ImageDto): Promise<ImageDto> {
    return {
      id: image.id,
      imageBlobHash: image.imageBlobHash,
      numberOfLikes: 0,
      liked: false,
      user: await LocationHelper.authAuthor(),
      createdAt: image.createdAt,
    };
  }

  async addLocationPhoto(id: string, photo: string): Promise<Result<ImageDto>> {
    try {
      const response = await this.repository.addPhoto(id, FormHelper.parseImageFile(photo));
      return response?.data
        ? {
            data: await this.setAddedImage(response.data),
          }
        : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteLocationImage(id: string): Promise<Result<boolean>> {
    try {
      const response = await this.repository.deleteLocationImage(id);
      return response ? { data: true } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async addNewComment(id: string, formData: AddCommentForm): Promise<Result<CommentDto>> {
    try {
      const response = await this.repository.addNewComment(id, {
        rating: formData.rating,
        message: formData.message,
        image: FormHelper.parseImageFile(formData.photo),
      });
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteComment(id: string): Promise<Result<boolean>> {
    try {
      const response = await this.repository.deleteComment(id);
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async destroyMap(): Promise<void> {
    this.repository.clearMapPins();
    this.clusterer?.clearMarkers();
    this.clusterer?.onRemove();

    return new Promise((resolve) => {
      this.mapMarkers = [];
      this.clusterer = undefined;
      resolve();
    });
  }

  async checkIn(id: string): Promise<Result<any> | null> {
    try {
      const response = await this.repository.checkIn(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async checkOut(id: string): Promise<Result<any> | null> {
    try {
      const response = await this.repository.checkOut(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
