<template>
  <div v-if="isPinpointingLocation" class="pinpointing-header">
    <span class="address">{{ localize('locations.add.cancel.message') }}</span>

    <RouterLink :to="{ ...route, query: { ...route.query, side_panel: undefined, mobile_step: undefined } }">
      <GamIcon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" />
    </RouterLink>
  </div>

  <div v-if="isPinpointingLocation" class="pinpointing-footer">
    <div class="address">
      {{ gamForm?.form.name }}
    </div>

    <GamButton :size="GamButtonSize.SMALL" :variant="GamButtonVariant.SECONDARY" @click="handleContinueFromPinpoint">
      Continue
    </GamButton>
  </div>

  <gam-side-panel v-bind="getAddLocSidePanel" @clear="clearAddMode">
    <LocationForm ref="locationForm" :key="`add-location-${instanceId}`" :form-id="formId" :instance-id="instanceId" />
  </gam-side-panel>

  <GamDialog ref="successDialog" :hide-back="true" :background="DialogBackgrounds.ADD_LOCATION">
    <SimpleContentDialog v-bind="addSuccess()" />
  </GamDialog>
</template>

<script setup lang="ts">
import type { MapLocationDto } from '@/core/data/location/location.interface';
import { localize } from '@/core/gambits';
import { useFormStore } from '@/stores/form.store';
import { useMapStore } from '@/stores/map.store';
import { useMarkerStore } from '@/stores/marker.store';
import { useTableStore } from '@/stores/table.store';
import SimpleContentDialog from '@/views/components/gam-dialog/dialog-contents/SimpleContentDialog.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import { DialogBackgrounds } from '@/views/composables/constants/dialog.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import type { SimpleContentDialogType } from '@/views/composables/models/dialog.interface';
import type { LocationFormProps, LocationValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { v4 as uuid4 } from 'uuid';
import { computed, ref, watch } from 'vue';
import { onBeforeRouteUpdate, RouterLink, useRoute, useRouter } from 'vue-router';
import LocationForm from '../forms/LocationForm.vue';
import { isLocationValid } from '../forms/validateLocation';
import { handleCreateLocation } from './locationActions';

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const listId = GamListId.ADD_LOCATION;
const panelId = GamSidePanelId.LOCATION_FORM;

const formId = FormId.ADD_LOCATION;
const formStore = useFormStore<LocationFormProps, LocationValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const tableStore = useTableStore<GamDropdownItem>(listId)();
const mapStore = useMapStore();
const mapPinTableStore = useMarkerStore(GamListId.MARKERS)();

const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);
const locationForm = ref<InstanceType<typeof LocationForm>>();
const successDialog = ref<InstanceType<typeof GamDialog>>();

const router = useRouter();
const route = useRoute();

const instanceId = ref<string>('');

const isPinpointingLocation = computed(
  () => route.query.mobile_step === 'pick_coordinate' && route.query.side_panel === 'add_location',
);

const handleContinueFromPinpoint = () => {
  router.push({ ...route, query: { ...route.query, mobile_step: undefined } });
};

const isInvalid = ref<boolean>(true);

watch(
  gamForm,
  (gamForm) => {
    isInvalid.value = !isLocationValid(gamForm);
  },
  { deep: true },
);

const addSuccess = (): SimpleContentDialogType => {
  return {
    title: 'dialog.add.location.title',
    message: 'dialog.add.location.message',
    action: {
      label: 'dialog.add.location.button',
      onClick: () => {
        successDialog.value?.close();
      },
    },
  };
};

const clearAddMode = () => {
  mapStore.clearAddMode();
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
      mobile_step: undefined,
    },
  });
};

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.address = undefined;
  form.about = undefined;
  form.chessSet = false;
  form.coordinates = undefined;
  form.locationTypes = [];
  form.name = '';
  form.numberOfChessboards = undefined;
  form.numberOfGiantChessboards = undefined;
  form.photos = [];
  form.pinpoint = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'add_location' && newPanel.side_panel === 'add_location') {
    clearForm();
  }
});

const closeAddLocation = (locationId: string) => {
  tableStore.resetList(true);
  mapStore.clearAddMode();

  const { return_path, return_key, return_value } = route.query;

  if (return_path) {
    router.push({
      path: String(return_path),
      query: {
        [String(return_key)]: return_value,
        new_club_location: locationId,
      },
    });
  } else {
    successDialog.value?.show();

    router.push({
      ...route,
      query: {
        ...route.query,
        side_panel: undefined,
      },
    });
  }
};

onBeforeRouteUpdate((to, from) => {
  if (to.query.side_panel === 'add_location' && from.query.side_panel !== 'add_location') {
    instanceId.value = uuid4();
    isInvalid.value = true;
  }
});

const getAddLocSidePanel = computed((): GamSidePanelType => {
  return {
    isOpened: route.query.side_panel === 'add_location' && route.query.mobile_step !== 'pick_coordinate',
    isComplementary: true,
    isModal: false,
    id: panelId,
    direction: GamCollapseDirection.LEFT,
    option: GamSidePanelOption.ADD,
    preventOpenState: true,
    onExpand: () => {
      // Respond to location expansion.
    },
    header: {
      title: 'locations.add.title.label',
      icon: GamIconName.LOCATION_EMPTY,
    },
    footer: {
      isColumn: true,
      action: {
        label: 'locations.add.form.action.button',
        isDisabled: isInvalid.value || isLoading.value,
        isLoading: isLoading.value,
        message: errorMessage.value,
        onClick: async () => {
          if (!gamForm.value?.form) return;

          isLoading.value = true;

          const result = await handleCreateLocation(gamForm.value.form);

          if (!result) {
            errorMessage.value = {
              label: 'Something went wrong.',
              variant: GamButtonMessageVariant.ERROR,
            };

            isLoading.value = false;
            return;
          }

          if ((result as GamButtonMessageType).variant === GamButtonMessageVariant.ERROR) {
            errorMessage.value = result as GamButtonMessageType;

            isLoading.value = false;
            return;
          }

          // TODO: Use the result coordinates to place the marker
          const { latitude, longitude } = mapPinTableStore.getLocationParam() || {};
          latitude && longitude && mapPinTableStore.addUniqueItems({ lat: latitude, lng: longitude });

          closeAddLocation((result as MapLocationDto).id);
          clearForm();

          emits(GamComponentsEmits.SUCCESS);

          isLoading.value = false;
        },
      },
      clear: {
        label: 'locations.add.form.clear.button',
        isDisabled: isLoading.value,
        onClick: clearAddMode,
      },
    },
  };
});
</script>

<style lang="scss" scoped>
#location-form {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}

.pinpointing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-l) var(--spacing-xl);

  position: absolute;
  z-index: 801;
  top: 0;

  background: linear-gradient(0deg, rgba(#181716, 0) 0%, rgba(#181716, 0.15) 9.5%, rgba(#181716, 0.8) 28%, #181716 58%);

  a {
    padding: var(--spacing-l);
  }
}

.pinpointing-footer {
  position: absolute;
  z-index: 802;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(#181716, 0) 0%,
    rgba(#181716, 0.15) 9.5%,
    rgba(#181716, 0.8) 28%,
    #181716 58%
  );
  transition:
    opacity var(--transition-router),
    bottom var(--transition);
  background-color: transparent;
  border: 0;
  position: fixed;
  min-height: 78px;
  padding: var(--spacing-xl);
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
