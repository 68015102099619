import type { CodeClient, CodeResponse } from '@/core/data/auth/google/oauth2';
import { ExternalLoader } from '@/core/helpers/external-loader';
import type { AuthScreenMethod } from '@/views/composables/auth/useAuth.interface';

let googleClient: CodeClient;

const initGoogle = (cb: (loaded: boolean) => void): void => {
  if (!window.google?.accounts) {
    new ExternalLoader(['https://accounts.google.com/gsi/client'], () => {
      cb(true);
    });
  }
};

const googleSignIn = (method: AuthScreenMethod, cb: (codeResponse: CodeResponse) => void): void => {
  if (!googleClient) {
    googleClient = window.google?.accounts.oauth2.initCodeClient({
      client_id: method.clientId || '',
      scope: 'profile email openid',
      ux_mode: 'popup',
      callback: cb,
    });
  }
  googleClient.requestCode();
};

export default () => {
  return { initGoogle, googleSignIn };
};
