<template>
  <div :id="platform" class="gam-rating-item" :class="[platform, getStyleClasses]">
    <div class="rating-header" @click="toggleAccordion">
      <div v-if="props.accordion && !isGambits()" class="accordion">
        <gam-button v-bind="getAccordionButton" />
      </div>

      <gam-icon v-if="icon" :name="icon" />

      <div class="rating-name">
        <span>{{ localize(getRatingName()) }}</span>
      </div>

      <div v-if="!!connect" class="connect-rating">
        <gam-button v-bind="getConnectButton" />
      </div>
    </div>

    <div ref="ratingBody" class="rating-body" :style="getRatingBodyHeight">
      <component :is="activeRatingComponent" :rating="props" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useRatingStore } from '@/stores/rating.store';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamRatingChess from '@/views/components/gam-ratings/contents/GamRatingChess.vue';
import GamRatingGambits from '@/views/components/gam-ratings/contents/GamRatingGambits.vue';
import GamRatingLichess from '@/views/components/gam-ratings/contents/GamRatingLichess.vue';
import GamRatingOffline from '@/views/components/gam-ratings/contents/GamRatingOffline.vue';
import GamRatingUnknown from '@/views/components/gam-ratings/contents/GamRatingUnknown.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { GamRatingPlatform } from '@/views/composables/constants/main/ratings.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamRatingItemType } from '@/views/composables/models/components/GamRatings';
import { computed, ref, type StyleValue } from 'vue';

const authStore = useAuthStore();
const ratingStore = useRatingStore();
const ratingBody = ref<HTMLDivElement>();
const childHeight = ref<number>();
const loading = ref<boolean>(false);
const updated = ref<boolean>(false);
const props = withDefaults(defineProps<GamRatingItemType>(), {
  accordion: true,
});

const emits = defineEmits([GamComponentsEmits.CHANGE]);

const getRatingName = () => {
  return `app.component.ratings.item.${props.platform.toString()}`;
};

const isGambits = (): boolean => {
  return props.platform === GamRatingPlatform.GAMBITS;
};

const updateChildHeight = () => {
  childHeight.value = ratingBody.value?.children[0]?.clientHeight;
};

const getPlatform = (): GamRatingPlatform => {
  return props.platform;
};

const isConnected = (): boolean => {
  if (props.platform === GamRatingPlatform.LICHESS) {
    return ratingStore.isLichessConnected();
  }
  return false;
};

const getConnectLabel = (): string => {
  if (isUpdated()) {
    return 'app.component.ratings.updated';
  }
  return isConnected() ? 'app.component.ratings.update' : 'app.component.ratings.get';
};

const isConnectEnabled = (): boolean => {
  return authStore.isAuthenticated() || !isConnected();
};

const updateValues = () => {
  loading.value = true;
  setTimeout(() => {
    switch (props.platform) {
      case GamRatingPlatform.LICHESS:
        ratingStore.updateLichessRatings();
        break;
    }
    loading.value = false;
  }, 500);
};

const toggleAccordion = (): void => {
  if (props.accordion && !isGambits()) {
    emits(GamComponentsEmits.CHANGE, props.platform);
  }
};

const isUpdated = (): boolean => {
  return (props.isUpdated || updated.value) && !(props.isLoading || loading.value);
};

const getConnectButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    label: getConnectLabel(),
    leftIcon: isUpdated() ? GamIconName.CHECK : undefined,
    isLoading: props.isLoading || loading.value,
    onClick: () => {
      if (isConnectEnabled()) {
        props.connect?.(props.platform);
      } else if (!updated.value) {
        updateValues();
        updated.value = true;
        setTimeout(() => {
          updated.value = false;
        }, 10000);
      }
    },
  };
});

const getAccordionButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.TERTIARY,
    size: GamButtonSize.SMALL,
    leftIcon: props.isOpened ? GamIconName.MINUS : GamIconName.PLUS,
    isIconOnly: true,
    isIconWhite: true,
    onClick: toggleAccordion,
  };
});

const getRatingBodyHeight = computed((): StyleValue => {
  if (props.isOpened) {
    updateChildHeight();
    return {
      height: childHeight.value ? `${childHeight.value}px` : 'auto',
    };
  }
  return {
    height: 0,
  };
});

const activeRatingComponent = computed((): any => {
  switch (props.platform) {
    case GamRatingPlatform.CHESS_COM:
      return GamRatingChess;
    case GamRatingPlatform.LICHESS:
      return GamRatingLichess;
    case GamRatingPlatform.OFFLINE:
      return GamRatingOffline;
    case GamRatingPlatform.UNKNOWN:
      return GamRatingUnknown;
    case GamRatingPlatform.GAMBITS:
      return GamRatingGambits;
    default:
      return null;
  }
});

const getStyleClasses = computed(() => {
  return {
    opened: props.isOpened && props.isAuth,
    accordion: props.accordion,
  };
});

defineExpose({
  getPlatform,
  updateChildHeight,
});
</script>

<style scoped lang="scss">
@use '@/ui/css/partial';

.gam-rating-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-l);
  border-radius: var(--radius-small);
  min-width: 0;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-dark-600);
  }

  &.gambits {
    background: var(--color-linear-gradient-10);
    box-shadow: var(--shadow-pink-15) var(--shadow-backdrop);

    &:before {
      background: var(--color-linear-gradient-30);
    }
  }

  &.accordion {
    .rating-header {
      cursor: pointer;
    }

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0.3;
    }
  }

  &.opened {
    &:before {
      opacity: 1;
    }
  }

  .rating-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    align-self: stretch;
    flex: 1 0 0;

    .rating-name {
      font-size: 16px;
      font-weight: 700;
      flex-grow: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rating-connect {
      align-self: flex-end;
    }
  }

  .rating-body {
    width: 100%;
    overflow: hidden;
    transition: height var(--transition);
  }
}
</style>
