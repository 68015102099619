<template>
  <div
    v-touch:tap="onFocus"
    disabled-v-on-click-outside="setBlur"
    :class="['gam-textarea']"
    @click="onFocus"
    @mouseenter="isOutOfBox = false"
    @mouseleave="isOutOfBox = true"
  >
    <div :class="['gam-textarea-container', getContainerClasses]">
      <div :class="['input-content', getContentClasses]">
        <div v-if="placeholder" class="label">
          {{ localize(placeholder) }}
        </div>
        <div class="scroll-area" :style="{ height: `${inputScrollHeight}px` }">
          <ScrollOverflow class="input-area" is-vertical>
            <textarea
              ref="textarea"
              v-bind="$attrs"
              v-model="inputValue"
              class="textarea"
              :readonly="readOnly"
              :disabled="isDisabled"
              autocomplete="false"
              :style="{ height: textHeight }"
              @focus="setFocus"
              @blur="setBlur"
              @change="updateInput"
            />
          </ScrollOverflow>
        </div>
        <div ref="drawer" class="drag-wrapper">
          <gam-icon :name="GamIconName.CORNER" />
        </div>
      </div>
    </div>
    <transition name="dropdown" mode="out-in">
      <gam-button-message v-if="message" v-bind="message" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamTexareaType } from '@/views/composables/models/components/GamInput';
import { useDrag, type Vector2 } from '@vueuse/gesture';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import ScrollOverflow from './ScrollOverflow.vue';

const props = defineProps<GamTexareaType>();
const drawer = ref<HTMLElement>();

const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE, GamComponentsEmits.UPDATE, GamComponentsEmits.CLEAR]);

const lockInput = ref<boolean>(false);

const setInputValue = (): string | number | null | undefined => {
  const value: string | number | undefined = 0;
  return value || props.value;
};

const textarea = ref<HTMLTextAreaElement>();
const inputValue = ref<string | number | null | undefined>(props.value);
const isActive = ref<boolean>(false);
const isOutOfBox = ref<boolean>(true);
const textHeight = ref<string>('100%');
const initScrollHeight = ref<number>(108);
const inputScrollHeight = ref<number>(initScrollHeight.value);

const onFocus = (): void => {
  if (!props.isDisabled && !props.isLoading) textarea.value?.focus();
};

const authStore = useAuthStore();
const router = useRouter();

const setFocus = (): void => {
  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  isActive.value = true;
};

const setBlur = (): void => {
  isActive.value = false;
  inputValue.value = setInputValue();
};

const updateInput = (value: Event) => {
  const target = value?.target as HTMLInputElement;
  emit(GamComponentsEmits.UPDATE_VALUE, target?.value);
};

const calculateHeight = () => {
  if (!textarea.value) {
    textHeight.value = '100%';
    return;
  }
  const inputLength = inputValue.value?.toString().length;
  const setScroll = textarea.value?.scrollHeight >= textarea.value?.clientHeight;
  textHeight.value = setScroll && inputLength ? `${textarea.value?.scrollHeight}px` : '100%';
};

const dragHandler = (movement: Vector2, dragging: boolean) => {
  if (dragging) {
    const tempHeight = initScrollHeight.value + movement[1];
    if (tempHeight < 108) {
      initScrollHeight.value = 108;
    } else {
      inputScrollHeight.value = tempHeight;
    }
  } else {
    initScrollHeight.value = inputScrollHeight.value;
  }
};

const isInputActive = (): boolean => {
  const valueEntered = inputValue.value?.toString();
  return isActive.value || !!valueEntered?.length;
};

const getContainerClasses = computed(() => ({
  'is-active': isActive.value,
  'is-disabled': props.isDisabled || props.isLoading,
  'is-error': props.isError,
  'read-only': props.readOnly,
}));

const getContentClasses = computed(() => ({
  active: isInputActive(),
}));

watch(inputValue, (value) => {
  calculateHeight();
  emit(GamComponentsEmits.UPDATE, value);
  emit(GamComponentsEmits.UPDATE_VALUE, value);
});

watch(
  () => props.isLoading,
  () => {
    lockInput.value = props.isLoading;
  },
);

watch(
  () => props.value,
  () => {
    inputValue.value = props.value;
  },
);

useDrag(({ movement, dragging }) => dragHandler(movement, dragging), {
  domTarget: drawer,
});
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .gam-textarea-container {
    width: inherit;
    display: flex;
    align-items: center;
    padding: var(--spacing-l) var(--spacing-md) var(--spacing-l) var(--spacing-l);
    border-radius: var(--radius-small);
    height: auto;
    cursor: text;
    border: 1px solid var(--color-white-5);
    transition: border var(--transition);
    position: relative;
    gap: 16px;
    @extend .gam-special-border !optional;

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0;
      inset: -1px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .input-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .label {
        width: inherit;
        position: relative;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.36px;
        text-transform: uppercase;
        color: var(--color-white-30);
      }

      .scroll-area {
        width: 100%;
        height: 108px;
        min-height: 108px;

        .input-area {
          width: 100%;
          height: 100%;

          .textarea {
            display: flex;
            width: calc(100% - var(--spacing-md));
            height: 100%;
            font-size: 16px;
            font-weight: 300;
            color: var(--color-white-100);
            position: relative;
            line-height: 110%;
            overflow-wrap: break-word;
            resize: none;
            overflow: hidden;
          }
        }
      }

      .drag-wrapper {
        position: absolute;
        right: 4px;
        bottom: -4px;
        cursor: se-resize;
      }
    }

    &.is-active {
      &:before {
        opacity: 1;
      }

      .input-content {
        .input {
          &::placeholder {
            display: none;
            color: transparent;
          }
        }
      }
    }

    &.is-disabled {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.3;

      &:before {
        content: none;
      }
    }

    &.is-error {
      border-color: var(--color-red) !important;

      &:before {
        content: none;
      }
    }

    &.read-only {
      cursor: default;

      &:hover {
        &:before {
          opacity: 0 !important;
        }
      }

      .label,
      .placeholder {
        transition: none !important;
      }

      .input {
        color: var(--color-white-100);
        transition: none !important;
        left: 0;
      }
    }
  }
}
</style>
