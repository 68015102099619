<template>
  <div class="simple-content-dialog" :class="{ mobile: isMobile }">
    <div v-if="loaderIcon" class="loader">
      <gam-loader v-bind="getGamLoader" />
    </div>
    <div class="info-wrapper">
      <div v-if="title" class="info-title" v-html="localize(title)" />
      <div v-if="message" class="info-description" v-html="localize(message)" />
    </div>
    <div v-if="action" class="button-group">
      <gam-button v-bind="getActionButton" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamLoader from '@/views/components/GamLoader.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamLoaderColor, GamLoaderSize } from '@/views/composables/constants/components/gamLoader.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamLoaderType } from '@/views/composables/models/components/GamLoader';
import type { SimpleContentDialogType } from '@/views/composables/models/dialog.interface';
import { computed } from 'vue';

const props = defineProps<SimpleContentDialogType>();

const getActionButton = computed((): GamButtonType => {
  return {
    ...props.action,
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
  };
});

const getGamLoader = computed((): GamLoaderType => {
  return {
    icon: props.loaderIcon,
    size: GamLoaderSize.LARGE,
    color: GamLoaderColor.GRADIENT,
    spinIcon: false,
  };
});
</script>

<style lang="scss">
.simple-content-dialog {
  display: flex;
  position: relative;
  width: calc(335px - 2 * var(--spacing-menu));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);

  .info-description {
    line-height: 1.5 !important;
  }

  &.mobile {
    width: 100%;
  }

  .info-wrapper {
    display: flex;
    padding: var(--spacing-menu) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-menu);
    align-self: stretch;

    .info-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      color: var(--color-white-100);
      text-align: center;
      align-self: stretch;
    }

    .info-description {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 110%;
      color: var(--color-white-70);
      text-align: center;
      z-index: 2;

      span {
        color: var(--color-white-100);
      }
    }
  }

  .button-group {
    display: flex;
    padding: var(--spacing-l) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
  }
}
</style>
