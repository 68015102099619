import type { MapLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import { GamButtonMessageVariant } from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { LocationFormProps } from '@/views/composables/models/form.interface';

export const handleCreateLocation = async (
  locationProps: LocationFormProps,
): Promise<MapLocationDto | GamButtonMessageType | undefined> => {
  const result = await gambits.locationService.addNewLocation({
    ...locationProps,
    locationTypes:
      Array.isArray(locationProps.locationTypes) && locationProps.locationTypes.length > 0
        ? locationProps.locationTypes
        : [LocationType.CHESS_FRIENDLY],
  });

  if (result.data) {
    return result.data;
  } else if (result.error) {
    return {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }
};
