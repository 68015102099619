<template>
  <div class="gam-rating-offline">
    <GamSimpleInput v-bind="getRatingInput()" v-model="ratings.rating" />
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { useRatingStore } from '@/stores/rating.store';
import GamSimpleInput from '@/views/components/GamSimpleInput.vue';
import { GamInputName, GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';

const ratingStore = useRatingStore();
const { ratings, maxRating } = storeToRefs(ratingStore);

const getRatingInput = (): GamInputType => {
  return {
    name: GamInputName.RATING_OFFLINE,
    type: GamInputValueType.NUMBER,
    placeholder: localize('app.component.ratings.item.offline.input'),
    label: localize('app.component.ratings.item.offline.input'),
    value: ratings.value.rating,
    min: 0,
    max: maxRating.value,
  };
};
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-rating-offline {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);
  width: 100%;
}
</style>
