import type { BaseGambitDto, GambitFormData, GambitRecurrenceOptions } from '@/core/data/gambit/gambit.interface';
import type { GambitRepository } from '@/core/data/gambit/gambit.repository';
import type { GeoLocation } from '@/core/data/location/location.interface';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GambitDetailDto } from '@/views/composables/models/components/GamGambit';
import type { GambitForm } from '@/views/composables/models/form.interface';

export class GambitService {
  private readonly repository: GambitRepository;

  constructor(repository: GambitRepository) {
    this.repository = repository;
  }

  async getGambits(listId: GamListId, location: GeoLocation): Promise<Result<BaseGambitDto[]>> {
    try {
      const response = await this.repository.getGambits(listId, location);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getGambit(id: string): Promise<Result<GambitDetailDto>> {
    try {
      const response = await this.repository.getGambit(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async addNewGambit(formData: GambitForm): Promise<Result<BaseGambitDto>> {
    try {
      const data: GambitFormData = {
        start: formData.start,
        end: formData.end ? formData.end : null,
        locationId: formData.locationId,
        locationPoint: formData.locationPoint,
        address: formData.address,
        mapBoxId: formData.mapBoxId,
        bringMyBoard: formData.bringMyBoard,
        clubId: formData.clubId !== 'user' ? formData.clubId : undefined,
        gambitType: formData.gambitType,
        gambitCategory: formData.gambitCategory,
        title: formData.title,
        description: formData.description,
        timed: formData.timed,
        streamed: formData.streamed,
        private: formData.private,
        repeat: formData.repeat,
        repeatUntil: formData.repeatUntil,
      };
      const response = await this.repository.addNewGambit(data);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateGambit(
    id: string,
    formData: GambitForm,
    options?: GambitRecurrenceOptions,
  ): Promise<Result<BaseGambitDto | { status: number }>> {
    try {
      const data: GambitFormData = {
        start: formData.start,
        end: formData.end,
        locationId: formData.locationId,
        locationPoint: formData.locationPoint,
        address: formData.address,
        mapBoxId: formData.mapBoxId,
        bringMyBoard: formData.bringMyBoard,
        clubId: formData.clubId !== 'user' ? formData.clubId : null,
        gambitType: formData.gambitType,
        gambitCategory: formData.gambitCategory,
        title: formData.title,
        description: formData.description,
        timed: formData.timed,
        streamed: formData.streamed,
        private: formData.private,
        repeat: formData.repeat,
      };

      const response = await this.repository.updateGambit(id, data, options);
      return response?.data ? response : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteGambit(id: string, options?: GambitRecurrenceOptions): Promise<Result<BaseGambitDto> | null> {
    try {
      const response = await this.repository.deleteGambit(id, options);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async createAttendance(id: string): Promise<Result<any> | null> {
    try {
      const response = await this.repository.createAttendance(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteAttendance(id: string): Promise<Result<any> | null> {
    try {
      const response = await this.repository.deleteAttendance(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
