<template>
  <input id="date" type="date" :value="formattedDate" @input="updateDate($event.target.value)" />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: new Date().toISOString(),
});

const emit = defineEmits(['update:modelValue']);

// Extract the date in YYYY-MM-DD format
const formattedDate = computed(() => {
  if (!props.modelValue) return '';
  return props.modelValue.split('T')[0]; // Extract YYYY-MM-DD from ISO string
});

const updateDate = (dateString) => {
  if (!props.modelValue) return;

  const timePart = props.modelValue.split('T')[1] || '00:00:00.000Z'; // Keep original time
  const updatedISO = `${dateString}T${timePart}`;

  emit('update:modelValue', updatedISO);
};
</script>
