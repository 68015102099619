import { LoginMethodType } from '@/core/data/auth/auth.type';
import type { LichessRatings } from '@/core/data/rating/rating.interface';
import { gambits } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import { AuthEndpoint } from '@/core/network/api/constants/api.url.constant';
import type { AuthScreenMethod } from '@/views/composables/auth/useAuth.interface';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

const lichessSignIn = async (serviceUrl: string, method: AuthScreenMethod) => {
  const redirectUrl: string = `${GamHelper.getCurrentUrl()}/auth`;
  const lichessAuthUrl = `${serviceUrl}${AuthEndpoint.LICHESS}`;

  if (redirectUrl) {
    gambits.authService.saveConnecting(LoginMethodType.Lichess);
    method.isLoading = true;
    window.location.href = `${lichessAuthUrl}${redirectUrl}`;
    setTimeout(() => {
      method.isLoading = false;
    }, 500);
  }
};

const lichessGetRating = async (serviceUrl: string, isAuth?: boolean) => {
  const page = isAuth ? 'auth/ratings' : 'profile';
  const redirectUrl: string = `${GamHelper.getCurrentUrl()}/${page}`;
  const lichessAuthUrl = `${serviceUrl}${AuthEndpoint.LICHESS_RATINGS}`;

  if (redirectUrl) {
    window.location.href = `${lichessAuthUrl}${redirectUrl}`;
  }
};

const parseLichessRatings = (route: RouteLocationNormalizedLoaded): LichessRatings | undefined => {
  const ratingsParams: LichessRatings = route.query;
  if (
    ratingsParams?.lichessComRapidRating ||
    ratingsParams?.lichessComBlitzRating ||
    ratingsParams?.lichessComBulletRating ||
    ratingsParams?.lichessComClassicalRating ||
    ratingsParams?.fideRating
  ) {
    return ratingsParams;
  }
  return undefined;
};

export default () => {
  return { lichessSignIn, lichessGetRating, parseLichessRatings };
};
