<template>
  <div :class="['main-view players-view', { 'is-mobile': isMobile }]">
    <gam-detail-view v-bind="getDetailView" :open="true">
      <scroll-overflow v-if="route.params.username" class="detail-scroll is-vertical">
        <GamButton v-if="isMobile" class="back-button" v-bind="getBackLink" />

        <PlayersDetail :key="`player-username-${route.params.username}`" :username="String(route.params.username)" />
      </scroll-overflow>
    </gam-detail-view>

    <PlayerFilters class="side-filters" />
  </div>
</template>

<script lang="ts" setup>
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { UserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import GamButton from '@/views/components/GamButton.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import GamDetailView from '@/views/components/detail-view/GamDetailView.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PlayersDetail from './PlayersDetail.vue';
import PlayerFilters from './side-panels/PlayerFilters.vue';

const listId = GamListId.PLAYERS;

const playerDetails = ref<UserDto | null>(null);

const route = useRoute();
const router = useRouter();
const appStore = useAppStore();

onBeforeMount(async () => {});

const getDetailView = computed((): GamDetailType => {
  return {
    listId,
    showListCursors: !!route.params.id,
    isOpen: true,
  };
});

onMounted(async () => {
  getPlayerDetail();
});

const getBackLink = computed(() => ({
  class: 'back-link',
  href: `/${RouterNameType.GAMBITS}`,
  size: GamButtonSize.ACTIONS,
  variant: GamButtonVariant.TERTIARY,
  leftIcon: GamIconName.ARROW_LEFT,
  onClick: (e: MouseEvent) => {
    e.preventDefault();

    const { previousPath } = appStore;

    if (appStore.isFirstVisit() || (previousPath && previousPath.indexOf(route.fullPath) > -1)) {
      router.push({ name: RouterNameType.GAMBITS });
      return;
    }

    if (appStore.previousPath) {
      router.push(appStore.previousPath);
      return;
    }

    router.back();
  },
}));

const getPlayerDetail = async () => {
  const username = route.params.username;

  if (!username) return null;

  const result = await gambits.userService.getUserByName(String(username));

  if (!result.data) return null;

  playerDetails.value = result.data;
};
</script>

<style scoped lang="scss">
.detail-scroll {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.detail-container {
  max-width: 596px;
  margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto 0;

  @media (max-width: 1180px) {
    max-width: 512px;
  }

  &.is-mobile {
    padding: 0 var(--spacing-l);
  }
}

.back-button {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 2;
}

.player-tab-selector {
  display: flex;
  justify-self: center;
  margin: 0 auto;
  padding: var(--spacing-xxl) 0;
  flex-grow: 1;
  justify-content: flex-end;

  .tab-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}

.metrics {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  // gap: var(--spacing-md);
  gap: var(--spacing-md);
}

.details-cards {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: var(--spacing-md);
}

.right-side-panel,
.gam-form-content {
  width: 100%;
  height: calc(100% - 5rem);
}
</style>

<style lang="scss">
.main-view.players-view .list-content {
  gap: var(--spacing-md) !important;
}
</style>
