<template>
  <div :class="['day-tag', type]">
    <span>{{ getTimeTag }}</span>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import GamHelper from '@/core/helpers/app.helper';
import GambitHelper from '@/core/helpers/gambit.helper';
import { GamDateType } from '@/views/composables/constants/components/gamGambit.constants';
import { computed } from 'vue';

interface DayTagProps {
  date: string;
}

const props = defineProps<DayTagProps>();

const type = computed(() => {
  return GambitHelper.getGambitsDateType(props.date, false);
});

const getTimeTag = computed(() => {
  if (!props.date) return;

  if (type.value === GamDateType.DAY) {
    return GamHelper.getShortDayOfWeek(props.date);
  } else {
    return localize(`gambit.card.date.time.type.${type.value}`);
  }
});
</script>

<style scoped lang="scss">
.day-tag {
  font-weight: 400;
  transition: color var(--transition-fast);
  text-transform: uppercase;

  &.soon,
  &.now {
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.tomorrow,
  &.today {
    background: var(--color-linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
