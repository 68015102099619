export const personalUserFields = [
  'id',
  'firstName',
  'lastName',
  'username',
  'emoji',
  'ratings',
  'playerTypes',
  'title',
  'profileImageBlobHash',
  'chessBoardImageBlobHash',
];
export const baseUserFields = [...personalUserFields, 'rating', 'birthDate', 'status', 'distance', 'featured'];

export const adminUserFields = [...personalUserFields, 'age', 'createdAt'];

export const ratingsFields = [
  'lichessComClassicalRating',
  'lichessComRapidRating',
  'lichessComBlitzRating',
  'lichessComBulletRating',
  'chessComBlitzRating',
  'chessComRapidRating',
  'chessComBulletRating',
  'chessComClassicalRating',
  'fideRating',
  'rating',
];

export const baseUserInfoFields = [
  ...personalUserFields,
  'email',
  'birthDate',
  'aboutMe',
  'provider',
  'location',
  'isPayingUser',
  'isAdmin',
  'preferredPlayingTime',
  'clubs',
  'curatedClubs',
  ...ratingsFields,
];

export const baseClubFields = [
  'id',
  'name',
  'username',
  'profileImageBlobHash',
  'followers',
  'canEdit',
  'ownerId',
  'clubType',
];

export const detailClubFields = [
  ...baseClubFields,
  'owner',
  'curators',
  'gambits',
  'homeLocations',
  'description',
  'gambitsCount',
];

export const detailUserFields = [
  ...baseUserInfoFields,
  'uid',
  'age',
  'mutual',
  'reliability',
  'friends',
  'mutualFriends',
  'players',
  'mutualPlayers',
  'gambitsCreated',
  'gambitsWon',
  'gambitsDraw',
  'gambitsPlayed',
  'gambitsNextMove',
  'likes',
  'upcomingGambit',
  'followers',
  'following',
  'isFollowed',
  'isBlocked',
  'createdAt',
  'gambitsCreated',
  'gambitsAttended',
  'locationsCheckedIn',
];

export const baseLocationFields = [
  'id',
  'coordinates',
  'locationTypes',
  'private',
  'favorite',
  'name',
  'address',
  'rating',
  'ratingCount',
  'image',
  'gambit',
  'gambitIds',
  'distance',
  'topLocation',
];

export const publicLocationFields = [
  'id',
  'coordinates',
  'locationTypes',
  'private',
  'name',
  'address',
  'rating',
  'ratingCount',
  'image',
  'gambit',
  'gambitIds',
  'distance',
];

export const detailsLocationFields = [
  'id',
  'coordinates',
  'locationTypes',
  'private',
  'favorite',
  'name',
  'address',
  'rating',
  'ratingCount',
  'about',
  'user',
  'chessSet',
  'numberOfChessboards',
  'numberOfGiantChessboards',
  'canComment',
  'createdAt',
  'updatedAt',
  'gambitIds',
  'hostedClubs',
  'visitors',
];

export const adminLocationFields = [...detailsLocationFields, 'verified'];

export const detailsLocationImagesFields = ['id', 'imageBlobHash', 'liked', 'numberOfLikes', 'user', 'createdAt'];

export const detailsLocationCommentsFields = ['id', 'message', 'rating', 'user', 'createdAt'];

export const baseGambitFields = [
  'id',
  'start',
  'end',
  'gambitCategory',
  'gambitType',
  'timed',
  'streamed',
  'playersCount',
  'distance',
  'clubId',
  'ownerId',
  'repeat',
  'parentId',
  // 'address',
  // 'coordinates',
  // 'isMyGambit',
  'locationId',
  'title',
  'locationImageBlobHash',
  'canEdit',
  'attendeeCount',
  'minEloRating',
  'maxEloRating',
  'isAttending',
];

export const detailGambitFields = [
  'id',
  'start',
  'end',
  'locationPoint',
  'address',
  'bringMyBoard',
  'gambitType',
  'gambitCategory',
  'streamed',
  'timed',
  'description',
  'invitation',
  'playersCount',
  'friendsCount',
  'distance',
  'locationImageBlobHash',
  'private',
  'createdAt',
  'updatedAt',
  'players',
  'ownerId',
  'clubId',
  'club',
  'repeat',
  'parentId',
  'locationId',
  'location',
  'title',
  'user',
  'canEdit',
  'attendeeCount',
  'minEloRating',
  'maxEloRating',
  'isAttending',
  'attendees',
];
