export enum GamInputValueType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date',
  DATETIME = 'datetime-local',
}

export enum GamInputName {
  SEARCH = 'search-input',
  LOCATION = 'location-input',
  GAM_DATETIME = 'gam-date-time',
  GAM_NAME = 'gam-name-search',
  GAM_SURNAME = 'gam-sname-search',
  GAM_USERNAME = 'gam-uname-search',
  GAM_EMAIL = 'gam-email',
  EMAIL = 'email',
  EMOJI = 'gam-emoji',
  GAM_BIRTH_DAY = 'birth-day',
  GAM_BIRTH_MONTH = 'birth-month',
  GAM_BIRTH_YEAR = 'birth-year',
  SLIDER = 'gam-slider',
  SLIDER_MAX = 'gam-slider-max',
  SLIDER_MIN = 'gam-slider-min',
  COUNTER = 'gam-counter',
  RATING_GAMBITS = 'rating-gambits',
  RATING_OFFLINE = 'rating-offline',
  RATING_BLITZ = 'rating-blitz',
  RATING_LICHESS = 'rating-rapid',
  RATING_BULLET = 'rating-bullet',
  RATING_CLASSICAL = 'rating-classical',
}
