<template>
  <div v-if="gamForm" class="gambit-form fields">
    <div class="fields">
      <fieldset class="fields required-fieldset">
        <div class="date-group">
          <div class="date-row">
            <Datepicker
              v-if="!(isTouchDevice && isSafari)"
              v-model="gamForm.form.start"
              :enable-time-picker="true"
              :min-date="new Date()"
              time-picker-inline
              dark
            />

            <div v-else class="ios-inputs">
              <WozniakDate v-model="gamForm.form.start" class="ios-date" />
              <WozniakTime v-model="gamForm.form.start" class="ios-time" />
            </div>

            <div v-if="!specifyEndTime">
              <div @click="showEndTime"><GamIcon :name="GamIconName.ARROW_RIGHT_END" /></div>
            </div>
            <div v-else class="alignment-placeholder"></div>
          </div>

          <div v-if="specifyEndTime">
            <div class="date-row">
              <Datepicker
                v-if="!(isTouchDevice && isSafari)"
                v-model="gamForm.form.end"
                :enable-time-picker="true"
                :min-date="new Date()"
                time-picker-inline
                dark
              />

              <div v-else-if="typeof gamForm.form.end === 'string'" class="ios-inputs">
                <WozniakDate v-model="gamForm.form.end" class="ios-date" />
                <WozniakTime v-model="gamForm.form.end" class="ios-time" />
              </div>

              <div @click="hideEndTime"><GamIcon :name="GamIconName.TRASH" /></div>
            </div>
          </div>
        </div>

        <SimpleLocationSearch v-if="!gamForm.form.locationPoint" v-bind="getSearchInput" />

        <LocationCreateFields
          v-if="gamLocationForm?.form && gamForm.form.locationPoint"
          :search-list-id="GamListId.GAMBIT_FORM"
          :dropdownlist-id="listId"
          :name-input="getNameInput"
          :set-form-value="setFormValue"
          :form-model="gamLocationForm?.form"
        />

        <ChangeLocationCoords />

        <gam-switch v-bind="getChessboardSwitch()" v-model:checked="gamForm.form.bringMyBoard" />
      </fieldset>

      <GamDetails title="Gambit Details (Optional)" class="fields">
        <div class="group">
          <h4>Game type</h4>

          <div class="game-type-row">
            <GamChip
              v-for="(item, index) in gambitTypeOptions"
              :key="`f-item-${index}`"
              ref="filterItem"
              :label="item.label"
              :is-active="gamForm.form.gambitType?.includes(item.value)"
              @click="() => toggleGambitType(item)"
            />
          </div>
        </div>

        <div class="group">
          <h4>Event type</h4>

          <div class="game-type-row">
            <GamChip
              v-for="(item, index) in eventTypeOptions"
              :key="`f-item-${index}`"
              ref="filterItem"
              :label="item.label"
              :is-active="gamForm.form.gambitCategory?.includes(item.value)"
              @click="() => changeGambitCategory(item)"
            />
          </div>
        </div>

        <GamInput
          v-bind="computedTitle"
          v-model="gamForm.form.title"
          placeholder="Event title"
          @click="handleTouchedTitle"
        />

        <gam-textarea v-model="gamForm.form.description" placeholder="About Gambit" :value="gamForm.form.description" />

        <div class="fields">
          <div class="gam-select-container">
            <select v-model="gamForm.form.repeat" placeholder="Single event">
              <option v-for="option in repeatOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>

          <div v-if="gamForm.form.repeat !== 'none'">
            <h4>Repeat until</h4>
            <Datepicker
              v-model="gamForm.form.repeatUntil"
              :enable-time-picker="true"
              :min-date="new Date()"
              :max-date="add(new Date(), { months: 6 })"
              time-picker-inline
              dark
            />
          </div>
        </div>
        <!-- <gam-switch v-bind="getPrivateSwitch()" v-model:checked="gamForm.form.private" /> -->
      </GamDetails>
    </div>

    <div v-if="organizerOptions.length > 1" class="fields organizer">
      <h4>Organizer</h4>

      <GamOrganizerDropdown v-model="gamForm.form.clubId" :options="organizerOptions" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GambitCategory, GambitRepeat, GambitType } from '@/core/data/gambit/gambit.type';
import type { GeoLocation } from '@/core/data/location/location.interface';
import { MapLocationSource } from '@/core/data/location/location.type';
import { gambits, localizeWithValues } from '@/core/gambits';
import { isSafari, isTouchDevice } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import { useTableStore } from '@/stores/table.store';
import GamChip from '@/views/components/GamChip.vue';
import GamDetails from '@/views/components/GamDetails.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamOrganizerDropdown from '@/views/components/GamOrganizerDropdown.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { GambitFormType } from '@/views/composables/models/components/GamGambit';
import type { BaseSearchType, GamInputType } from '@/views/composables/models/components/GamInput';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type {
  GambitForm,
  GambitValidator,
  LocationFormProps,
  LocationValidator,
} from '@/views/composables/models/form.interface';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { add } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ChangeLocationCoords from '../../locations/forms/ChangeLocationCoords.vue';
import LocationCreateFields from '../../locations/forms/LocationCreateFields.vue';
import SimpleLocationSearch from '../../locations/widgets/SimpleLocationSearch.vue';
import WozniakDate from './WozniakDate.vue';
import WozniakTime from './WozniakTime.vue';

const props = defineProps<GambitFormType>();

const { gambit } = props || {};

const listId = GamListId.GAMBIT;
const formStore = useFormStore<GambitForm, GambitValidator>(props.formId)();
const { gamForm } = storeToRefs(formStore);
const gambitStore = useTableStore<GamDropdownItem>(GamListId.GAMBIT)();
const { selectedItem } = storeToRefs(gambitStore);
const specifyEndTime = ref<boolean>(!!gambit?.end);
const authStore = useAuthStore();
const { userDetails, userClubs } = storeToRefs(authStore);

const router = useRouter();
const route = useRoute();

const locationFormStore = useFormStore<LocationFormProps, LocationValidator>(props.formId)();
const { gamForm: gamLocationForm } = storeToRefs(locationFormStore);

const getNameInput = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamLocationForm.value?.form.name,
    placeholder: 'locations.add.form.name.placeholder',
  };
});

const formFromProps = (props: GambitFormType) => {
  let start = new Date();
  start.setMilliseconds(0);
  start.setMinutes(0);
  start = add(start, { hours: 1 });

  const repeatUntil = add(start, { months: 6 });

  const { gambit } = props || {};

  return {
    start: gambit?.start || start.toISOString(),
    end: gambit?.end || undefined,
    locationId: gambit?.locationId || undefined,
    address: gambit?.location?.address || gambit?.address || undefined,
    locationInputValue: gambit?.locationId ? gambit?.location?.name : gambit?.address || undefined,
    locationPoint: gambit?.locationPoint || undefined,
    bringMyBoard: gambit?.bringMyBoard || false,
    gambitType: gambit?.gambitType || [GambitType.ANY],
    gambitCategory: gambit?.gambitCategory || GambitCategory.CASUAL,
    title: gambit?.title || '',
    description: gambit?.description || '',
    clubId: gambit?.clubId || 'user',
    timed: gambit?.timed || false,
    streamed: gambit?.streamed || false,
    private: gambit?.private || false,
    repeat: gambit?.repeat || GambitRepeat.NONE,
    repeatUntil: gambit?.repeatUntil || repeatUntil.toISOString(),
  };
};

const getRules = {
  start: { required },
  bringMyBoard: { required },
  gambitType: { required },
  gambitCategory: { required },
  streamed: { required },
  timed: { required },
  private: { required },
  title: { required },
};

function getForm(props: GambitFormType) {
  return reactive(formFromProps(props));
}

const reactiveForm = ref();
const v$ = ref();

onMounted(async () => {
  reactiveForm.value = getForm(props);
  v$.value = useVuelidate(getRules, reactiveForm.value);
  formStore.setForm({ form: reactiveForm.value, rules: getRules });
});

watch(
  () => props.instanceId,
  () => {
    reactiveForm.value = getForm(props);
    v$.value = useVuelidate(getRules, reactiveForm.value);
    formStore.setForm({ form: reactiveForm.value, rules: getRules });
  },
);

const gambitTypeOptions = [
  { value: GambitType.ANY, label: 'gambit.detail.tag.category.any' },
  { value: GambitType.TIMED, label: 'gambit.detail.tag.category.timed' },
  { value: GambitType.BLITZ, label: 'gambit.detail.tag.category.blitz' },
  { value: GambitType.RAPID, label: 'gambit.detail.tag.category.rapid' },
  { value: GambitType.BULLET, label: 'gambit.detail.tag.category.bullet' },
  { value: GambitType.CLASSIC, label: 'gambit.detail.tag.category.classic' },
  { value: GambitType.STREAMED, label: 'gambit.detail.tag.category.streamed' },
  { value: GambitType.FREESTYLE, label: 'gambit.detail.tag.category.freestyle' },
  { value: GambitType.BUGHOUSE, label: 'gambit.detail.tag.category.bughouse' },
  { value: GambitType.HAND_AND_BRAIN, label: 'gambit.detail.tag.category.hand_and_brain' },
  { value: GambitType.STIMUL, label: 'gambit.detail.tag.category.stimul' },
];

const eventTypeOptions = [
  { value: GambitCategory.CASUAL, label: 'gambit.detail.tag.category.casual' },
  { value: GambitCategory.TOURNAMENT, label: 'gambit.detail.tag.category.tournament' },
  { value: GambitCategory.SOCIALISING, label: 'gambit.detail.tag.category.event' },
  { value: GambitCategory.LESSON, label: 'gambit.detail.tag.category.lesson' },
];

const repeatOptions = [
  { value: GambitRepeat.NONE, label: "Don't repeat" },
  { value: GambitRepeat.EVERY_DAY, label: 'Repeat every day' },
  { value: GambitRepeat.EVERY_WEEK, label: 'Repeat every week' },
  { value: GambitRepeat.EVERY_MONTH, label: 'Repeat every month' },
];

const organizerOptions = computed(() => {
  const clubOptions =
    userClubs.value?.map((c) => ({
      value: c.id,
      label: c.name,
      image: c.profileImageBlobHash,
    })) || [];

  return [
    {
      value: 'user',
      label: userDetails.value ? `Me (@${userDetails.value?.username})` : 'Unknown',
      image: userDetails.value?.profileImageBlobHash,
    },
    ...clubOptions,
  ];
});

const computedTitle = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamForm.value?.form.title,
    placeholder: 'gambit.add.form.title.placeholder',
  };
});

const showEndTime = () => {
  specifyEndTime.value = true;

  if (!gamForm.value) {
    return;
  }

  const startTime = new Date(gamForm.value.form.start);
  const endTime = add(startTime, { hours: 1 });
  gamForm.value.form.end = endTime.toISOString();
};

const hideEndTime = () => {
  specifyEndTime.value = false;

  if (!gamForm.value) {
    return;
  }

  gamForm.value.form.end = null;
};

watch(
  () => gamForm.value?.form.start,
  () => {
    if (!gamForm.value || !specifyEndTime.value) {
      return;
    }

    const startTime = new Date(gamForm.value?.form.start);
    const endTime = add(startTime, { hours: 1 });
    gamForm.value.form.end = endTime.toISOString();
  },
);

const toggleGambitType = (gambitType: any) => {
  if (!gambitType.value || !gamForm.value) {
    return;
  }

  const currentTypes = gamForm.value.form.gambitType;

  if (gambitType.value === 'any') {
    gamForm.value.form.gambitType = [gambitType.value];
    return;
  } else {
    gamForm.value.form.gambitType = currentTypes.filter((t) => t !== GambitType.ANY);
  }

  if (currentTypes.includes(gambitType.value)) {
    gamForm.value.form.gambitType = currentTypes.filter((t) => t !== gambitType.value);
  } else {
    gamForm.value.form.gambitType.push(gambitType.value);
  }

  if (gamForm.value.form.gambitType.length === 0) {
    gamForm.value.form.gambitType = [GambitType.ANY];
  }
};

const changeGambitCategory = (gambitCategory: any) => {
  if (!gambitCategory.value || !gamForm.value) {
    return;
  }

  gamForm.value.form.gambitCategory = gambitCategory.value;
};

const getChessboardSwitch = (): GamSwitchType => {
  return {
    label: 'Bring your own chessboard',
    name: '',
    checked: gamForm.value?.form.bringMyBoard,
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.RIGHT,
  };
};

// TODO: Refactor into more explicit state management
const getNearbyAddress = (humanizeLabel = true): GamButtonMessageType | undefined => {
  if (selectedItem.value?.nearbyPlaceAddress) {
    let label = selectedItem.value.nearbyPlaceAddress;
    if (humanizeLabel) {
      label = localizeWithValues('locations.add.form.search.nearest.address', [label]);
    }
    return {
      label,
    };
  }
  return undefined;
};

const getSearchInput = computed((): BaseSearchType => {
  return {
    id: props.formId,
    canClear: true,
    onClickClear: handleClearLocation,
    input: {
      name: GamInputName.LOCATION,
      placeholder: 'locations.add.form.search.choose',
      leftIcon: GamIconName.LOCATION_EMPTY,
      message: getNearbyAddress(),
      value: gamForm.value?.form.locationInputValue,
      onFocus: props.onLocationFocus,
    },
    dropdown: {
      id: listId,
    },
    callback: (locationData: GamDropdownItem) => {
      if (!locationData) {
        return;
      }

      if (locationData.source === MapLocationSource.INTERNAL) {
        setFormValue({
          locationId: locationData.id,
          locationInputValue: locationData.label,
          locationPoint: undefined,
        });
        // @ts-ignore
        window.gMap.map.panTo(locationData.coordinates);
        // @ts-ignore
        window.gMap.map.setZoom(17);

        router.push({ ...route, query: { ...route.query, location: locationData.id } });
      } else if (
        locationData.source === MapLocationSource.EXTERNAL ||
        locationData.source === MapLocationSource.GOOGLE
      ) {
        setFormValue({
          locationId: undefined,
          address: `${locationData.label}, ${locationData.subLabel}`,
          locationInputValue: `${locationData.label}, ${locationData.subLabel}`,
          locationPoint: locationData.coordinates,
          pinpoint: locationData.coordinates,
          // @ts-ignore
          name: locationData.label,
        });
      }
    },
  };
});

const handleClearLocation = () => {
  setFormValue({
    locationId: undefined,
    mapBoxId: undefined,
    address: undefined,
    locationInputValue: undefined,
    locationPoint: undefined,
    pinpoint: undefined,
    // @ts-ignore
    name: undefined,
  });

  router.push({ ...route, query: { ...route.query, location: undefined } });
};

function apostrophize(name: string) {
  if (name[name.length - 1] === 's') {
    return `${name}’`;
  } else {
    return `${name}’s`;
  }
}

const isDataLoaded = ref(false);

onBeforeMount(async () => {
  if (!userDetails.value) {
    await authStore.fetchUserInfo();
  }

  if (!userClubs.value) {
    await authStore.fetchUserClubs();
  }

  isDataLoaded.value = true;
});

const touchedTitle = ref<boolean>(false);
const handleTouchedTitle = () => {
  touchedTitle.value = true;
};

watch(
  () => [gamForm.value?.form.clubId, touchedTitle.value, isDataLoaded.value],
  ([organizer, touchedTitle]) => {
    if (touchedTitle !== false) return;
    if (organizer === undefined) return;
    if (!gamForm.value) return;

    if (organizer === 'user') {
      if (!userDetails.value) return;
      const capitalized = userDetails.value.username[0].toUpperCase() + userDetails.value.username.substring(1);
      const generatedName = `${apostrophize(capitalized)} Gambit`;
      gamForm.value.form.title = generatedName;

      return;
    }

    if (!organizerOptions.value) return;

    const organizerOption = organizerOptions.value.find((c) => c.value === organizer) as unknown as {
      label: string;
    };

    if (organizerOption) {
      const generatedName = `${apostrophize(organizerOption.label)} Gambit`;
      gamForm.value.form.title = generatedName;
    }
  },
);

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  const { form } = gamForm.value;
  const { locationId, mapBoxId, locationPoint, title } = form;
  if (!locationId && !mapBoxId && !locationPoint) return true;
  if (!title || (typeof title === 'string' && title.trim() === '')) return true;
  return v$.value.value.$invalid;
});

const setFormValue = (value: Partial<GambitForm>) => {
  const form = {
    ...(formStore.gamForm?.form ?? {}),
    ...value,
  } as GambitForm;
  formStore.setForm({ form, rules: getRules });
};

watch(
  () => formStore.gamForm?.form.pinpoint,
  async (newPoint) => {
    if (!newPoint) return;
    const { lat, lng } = newPoint as unknown as GeoLocation;

    const { locationPoint } = formStore.gamForm?.form || {};
    if (newPoint.lat === locationPoint?.lat && newPoint.lng === locationPoint.lng) return;

    const result = await gambits.locationService.reverseGeocode(lat, lng);
    const loc = result?.data as any;

    if (!loc || !gamForm.value || !gamForm.value.form.address === loc.address) return;

    gamForm.value.form.address = loc.address;
    gamForm.value.form.locationInputValue = loc.name || loc.address;
    // @ts-ignore
    gamForm.value.form.name = loc.name;
    gamForm.value.form.locationPoint = loc.coordinates;

    // @ts-ignore
    const map = window.gMap.map;

    // @ts-ignore
    if (!map || !newPoint.lat || !newPoint.lng) return;

    // @ts-ignore
    map.panTo(newPoint);
  },
);

defineExpose({
  isInvalid,
});
</script>

<style lang="scss">
.gambit-form {
  .dp__theme_dark {
    --dp-primary-color: var(--color-pink);
    --dp-background-color: var(--color-dark-700);
    --dp-input-padding: 12px 30px 12px 12px;
    --dp-font-family: 'Hanken Grotesk', sans-serif;
  }

  .dp__input {
    border-radius: 24px;
  }
}
</style>
<style scoped lang="scss">
@use '@/ui/css/partial';

.required-fieldset {
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-linear-gradient);
    inset: -1px;
  }
}

.fields,
.group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

details.fields {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.group {
  border: 1px solid var(--color-white-5);
  border-radius: 1rem;
  padding: var(--spacing-l);
}

.gambit-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 4rem;

  fieldset {
    border-radius: 24px;
    border: 1px solid var(--color-white-5);
    padding: 16px;
  }

  .date-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    justify-content: stretch;
  }

  .date-row {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: stretch;

    .alignment-placeholder {
      width: 1.1rem;
    }

    .ios-inputs {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: space-between;
      gap: 1rem;
    }

    .ios-date,
    .ios-time {
      border: 1px solid var(--color-white-5);
      font-size: 16px;
      line-height: 50px;
      padding: 0;
      margin: 0;
      height: 50px;
      border-radius: 25px;
      font-family:
        'Hanken Grotesk',
        system-ui,
        -apple-system,
        sans-serif;
      width: 100%;
    }

    .ios-date {
      flex-grow: 1;
    }
    .ios-time {
      width: 5.5rem;
      flex-shrink: 0;
    }

    .dp__input {
      @extend .gam-special-border !optional;

      &:before {
        background: var(--color-linear-gradient);
        inset: -1px;
      }
    }
  }

  .game-type-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: calc(var(--spacing-md) * 1) calc(var(--spacing-md) * 1);

    .gam-chip {
      text-transform: uppercase;
    }
  }

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;

    span {
      opacity: 0.7;
    }
  }

  h4 {
    margin: 0 0;
    font-size: 16px;
    font-weight: normal;
    opacity: 0.7;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }

  .gam-select-container {
    padding: var(--spacing-md) var(--spacing-l);
    border: 1px solid var(--color-white-5);
    border-radius: var(--radius-large);
    @extend .gam-special-border !optional;
    position: relative;
  }

  .gam-select-container:after {
    display: block;
    content: '';
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 15px;
    transform: rotate(45deg);
  }

  select {
    color: white;
    height: 33px;
  }

  .optional-details {
    border: 1px solid var(--color-white-10);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
    margin-top: var(--spacing-md);

    legend {
      padding: 0 var(--spacing-sm);
      font-weight: bold;
    }
  }
}
</style>
