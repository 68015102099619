<template>
  <div
    :id="id"
    ref="sidePanelContainer"
    :class="[
      'gam-side-panel',
      direction,
      option,
      { 'is-mobile': isMobile, modal: isModal, complementary: isComplementary, opened: isOpened },
    ]"
  >
    <div
      disabled-v-on-click-outside="closeSidePanel"
      class="gam-side-panel-container"
      :class="{ 'image-expanded': imageExpanded }"
      :style="getSidePanelStyle"
    >
      <ScrollOverflow class="scroll" is-vertical>
        <div
          :class="[
            'gam-side-panel-content',
            {
              'scroll-spacing': option === GamSidePanelOption.ACTION,
              header: header,
            },
          ]"
        >
          <div v-if="header" class="panel-header">
            <span v-if="header.icon" class="header-icon">
              <gam-icon v-bind="getIcon"></gam-icon>
            </span>

            <span class="header-title">
              {{ localize(header.title) }}
            </span>

            <div class="close-button" @click="props.footer?.clear?.onClick">
              <GamIcon class="close-icon" :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE"></GamIcon>
            </div>
          </div>

          <div ref="dialogBody" :class="['panel-body', bodyClass, { 'is-mobile': isMobile }]">
            <div v-if="props.footer?.action?.message" class="form-errors">
              <transition name="dropdown" mode="out-in">
                <GamButtonMessage v-bind="props.footer?.action?.message"></GamButtonMessage>
              </transition>
            </div>

            <slot></slot>
          </div>

          <div v-if="footer" class="panel-footer" :class="{ column: footer.isColumn }">
            <gam-button v-if="footer.action" v-bind="getActionButton"></gam-button>
          </div>
        </div>
      </ScrollOverflow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import GamButton from '@/views/components/GamButton.vue';
import GamButtonMessage from '@/views/components/GamButtonMessage.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamSidePanelOption } from '@/views/composables/constants/components/gamSidePanel.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import ScrollOverflow from '../ScrollOverflow.vue';

const props = withDefaults(defineProps<GamSidePanelType>(), {
  option: GamSidePanelOption.ACTION,
});

const appStore = useAppStore();
const { imageExpanded } = storeToRefs(appStore);

const sidePanelContainer = ref<HTMLElement>();
const dialogBody = ref<HTMLElement>();
const panelContentHeight = ref<number>(0);

const getSidePanelHeight = (): number => {
  if (!isMobile.value) return 0;
  return panelContentHeight.value || useWindowSize().height.value;
};

const getIcon = computed((): GamIconType => {
  return {
    name: props.header?.icon || '',
  };
});

const getSidePanelStyle = computed(() => {
  const value: number = props.isOpened || props.option !== GamSidePanelOption.ACTION ? 0 : getSidePanelHeight() * -1;
  return {
    bottom: `${value}px `,
    height: isMobile.value && props.option === GamSidePanelOption.ACTION ? `${getSidePanelHeight()}px` : '100%',
  };
});

const getActionButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
    ...props.footer?.action,
  };
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-side-panel {
  height: 100svh;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  z-index: 700;
  pointer-events: none;

  &,
  .gam-side-panel-container,
  .gam-side-panel-content,
  .panel-body {
    width: max-content;
  }

  .panel-body {
    width: 100%;
    height: 100%; // TODO: Revise this 25/03/24 rebase
  }

  .ScrollOverflow {
    width: 100%;
    display: flex;
  }

  .gam-side-panel-container,
  .ScrollOverflow {
    height: 100%;
  }

  &:not(.list) .panel-body {
    padding-top: calc(68px + var(--spacing-xxl));
    padding-bottom: calc(85px + var(--spacing-xl));
    padding-right: calc(var(--spacing-xl) - var(--spacing-s));
    padding-left: var(--spacing-xl);
    height: auto;
  }

  &.disabled {
    pointer-events: none;
  }

  .close-button {
    display: flex;
    width: 48px;
    height: 51px;
    align-items: center;
    justify-content: center;
  }

  .close-icon {
    g {
      transition: fill 128ms;
    }

    &:hover {
      cursor: pointer;

      g {
        fill: red;
      }
    }
  }

  &.is-mobile {
    width: 100% !important;
    position: fixed;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: flex-end;

    .gam-side-panel-container {
      box-shadow: none;
      width: 100%;
      height: fit-content;
      max-height: 100%;
      min-height: 120px;
      right: 0;
      bottom: 0;
      padding: 0;
      transition:
        bottom var(--transition-side-panel),
        box-shadow var(--transition-side-panel);
      align-self: flex-end;
      border: 0;

      .drawer-stick {
        position: absolute;
        width: 100%;
        display: flex;
        top: 0;
        cursor: grab;
        left: 0;
        align-items: center;
        justify-content: center;

        &:before {
          content: '';
          display: block;
          width: 56px;
          height: 4px;
          border-radius: 2px;
          background: var(--color-white-30);
        }
      }

      .panel-footer {
        margin: 0;
        border-radius: 0;
      }
    }
  }

  &.opened {
    pointer-events: auto;

    &:not(.modal) {
      .gam-side-panel-container {
        pointer-events: auto;
      }
    }

    &.complementary {
      pointer-events: none;
    }
  }

  &:not(.opened) {
    pointer-events: none;
  }

  &.left {
    left: 0;
    justify-content: flex-start;
    width: 417px;

    &.modal {
      left: 0;

      .gam-side-panel-container {
        left: -100%;
      }

      &.opened {
        left: 0;
      }
    }

    .gam-side-panel-container {
      transition:
        left var(--transition-side-panel),
        box-shadow var(--transition-side-panel);
      left: -100%;
    }

    &.opened {
      .gam-side-panel-container {
        left: 0;
        border-right: 1px solid var(--color-white-10);
      }
    }
  }

  &.right {
    right: 0;

    &.modal {
      right: 0;

      .gam-side-panel-container {
        right: -100%;
      }

      &.opened {
        right: 0;
      }
    }

    .gam-side-panel-container {
      transition:
        right var(--transition-side-panel),
        box-shadow var(--transition-side-panel);
      right: -100%;
    }

    &.opened {
      .gam-side-panel-container {
        border-left: 1px solid var(--color-white-10);
        right: 0;
      }
    }
  }

  &.list {
    width: max-content;
    z-index: 500;

    .gam-side-panel-container {
      width: max-content;
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      &:before {
        background: var(--color-white-10);
      }

      .gam-side-panel-content {
        &.ps {
          padding-bottom: 0;
        }
      }

      .panel-footer {
        width: 100%;
        border-radius: 0;
      }
    }

    &.is-mobile {
      height: 100%;
      pointer-events: none;

      .gam-side-panel-container {
        box-shadow: none;
        padding: 0;
        transition:
          left var(--transition-side-panel),
          right var(--transition-side-panel);
      }

      .drawer-stick {
        display: none;
      }
    }
  }

  &.modal {
    background-color: rgba(0, 0, 0, 0.8);
    transition: var(--transition-side-panel);
  }

  &.complementary {
    z-index: 500;
    background-color: rgba(0, 0, 0, 0);
    transition: var(--transition-side-panel);
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;

    .gam-side-panel-container {
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      pointer-events: auto;

      &:before {
        background: var(--color-white-10);
      }

      .panel-footer {
        width: 100%;
        border-radius: 0;
      }
    }

    &:not(.opened) {
      pointer-events: none;
      .gam-side-panel-container {
        pointer-events: auto;
      }
    }

    &.modal {
      pointer-events: auto;
    }

    &.is-mobile {
      width: 100%;
      height: 100%;
      max-width: none;
      pointer-events: none;

      .gam-side-panel-container {
        box-shadow: none;
        transition:
          left var(--transition-side-panel),
          right var(--transition-side-panel);

        .gam-side-panel-content {
          &.ps {
            padding-bottom: 0;
          }
        }
      }

      .drawer-stick {
        display: none;
      }
    }
  }

  .gam-side-panel-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-l);
    flex-shrink: 0;
    align-self: stretch;
    background: var(--color-dark-700);
    position: relative;

    .drawer-stick {
      display: none;
    }

    .panel-header {
      display: flex;
      padding-top: calc(var(--spacing-xl) - var(--spacing-xs));
      padding-bottom: var(--spacing-md);
      padding-left: var(--spacing-xl);
      padding-right: var(--spacing-xs);
      align-items: center;
      justify-content: space-between;

      position: absolute;
      top: 0;
      left: 6px;
      right: 6px;
      z-index: 750;

      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);

      background: rgba(var(--color-dark-700), 0.4) !important;
      border-bottom: 1px solid var(--color-white-5);

      & > * {
        height: 51px;
      }

      .header-icon {
        display: flex;
        padding: var(--spacing-xs);
        justify-content: center;
        align-items: center;
      }

      .header-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;
      }
    }

    .gam-side-panel-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      z-index: 710;
      flex-grow: 1;

      .panel-body {
        .form-errors {
          margin-bottom: 2rem;
        }

        &.hidden {
          display: none;
        }
      }
    }

    .panel-footer {
      width: 100%;
      display: flex;
      padding: var(--spacing-l) var(--spacing-xxl) var(--spacing-l);
      gap: var(--spacing-md);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 750;
      border-top: 1px solid var(--color-white-5);
      background: rgba(#222, 0.4) !important;

      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);

      overflow: hidden;
    }

    &.image-expanded {
      .panel-footer {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          width: 100svw;
          height: 100svh;
          background: var(--color-dark-70);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 751;
        }
      }
    }
  }
}
</style>
