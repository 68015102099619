<template>
  <div class="report-dialog" :class="{ mobile: isMobile }">
    <div class="title">
      {{ localize(getReportTitle()) }}
    </div>
    <div v-if="reportItems && gamForm" class="report-items">
      <gam-switch
        v-for="(item, i) in reportItems"
        :key="`report-item-${i}`"
        v-bind="item"
        v-model="gamForm.form.reason"
      />
    </div>
    <div v-if="gamForm" class="other-reason">
      <gam-textarea
        v-model="gamForm.form.message"
        placeholder="dialog.report.other.textarea"
        :value="gamForm.form.message"
      />
    </div>
    <div class="button-group">
      <gam-button v-bind="getActionButton" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gambits, localize } from '@/core/gambits';
import FormHelper from '@/core/helpers/form.helper';
import ReportHelper from '@/core/helpers/report.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useFormStore } from '@/stores/form.store';
import GamButton from '@/views/components/GamButton.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTextarea from '@/views/components/GamTextarea.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType, GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { ReportDialogType } from '@/views/composables/models/dialog.interface';
import type { AddReportForm, AddReportValidator } from '@/views/composables/models/form.interface';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue';

const formId = FormId.ADD_REPORT;
const props = defineProps<ReportDialogType>();
const emits = defineEmits([GamComponentsEmits.CLOSE]);

const formStore = useFormStore<AddReportForm, AddReportValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const reportItems = ref<GamSwitchType[]>();
const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);

const reportValidator = (message: string | null) => {
  return FormHelper.reportValidator(message, gamForm.value?.form.reason);
};

onBeforeMount(() => {
  reportItems.value = ReportHelper.getReportReasons(props.type);
});

const getForm = reactive({
  reason: undefined,
  message: null,
});

const getRules = {
  reason: { required },
  message: { reportValidator },
};

onMounted(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
});

const v$ = useVuelidate(getRules, getForm);

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  return v$.value.$invalid;
});

const getReportTitle = (): string => {
  return `dialog.report.${props.type}.title`;
};

const getActionButton = computed((): GamButtonType => {
  return {
    label: 'dialog.report.button.label',
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
    isLoading: isLoading.value,
    isDisabled: isInvalid.value,
    message: errorMessage.value,
    onClick: async () => {
      if (gamForm.value?.form && !isLoading.value) {
        isLoading.value = true;
        const result = await gambits.reportService.addReport(
          props.id,
          props.type,
          gamForm.value.form,
          props.blobSource,
        );
        if (result.data) {
          emits(GamComponentsEmits.CLOSE);
        } else if (result.error) {
          errorMessage.value = {
            label: result.error.message,
            variant: GamButtonMessageVariant.ERROR,
          };
        }
        isLoading.value = false;
      }
    },
  };
});
</script>

<style lang="scss">
.report-dialog {
  display: flex;
  position: relative;
  width: calc(335px - 2 * var(--spacing-menu));
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-md);

  &.mobile {
    width: 100%;
    min-width: 0;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;
    padding-bottom: var(--spacing-md);
    flex: 1 0 0;
  }

  .report-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--spacing-md);
    align-self: stretch;
  }

  .button-group {
    display: flex;
    padding: var(--spacing-l) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
  }
}
</style>
