<template>
  <div :class="getClasses">
    <gam-button v-bind="getCenterButton" />
    <gam-button v-bind="getSatelliteButton" />
  </div>
</template>

<script setup lang="ts">
import { isSafari } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const mapStore = useMapStore();
const { satelliteMode } = storeToRefs(mapStore);

const geolocationStore = useGeolocationStore();
const { userLocation, isLocationPermissionGranted } = storeToRefs(geolocationStore);

const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);

const getClasses = computed(() => ({
  'mod-actions': true,
  'mod-actions-guest': !userDetails.value,
}));

const handlePreciseLocationRequest = async () => {
  if (!isLocationPermissionGranted.value) {
    geolocationStore.setShouldPanToChangedUserLocation(true);
    geolocationStore.setLocationPermissionRequested(true);

    if (!isSafari.value) return;
  }

  // @ts-ignore
  window.gMap?.map?.panTo(userLocation.value?.location);
  // @ts-ignore
  // window.gMap?.map.setZoom(15);
};

const getCenterButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.TARGET,
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    isIconOnly: true,
    onClick: handlePreciseLocationRequest,
  };
});

const getSatelliteButton = computed((): GamButtonType => {
  return {
    leftIcon: satelliteMode.value ? GamIconName['2D_MAP'] : GamIconName['3D_MAP'],
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.ACTIONS,
    isIconOnly: true,
    onClick: () => {
      mapStore.toggleSatelliteMode();
    },
  };
});
</script>

<style scoped lang="scss">
.mod-actions {
  display: flex;
  width: 100%;
  align-items: center;
  align-self: stretch;
  gap: var(--spacing-md);
  justify-content: flex-end;
}

.is-mobile .mod-actions-guest {
  transform: translateY(-1rem);
}

.is-mobile .mod-actions .gam-button.actions {
  position: absolute;
  margin-top: -6rem;
}
</style>
