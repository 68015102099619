import type { GeolocationRepository } from '@/core/data/location/geolocation.repository';
import type { UserGeoData } from '@/core/data/location/location.interface';
import { gambits } from '@/core/gambits';
// import { createLogger } from '@/core/helpers/logger.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';

// const logger = createLogger('GeolocationService');
export class GeolocationService {
  private readonly repository: GeolocationRepository;
  private watchId: number | undefined;

  constructor(repository: GeolocationRepository) {
    this.repository = repository;
  }

  public saveGeoLocation(geoData: UserGeoData): UserGeoData {
    this.repository.setUserLocation(geoData);

    if (useAuthStore().isAuthenticated()) {
      gambits.userService.updateLocation(geoData.location);
    }

    return geoData;
  }

  public async startTracking() {
    if (!navigator.geolocation) {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by this browser.');
    }

    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        useGeolocationStore().setUserLocation({
          location: { lat: position.coords.latitude, lng: position.coords.longitude },
          accuracy: position.coords.accuracy,
        });
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error('Error watching location:', error);
        useGeolocationStore().setUserIpLocation();
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      },
    );
  }

  clearPositionWatcher() {
    if (!this.watchId) return;
    navigator.geolocation.clearWatch(this.watchId);
  }

  getUserLocation(): UserGeoData | null {
    return this.repository.getUserLocation();
  }
}
