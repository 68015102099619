<template>
  <div v-if="locationGambits.length > 0" class="location-gambits-tab" :class="{ mobile: isMobile }">
    <div class="sub-head">
      <h4>Upcoming gambits</h4>
      <hr />
    </div>

    <div class="gambits-wrapper">
      <ScrollOverflow class="gambits-container" is-horizontal>
        <div v-for="(gambit, index) in locationGambits" :key="`gambit-${index}`">
          <TearOff :gambit="gambit" :category="gambit.category" />
        </div>
      </ScrollOverflow>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GambitDto } from '@/core/data/gambit/gambit.interface';
import type { DetailLocationDto } from '@/core/data/location/location.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import TearOff from '@/views/components/gam-gambits/TearOff.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { computed } from 'vue';

interface Props {
  location: DetailLocationDto;
}

const props = defineProps<Props>();

const locationGambits = computed(() => {
  if (!Array.isArray(props.location.gambitIds?.data)) {
    return [];
  }

  const gambits = [...props.location.gambitIds.data];

  return gambits.sort((a: GambitDto, b: GambitDto) => {
    const diff = new Date(a.start).getTime() - new Date(b.start).getTime();
    return diff;
  });
});
</script>

<style scoped lang="scss">
a.tear-off {
  display: block;
  background: var(--color-linear-gradient);
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.5rem;
  color: white;
  border-radius: 2rem;
  text-decoration: none;
}

.location-gambits-tab {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sub-head {
  h4 {
    margin: 0;
  }

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background: var(--color-white-10);
    margin: 1rem 0;
  }
}

.gambits-wrapper {
  width: calc(100% + 2 * 32px);
  margin-left: -32px;
  margin-right: -32px;
}

.gambits-container {
  width: 100%;
  max-height: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  position: relative;
  padding: 0 32px;

  &.mobile {
    .photos-wrapper {
      padding: 0;
    }
  }

  .photos-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    height: 100%;

    .featured-photo {
      width: 100%;
    }

    .all-photos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-md);
      flex-wrap: wrap;
    }
  }
}
</style>
