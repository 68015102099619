<template>
  <div :class="['gam-map-pin', getClasses, variant]">
    <div v-if="icon" class="content">
      <div class="icon">
        <gam-icon :name="icon" :size="GamIconSize.X_LARGE" is-primary />
      </div>
    </div>

    <div v-else-if="location === LocationType.MY_LOCATION" class="gam-pin-my-location">
      <gam-icon :name="GamIconName.LOCATION" :size="GamIconSize.SMALL" />
    </div>

    <div v-else-if="isTournament || isEvent" class="tournament-icon">
      <gam-icon v-bind="getTournamentIcon" />
    </div>

    <div v-else :class="['gam-map-pin-container', getContainerClasses]">
      <transition mode="out-in">
        <div v-if="showGroupLocations" class="group-locations">
          <span>{{ groupLocations }}</span>
        </div>
      </transition>
      <div v-if="!showGroup" class="content">
        <div v-if="getIcon" class="icon">
          <gam-icon v-bind="getIcon" />
        </div>
      </div>
      <div v-if="showLive" class="live">
        <span>{{ localize(getInfoLabel) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LocationType } from '@/core/data/location/location.type';
import { localize } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import GamIcon from '@/views/components/GamIcon.vue';
import {
  GamIconName,
  GamIconSize,
  GamIconSpecialName,
  GamIconSpecialSize,
} from '@/views/composables/constants/components/gamIcon.constants';
import { GamMapPinVariant } from '@/views/composables/constants/components/gamMap.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamMapPinType } from '@/views/composables/models/components/GamMap';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<GamMapPinType>(), {
  variant: GamMapPinVariant.DEFAULT,
});
const isActive = ref<boolean>(false);

const getClasses = computed(() => ({
  tournament: props.isTournament || props.isEvent,
  my: props.location === LocationType.MY_LOCATION,
  small: props.isSmall,
}));

const getContainerClasses = computed(() => ({
  'is-active': isActive.value,
  'is-group': props.showGroup,
  'is-selected': props.isSelected && !props.isDisabled,
  'no-events': props.preventPointer,
}));

const getIcon = computed((): GamIconType | undefined => {
  if (!props.showGroup) {
    return {
      name: LocationHelper.getLocationTypeIcon([props.location || LocationType.CHESS_FRIENDLY]),
      size: props.isSmall ? GamIconSize.SMALL : GamIconSpecialSize.PIN,
      isDark: props.variant !== GamMapPinVariant.GAMBIT && !isActive.value && !props.isSelected,
    };
  }
  return undefined;
});

const getTournamentIcon = computed((): GamIconType => {
  return {
    name: props.isTournament ? GamIconSpecialName.TOURNAMENT : GamIconSpecialName.EVENT,
    size: GamIconSpecialSize.TOURNAMENT,
  };
});

const getInfoLabel = computed((): string => {
  return 'app.component.map.pin.live';
});

const showGroupLocations = computed((): boolean => {
  return !!props.groupLocations;
});

const showLive = computed((): boolean => {
  return false;
});
</script>

<style lang="scss" scoped>
.no-events {
  pointer-events: none;
}

.gam-map-pin-container.is-selected {
  background: transparent;
}
</style>
