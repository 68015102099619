import { BaseReportReason, ReportReasonLocation, ReportType } from '@/core/data/report/report.type';
import { GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';

export default class ReportHelper {
  private static getReportSwitchType(type: ReportType, key: string): GamSwitchType {
    return {
      type: GamSwitchValueType.RADIO,
      label: `dialog.report.reason.${key}`,
      name: type,
      value: key,
    };
  }
  private static mapReportReasons(type: ReportType, keys?: string[]): GamSwitchType[] {
    const typeReason = keys?.map((key) => {
      return this.getReportSwitchType(type, key);
    });
    const baseReason = Object.values(BaseReportReason).map((value) => {
      return this.getReportSwitchType(type, value);
    });
    return [...(typeReason || []), ...baseReason];
  }

  static getReportReasons(type: ReportType): GamSwitchType[] {
    switch (type) {
      case ReportType.LOCATION:
        return this.mapReportReasons(type, Object.values(ReportReasonLocation));
      // case ReportType.USERS:
      //   return this.mapReportReasons(type, Object.values(ReportReasonPlayer));
      default:
        return this.mapReportReasons(type);
    }
  }
}
