<template>
  <div class="gam-map">
    <div
      :class="[
        'gmap-container',
        zoomClassName,
        {
          'add-mode': isEditingLocation || isEditingGambit,
          'location-mode': isEditingLocation,
          'gambit-mode': isEditingGambit,
          'pinpointing-location': isChoosingCoordinate,
        },
      ]"
    >
      <GoogleMap
        id="g-map"
        ref="gMap"
        v-bind="getGMapOptions"
        @mousemove="getCoords"
        @dragstart="startDrag"
        @dragend="endDrag"
        @idle="initMap"
        @zoom_changed="handleZoomChanged"
        @click="handleClick"
      >
        <template v-if="cursorLocation">
          <gam-add-pin
            :is-dragging="isDragging"
            :pin="cursorLocation"
            :label="`${parseFloat(cursorLocation.coordinates.lat.toFixed(5))}, ${parseFloat(
              cursorLocation.coordinates.lng.toFixed(5),
            )}`"
            is-unclickable
          />
        </template>

        <CustomMarker
          v-if="(isEditingLocation || isEditingGambit) && pinpoint && !isLocationSelected"
          :options="{ position: pinpoint }"
        >
          <gam-add-pin :pin="cursorLocation" is-pinned :is-dragging="isDragging" :is-unclickable="true" />
        </CustomMarker>

        <CustomMarker v-if="userCenter" :options="{ position: userCenter }">
          <div
            style="
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background-color: rgba(0, 255, 0, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div style="width: 6px; height: 6px; border-radius: 6px; background-color: green"></div>
          </div>
        </CustomMarker>
      </GoogleMap>
    </div>

    <div v-if="!isMobile" class="map-modes-container">
      <map-modes />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GeoLocation, MapLocationDto } from '@/core/data/location/location.interface';
import { MapLocationType } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useFormStore } from '@/stores/form.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { incomingMarkers, outgoingMarkers, useMarkerStore } from '@/stores/marker.store';
import { useTableStore } from '@/stores/table.store';
import GamAddPin from '@/views/components/gam-map/GamAddPin.vue';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import { type GamMapType } from '@/views/composables/models/components/GamMap';
import { type LocationFormProps, type LocationValidator } from '@/views/composables/models/form.interface';
import MapModes from '@/views/main/locations/widgets/MapModes.vue';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CustomMarker, GoogleMap } from 'vue3-google-map';
import LatLngBounds = google.maps.LatLngBounds;
import LatLng = google.maps.LatLng;

const FALLBACK_LOCATION = {
  lat: 0,
  lng: 0,
};

const DEFAULT_ZOOM = 15;

defineProps<GamMapType>();

const router = useRouter();
const route = useRoute();

const isEditingLocation = computed(() => ['add_location', 'edit_location'].includes(String(route.query.side_panel)));
const isEditingGambit = computed(() => ['add_gambit', 'edit_gambit'].includes(String(route.query.side_panel)));
const isChoosingCoordinate = computed(() => isEditingLocation.value || isEditingGambit.value);

const emits = defineEmits([GamComponentsEmits.UPDATE]);
const listId = GamListId.MARKERS;

const mapStore = useMapStore();
const { addMode, cursorLocation, satelliteMode } = storeToRefs(mapStore);

const geolocationStore = useGeolocationStore();
const { userLocation, shouldPanToChangedUserLocation } = storeToRefs(geolocationStore);

watch(
  () => route.query,
  (query) => {
    if (!['add_location', 'edit_location', 'add_gambit'].includes(String(query.side_panel))) {
      mapStore.setAddMode(false);
      return;
    }

    mapStore.setAddMode(true);
  },
);

watch(
  () => route.query,
  (query, oldQuery) => {
    const oldLocation = String(oldQuery.location);
    const newLocation = String(query.location);

    if (oldLocation === newLocation) return;

    const oldLocatinPin = document.getElementById(`pin-${oldLocation}`);
    const newLocationPin = document.getElementById(`pin-${newLocation}`);

    if (oldLocatinPin) {
      oldLocatinPin.classList.remove('selected');
    }

    if (newLocationPin) {
      newLocationPin.classList.add('selected');
    }
  },
);

const addLocationFormStore = useFormStore<LocationFormProps, LocationValidator>(FormId.ADD_LOCATION)();
const editLocationFormStore = useFormStore<LocationFormProps, LocationValidator>(FormId.EDIT_LOCATION)();
const addGambitFormStore = useFormStore<LocationFormProps, LocationValidator>(FormId.ADD_GAMBIT)();
const { gamForm: addLocationFormRef } = storeToRefs(addLocationFormStore);
const { gamForm: editLocationFormRef } = storeToRefs(editLocationFormStore);
const { gamForm: addGambitFormRef } = storeToRefs(addGambitFormStore);

const isLocationSelected = computed(() => {
  return !!route.query.location;
});

// TODO: Refactor to remove coupling with the global form state
function handleCursorClick(point: GeoLocation) {
  router.push({ ...route, query: { ...route.query, location: undefined } });

  if (addLocationFormRef.value) {
    (addLocationFormRef.value.form as any).pinpoint = point;
  }

  if (editLocationFormRef.value) {
    (editLocationFormRef.value.form as any).pinpoint = point;
  }

  if (addGambitFormRef.value) {
    (addGambitFormRef.value.form as any).pinpoint = point;
    (addGambitFormRef.value.form as any).locationId = undefined;
  }
}

const pinpoint = computed(() => {
  const { side_panel } = route.query;

  if (side_panel === 'add_location' && (addLocationFormRef?.value?.form as any)?.pinpoint) {
    return (addLocationFormRef.value?.form as any).pinpoint;
  }

  if (side_panel === 'edit_location' && (editLocationFormRef.value?.form as any)?.pinpoint) {
    return (editLocationFormRef.value?.form as any).pinpoint;
  }

  if (side_panel === 'add_gambit' && (addGambitFormRef.value?.form as any)?.pinpoint) {
    return (addGambitFormRef.value?.form as any).pinpoint;
  }

  return null;
});

const gMap = ref<InstanceType<typeof GoogleMap>>();

const markerStore = useMarkerStore(listId)();
const { lastUpdated } = storeToRefs(markerStore);

const isMapInitialized = ref<boolean>(false);
const isDragging = ref<boolean>(false);
const addCenter = ref<GeoLocation | null>(null);
const mapCenter = ref<GeoLocation>();
const mapZoom = ref<number>();

const tableStore = useTableStore<GamDropdownItem>(GamListId.ADD_LOCATION)();

async function geocodeCoordinates(coordinates: GeoLocation) {
  await tableStore.setSearchQuery({
    query: `${coordinates.lat}, ${coordinates.lng}`,
    preventLoad: false,
    selectFirstItem: true,
  });
}

const mapCenterFromQueryParams = computed(() => {
  const { lat, lng } = route.query;
  if (!lat || !lng) {
    return gMap.value?.center;
  }

  const latNum = parseFloat(String(lat));
  const lngNum = parseFloat(String(lng));

  if (isNaN(latNum) || isNaN(lngNum)) {
    return gMap.value?.center;
  }

  return { lat: latNum, lng: lngNum };
});

watch(
  () => route.query.initialize_with_current_coords,
  (new_init_request, old_init_request) => {
    if (String(new_init_request) === 'true' && String(old_init_request) !== 'true') {
      const lat = String(route.query.lat);
      const lng = String(route.query.lng);

      handleCursorClick({ lat: parseFloat(lat), lng: parseFloat(lng) });

      setTimeout(() => {
        router.replace({ ...route, query: { ...route.query, initialize_with_current_coords: undefined } });
      }, 1);
    }
  },
);

watch([isEditingLocation, isChoosingCoordinate], (currentState, previousState) => {
  const isChoosingCoordinate = currentState[1];
  const wasEditingLocation = previousState[0];

  if (!isChoosingCoordinate && isMobile.value) return;
  if (wasEditingLocation) return;

  const center = mapCenterFromQueryParams.value || gMap.value?.center;
  if (!center || typeof center.lat !== 'number' || typeof center.lng !== 'number') return;

  const coords = { lat: center.lat, lng: center.lng };

  setCursorLocation(coords);
  geocodeCoordinates(coords);
});

const handleClick = (e: MouseEvent & { latLng: any }) => {
  if ((!isEditingLocation.value && !isEditingGambit.value) || !e.latLng || isDragging.value) return;

  const coords: GeoLocation = { lat: e.latLng.lat(), lng: e.latLng.lng() };

  handleCursorClick(coords);
};

onBeforeMount(() => {
  markerStore.setLoadCallback((listId, newCenter?: GeoLocation) => {
    if (newCenter) {
      mapCenter.value = newCenter;
      markerStore.setLocationParam(newCenter);
      return gambits.locationService.getMapLocations(listId, newCenter);
    }

    if (route.query.lat && route.query.lng) {
      mapCenter.value = { lat: parseFloat(String(route.query.lat)), lng: parseFloat(String(route.query.lng)) };
    } else if (gMap?.value?.map?.getCenter()) {
      const center = gMap?.value?.map?.getCenter() as LatLng;
      mapCenter.value = { lat: center?.lat(), lng: center?.lng() };
    } else {
      mapCenter.value = FALLBACK_LOCATION;
    }

    markerStore.setLocationParam(mapCenter.value);
    return gambits.locationService.getMapLocations(listId, mapCenter.value);
  });
});

onMounted(() => {
  markerStore.loadItems({ loadOnInit: true });

  // @ts-ignore
  window.gMap = gMap.value;

  handleBoundsChanged();
  setTimeout(() => repositionLegalElements(), 1000);
});

const initMap = async () => {
  if (!isMapInitialized.value) {
    if (route.query.lat && route.query.lng && route.query.zoom) {
      updateAddCenter(
        { lat: parseFloat(String(route.query.lat)), lng: parseFloat(String(route.query.lng)) },
        parseInt(String(route.query.zoom), 10),
      );
    } else {
      mapStore.updateMapBounds(getMapBounds());
    }
    isMapInitialized.value = true;
  }

  handleBoundsChanged();
};

const getMapBounds = (): LatLngBounds | undefined => {
  return gMap.value?.map?.getBounds();
};

const getMapCenter = (): LatLng | undefined => {
  return gMap.value?.map?.getCenter();
};

const setCursorLocation = (coords: GeoLocation) => {
  mapStore.setCursorLocation({
    address: `${coords.lat}, ${coords.lng}`,
    coordinates: coords,
  });
};

const getCoords = (event: google.maps.MapMouseEvent) => {
  if (!addMode.value) return;
  const coords: GeoLocation = {
    lat: event.latLng?.lat() || 0,
    lng: event.latLng?.lng() || 0,
  };
  setCursorLocation(coords);
  event.stop();
};

const startDrag = () => {
  isDragging.value = true;
  handleBoundsChanged.cancel?.();
  markerStore.addUniqueItems.cancel?.();
};

const endDrag = () => {
  setTimeout(() => {
    isDragging.value = false;
  }, 200);
};

const updateAddCenter = (center: GeoLocation | null, zoom?: number) => {
  addCenter.value = center;
  if (center) {
    markerStore.setLocationParam(center);
  }
  if (zoom) gMap.value?.map?.setZoom(zoom);
};

const repositionLegalElements = () => {
  if (window.innerWidth >= 768) return;

  const logoEl = document.querySelector('#g-map > div.mapdiv > div > div.gm-style > div:nth-child(14) > div');
  if (logoEl) {
    // @ts-ignore
    logoEl.style = `
      position: absolute; left: 0; right: auto; top: 0px; bottom: auto; height: min-content;
      opacity: 0.9;
    `;
  }

  const logoImg = document.querySelector(
    '#g-map > div.mapdiv > div > div.gm-style > div:nth-child(14) > div > a > div > img',
  );
  if (logoImg) {
    // @ts-ignore - ???
    logoImg.style = 'width: 50px; height: 20px;';
  }

  const gTermsEl = document.querySelector('#g-map > div.mapdiv > div > div.gm-style > div:nth-child(16) > div');
  if (gTermsEl) {
    // @ts-ignore - ???
    gTermsEl.style = `
      position: absolute;

      top: 3px;
      left: 50px;
      right: 0;
      bottom: auto;
      height: min-content;
      display: flex;
      alignItems: center;
      justifyContent: flex-end;
      filter: invert();
      opacity: 0.9;
    `;
  }

  const kbdHelpEl = document.querySelector(
    '#g-map > div.mapdiv > div > div.gm-style > div:nth-child(16) > div > div:nth-child(1)',
  );
  if (kbdHelpEl) {
    // @ts-ignore - ???
    kbdHelpEl.style = 'display: none';
  }
};

const handleBoundsChanged = debounce(
  () => {
    if (!isMapInitialized.value) {
      return;
    }

    handleBoundsChanged.cancel();

    const mapCenter = getMapCenter();
    const mapBounds = getMapBounds();

    if (!mapCenter || !mapBounds) return;

    mapStore.updateMapBounds(mapBounds);

    const northEast = mapBounds.getNorthEast();
    const radius = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, northEast);
    mapStore.setNewRadius(radius);

    const center = {
      lat: mapCenter.lat(),
      lng: mapCenter.lng(),
    };

    const zoom = gMap.value?.map?.getZoom() || DEFAULT_ZOOM;

    nextTick(() => {
      markerStore.setLocationParam(center);
      markerStore.addUniqueItems(center);
    });

    router.replace({
      ...route,
      query: { ...route.query, ...center, zoom },
    });

    mapStore.setCurrentLocation({ ...center, zoom });
  },
  128,
  { trailing: true },
);

// TODO: mapStore location is the source of truth for the location
const userCenter = computed((): GeoLocation | null => {
  try {
    return userLocation.value?.location || gambits.geolocationService.getUserLocation()?.location || null;
  } catch {
    return null;
  }
});

const onClickMarker = async (location: MapLocationDto): Promise<void> => {
  if (location.type === MapLocationType.LOCATION) {
    emits(GamComponentsEmits.UPDATE, location.id);
    gMap.value?.map?.panTo(location.coordinates);

    if (router.currentRoute.value.query.side_panel === 'add_gambit') {
      router.push({ ...route, query: { ...route.query, location: location.id, locationName: location.name } });
      mapStore.setCursorLocation({ coordinates: location.coordinates });
      return;
    }

    router.push({ ...route, query: { ...route.query, location: location.id, side_panel: 'location_detail' } });
  } else if (location.type === MapLocationType.CLUSTER) {
    const zoom = gMap.value?.map?.getZoom();
    if (!zoom) return;

    gMap.value?.map?.setCenter(location.coordinates);
    gMap.value?.map?.setZoom(zoom + 2);
  }
};

const zoomClassName = computed(() => {
  if (typeof mapZoom.value !== 'number') {
    return 'zoom-default';
  }

  if (mapZoom.value < 9) {
    return 'zoom-nano';
  }

  if (mapZoom.value < 11) {
    return 'zoom-tiny';
  }

  if (mapZoom.value < 14) {
    return 'zoom-small';
  }

  return 'zoom-default';
});

const handleZoomChanged = () => {
  const zoom = gMap.value?.map?.getZoom();
  mapZoom.value = Number(zoom);
};

const getGMapOptions = computed((): any => {
  return {
    ...gambits.configService.getGMapConfig(),
    ...gambits.configService.getMapZoom(),
    center: addCenter.value || mapCenter.value,
    apiKey: gambits.configService.getGMapApiKey(),
    mapId: gambits.configService.getGMapMapId(),
    mapTypeId: satelliteMode.value ? 'satellite' : 'roadmap',
  };
});

const isClustererInitialized = ref<boolean>(false);

watch(lastUpdated, async () => {
  if (isClustererInitialized.value === false) {
    await gambits.locationService.getMarkerCluster({
      map: gMap.value?.map,
      items: [],
      onClickMarker,
    });
    isClustererInitialized.value = true;
  }

  gambits.locationService.addItems(incomingMarkers, onClickMarker);
  gambits.locationService.removeItems(outgoingMarkers);
});

watch(userLocation, (newLocation) => {
  if (!shouldPanToChangedUserLocation.value) return;
  if (!newLocation?.location) return;

  geolocationStore.setShouldPanToChangedUserLocation(false);

  gMap?.value?.map?.panTo(newLocation.location);
  // @ts-ignore
  // window.gMap.map.setZoom(15);
});

onUnmounted(async () => {
  await gambits.locationService.destroyMap();
  markerStore.resetList();
});
</script>
<style scoped lang="scss">
.gam-map {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 100;

  .gmap-container {
    width: 100%;
    height: 100%;
    position: relative;

    &.add-mode {
      * {
        cursor: none !important;
        cursor: crosshair !important;
      }
    }

    #g-map {
      width: 100%;
      height: 100%;

      * {
        border: 0 !important;
      }
    }
  }

  .map-modes-container {
    position: absolute;
    z-index: 500;
    bottom: 24px;
    right: 24px;
  }
}
</style>

<style lang="scss">
.location-mode {
  .gam-map-pin {
    pointer-events: none !important;
    .gam-map-pin-container {
      width: 6px !important;
      height: 6px !important;
    }

    .content {
      .icon-pin {
        width: 6px !important;
        height: 6px !important;
        opacity: 0 !important;
      }
    }
  }

  .gam-pin-cluster-wrapper {
    width: 36px !important;
    height: 36px !important;

    .cluster-count {
      min-width: 16px !important;
      height: 16px !important;
      font-size: 8px !important;
    }
  }
}
</style>
