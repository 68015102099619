<template>
  <div :class="['main-view gambits-view', { 'is-mobile': isMobile }]">
    <EditGambit v-if="gambitDetails" :key="gambitDetails.id" :gambit="gambitDetails" />

    <gam-detail-view v-bind="getDetailView">
      <ScrollOverflow class="detail-scroll" is-vertical>
        <div class="detail-container gambit">
          <GamGambitDetail v-if="getGambitData" v-bind="getGambitData" />
        </div>
      </ScrollOverflow>
    </gam-detail-view>
  </div>
</template>

<script lang="ts" setup>
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamDetailView from '@/views/components/detail-view/GamDetailView.vue';
import GamGambitDetail from '@/views/components/gam-gambits/GamGambitDetail.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { GamGambitCardCategory } from '@/views/composables/constants/components/gamGambit.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import type { GambitDetailDto, GamGambitDetailViewType } from '@/views/composables/models/components/GamGambit';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EditGambit from './side-panels/EditGambit.vue';

const listId = GamListId.GAMBITS;

const gambitDetails = ref<GambitDetailDto>();

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  gambitDetails.value = await getGambitDetail();
});

watch(
  () => route.query.reload,
  async (reload) => {
    if (reload !== 'true') return;

    gambitDetails.value = await getGambitDetail();
    router.replace({ ...route, query: { ...route.query, reload: undefined } });
  },
);

const getGambitDetail = async (): Promise<GambitDetailDto | undefined> => {
  if (route.params.id) {
    const gambitResult = await gambits.gambitService.getGambit(String(route.params.id));

    if (gambitResult?.data) {
      return gambitResult.data;
    }
  }
};

const getGambitData = computed((): GamGambitDetailViewType => {
  return {
    gambit: gambitDetails.value,
    category: GamGambitCardCategory.DETAIL,
    isMobile: isMobile.value,
  };
});

const getDetailView = computed((): GamDetailType => {
  return {
    listId,
    showListCursors: !!route.params.id,
    isOpen: true,
  };
});
</script>

<style scoped lang="scss">
.main-view {
  .detail-header {
    .back-link {
      display: flex;
      gap: 0.5rem;
      text-decoration: none;
      color: var(--color-white);
    }
  }

  .detail-scroll {
    width: 100%;
    height: 100%;

    .detail-container {
      max-width: 596px;
      margin: 0 auto;

      @media (min-width: 640px) {
        margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto 0;
      }
    }
  }
}

.right-side-panel,
.gam-form-content {
  width: 100%;
  height: calc(100% - 5rem);
}

.detail-wrapper.is-mobile {
  .detail-header {
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 0px;
  }

  .back-link {
    padding: calc(var(--spacing-l) + var(--spacing-xs));
  }
}
</style>
