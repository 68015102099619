export enum GamListId {
  ANY = 'any',
  PLAYERS = 'players',
  PLAYERS_FILTER_LOCATION = 'players-filter-location',
  PLAYERS_FILTER_PLAYER = 'players-filter-player',
  GAMBITS = 'gambits',
  CLUBS = 'clubs',
  LOCATIONS = 'locations',
  LOCATION_DETAIL = 'location-detail',
  GAMBIT_ORGANIZER = 'gambit-ogranizer',
  LOCATION_SEARCH = 'location-search',
  LOCATION_IMAGES = 'location-images',
  LOCATION_GAMBITS = 'location-gambits',
  LOCATION_COMMENTS = 'location-comments',
  ADD_LOCATION = 'add-location',
  LOCATION_FORM = 'location-form',
  GAMBIT = 'gambit',
  GAMBIT_FORM = 'gambit-form',
  ADD_CLUB = 'add-club',
  EDIT_CLUB = 'edit-club',
  CLUB_CURATORS = 'club-curators',
  CLUB_LOCATIONS = 'club-locations',
  MARKERS = 'markers',
  MONTHS = 'months',
  COUNTRIES = 'countries',
  ADMIN_USERS = 'admin-users',
  ADMIN_LOCATIONS = 'admin-locations',
  ADMIN_REPORTS = 'admin-reports',
}
