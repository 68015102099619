<template>
  <div class="gam-filter-item-checkbox">
    <div v-if="items?.length" class="checkbox-items">
      <gam-switch
        v-for="item in items"
        v-bind="getCheckboxItem(item)"
        :key="item.value"
        v-model:checkbox="checkboxItems"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import GamHelper from '@/core/helpers/app.helper';
import GamSwitch from '@/views/components/GamSwitch.vue';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamFilterItem, GamFilterType } from '@/views/composables/models/components/GamFilter';
import type { GamCheckboxEmit, GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import { onBeforeMount, ref, watch } from 'vue';

const props = defineProps<GamFilterType>();
const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);
const checkboxItems = ref<GamCheckboxEmit>();
const selectedItems = ref<string[]>([]);

onBeforeMount(() => {
  props.items?.forEach((item) => {
    if (item.selected) selectedItems.value.push(item.value);
  });
});

const getCheckboxItem = (item: GamFilterItem): GamSwitchType => {
  return {
    label: item.label,
    value: item.value.toString(),
    name: item.name || item.value,
    type: GamSwitchValueType.CHECKBOX,
    position: GamSwitchPosition.RIGHT,
    checked: selectedItems.value.includes(item.value),
  };
};

const setSelected = (checkbox: GamCheckboxEmit | undefined) => {
  if (!checkbox) return;

  if (selectedItems.value?.includes(checkbox.value)) {
    selectedItems.value = GamHelper.removeItem(selectedItems.value, checkbox.value);
  } else {
    selectedItems.value.push(checkbox.value);
  }
};

watch(checkboxItems, (value) => {
  setSelected(value);
  emit(GamComponentsEmits.UPDATE_VALUE, selectedItems.value);
});
</script>

<style scoped lang="scss">
.gam-filter-item-checkbox {
  display: flex;
  width: 100%;

  .checkbox-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
}
</style>
