<template>
  <div v-if="true" ref="intersectRoot" :class="['gam-intersect', { 'is-mobile': isMobile }]">
    <transition name="intersect" mode="out-in">
      <ScrollOverflow
        :class="['gam-intersect-wrapper', { 'scroll-spacing': scrollSpacing, dropdown: dropdown }]"
        is-vertical
      >
        <div class="slot-content" :style="getIntersectStyle">
          <slot />
        </div>
      </ScrollOverflow>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamSimpleIntersectType } from '@/views/composables/models/components/GamSimpleIntersect';
import { useIntersectionObserver } from '@vueuse/core';
import { computed, onUnmounted, ref, type StyleValue } from 'vue';
import ScrollOverflow from './ScrollOverflow.vue';

const props = withDefaults(defineProps<Partial<GamSimpleIntersectType>>(), {
  showMore: true,
  noData: false,
  noDataInfo: undefined,
  threshold: 0.5,
  rootMargin: '200px 0px 0px',
  widthSpacing: 20,
  itemsGap: 8,
  spacerPadding: isMobile ? 130 : 168,
  footerPadding: isMobile ? 20 : 42,
});

const intersectRoot = ref<HTMLElement>();
const intersectTarget = ref<HTMLElement>();
const emits = defineEmits([GamComponentsEmits.INFINITE]);

const onIntersection = (intersectionObserver: IntersectionObserverEntry[]): void => {
  const { isIntersecting } = intersectionObserver[0];
  if (isIntersecting && !props.showMore) {
    emits(GamComponentsEmits.INFINITE);
  }
};

const getIntersectStyle = computed((): StyleValue => {
  return {
    gap: `${props.itemsGap}px`,
  };
});

const { stop } = useIntersectionObserver(intersectTarget, onIntersection, {
  root: intersectRoot,
  threshold: props.threshold,
  rootMargin: props.rootMargin,
});

onUnmounted(() => {
  stop();
});
</script>

<style lang="scss">
.gam-intersect {
  width: inherit;
  height: 100%;

  .slot-content {
    scroll-behavior: smooth;
  }

  &.is-mobile {
    .gam-no-data-wrapper {
      padding: var(--spacing-mobile-top) var(--spacing-mobile-wrapper) var(--spacing-mobile-bottom);
    }

    .gam-intersect-wrapper {
      padding: 0;
    }
  }

  .gam-no-data-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: var(--spacing-desktop-top) var(--spacing-xxl) var(--spacing-desktop-bottom);
    position: absolute;
    top: 0;
  }

  .intersect-loader-wrapper,
  .geo-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
    background: var(--color-dark-70);

    &.dropdown {
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      background: var(--color-dark-30);
    }
  }

  .gam-intersect-wrapper {
    width: calc(100%);
    height: 100%;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;

    .slot-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    &.ps {
      &.dropdown {
        .intersect-loader-wrapper {
          &.show-more {
            padding-bottom: var(--spacing-l);
          }
        }
      }
    }

    .intersect-element {
      width: 100%;
      display: flex;
      justify-content: center;

      &.show-more {
        padding-bottom: var(--spacing-xxl);
      }
    }
  }
}
</style>
