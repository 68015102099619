<template>
  <div class="location-search">
    <GamSimpleLocationSearch v-bind="getSearchInput" @click-clear="props.onClickClear" />
  </div>
</template>

<script setup lang="ts">
import { gambits } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import type { Result } from '@/core/network/http/httpClient.interface';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamSimpleLocationSearch from '@/views/components/GamSimpleLocationSearch.vue';
import { GamDropdownCategory, GamDropdownSize } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import type { BaseSearchType, GamSimpleInputSearchType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

const props = withDefaults(defineProps<BaseSearchType>(), {
  canClear: true,
  internal: true,
  mapbox: true,
});

const mapStore = useMapStore();
const { seekingLocation } = storeToRefs(mapStore);

const tableStore = useTableStore<GamDropdownItem>(props.id as GamListId)();
const { selectedItem } = storeToRefs(tableStore);

onMounted(() => {
  selectedItem.value = gambits.filterService.getSavedTableLocation(props.id as GamListId);
});

const getSearchInput = computed((): GamSimpleInputSearchType<GamDropdownItem> => {
  return {
    id: props.id as GamListId,
    loadOnInit: props.loadOnInit,
    selectFirstItem: props.selectFirstItem,
    isSearch: false,
    input: {
      ...props.input,
      name: props.input?.name || GamInputName.SEARCH,
      placeholder: props.input?.placeholder || 'locations.general.search.placeholder',
      leftIcon: props.input?.leftIcon || GamIconName.SEARCH,
      canClear: props.canClear,
    },
    dropdown: {
      ...props.dropdown,
      id: props.id,
      category: GamDropdownCategory.LOCATIONS,
      size: GamDropdownSize.LARGE,
      itemSpacing: 0,
      reversed: isMobile.value && seekingLocation.value,
    },
    selectCallback: props.callback,
    loadCallback: async (query: string): Promise<Result<GamDropdownItem[]>> => {
      const result = await gambits.locationService.searchLocationsByQuery(query, {
        internal: props.internal,
        mapbox: props.mapbox,
      });

      return {
        ...result,
        data: LocationHelper.mapSearchLocations(result.data),
      };
    },
    onQueryChanged: (query: string | undefined) => {
      props.onQueryChanged && props.onQueryChanged(query);
    },
    onMounted: props.onMounted,
  };
});
</script>

<style scoped lang="scss">
.location-search {
  width: 100%;
}
</style>
