<template>
  <div class="gam-more">
    <gam-button v-bind="getMoreBtn" />
    <div
      v-if="isMoreOpened && items"
      v-on-click-outside="closeMoreMenu"
      class="gam-more-container"
      :class="[position, size]"
    >
      <gam-button v-for="(item, i) in items" :key="`m-item-${i}`" v-bind="getItemBtn(item)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamMoreItem, GamMoreType } from '@/views/composables/models/components/GamMore';
import { vOnClickOutside } from '@vueuse/components';
import { computed, ref } from 'vue';

const props = defineProps<GamMoreType>();
const isMoreOpened = ref<boolean>(false);

const getItemBtn = (item: GamMoreItem): GamButtonType => {
  return {
    ...item,
    isFullSize: true,
    variant: item.variant || GamButtonVariant.SECONDARY,
    size: item.size || GamButtonSize.ACTIONS,
    onClick: (e: MouseEvent) => {
      isMoreOpened.value = false;
      item.onClick?.(e);
    },
  };
};

const getMoreIcon = (): GamIconName => {
  if (props.size === GamButtonSize.X_SMALL) {
    return GamIconName.DOTS_V;
  } else {
    return isMoreOpened.value ? GamIconName.X_CLOSE : GamIconName.PLUS;
  }
};

const getMoreBtn = computed((): GamButtonType => {
  return {
    isIconOnly: true,
    leftIcon: props.icon || getMoreIcon(),
    size: props.size,
    variant: props.variant
      ? props.variant
      : props.size === GamButtonSize.X_SMALL
        ? GamButtonVariant.SECONDARY
        : GamButtonVariant.PRIMARY,
    isActive: isMoreOpened.value,
    onClick: () => {
      isMoreOpened.value = !isMoreOpened.value;
    },
  };
});

const closeMoreMenu = (event: MouseEvent) => {
  event.stopPropagation();
  isMoreOpened.value = false;
};
</script>

<style scoped lang="scss">
.gam-more {
  position: relative;

  .gam-more-container {
    position: absolute;
    width: max-content;
    min-width: 166px;
    background-color: var(--color-dark-70);
    border: 1px solid var(--color-white-10);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: var(--radius-normal);
    padding: var(--spacing-md);

    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);

    &.actions,
    &.small {
      &.left-below,
      &.right-below {
        top: 38px;
      }

      &.left-above,
      &.right-above {
        bottom: 38px;
      }
    }

    &.large {
      &.left-below,
      &.right-below {
        top: 60px;
      }

      &.left-above,
      &.right-above {
        bottom: 60px;
      }
    }

    &.left-below {
      right: 0;
      top: 28px;
    }

    &.left-above {
      right: 0;
      bottom: 28px;
    }

    &.right-below {
      left: 0;
      top: 28px;
    }

    &.right-above {
      left: 0;
      bottom: 28px;
    }

    .items-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xs);
    }
  }
}
</style>
