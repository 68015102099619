<template>
  <GamHighlight :class="['highlight', { 'is-mobile': isMobile }]" />
  <div class="gam-gambit-location" :class="{ 'is-mobile': isMobile, shouldHover, detail: !isList }">
    <div class="location-image">
      <gam-chip v-if="props.distance" v-bind="getDistanceChip" />
      <img
        v-if="props.locationImageHash && !isImageError"
        :class="['gam-location-image', isImageLoading ? 'image-loading' : 'image-loaded']"
        loading="lazy"
        :src="getImage(props.locationImageHash)"
        alt="image"
        @load="imageLoadEnd"
        @error="imageLoadError"
      />
      <div class="pin" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gambits, localizeWithValues } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamChip from '@/views/components/GamChip.vue';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamGambitLocationType } from '@/views/composables/models/components/GamGambit';
import { computed, ref } from 'vue';
import GamHighlight from '../GamHighlight.vue';

const props = defineProps<GamGambitLocationType>();

const getImage = (hash: string) => {
  return gambits.imageService.getImageSrc(hash);
};

const isImageLoading = ref<boolean>(true);
const isImageLoaded = ref<boolean>(false);
const isImageError = ref<boolean>(false);

const imageLoadEnd = () => {
  isImageLoading.value = false;
  isImageLoaded.value = true;
};
const imageLoadError = () => {
  isImageLoading.value = false;
  isImageError.value = true;
};

const getDistanceLabel = (): string | undefined => {
  if (!props.distance) return 'missing';
  return localizeWithValues('gambit.card.location.distance.label', [props.distance.toString()]);
};

const getDistanceChip = computed((): GamChipType => {
  return {
    size: GamChipSize.SMALL,
    label: getDistanceLabel(),
    variant: GamChipVariant.DEFAULT,
    isOutline: false,
  };
});
</script>

<style lang="scss" scoped>
.highlight.is-mobile {
  display: none;
}

.gam-gambit-location {
  position: relative;
  flex: 1 0 0;
  overflow: hidden;
  align-self: stretch;
  height: 96px;
  border-radius: 10px;

  &.detail {
    height: 216px !important;

    border-radius: 18px;
  }

  &.is-mobile {
    &.list {
      height: 72px;
    }
  }

  &.shouldHover {
    .location-image {
      img.gam-location-image:hover {
        filter: #{'brightness(0.5) contrast(2.25);'};
        transition:
          opacity 16ms linear,
          filter 96ms linear;
      }
    }
  }

  .location-image {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-dark-700);

    img.image-loading {
      opacity: 0;
    }

    img.image-loaded {
      opacity: 1;
    }

    img.gam-location-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      mask-image: linear-gradient(rgb(0, 0, 0), transparent);
      filter: #{'brightness(0.5) contrast(2);'};
      transition:
        opacity 64ms linear,
        filter 200ms linear;
    }

    .pin {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: rgba(249, 88, 206, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: rgba(249, 88, 206, 1);
        position: absolute;
        border-radius: inherit;
      }
    }

    .gam-chip-container {
      position: absolute;
      right: var(--spacing-xs);
      bottom: var(--spacing-xs);

      .content {
        text-transform: initial;

        span {
          color: var(--color-white-70);
        }
      }
    }
  }
}
</style>
