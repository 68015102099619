<template>
  <input id="time" type="time" :value="formattedTime" @input="updateTime($event.target.value)" />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: new Date().toISOString(),
});

const emit = defineEmits(['update:modelValue']);

// Extract the time in HH:MM format
const formattedTime = computed(() => {
  if (!props.modelValue) return '';
  return props.modelValue.substring(11, 16); // Extract HH:MM from ISO string
});

const updateTime = (timeString) => {
  if (!props.modelValue) return;

  const datePart = props.modelValue.split('T')[0]; // Keep original date
  const updatedISO = `${datePart}T${timeString}:00.000Z`;

  emit('update:modelValue', updatedISO);
};
</script>
