<template>
  <div :class="['gam-list-view gam-simple-list-view', getClasses]">
    <div :class="['header', { isMobile }]"><slot name="header"></slot></div>
    <div :id="id" class="gam-list-container">
      <GamSimpleIntersect v-if="!isEmpty" v-bind="getIntersect" @infinite="loadMore()">
        <!-- <ScrollOverflow
          :class="['gam-intersect-wrapper', { 'scroll-spacing': true, dropdown: false }]"
          is-vertical
        > -->
        <!-- <div class="slot-content" :style="getIntersectStyle"> -->
        <span class="spacer" :style="getSpacerPadding"></span>
        <div class="list-content">
          <slot name="list"></slot>
        </div>
        <span class="spacer" :style="getFooterPadding"></span>
        <!-- </div> -->
        <!-- </ScrollOverflow> -->
      </GamSimpleIntersect>
      <div v-if="isEmpty" class="gam-no-data-wrapper">
        <GamErrorInfo v-if="noDataInfo" v-bind="noDataInfo"></GamErrorInfo>
      </div>
    </div>
  </div>
</template>
<script
  setup
  lang="ts"
  generic="T extends BaseUserDto | BaseGambitDto | BaseLocationDto | BaseClubDto | SimpleTableRow"
>
import type { BaseClubDto } from '@/core/data/club/club.interface';
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import type { BaseLocationDto } from '@/core/data/location/location.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import type { SimpleTableRow } from '@/views/composables/models/components/SimpleTable';
import { computed, type StyleValue } from 'vue';
import GamErrorInfo from '../GamErrorInfo.vue';
import GamSimpleIntersect from '../GamSimpleIntersect.vue';

interface GamIntersectType {
  showMore: boolean;
  noData: boolean;
  itemsGap?: number;
  widthSpacing?: number;
  scrollSpacing?: boolean;
  footerPadding?: number;
  spacerPadding?: number;
}

interface Props {
  id: string;
  isEmpty?: boolean;
  selectFirst?: boolean;
  spacerPadding?: number;
  footerPadding?: number;
  itemsGap?: number;
  scrollSpacing?: boolean;
  noDataInfo: any;
  loadMore: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  selectFirst: true,
  spacerPadding: isMobile.value ? 90 : 110,
  footerPadding: isMobile.value ? 78 : 42,
  showMore: true,
  noData: false,
  threshold: 0.5,
  rootMargin: '200px 0px 0px',
  widthSpacing: 20,
  itemsGap: 8,
  scrollSpacing: true,
});

const getIntersect = computed((): GamIntersectType => {
  return {
    scrollSpacing: props.scrollSpacing,
    widthSpacing: props.widthSpacing,
    showMore: props.showMore,
    noData: props.noData,
    spacerPadding: props.spacerPadding,
    footerPadding: props.footerPadding,
    itemsGap: props.itemsGap,
  };
});

const getSpacerPadding = computed((): StyleValue => {
  return {
    height: `${props.spacerPadding}px`,
  };
});

const getFooterPadding = computed((): StyleValue => {
  return {
    height: `${props.footerPadding}px`,
  };
});

const getClasses = computed(() => {
  return {
    'no-data': props.isEmpty,
    'is-mobile': isMobile.value,
  };
});
</script>

<style scoped lang="scss">
.header {
  padding-top: calc(var(--spacing-mobile-wrapper) + var(--spacing-l));
  padding-right: var(--spacing-xxl);
  padding-left: var(--spacing-xxl);
  padding-bottom: var(--spacing-menu);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(var(--color-dark-700) 66%, transparent 85%);
}

.header.isMobile {
  padding-top: calc(var(--spacing-l) + var(--spacing-s));
  padding-right: var(--spacing-mobile-wrapper);
  padding-left: var(--spacing-mobile-wrapper);
  padding-bottom: calc(var(--spacing-l) + var(--spacing-s) + var(--spacing-s));
}

.gam-list-view {
  width: 100%;
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  &.no-data {
    .spacer {
      display: none;
    }
  }

  &.is-mobile {
    .gam-list-container {
      .list-content {
        padding: 0 var(--spacing-l);
      }
    }
  }

  .gam-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    // flex: 1 0 auto;
    align-items: center;
    justify-content: center;

    .spacer {
      width: 100%;
      height: 0;
      transition: height var(--transition-fast);
      display: block;
    }

    .list-content {
      padding: 0 var(--spacing-xxl) 0 var(--spacing-xxl);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-md);
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(var(--spacing-desktop-bottom));
    background: linear-gradient(transparent, var(--color-dark-700));
    bottom: 0;
    left: 0;
    z-index: 410;
  }

  .gam-intersect-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;

    .slot-content {
      display: flex;
      width: calc(100%);
      flex-direction: column;
    }

    &.ps {
      &.dropdown {
        .intersect-loader-wrapper {
          &.show-more {
            padding-bottom: var(--spacing-l);
          }
        }
      }
    }

    .intersect-element {
      width: 100%;
      display: flex;
      justify-content: center;

      &.show-more {
        padding-bottom: var(--spacing-xxl);
      }
    }
  }
}
</style>
