<template>
  <div style="display: none" />
</template>

<script setup lang="ts">
import { gambits } from '@/core/gambits';
import { storeToRefs } from 'pinia';
import { onMounted, watch } from 'vue';
import { useGeolocationStore } from './stores/geolocation.store';

const geolocationStore = useGeolocationStore();
const { isLocationPermissionGranted, isLocationPermissionRequested } = storeToRefs(geolocationStore);

onMounted(async () => {
  if (isLocationPermissionGranted.value) {
    document.body.onclick = () => {
      gambits.geolocationService.startTracking();
      document.body.onclick = null;
    };
  }
});

watch([isLocationPermissionGranted, isLocationPermissionRequested], ([isGranted, isRequested]) => {
  if (!isGranted && !isRequested) {
    return;
  }

  gambits.geolocationService.startTracking();
});
</script>
