<template>
  <div :class="['intersect-loader-container', size, color]">
    <div :class="['intersect-loader', { spin: spinIcon }]">
      <gam-icon class="load-icon" v-bind="getIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamLoaderColor, GamLoaderSize } from '@/views/composables/constants/components/gamLoader.constants';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamLoaderType } from '@/views/composables/models/components/GamLoader';
import { computed } from 'vue';

const props = withDefaults(defineProps<GamLoaderType>(), {
  size: GamLoaderSize.SMALL,
  color: GamLoaderColor.WHITE,
  icon: GamIconName.LOADING,
  spinIcon: true,
});

const getIcon = computed((): GamIconType => {
  return {
    id: props.id,
    name: props.icon,
    size: props.size === GamLoaderSize.LARGE ? GamIconSize.X_LARGE : GamIconSize.NORMAL,
    isPrimary: props.color !== GamLoaderColor.GRADIENT,
  };
});
</script>

<style scoped lang="scss">
.intersect-loader-container {
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: content-box;
  border-radius: 50%;
  position: relative;

  &.small {
    width: 36px;
    height: 36px;
    padding: 5px;
  }

  &.medium {
    width: 48px;
    height: 48px;
    padding: 10px;
  }

  &.large {
    width: 72px;
    height: 72px;
    padding: 10px;
  }

  &.white {
    background: var(--color-white-10);

    .intersect-loader {
      background: var(--color-white-100);
    }
  }

  &.gradient {
    background: var(--color-linear-gradient-10);

    .intersect-loader {
      background: var(--color-linear-gradient);
    }
  }

  &.dark {
    background: var(--color-white-5);

    .intersect-loader {
      background: var(--color-dark-600);
    }
  }

  .intersect-loader {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
