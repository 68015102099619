<template>
  <div :class="['location-panel', 'detail-container', { 'is-mobile': isMobile }]">
    <div class="nav">
      <slot name="navigation"></slot>
    </div>

    <div v-if="location" class="location-info">
      <div ref="location" class="location-header">
        <location-detail-card :location="location" />
      </div>

      <div v-if="(location?.visitors || []).length > 0" class="visitors">
        <GamAttendeeRow v-for="(visitor, a) in getVisitors" :key="`visitor-${a}`" :player="visitor" />
      </div>

      <LocationClubs :location="location" />

      <LocationGambits :location="location" />

      <LocationImages :id="location.id" />

      <AboutLocation :key="location.id" :detail="location" />

      <!-- <CommentsLocation v-if="false" :id="location.id" :can-comment="location.canComment" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DetailLocationDto } from '@/core/data/location/location.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import GamAttendeeRow from '@/views/components/gam-player/GamAttendeeRow.vue';
import LocationDetailCard from '@/views/main/locations/details/LocationDetailCard.vue';
import AboutLocation from '@/views/main/locations/details/tabs/AboutLocation.vue';
import { computed } from 'vue';
import LocationClubs from './tabs/LocationClubs.vue';
import LocationGambits from './tabs/LocationGambits.vue';
import LocationImages from './tabs/LocationImages.vue';

interface Props {
  location?: DetailLocationDto;
}

const props = defineProps<Props>();

const getVisitors = computed(() => {
  if (!props.location || !Array.isArray(props.location?.visitors)) {
    return [];
  }

  return props.location.visitors;
});
</script>

<style scoped lang="scss">
.location-panel.is-mobile {
  width: 100svw;
}

.is-mobile {
  .check-in,
  .check-out {
    margin-right: 1rem;
  }
}

.location-panel:not(.is-mobile) {
  width: var(--side-panel-form-width);
}

.visitors {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--spacing-md);
}

.location-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  .nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--spacing-xl);
    padding-right: calc(var(--spacing-l) + var(--spacing-scroll));
    padding-bottom: var(--spacing-xl);
    padding-left: var(--spacing-l);
    position: sticky;
    top: 0px;
    background: linear-gradient(var(--color-dark-700) 75%, transparent);
    z-index: 3;
  }

  .location-info {
    width: 100%;
    padding-bottom: 10rem;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2rem;
  }

  .location-header {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-xl);
    background: #181718;
  }

  &.is-mobile {
    .nav {
      padding-top: calc(var(--spacing-l) + var(--spacing-s));
      padding-bottom: calc(var(--spacing-l) + var(--spacing-s));
      padding-left: var(--spacing-s);
      padding-right: var(--spacing-s);
    }

    .location-info {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }
  }

  &:not(.is-mobile) {
    .location-header {
      margin-left: calc((var(--spacing-xl) - var(--spacing-l)) * -1);
      margin-right: calc((var(--spacing-xl) - var(--spacing-l)) * -1);
    }
  }

  .location-images-tab {
    width: 100%;
  }

  .tabs-selection-row {
    align-self: stretch;
  }

  .tab-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    position: relative;
    flex: 1;
  }
}
</style>

<style lang="scss">
.is-mobile {
  .location-images-tab {
    padding-bottom: var(--spacing-xl);
    .gam-intersect.is-mobile {
      .gam-intersect-wrapper {
        .slot-content {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
