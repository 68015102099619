<template>
  <svg
    :class="{ 'is-silver': isSilver }"
    width="211"
    height="140"
    viewBox="0 0 211 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="shapeData"
      :fill="!isSilver ? 'url(#paint0_linear_22_10)' : 'url(#paint0_linear_22_11)'"
    />
    <defs>
      <linearGradient
        :id="!isSilver ? 'paint0_linear_22_10' : 'paint0_linear_22_11'"
        x1="0.148437"
        y1="0.151855"
        x2="49.3929"
        y2="92.9566"
        gradientUnits="userSpaceOnUse"
      >
        <stop v-if="!isSilver" stop-color="#F54CFF" stop-opacity="0.81" />
        <stop v-if="!isSilver" offset="0.205" stop-color="#FF6DA2" />
        <stop v-if="!isSilver" offset="0.68" stop-color="#F54CFF" stop-opacity="0" />
        <stop v-if="isSilver" stop-color="#7D7D7D" stop-opacity="0.81" />
        <stop v-if="isSilver" offset="0.205" stop-color="#CCCCCC" />
        <stop v-if="isSilver" offset="0.68" stop-color="#2A2A2A" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
interface GamHighlightProps {
  isSilver?: boolean;
}

defineProps<GamHighlightProps>();

const shapeData =
  'M17.1484 0.151855C7.7596 0.151855 0.148438 7.76301 0.148438 17.1519V133.721C0.148438 ' +
  '135.742 0.501171 137.681 1.14844 139.479V17.1519C1.14844 8.3153 8.31188 1.15186 17.1484 ' +
  '1.15186H210.906C209.107 0.504589 207.169 0.151855 205.147 0.151855H17.1484Z';
</script>

<style lang="scss" scoped>
svg {
  position: absolute;
  pointer-events: none;
  top: -1px;
  left: -1px;

  &.is-silver {
    opacity: 0.33;
  }
}
</style>
