<template>
  <div :class="['main-view clubs-view', { 'is-mobile': isMobile }]">
    <template v-if="authStore.isAuthenticated()">
      <EditClub v-if="clubDetails" :key="`edit-club-${clubDetails.id}`" :club="clubDetails" />

      <EditLocations
        v-if="clubDetails"
        :id="clubDetails.id"
        :key="`locations-club-${clubDetails.id}`"
        :club="clubDetails"
      />

      <EditCurators
        v-if="clubDetails"
        :id="clubDetails.id"
        :key="`curate-club-${clubDetails.id}`"
        :club="clubDetails"
      />
    </template>

    <SimpleDetailView v-bind="getDetailView">
      <template #header>
        <RouterLink v-if="isMobile && isNotUsernameRoute" to="/clubs" class="back-link">
          <GamIcon :name="GamIconName.ARROW_LEFT" />
        </RouterLink>
      </template>

      <ScrollOverflow v-if="clubDetails" class="detail-scroll" is-vertical>
        <div class="detail-container club">
          <GamClubDetail v-bind="getDetailProps" :key="clubDetails?.id" />
        </div>
      </ScrollOverflow>
    </SimpleDetailView>
  </div>
</template>

<script lang="ts" setup>
import type { DetailClubDto } from '@/core/data/club/club.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useClubStore } from '@/stores/club.store';
import SimpleDetailView from '@/views/components/detail-view/SimpleDetailView.vue';
import GamClubDetail from '@/views/components/gam-clubs/GamClubDetail.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { GamClubCardCategory, GamClubCardSize } from '@/views/composables/constants/components/gamClub.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamClubDetailViewType } from '@/views/composables/models/components/GamClub';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EditClub from './side-panels/EditClub.vue';
import EditCurators from './side-panels/EditCurators.vue';
import EditLocations from './side-panels/EditLocations.vue';

const listId = GamListId.CLUBS;

const clubStore = useClubStore();
const { clubs, club } = storeToRefs(clubStore);

const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();

const isNotUsernameRoute = computed(() => route.name !== RouterNameType.USERNAME);

const clubDetails = ref<DetailClubDto | null>(null);

const getClubDetail = async (username: string): Promise<DetailClubDto | null> => {
  const clubsResult = await clubStore.fetchOne({ username });

  if (clubsResult?.data) {
    return clubsResult.data;
  }

  return null;
};

const getDetailView = computed((): GamDetailType => {
  return { listId, isOpen: true, showListCursors: !!clubDetails.value };
});

const getDetailProps = computed((): GamClubDetailViewType => {
  const { username } = route.params;

  return {
    club: username && club.value?.username === username ? club.value : null,
    size: GamClubCardSize.LARGE,
    category: GamClubCardCategory.DETAIL,
  };
});

onBeforeMount(async () => {
  clubStore.setCursor(null, null);
  clubDetails.value = await getClubDetail(String(route.params.username));
});

watch(
  () => ({ username: route.params.username, clubs: clubs.value }),
  async ({ username, clubs }) => {
    if (!username && clubs.length === 0) return;

    if (!username) {
      username = clubs[0].username;
      router.replace({ name: RouterNameType.CLUBS, params: { username } });
    }
  },
);
</script>

<style scoped lang="scss">
.main-view {
  .detail-header {
    padding-top: var(--spacing-xl);

    .back-link {
      display: flex;
      text-decoration: none;
      color: var(--color-white);
    }
  }

  .detail-scroll {
    width: 100%;
    height: 100svh;

    .detail-container {
      max-width: 596px;
      margin: 0 auto var(--spacing-xxl);

      @media (min-width: 640px) {
        margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto 0;
      }
    }
  }
}

.frame-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 12px;
}
</style>
