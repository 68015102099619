export enum GamCollapseDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum GamCollapseState {
  OPENED = 'opened',
  CLOSED = 'closed',
}

export enum GamSidePanelId {
  PLAYER_FILTER = 'player-filter',
  GAMBIT_FILTER = 'gambit-filter',
  LOCATIONS = 'location-view',
  LOCATION_FORM = 'location-form',
  LOCATION_DETAIL = 'location-detail',
  GAMBITS = 'gambits-view',
  GAMBIT_FORM = 'gambit-form',
  CLUBS = 'clubs-view',
  CLUB_FORM = 'club-form',
  CLUB_CURATORS = 'club-curators',
  CLUB_LOCATIONS = 'club-locations',
  CREATE_CLUB = 'create-club',
  PROFILE_GENERAL = 'profile-general',
  PROFILE_RATINGS = 'profile-ratings',
  ADMIN_EDIT = 'admin-edit',
  ADMIN_LOCATION_FILTER = 'admin-location-filter',
  ADMIN_USER_FILTER = 'admin-user-filter',
}

export enum GamSidePanelOption {
  LIST = 'list',
  ACTION = 'action',
  ADD = 'add',
  EDIT = 'edit',
  DETAIL = 'detail',
}
