<template>
  <gam-side-panel v-bind="ratingsSidePanel">
    <gam-ratings v-if="isOpened" class="ratings-profile" />
  </gam-side-panel>
</template>

<script setup lang="ts">
import { gambits } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import { useRatingStore } from '@/stores/rating.store';
import GamRatings from '@/views/components/gam-ratings/GamRatings.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import { getProfileSidePanel } from '@/views/composables/constants/main/profile.constants';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const emits = defineEmits([GamComponentsEmits.UPDATE]);

const panelId = GamSidePanelId.PROFILE_RATINGS;
const authStore = useAuthStore();
const { userDetails } = storeToRefs(authStore);
const ratingStore = useRatingStore();
const { ratings } = storeToRefs(ratingStore);
const isLoading = ref<boolean>(false);

const router = useRouter();
const route = useRoute();

const isOpened = computed(() => {
  return route.query.side_panel === 'ratings';
});

const ratingsSidePanel = computed(() => {
  return {
    isOpened: isOpened.value,
    ...getProfileSidePanel({
      title: 'Your ratings',
      panelId,
      onSubmit: saveRatings,
      onCancel: cancelRatings,
      isActionDisabled: !ratingStore.isRatingAdded(),
      isLoading: isLoading.value,
    }),
  };
});

const saveRatings = async () => {
  if (ratingStore.isRatingAdded() && userDetails.value) {
    isLoading.value = true;
    const result = await gambits.ratingService.updateRatings({
      username: userDetails.value.username,
      firstName: userDetails.value.firstName || '',
      lastName: userDetails.value.lastName || '',
      birthDate: userDetails.value.birthDate,
      ...ratings.value,
    });

    if (result.data) {
      emits(GamComponentsEmits.UPDATE);
      router.push({
        ...route,
        query: {
          ...route.query,
          side_panel: undefined,
        },
      });
    }
    isLoading.value = false;
  }
};

const cancelRatings = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};
</script>

<style lang="scss">
#profile-ratings {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}
</style>
