export enum LocationType {
  MY_LOCATION = 'current',
  HOME = 'home',
  CHESS_TABLE = 'chess_table',
  GIANT_CHESS = 'giant_chess',
  CAFFE_CHESS = 'cafe_chess',
  CHESS_FRIENDLY = 'chess_friendly',
  CLUB = 'club',
  GAMBIT = 'gambit',
  VENUE = 'venue',
  FAVORITE = 'favorite',
}

export enum MapLocationType {
  LOCATION = 'location',
  GAMBIT = 'gambit',
  CLUB = 'club',
  CLUSTER = 'cluster',
}

export enum MapLocationSource {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  GOOGLE = 'google_maps',
}
