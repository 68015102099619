<template>
  <RouterLink ref="clubCard" :to="getLocationLinkParams" class="gam-club-card" :class="getClass">
    <GamDisplayImage v-if="club.profileImageBlobHash" :hash="club.profileImageBlobHash" :size="GamImageSize.CARD" />

    <div v-else class="no-image">
      <InlineSvg :src="getPlaceholderURL()"></InlineSvg>
    </div>

    <div class="club-names">
      <div class="club-username">@{{ club.username }}</div>

      <div class="club-name">
        {{ club.name }}
      </div>

      <span v-if="club.clubType === ClubType.PROFESSIONAL" class="club-type-pro">PRO</span>
    </div>

    <div v-if="club.canEdit" class="gam-owner">
      <GamGambitOwner is-curator :is-list="isList()" />
    </div>

    <div v-if="!club.canEdit && isClubFollower" class="gam-owner">
      <GamGambitOwner is-follower :is-list="isList()" />
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { ClubType } from '@/core/data/club/club.type';
import { useAuthStore } from '@/stores/auth.store';
import { GamClubCardCategory } from '@/views/composables/constants/components/gamClub.constants';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import type { GamClubCardType } from '@/views/composables/models/components/GamClub';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';
import GamGambitOwner from '../gam-gambits/GamGambitOwner.vue';
import GamDisplayImage from '../GamDisplayImage.vue';

const props = defineProps<GamClubCardType>();

const clubCard = ref<HTMLDivElement>();

const isList = (): boolean => {
  return props.category === GamClubCardCategory.LIST;
};

const isDetail = (): boolean => {
  return props.category === GamClubCardCategory.DETAIL;
};

const getClass = computed(() => {
  return {
    selected: props.selected,
    'is-list': isList(),
    'is-detail': isDetail(),
    'is-mobile': props.isMobile,
  };
});

const getLocationLinkParams = computed(() => {
  return { path: `/@${props.club.username}` };
});

watch(props, ({ selected }) => {
  if (!selected) return;

  // @ts-ignore
  clubCard?.value?.$el?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
});

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const isClubFollower = computed(() => {
  if (!user.value) return false;
  if (!props.club) return false;
  if (!Array.isArray(props.club.followers)) return false;

  return props.club.followers.findIndex((c) => c.userId === user.value?.id) > -1;
});

const getPlaceholderURL = () => new URL('../../../ui/icons/image-placeholder.svg', import.meta.url).href;
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-club-card {
  display: flex;
  width: 100%;
  min-width: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  gap: var(--spacing-l);
  gap: 16px;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  .club-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  & {
    border-radius: var(--radius-small);
    background-color: var(--color-dark-600);
    transition: box-shadow var(--transition);
    height: 80px;
    cursor: pointer;

    .no-image {
      width: 96px;
      height: 80px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-dark-30);

      svg {
        width: 25%;
        height: 25%;
      }
    }

    .club-names {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 16px;
      font-weight: bold;
      gap: var(--spacing-md);
    }

    .club-username {
      color: var(--color-white-100);
    }

    .club-name {
      color: var(--color-white-30);
      font-weight: 300;
    }

    .club-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      word-break: break-word;
      hyphens: auto;
      overflow: hidden;
      line-height: 1.25;
    }

    .club-username {
      letter-spacing: 0.5px;
      font-weight: 500;
    }

    .club-type-pro {
      // Hide for now
      display: none;
      opacity: 0.5;
      font-weight: normal;
    }

    &:before {
      background: var(--color-white-30);
      padding: 1px;
      opacity: 0;
      z-index: 110;
    }

    &:hover {
      background-color: var(--color-dark-500);
      box-shadow: var(--shadow-card-hover);

      .club-base-info {
        .gambit-tags-wrapper {
          .gam-club-tags {
            .gambit-tag-container {
              &:before {
                background: linear-gradient(
                  to left,
                  rgba(0, 0, 0, 0) 0,
                  var(--color-dark-500) 50%,
                  var(--color-dark-500)
                );
              }

              &:after {
                background: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0) 0,
                  var(--color-dark-500) 50%,
                  var(--color-dark-500)
                );
              }
            }
          }
        }
      }
    }

    &.selected {
      background-color: var(--color-dark-500) !important;

      &:before {
        opacity: 1;
      }
    }

    &:hover,
    &.selected {
      .gambit-tag-container {
        &:before {
          background: linear-gradient(
            to left,
            rgba(0, 0, 0, 0) 0,
            var(--color-dark-500) 50%,
            var(--color-dark-500)
          ) !important;
        }

        &:after {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0,
            var(--color-dark-500) 50%,
            var(--color-dark-500)
          ) !important;
        }
      }
    }

    .club-base-info {
      flex-direction: column;

      .gambit-time-location {
        align-items: flex-end;
      }
    }
  }

  &.is-mobile {
    .gambit-address-info {
      width: 100%;
    }
  }

  .club-base-info {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: var(--spacing-l);
    min-width: 0;

    .gambit-time-location {
      position: relative;
      display: flex;
      align-self: stretch;
      align-items: flex-start;
      min-width: 0;

      .gam-owner {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
      }
    }

    .gambit-tags-wrapper {
      width: inherit;
      min-width: 0;
    }
  }
}
</style>
