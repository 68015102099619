<template>
  <div class="auth-registration">
    <div v-if="isAuthLoading" class="auth-loading">
      <GamLoader />
    </div>

    <form class="auth-content" @submit="handleSubmit">
      <div class="auth-context left">
        <span class="auth-title">{{ localize('auth.page.registration.title') }}</span>
        <span class="auth-subtitle">{{ localize('auth.page.registration.subtitle') }}</span>
      </div>

      <div class="auth-content-wrapper">
        <div v-if="gamForm" class="auth-form">
          <div class="form-row">
            <gam-input v-bind="firstName" v-model="gamForm.form.firstName" />
            <gam-input v-bind="lastName" v-model="gamForm.form.lastName" />
          </div>

          <div class="form-row username-row">
            <div class="input-with-support">
              <gam-input v-bind="username" v-model="gamForm.form.username" @update="checkUsername" />
              <gam-support-text v-if="!usernameField.error" label="Letters, numbers, underscores, or dots." />
            </div>

            <gam-input v-bind="emoji" v-model="gamForm.form.emoji" class="emoji" />
          </div>

          <div class="form-row">
            <gam-input-date v-model="gamForm.form.date" />
          </div>
        </div>
      </div>

      <input type="submit" class="hidden" />
    </form>

    <div class="auth-methods-group">
      <div class="methods-row">
        <gam-button v-bind="getContinueButton" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterAuthType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserInfo } from '@/core/data/user/user.interface';
import { gambits, localize } from '@/core/gambits';
import FormHelper from '@/core/helpers/form.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useFormStore } from '@/stores/form.store';
import GamButton from '@/views/components/GamButton.vue';
import GamInput from '@/views/components/GamInput.vue';
import GamInputDate from '@/views/components/GamInputDate.vue';
import GamLoader from '@/views/components/GamLoader.vue';
import GamSupportText from '@/views/components/GamSupportText.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { BaseUserForm, BaseUserValidator } from '@/views/composables/models/form.interface';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import vibes from './vibes.json';

const formId = FormId.AUTH_REGISTRATION;
const isAuthLoading = ref<boolean>(true);
const isLoading = ref<boolean>(false);

const emojiValidator = FormHelper.emojiValidator;
const usernameValidator = FormHelper.usernameValidator;
const formStore = useFormStore<BaseUserForm, BaseUserValidator>(formId)();
const { gamForm, usernameField } = storeToRefs(formStore);
const router = useRouter();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const authUser = ref<BaseUserInfo>();

const genUserFromEmail = computed(() => user.value?.email.split('@')[0].toLocaleLowerCase());

const genFirstName = computed(() => {
  if (!genUserFromEmail.value) return;

  let firstName = genUserFromEmail.value.split('.')[0];

  if (!firstName) return;

  firstName = firstName.replace(/[^a-z]/g, '');

  return firstName;
});

const genLastName = computed(() => {
  if (!genUserFromEmail.value) return;

  let lastName = genUserFromEmail.value.split('.')[1];

  if (!lastName) return;

  lastName = lastName.replace(/[^a-z]/g, '');

  return lastName;
});

const genUsername = computed(() => {
  if (!genUserFromEmail.value) return;

  return (
    genUserFromEmail.value
      // eslint-disable-next-line no-useless-escape
      .replace(/[^a-z0-9_\.]/g, '')
  );
});

const getForm = reactive({
  firstName: '',
  lastName: '',
  username: '',
  emoji: vibes[Math.floor(Math.random() * vibes.length)],
  date: undefined,
});

const dateValidator = (date: Date): boolean => {
  return FormHelper.isValidDate(date, gambits.configService.getAuthMinAge());
};

const getRules = {
  firstName: { required },
  lastName: { required },
  username: { required, usernameValidator },
  emoji: { emojiValidator },
  date: { required, dateValidator },
};

onBeforeMount(async () => {
  formStore.setForm({ form: getForm, rules: getRules });
  await generateDefaults();
  await getAuthUser();
  FormHelper.checkUsername(formId, gamForm.value?.form.username || '', v$);
  isAuthLoading.value = false;
});

onMounted(() => {
  setTimeout(() => {
    FormHelper.checkUsername(formId, gamForm.value?.form.username || '', v$);
  }, 3000);
});

const v$ = useVuelidate(getRules, getForm);

function capitalize(word: string): string {
  if (word.length === 0) return '';

  return word[0].toUpperCase() + word.substring(1);
}

const generateDefaults = async () => {
  if (!gamForm.value?.form) return;

  gamForm.value.form.firstName = capitalize(genFirstName.value || '');
  gamForm.value.form.lastName = capitalize(genLastName.value || '');
  gamForm.value.form.username = genUsername.value || '';
};

const isInvalid = computed((): boolean => {
  if (!gamForm.value) return true;
  return v$.value.$invalid || !usernameField.value.isValidated;
});

const checkUsername = debounce(() => {
  nextTick(() => {
    FormHelper.checkUsername(formId, gamForm.value?.form.username || '', v$);
  });
}, 400);

const getAuthUser = async (): Promise<void> => {
  const result = await gambits.userService.getUserInfo(true);
  isAuthLoading.value = false;

  if (result.data) {
    authUser.value = result.data;
    if (gamForm.value?.form) {
      gamForm.value.form.firstName = authUser.value?.firstName || gamForm.value.form.firstName || '';
      gamForm.value.form.lastName = authUser.value?.lastName || gamForm.value.form.lastName || '';
      gamForm.value.form.username = authUser.value?.username || gamForm.value.form.username || '';
      gamForm.value.form.date = authUser.value?.birthDate ? new Date(authUser.value?.birthDate) : undefined;
    }
  }
};

const firstName = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_NAME,
    value: gamForm.value?.form.firstName,
    placeholder: 'auth.page.registration.form.firstName.placeholder',
  };
});

const lastName = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_SURNAME,
    value: gamForm.value?.form.lastName,
    placeholder: 'auth.page.registration.form.lastName.placeholder',
  };
});

const username = computed((): GamInputType => {
  return {
    name: GamInputName.GAM_USERNAME,
    value: gamForm.value?.form.username || '',
    placeholder: 'auth.page.registration.form.username.placeholder',
    isLoading: usernameField.value.isLoading,
    isError: usernameField.value.isValidated === false,
    message: usernameField.value.error,
    rightIcon: usernameField.value.isValidated ? GamIconName.CHECK : undefined,
    maxLength: 42,
    isPrimary: true,
    isUsername: true,
  };
});

const emoji = computed((): GamInputType => {
  return {
    name: GamInputName.EMOJI,
    value: gamForm.value?.form.emoji,
    placeholder: 'auth.page.registration.form.emoji.placeholder',
    isEmoji: true,
    maxLength: 1,
  };
});

async function handleSubmit(event: Event) {
  event?.preventDefault();
  if (isInvalid.value) return;

  if (!gamForm.value?.form || !user.value?.email) return;

  isLoading.value = true;

  await gambits.authService.submitPersonalInfo({ ...gamForm.value.form });
  await router.push({ name: RouterAuthType.AUTH_RATINGS });

  isLoading.value = false;
}

const getContinueButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    label: 'auth.page.registration.continue.button.label',
    isFullSize: true,
    center: true,
    isDisabled: isInvalid.value,
    isLoading: isLoading.value,
    onClick: handleSubmit,
  };
});
</script>

<style scoped lang="scss">
.auth-registration {
  .auth-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-dark-30);
    z-index: 600;
  }

  .auth-content {
    gap: var(--spacing-menu);

    .auth-content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: var(--spacing-menu) !important;

      .auth-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl);
        align-self: stretch;

        .form-row {
          width: 100%;
          display: flex;
          gap: var(--spacing-md);

          .emoji {
            width: 80px;
          }
        }

        &.images {
          flex-direction: row;

          @media (min-width: 640px) {
            margin-bottom: var(--spacing-menu);
          }

          .image {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-md);

            .label {
              font-size: 14px;
              font-weight: 400;
              color: var(--color-white-70);
              line-height: 110%;
            }
          }
        }
      }
    }
  }

  .input-with-support {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    width: 100%;

    .gam-support-text {
      position: absolute;
      top: 3.75rem;
      padding-left: 1.5rem;
      // max-width: 12rem;
      width: 20rem;
    }
  }

  .input-with-support {
    padding-bottom: 1rem;
  }

  .auth-methods-group {
    margin-top: var(--spacing-xl);
  }
}
</style>
