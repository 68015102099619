<template>
  <router-link
    ref="cardElement"
    :to="{
      ...route,
      query: {
        ...route.query,
        side_panel: 'location_detail',
        location: location.id,
      },
    }"
    :class="['gam-location-card', getClass]"
    @click="emit(GamComponentsEmits.UPDATE)"
  >
    <GamHighlight :is-silver="true" class="highlight" />

    <div class="location-base-info">
      <div class="location-title">
        <span class="location-icon">
          <gam-icon :name="getTypeIcon" />
        </span>
        <span class="location-name">{{ location.name || '' }}</span>
        <span
          v-if="location.gambitIds && location.gambitIds.data?.length > 0"
          :class="[
            'gambit-on',
            { single: location.gambitIds.data?.length === 1, 'double-digit': location.gambitIds.data?.length > 9 },
          ]"
        >
          {{ location.gambitIds.data?.length }}
        </span>
      </div>

      <div class="location-address">
        <span><gam-icon :name="GamIconName.LOCATION_EMPTY" /></span>
        <span class="address">
          {{ location.address || 'Some address 123, 1000 Ljubljana, Slovenia' }}
        </span>
      </div>

      <div class="location-tags-wrapper">
        <gam-scroll-items v-bind="getGamScrollItemType">
          <gam-chip v-if="props.location?.ratingCount > 0" v-bind="getRatingTag" />
          <gam-chip v-for="type in location.locationTypes" :key="type" v-bind="getTypeTag(type)" />
          <div class="distance" v-html="getDistanceLabel()" />
        </gam-scroll-items>
      </div>
    </div>

    <div class="location-image-wrapper">
      <gam-image v-bind="getImage" />
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { ObjectType } from '@/core/data/localization/localization.type';
import { LocationType } from '@/core/data/location/location.type';
import { localizeType, localizeWithValues } from '@/core/gambits';
import LocationHelper from '@/core/helpers/location.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import GamChip from '@/views/components/GamChip.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamImage from '@/views/components/GamImage.vue';
import GamScrollItems from '@/views/components/GamScrollItems.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamImageType } from '@/views/composables/models/components/GamImage';
import type { GamLocationCardType } from '@/views/composables/models/components/GamLocation';
import type { GamScrollItemsType } from '@/views/composables/models/components/GamScrollItems';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import GamHighlight from '../GamHighlight.vue';

const props = defineProps<GamLocationCardType>();
const emit = defineEmits([GamComponentsEmits.UPDATE]);
const cardElement = ref<HTMLDivElement>();

const route = useRoute();

const getDistanceLabel = (): string | undefined => {
  const distance = props.location?.distance ? props.location.distance : 0;

  let values = [];
  if (distance === 0) {
    return 'Here';
  } else if (distance < 1000) {
    values = [distance, 'm'];
  } else if (distance < 10000) {
    values = [(distance / 1000).toPrecision(2), 'km'];
  } else {
    values = [Math.round(distance / 1000), 'km'];
  }

  return localizeWithValues('location.card.distance.label', values as string[]);
};

const getTypeTag = (type: LocationType): GamChipType => {
  const typeLabel = localizeType(ObjectType.LOCATION, type);
  return LocationHelper.getBaseLocationTag(typeLabel, props.isMobile);
};

const getRatingTag = computed((): GamChipType => {
  const ratingLabel = `${props.location?.rating || 0} <span>(${props.location?.ratingCount})</span>`;
  return LocationHelper.getBaseLocationTag(ratingLabel, props.isMobile, GamIconName.STAR);
});

const getImage = computed((): GamImageType => {
  return {
    size: GamImageSize.SMALL,
    image: props.location.image?.imageBlobHash || undefined,
    noExpand: true,
    noFilter: true,
  };
});

const getClass = computed(() => {
  return {
    top: props.location.topLocation,
    selected: props.selected,
    'is-mobile': isMobile.value,
  };
});

const getTypeIcon = computed((): GamIconName => {
  return LocationHelper.getLocationTypeIcon(
    props.location.locationTypes,
    props.location.private,
    props.location.favorite,
  );
});

const getGamScrollItemType = computed((): GamScrollItemsType => {
  return {
    parent: cardElement.value,
    selected: props.selected,
    hideRail: true,
  };
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-location-card {
  display: flex;
  width: 100%;
  min-width: 0;
  align-items: flex-start;
  gap: var(--spacing-l);
  padding: var(--spacing-l);
  border-radius: var(--radius-small);
  background-color: var(--color-dark-600);
  cursor: pointer;
  text-decoration: none;

  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-white-30);
    padding: 1px;
    opacity: 0;
    z-index: 110;
  }

  &:hover {
    box-shadow: var(--shadow-card-hover);

    .gam-scroll-items:before {
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 0, var(--color-dark-500) 50%, var(--color-dark-500));
    }

    .gam-scroll-items:after {
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--color-dark-500) 50%, var(--color-dark-500));
    }
  }

  &.selected {
    &:before {
      opacity: 1;
    }
  }

  &:hover,
  &.selected {
    background-color: var(--color-dark-500) !important;

    .location-base-info {
      .location-tags-wrapper {
        &:after {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--color-dark-500) 50%, var(--color-dark-500));
        }
      }
    }
  }

  &.top {
    background: var(--color-linear-gradient-vertical);
    background-color: var(--color-dark-600);
  }

  .location-base-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    position: relative;
    min-width: 0;
    flex: 1 0 0;

    .location-title {
      display: flex;
      padding-right: var(--spacing-xl);
      align-items: center;
      justify-content: flex-start;
      gap: var(--spacing-md);
      align-self: stretch;
      position: relative;
      color: var(--color-white-70);

      .location-name {
        font-size: 16px;
        font-weight: 700;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .gambit-on {
        min-width: 16px;
        height: 16px;
        padding: 0 4px;
        border-radius: 16px;
        background: var(--color-linear-gradient);
        color: var(--color-dark-500);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: -1px;
      }

      .gambit-on.single {
        text-indent: -2px;
      }

      .gambit-on.double-digit {
        text-indent: 0 !important;
      }
    }

    .location-address {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
      min-width: 0;

      .address {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 300;

        color: var(--color-white-30);
      }

      .address.active {
        background: var(--color-linear-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .location-tags-wrapper {
      span {
        color: var(--color-white-30);
      }

      .distance {
        font-size: 14px;
        color: var(--color-white-70);
        font-weight: 400;
        white-space: nowrap;
        padding-left: 0.25rem;

        * {
          color: inherit;
        }
      }
    }
  }

  .location-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}
</style>
