import { ObjectType } from '@/core/data/localization/localization.type';
import { RatingType } from '@/core/data/rating/rating.type';
import { PlayerStatus, PlayerTitle, PlayerType } from '@/core/data/user/user.type';
import { localizeType } from '@/core/gambits';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';

export default class PlayerHelper {
  static getPlayerTitleLocale(title: PlayerTitle, fullName: boolean): string {
    const playerTitle = fullName ? `${title.toLowerCase()}.full` : title.toLowerCase();
    return localizeType(ObjectType.PLAYER_TITLE, playerTitle);
  }

  static getPlayerTypeLocale(type: PlayerType): string {
    return localizeType(ObjectType.PLAYER, type);
  }

  static getPlayerStatusLocale(status?: PlayerStatus): string {
    return `player.card.status.${status || PlayerStatus.OFFLINE}`;
  }

  static getPlayerRatingIcon(type: RatingType): GamIconName {
    switch (type) {
      case RatingType.CHESSCOM:
        return GamIconName.CHESS_COM;
      case RatingType.FIDE:
        return GamIconName.FIDE;
      case RatingType.LICHESS:
        return GamIconName.LICHESS;
      default:
        return GamIconName.G_CROWN;
    }
  }
}
