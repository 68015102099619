<template>
  <div :class="['gam-display-image', getClasses, size, format]">
    <div class="image-container">
      <div class="image">
        <img
          v-if="hash"
          class="gam-display-image"
          loading="lazy"
          :src="getImage(hash)"
          alt="image"
          @load="loaded"
          @loadstart="loadingImage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { JwtRepository } from '@/core/data/auth/jwt.repository';
import { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import { UIConfigRepository } from '@/core/data/config/uiConfig.repository';
import { ImageRepository } from '@/core/data/image/image.repository';
import { StorageType } from '@/core/data/storage/storage.interface';
import { StorageRepository } from '@/core/data/storage/storage.repository';
import { JwtService } from '@/core/domain/auth/jwt.service';
import { ConfigService } from '@/core/domain/config.service';
import { ImageService } from '@/core/domain/image.service';
import { ImageApi } from '@/core/network/api/image.api';
import { HttpClient } from '@/core/network/http/httpClient';
import { GamImageFormat, GamImageSize } from '@/views/composables/constants/components/gamImage.constants';
import type { GamDisplayImage } from '@/views/composables/models/components/GamImage';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<GamDisplayImage>(), {
  size: GamImageSize.SMALL,
  format: GamImageFormat.VERTICAL,
  rounded: false,
});

const isLoading = ref<boolean>(false);

const clientConfigRepository = new ClientConfigRepository();
const spices = clientConfigRepository.parseServiceSpices();
const localStorage = new StorageRepository(window.localStorage, StorageType.Local, spices);
const jwtRepository = new JwtRepository(localStorage);
const uiConfigRepository = new UIConfigRepository(localStorage);
const configService = new ConfigService(clientConfigRepository, uiConfigRepository);
const jwtService = new JwtService(jwtRepository, configService.getServiceRoutingHost());
const httpClient = new HttpClient(jwtService, configService.getServiceRoutingHost());
const imageApi = new ImageApi(httpClient);
const imageRepository = new ImageRepository(imageApi);
const imageService = new ImageService(imageRepository, configService);

const getImage = (hash: string) => {
  return imageService.getImageSrc(hash);
};

const getClasses = computed(() => {
  return {
    missing: !props.hash,
    loading: isLoading.value,
    rounded: props.rounded,
  };
});

const loadingImage = () => {
  isLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    isLoading.value = false;
  });
};
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-display-image {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  &.rounded {
    border-radius: var(--radius-x-small) !important;
  }

  &.missing {
    background: var(--color-dark-700);
  }

  &.mini {
    width: 32px;
    height: 32px;
  }

  &.small {
    width: 60px;
    height: 75px;
  }

  &.card {
    width: 96px;
    height: 80px;
  }

  &.medium {
    width: 100px;
    height: 125px;
  }

  &.large {
    width: 200px;
    height: 250px;
  }

  .image-container {
    width: inherit;
    height: inherit;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    .image {
      width: inherit;
      height: inherit;

      &.skeleton {
        border-radius: 0 !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
      }

      &.no-filter {
        img {
          filter: none;
        }
      }
    }
  }
}
</style>
