<template>
  <gam-side-panel v-bind="addClubSidePanel">
    <div class="add-club" :class="{ 'is-mobile': isMobile }">
      <ClubFormComponent ref="clubForm" :form-id="formId" />
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import { ClubType } from '@/core/data/club/club.type';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useClubStore } from '@/stores/club.store';
import { useFormStore } from '@/stores/form.store';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { ClubForm, ClubValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ClubFormComponent from '../forms/ClubForm.vue';

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const formId = FormId.ADD_CLUB;
const panelId = GamSidePanelId.CLUB_FORM;

const formStore = useFormStore<ClubForm, ClubValidator>(formId)();
const { gamForm } = storeToRefs(formStore);

const clubStore = useClubStore();

const clubForm = ref<InstanceType<typeof ClubFormComponent>>();
const isLoading = ref<boolean>(false);
const isInvalid = ref<boolean>(true);
const errorMessage = ref<GamButtonMessageType | null>(null);

watch(
  gamForm,
  () => {
    if (!clubForm.value) {
      return;
    }
    isInvalid.value = clubForm.value.isInvalid;
  },
  { deep: true },
);

const closeAddClub = (newUsername?: string) => {
  router.push({
    name: RouterNameType.CLUBS,
    params: { username: newUsername },
    query: { ...route.query, side_panel: 'edit_club_locations' },
  });
};

const router = useRouter();
const route = useRoute();

const submitButton = {
  variant: GamButtonVariant.SECONDARY,
  size: GamButtonSize.LARGE,
  label: 'clubs.add.form.create.button',
  isFullSize: true,
  center: true,
};

const cancelButton = {
  variant: GamButtonVariant.TERTIARY,
  size: GamButtonSize.LARGE,
  label: 'clubs.add.form.clear.button',
  isFullSize: true,
  center: true,
};

const clearAddMode = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.name = '';
  form.username = '';
  form.clubType = ClubType.CASUAL;
  form.description = undefined;
  form.photos = [];
  form.profileImage = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'add_club' && newPanel.side_panel === 'add_club') {
    clearForm();
  }
});

const handleSubmit = async () => {
  if (!gamForm.value?.form) {
    return;
  }

  errorMessage.value = null;
  isLoading.value = true;

  const result = await gambits.clubService.addNewClub(gamForm.value.form);

  if (result.data) {
    clubStore.addClub({ ...result.data, canEdit: true });
    emits(GamComponentsEmits.SUCCESS);
    closeAddClub(result.data.username);
    clearForm();
  } else if (result.error) {
    errorMessage.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }

  isLoading.value = false;
};

const addClubSidePanel = computed(() => ({
  id: panelId,
  isOpened: route.query.side_panel === 'add_club',
  isComplementary: false,
  isModal: false,
  direction: GamCollapseDirection.RIGHT,
  option: GamSidePanelOption.ADD,
  header: {
    title: 'clubs.add.title.label',
    icon: GamIconName.MUTUAL_EMPTY,
  },
  bodyClass: 'add-club',
  footer: {
    action: {
      onClick: handleSubmit,
      message: errorMessage.value,
      isDisabled: isInvalid.value,
      isLoading: isLoading.value,
      ...submitButton,
    },
    clear: {
      onClick: clearAddMode,
      isDisabled: isInvalid.value,
      isLoading: isLoading.value,
      ...cancelButton,
    },
  },
}));
</script>

<style lang="scss">
@use '@/ui/css/partial';
#club-form {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100%;
  }
}

.add-club {
  position: relative;

  &.is-mobile {
    width: 100% !important;
    border: 0;
  }
}
</style>
