<template>
  <div class="gam-club-detail" :class="getClass">
    <div v-if="club" class="club-base-info">
      <div class="gam-club-header">
        <GamHighlight :class="['highlight', { 'is-mobile': isMobile }]" />

        <div class="more-wrapper">
          <GamMore v-bind="getMoreItems" />
        </div>

        <div v-if="club.clubType === ClubType.PROFESSIONAL" class="club-type">
          <GamChip :is-upper-case="true">
            {{ localize('clubs.general.clubType.professional') }}
          </GamChip>
        </div>

        <div v-if="club.clubType === ClubType.CASUAL" class="club-type">
          <GamChip :is-upper-case="true">
            {{ localize('clubs.general.clubType.casual') }}
          </GamChip>
        </div>

        <img
          v-if="club.profileImageBlobHash"
          class="gam-club-header-image"
          loading="lazy"
          :src="imageService.getImageSrc(club.profileImageBlobHash)"
          alt="image"
          @load="loaded"
          @loadstart="loadingImage"
        />

        <InlineSvg v-else class="gam-club-header-image placeholder" :src="getPlaceholderURL()"></InlineSvg>

        <div class="gam-club-header-cover"></div>

        <div class="gam-club-header-content">
          <div class="names">
            <p>@{{ club.username }}</p>
            <h1>
              <RouterLink :to="{ name: RouterNameType.USERNAME, params: { username: club.username } }">
                {{ club.name }}
              </RouterLink>
            </h1>
          </div>

          <div v-if="!isMobile" class="actions">
            <GamButton
              v-if="!isFollowed"
              :size="GamButtonSize.X_SMALL"
              :variant="GamButtonVariant.PRIMARY"
              :left-icon="GamIconName.HEART_EMPTY"
              @click="() => followClub(club?.id)"
            >
              {{ localize('Follow') }}
            </GamButton>

            <GamButton
              v-if="isFollowed"
              :size="GamButtonSize.X_SMALL"
              :variant="GamButtonVariant.SECONDARY"
              :left-icon="GamIconName.HEART"
              @click="() => unfollowClub(club?.id)"
            >
              {{ localize('Following') }}
            </GamButton>
          </div>

          <div v-if="isMobile" class="action">
            <gam-button v-if="authStore.isAuthenticated()" v-bind="getMobileFollow" />
          </div>
        </div>
      </div>

      <div v-if="club.description" class="gam-club-description">
        <h4>Our Story</h4>
        <p>{{ club.description }}</p>
      </div>
    </div>

    <div v-if="club" class="gam-club-stats-info">
      <div class="gam-club-stats-count gam-club-gambits-created">
        <div class="gam-club-stats-metric">All created</div>
        <div class="gam-club-stats-amount">{{ club.gambits.length }}</div>
      </div>

      <div class="gam-club-stats-count gam-club-gambits-upcoming">
        <div class="gam-club-stats-metric">Upcoming</div>
        <div class="gam-club-stats-amount">{{ futureGambits.length }}</div>
      </div>

      <div class="gam-club-stats-count gam-club-gambits-followers">
        <div class="gam-club-stats-metric">Followers</div>
        <div class="gam-club-stats-amount">{{ followerCount }}</div>
      </div>
    </div>

    <div v-if="club && club.homeLocations?.length > 0" class="gam-club-location">
      <RouterLink
        v-for="(location, index) in club.homeLocations"
        :key="`club-loc-${index}`"
        :to="{
          name: RouterNameType.LOCATIONS,
          query: { side_panel: 'location_detail', location: location.id, ...location.coordinates, zoom: 16 },
        }"
        :class="`location loc-count-${club.homeLocations.length}`"
      >
        <div class="name">
          <GamIcon :name="GamIconName.LOCATION" /> <span class="text-label">{{ location.name }}</span>
        </div>
        <div v-if="location.address" class="address">{{ formatAddress(location.address) }}</div>
      </RouterLink>
    </div>

    <div class="gam-club-gambits">
      <GamGambitCard
        v-for="(gambit, index) in futureGambits"
        v-bind="getGambitCard(gambit)"
        :key="`future-gambit-${index}`"
      />
    </div>
  </div>

  <div v-if="isClubCurator" class="gam-club-footer">
    <GamButton
      :size="GamButtonSize.LARGE"
      :variant="GamButtonVariant.SECONDARY"
      label="Create Gambit"
      style="max-width: 16rem; margin: auto"
      @click="navigateToNewGambit"
    />
  </div>

  <GamDialog v-if="club" ref="dialog">
    <ReportingDialog :id="club.id" :type="ReportType.CLUB" @close="closeReport" />
  </GamDialog>
</template>

<script setup lang="ts">
import { JwtRepository } from '@/core/data/auth/jwt.repository';
import { ClubType } from '@/core/data/club/club.type';
import { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { UIConfigRepository } from '@/core/data/config/uiConfig.repository';
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import { ImageRepository } from '@/core/data/image/image.repository';
import { ReportType } from '@/core/data/report/report.type';
import { StorageType } from '@/core/data/storage/storage.interface';
import { StorageRepository } from '@/core/data/storage/storage.repository';
import { JwtService } from '@/core/domain/auth/jwt.service';
import { ConfigService } from '@/core/domain/config.service';
import { ImageService } from '@/core/domain/image.service';
import { gambits, localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { ImageApi } from '@/core/network/api/image.api';
import { HttpClient } from '@/core/network/http/httpClient';
import { useAuthStore } from '@/stores/auth.store';
import { useClubStore } from '@/stores/club.store';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamClubCardCategory } from '@/views/composables/constants/components/gamClub.constants';
import { GamGambitCardCategory } from '@/views/composables/constants/components/gamGambit.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamMorePosition } from '@/views/composables/constants/components/gamMore.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamClubDetailViewType } from '@/views/composables/models/components/GamClub';
import type { GamGambitCardType } from '@/views/composables/models/components/GamGambit';
import type { GamMoreType } from '@/views/composables/models/components/GamMore';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import GamButton from '../GamButton.vue';
import GamChip from '../GamChip.vue';
import GamHighlight from '../GamHighlight.vue';
import GamIcon from '../GamIcon.vue';
import GamDialog from '../gam-dialog/GamDialog.vue';
import ReportingDialog from '../gam-dialog/dialog-contents/ReportingDialog.vue';
import GamGambitCard from '../gam-gambits/GamGambitCard.vue';
import GamMore from '../gam-nav/gam-more/GamMore.vue';

const props = defineProps<GamClubDetailViewType>();
const clubStore = useClubStore();

const dialog = ref<InstanceType<typeof GamDialog>>();

const closeReport = () => {
  dialog.value?.close();
};

const isList = (): boolean => {
  return props.category === GamClubCardCategory.LIST;
};

const isDetail = (): boolean => {
  return props.category === GamClubCardCategory.DETAIL;
};

const authStore = useAuthStore();
const { user, userDetails } = storeToRefs(authStore);

const isClubCurator = computed(() => {
  return props.club?.canEdit;
});

const futureGambits = computed(() => {
  if (!props.club) return [];

  const now = new Date().getTime();
  return (
    props.club.gambits?.filter((gambit) => {
      const start = new Date(gambit.start).getTime();
      const end = gambit.end ? new Date(gambit.end).getTime() : start + 60 * 60 * 1000;
      return start > now || end > now;
    }) || []
  );
});

const followerCount = computed(() => {
  if (!props.club) return '';

  return (props.club.followers || []).length;
});

const deleteClub = async () => {
  if (!props.club) return;

  if (!confirm('Are you sure? Deleting a club is not undoable.')) return;

  const result = await gambits.clubService.deleteClub(props.club.id);

  if (!result?.error) {
    clubStore.removeClub(props.club.id);

    router.push({
      name: RouterNameType.CLUBS,
      params: { id: undefined },
    });
  } else {
    window.alert(result.error.message);
  }
};

const imgLoading = ref<boolean>(true);

const loadingImage = () => {
  imgLoading.value = true;
};

const loaded = () => {
  setTimeout(() => {
    imgLoading.value = false;
  });
};

const clientConfigRepository = new ClientConfigRepository();
const spices = clientConfigRepository.parseServiceSpices();
const localStorage = new StorageRepository(window.localStorage, StorageType.Local, spices);
const jwtRepository = new JwtRepository(localStorage);
const uiConfigRepository = new UIConfigRepository(localStorage);
const configService = new ConfigService(clientConfigRepository, uiConfigRepository);
const jwtService = new JwtService(jwtRepository, configService.getServiceRoutingHost());
const httpClient = new HttpClient(jwtService, configService.getServiceRoutingHost());

const imageApi = new ImageApi(httpClient);
const imageRepository = new ImageRepository(imageApi);
const imageService = new ImageService(imageRepository, configService);

const isFollowed = computed(() => {
  if (!props.club) return false;
  if (!Array.isArray(props.club.followers)) return false;

  const index = props.club.followers.findIndex((c) => c.userId === user.value?.id);

  return index > -1;
});

const followClub = async (id?: string) => {
  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  if (!id) return;

  const clubFollow = await gambits.clubService.followClub(id);

  const followedClub = clubStore.clubs.find((c) => c.id === id);
  if (followedClub === undefined) return;
  // @ts-ignore
  followedClub?.followers.push(clubFollow);
  clubStore.updateClub(followedClub);
};

const unfollowClub = async (id?: string) => {
  if (!id) return;

  await gambits.clubService.unfollowClub(id);

  const unfollowedClub = clubStore.clubs.find((c) => c.id === id);
  if (unfollowedClub === undefined) return;
  unfollowedClub.followers = unfollowedClub.followers.filter((fw) => fw.userId !== user.value?.id);
  clubStore.updateClub(unfollowedClub);
};

const getClass = computed(() => {
  return {
    selected: props.selected,
    'is-list': isList(),
    'is-detail': isDetail(),
    'is-mobile': isMobile.value,
  };
});

const getGambitCard = (gambit: BaseGambitDto): GamGambitCardType => {
  return {
    gambit,
    category: GamGambitCardCategory.LIST,
    isMobile: isMobile.value,
  };
};

const router = useRouter();
const route = useRoute();

const getMoreItems = computed((): GamMoreType => {
  const items: any[] = [];

  const isAdmin = userDetails.value?.isAdmin;

  if (isClubCurator.value === true || isAdmin) {
    items.splice(0, 0, {
      label: 'app.more.edit',
      onClick: () => {
        router.push({
          ...route,
          query: {
            ...route.query,
            side_panel: 'edit_club',
          },
        });
      },
    });

    items.splice(1, 0, {
      label: 'app.more.locations',
      onClick: () => {
        router.push({
          ...route,
          query: {
            ...route.query,
            side_panel: 'edit_club_locations',
          },
        });
      },
    });

    items.splice(2, 0, {
      label: 'app.more.curators',
      onClick: () => {
        router.push({
          ...route,
          query: {
            ...route.query,
            side_panel: 'edit_club_curators',
          },
        });
      },
    });

    items.splice(3, 0, {
      label: 'app.more.delete',
      onClick: () => {
        deleteClub();
      },
    });
  } else {
    items.push({
      label: 'app.more.report',
      onClick: () => {
        if (!authStore.isAuthenticated()) {
          router.push('/auth');
          return;
        }

        dialog.value?.show();
      },
    });
  }

  return {
    items,
    size: GamButtonSize.X_SMALL,
    position: GamMorePosition.LEFT_BELOW,
  };
});

const navigateToNewGambit = () => {
  router.push({
    name: RouterNameType.LOCATIONS,
    query: { side_panel: 'add_gambit', clubId: props.club?.id },
  });
};

const getMobileFollow = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    isIconOnly: true,
    leftIcon: isFollowed.value === true ? GamIconName.HEART : GamIconName.HEART_EMPTY,
    onClick: async () => {
      if (isFollowed.value !== true) {
        followClub(props.club?.id);
      } else {
        unfollowClub(props.club?.id);
      }
    },
  };
});

const getPlaceholderURL = () => new URL('../../../ui/icons/image-placeholder.svg', import.meta.url).href;

const formatAddress = (address: string) => {
  const parts = address.split(' ');
  const retained = [parts.pop(), parts.pop()].reverse().join(' ');

  return retained;
};
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-club-gambits {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: var(--spacing-l);

  @media (min-width: 640px) {
    margin-bottom: var(--spacing-xxl);
  }
}

.gam-club-detail {
  display: flex;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l);
  background-color: transparent;

  &:not(.is-mobile) {
    .gam-club-header,
    .gam-club-header-cover,
    .gam-club-header-image {
      border-radius: var(--radius-small);
    }
  }

  .gam-club-actions {
    display: flex;
    gap: 4px;
    margin-top: 1rem;
  }

  .gam-club-header {
    background: var(--color-dark-600);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: #{var(--bg-image)};
    position: relative;

    .gam-club-header-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 1;
      filter: saturate(0%);

      &.placeholder {
        top: 50%;
        left: 50%;
        width: 4rem;
        height: 4rem;
        transform: translate(-50%, -50%);
      }
    }

    .gam-club-header-cover {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.5) 100%
      );

      z-index: 2;
    }

    .more-wrapper {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 3;
    }

    .club-type {
      position: absolute;
      top: 1rem;
      left: 20px;
      z-index: 3;
    }

    .gam-club-header-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 3;
      padding: 20px;

      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      .actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

      .names {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        h1 {
          margin: 0;
        }

        a {
          color: var(--color-white);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          font-weight: bold;
          letter-spacing: 0.5px;
          margin-bottom: 0.5rem;
          color: var(--color-pink);
          background-color: rgba(0, 0, 0, 0.33);
          -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
          display: flex;
          padding: var(--spacing-xs) var(--spacing-md);
          border-radius: var(--radius-small);
          margin-left: -0.25rem;
        }
      }
    }
  }

  .gam-club-stats-info {
    display: flex;
    gap: var(--spacing-md);
    width: 100%;

    .gam-club-stats-count {
      background: var(--color-dark-600);
      color: white;
      border-radius: var(--radius-small);
      padding: 12px 12px 16px 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      .gam-club-stats-metric {
        opacity: 0.7;
      }

      .gam-club-stats-amount {
        font-size: 20px;
        line-height: 110%;
        font-weight: bold;
      }
    }
  }

  .gam-club-description {
    padding: 18px 20px 20px 20px;
    background: var(--color-dark-600);
    border-radius: var(--radius-small);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h4 {
      margin-top: 0;
      margin-bottom: 0.25rem;
    }
    p {
      margin-top: 0.75rem;
      margin-bottom: 0;
    }
  }

  .gam-club-location {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: var(--spacing-md);
    width: 100%;

    a.location {
      padding: 0;
      background: var(--linear-gradient);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      flex-grow: 1;

      text-decoration: none;
      color: var(--color-white-100);
      flex-grow: 1;
      border-radius: var(--radius-small);
      border: 1px solid var(--color-pink-border-70);
      padding: 18px 20px 20px 20px;
      gap: 0.5rem;

      overflow: hidden;
      flex-basis: 1;

      &:hover {
        background-color: var(--color-dark-600);
        border-color: var(--color-pink-border-100);
      }

      &.loc-count-3 {
        width: calc(100% / 3 - 1rem);
      }

      &.loc-count-2 {
        width: calc(100% / 2 - 0.5rem);
      }

      &.loc-count-1 {
        width: calc(100% / 1);
      }

      .name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.25rem;
        width: 100%;
        overflow: hidden;
      }

      .text-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      .address {
        color: var(--color-white-30);
      }
    }
  }

  &.is-mobile {
    .gam-club-header {
      height: 100svw;
      border-radius: 0;
    }

    .club-type {
      left: 50%;
      transform: translateX(-50%);
    }

    .gambit-address-info {
      width: 100%;
    }

    .club-base-info {
      margin-top: 0;
    }

    .gam-club-description {
      border-radius: 0;

      h4 {
        display: none;
      }

      p {
        margin-top: 0;
      }
    }

    .gam-club-stats-info,
    .gam-club-gambits,
    .gam-club-location {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }
  }

  &:not(.is-mobile) {
    .gam-club-header {
      height: 350px;
    }

    .club-base-info {
      margin-top: 22px;
      gap: var(--spacing-l);
    }

    .gam-button {
      width: 7.5rem;
    }
  }

  .club-base-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    min-width: 0;

    .gambit-time-location {
      position: relative;
      display: flex;
      align-self: stretch;
      align-items: flex-start;
      gap: var(--spacing-l);
      min-width: 0;

      .gam-owner {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
      }
    }

    .gambit-tags-wrapper {
      width: inherit;
      min-width: 0;
    }
  }
}

.highlight.is-mobile {
  display: none;
}
</style>

<style lang="scss">
.is-mobile .detail-container.club {
  margin-bottom: calc(2 * var(--spacing-xxl)) !important;
}
</style>
