<template>
  <div v-if="isPinpointingLocation" class="pinpointing-header">
    <span class="address">{{ localize('gambit.add.cancel.message') }}</span>

    <RouterLink :to="{ ...route, query: { ...route.query, side_panel: undefined, mobile_step: undefined } }">
      <GamIcon :name="GamIconName.X_CLOSE" :size="GamIconSize.X_LARGE" />
    </RouterLink>
  </div>

  <div v-if="isPinpointingLocation" class="pinpointing-footer">
    <div class="address">
      {{ gamForm?.form.locationInputValue }}
    </div>

    <GamButton :size="GamButtonSize.SMALL" :variant="GamButtonVariant.SECONDARY" @click="handleContinueFromPinpoint">
      Continue
    </GamButton>
  </div>

  <gam-side-panel v-bind="getAddGambitSidePanel">
    <GambitFormComponent
      ref="gambitForm"
      :key="`gambit-form-${instanceId}`"
      :instance-id="instanceId"
      :form-id="formId"
    />
  </gam-side-panel>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { type BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import { GambitCategory, GambitType } from '@/core/data/gambit/gambit.type';
import type { MapLocationDto } from '@/core/data/location/location.interface';
import { LocationType } from '@/core/data/location/location.type';
import { gambits, localize } from '@/core/gambits';
import { useFormStore } from '@/stores/form.store';
import { useMarkerStore } from '@/stores/marker.store';
import { useTableStore } from '@/stores/table.store';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import {
  GamCollapseDirection,
  GamSidePanelId,
  GamSidePanelOption,
} from '@/views/composables/constants/components/gamSidePanel.constants';
import { FormId } from '@/views/composables/constants/form.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonMessageType } from '@/views/composables/models/components/GamButton';
import type { GambitForm, GambitValidator } from '@/views/composables/models/form.interface';
import { storeToRefs } from 'pinia';
import { v4 as uuid4 } from 'uuid';
import { computed, ref, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { handleCreateLocation } from '../../locations/side-panels/locationActions';
import GambitFormComponent from '../forms/GambitForm.vue';

const emits = defineEmits([GamComponentsEmits.SUCCESS]);

const formId = FormId.ADD_GAMBIT;
const panelId = GamSidePanelId.GAMBIT_FORM;

const formStore = useFormStore<GambitForm, GambitValidator>(formId)();
const { gamForm } = storeToRefs(formStore);
// const locationFormStore = useFormStore<LocationFormProps, LocationValidator>('add-location' as FormId)();
// const { gamForm: gamLocationForm } = storeToRefs(locationFormStore);

const mapPinTableStore = useMarkerStore(GamListId.MARKERS)();

const gambitTableStore = useTableStore<BaseGambitDto>(GamListId.GAMBITS)();

const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);
const gambitForm = ref<InstanceType<typeof GambitFormComponent>>();
const instanceId = ref<string>(uuid4());

const router = useRouter();
const route = useRoute();

const lastCleared = ref<number>(new Date().getTime());

const isInvalid = ref<boolean>(true);
watch(
  () => gambitForm.value?.isInvalid,
  (isFormInvalid) => {
    if (typeof isFormInvalid !== 'boolean') {
      return;
    }

    isInvalid.value = isFormInvalid;
  },
);

const isPinpointingLocation = computed(
  () => route.query.mobile_step === 'pick_coordinate' && route.query.side_panel === 'add_gambit',
);

// TODO: Refactor this once we don't have complex form generators in parent views
const clearForm = () => {
  errorMessage.value = null;

  const { form } = formStore.gamForm || {};
  if (!form) return;

  form.address = undefined;
  form.bringMyBoard = false;
  form.clubId = undefined;
  form.description = undefined;
  form.end = undefined;
  form.gambitCategory = GambitCategory.CASUAL;
  form.gambitType = [GambitType.ANY];
  form.locationId = undefined;
  form.locationInputValue = undefined;
  form.locationPoint = undefined;
  form.name = undefined;
  form.mapBoxId = undefined;
  form.pinpoint = undefined;
  form.private = false;
  form.recurrences = undefined;
  form.repeat = undefined;
  form.streamed = false;
  form.timed = false;
  form.title = undefined;
};

watch(route.query, (newPanel, prevPanel) => {
  if (prevPanel.side_panel !== 'add_gambit' && newPanel.side_panel === 'add_gambit') {
    clearForm();
  }
});

// Set default values when arriving from clicked location markers or club pages
// TODO: Split up location and club route param logic into two watch blocks
// TODO: Implement form state changes by exposing a callback function instead of routes
watch(
  () => [route.name, route.query.side_panel, route.query.location, route.query.locationName, route.query.clubId],
  ([routeName, panelQuery, locationIdQuery, locationNameQuery, clubQuery]) => {
    if (!gamForm.value) {
      return;
    }

    if (
      ![RouterNameType.LOCATIONS, RouterNameType.GAMBITS].includes(String(routeName) as RouterNameType) ||
      panelQuery !== 'add_gambit'
    ) {
      return;
    }

    if (!(locationIdQuery && locationNameQuery) && !clubQuery) {
      return;
    }

    if (locationIdQuery && locationNameQuery) {
      gamForm.value.form.locationId = String(locationIdQuery);
      gamForm.value.form.locationInputValue = String(locationNameQuery);
      gamForm.value.form.address = '';
      gamForm.value.form.locationPoint = undefined;
    }

    if (clubQuery) {
      gamForm.value.form.clubId = String(clubQuery);
    }

    router.replace({
      ...route,
      query: { ...route.query, locationName: undefined, clubId: undefined },
    });
  },
);

const handleContinueFromPinpoint = () => {
  router.push({ ...route, query: { ...route.query, mobile_step: undefined } });
};

onBeforeRouteUpdate((to, from) => {
  if (to.query.side_panel === 'add_gambit' && from.query.side_panel !== 'add_gambit') {
    instanceId.value = uuid4();
    isInvalid.value = true;
  }
});

const clearAddMode = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
      location: undefined,
    },
  });
  lastCleared.value = new Date().getTime();
};

const closeAddGambit = (gambitId: string) => {
  router.push({
    name: RouterNameType.GAMBIT,
    params: { id: gambitId },
  });
  lastCleared.value = new Date().getTime();
};

const handleGambitCreate = async () => {
  if (!gamForm.value?.form) return;

  isLoading.value = true;

  const { form } = gamForm.value;

  const payload = {
    ...form,
    streamed: form.gambitType.includes(GambitType.STREAMED),
    timed: form.gambitType.includes(GambitType.TIMED),
    gambitType: form.gambitType.filter((t) => !['streamed', 'timed'].includes(t)),
  };

  if (payload.gambitType.length === 0) {
    payload.gambitType = [GambitType.ANY];
  }

  const result = await gambits.gambitService.addNewGambit(payload);

  if (result.data) {
    gambitTableStore.addItem(result.data);
    emits(GamComponentsEmits.SUCCESS);
    closeAddGambit(result.data.id);
    clearForm();
  } else if (result.error) {
    errorMessage.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }
  isLoading.value = false;
};

const handleLocationCreate = async () => {
  const { form: formData } = gamForm.value || {};
  if (!formData) return;

  isLoading.value = true;

  const locationProps = {
    name: formData.name!,
    address: formData.address,
    locationTypes: [LocationType.CHESS_FRIENDLY],
    coordinates: formData.locationPoint,
    chessSet: false,
    photos: [],
  };

  const result = await handleCreateLocation(locationProps);

  if ((result as GamButtonMessageType).variant === GamButtonMessageVariant.ERROR) {
    errorMessage.value = result as GamButtonMessageType;
    isLoading.value = false;

    return result;
  } else if (result) {
    // TODO: Use the result coordinates to place the marker
    const { latitude, longitude } = mapPinTableStore.getLocationParam() || {};
    latitude && longitude && mapPinTableStore.addUniqueItems({ lat: latitude, lng: longitude });
    isLoading.value = false;

    return result;
  }
};

const submitButton = {
  label: 'gambit.add.form.action.button',
  isFullSize: true,
  center: true,
};

const cancelButton = {
  variant: GamButtonVariant.TERTIARY,
  size: GamButtonSize.LARGE,
  label: 'gambit.add.form.clear.button',
  isFullSize: true,
  center: true,
};

const getAddGambitSidePanel = computed(() => ({
  id: panelId,
  isOpened: route.query.side_panel === 'add_gambit' && route.query.mobile_step !== 'pick_coordinate',
  isComplementary: true,
  isModal: false,
  direction: GamCollapseDirection.LEFT,
  option: GamSidePanelOption.ADD,
  header: {
    title: 'gambit.add.title.label',
    icon: GamIconName.GAMBIT_EMPTY,
  },
  bodyClass: 'add-gambit',
  footer: {
    isColumn: true,
    action: {
      onClick: async () => {
        if (!gamForm.value) return;

        if (gamForm.value.form.locationId) {
          await handleGambitCreate();
          return;
        }

        const result = await handleLocationCreate();

        if ((result as GamButtonMessageType).variant === GamButtonMessageVariant.ERROR) {
          return;
        } else {
          gamForm.value.form.locationId = (result as MapLocationDto).id;

          gamForm.value.form.pinpoint = undefined;
          gamForm.value.form.locationPoint = undefined;
        }

        await handleGambitCreate();
      },
      message: errorMessage.value,
      isDisabled: isInvalid.value || isLoading.value,
      isLoading: isLoading.value,
      ...submitButton,
    },
    clear: {
      onClick: clearAddMode,
      isDisabled: isInvalid.value,
      isLoading: isLoading.value,
      ...cancelButton,
    },
  },
}));
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.pinpointing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-l) var(--spacing-xl);

  position: absolute;
  z-index: 801;
  top: 0;

  background: linear-gradient(0deg, rgba(#181716, 0) 0%, rgba(#181716, 0.15) 9.5%, rgba(#181716, 0.8) 28%, #181716 58%);

  a {
    padding: var(--spacing-l);
  }
}

.add-gambit {
  position: relative;

  &.is-mobile {
    border: 0;
  }
}

#gambit-form {
  .panel-body {
    width: var(--side-panel-form-width);
  }

  .panel-body.is-mobile {
    width: 100svw;
  }
}

.pinpointing-footer {
  position: absolute;
  z-index: 802;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(#181716, 0) 0%,
    rgba(#181716, 0.15) 9.5%,
    rgba(#181716, 0.8) 28%,
    #181716 58%
  );
  transition:
    opacity var(--transition-router),
    bottom var(--transition);
  background-color: transparent;
  border: 0;
  position: fixed;
  min-height: 78px;
  padding: var(--spacing-xl);
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
