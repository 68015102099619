<template>
  <div :class="['gam-list-view', getClasses]">
    <div
      :class="[
        'header',
        {
          'is-mobile': isMobile,
          'is-padded': isHorizontallyPadded && (isLaptop || isDesktop),
          'is-lightly-padded': isHorizontallyPadded && !(isLaptop || isDesktop),
          'is-lower': listId === GamListId.LOCATIONS,
        },
      ]"
    >
      <slot name="header" />
    </div>
    <div :id="listId" class="gam-list-container">
      <gam-intersect v-bind="getIntersect" @infinite="tableStore.infiniteLoad()">
        <span class="spacer" :style="getSpacerPadding" />
        <div class="list-content">
          <slot name="list" />
        </div>
        <span class="spacer" :style="getFooterPadding" />
      </gam-intersect>
    </div>
  </div>
</template>
<script setup lang="ts" generic="T extends BaseUserDto | BaseGambitDto | BaseLocationDto | SimpleTableRow">
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import type { BaseLocationDto } from '@/core/data/location/location.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import LocationHelper from '@/core/helpers/location.helper';
import { isDesktop, isLaptop, isMobile } from '@/core/helpers/ui.helper';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useTableStore } from '@/stores/table.store';
import GamIntersect from '@/views/components/GamIntersect.vue';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamIntersectType } from '@/views/composables/models/components/GamIntersect';
import type { GamListType } from '@/views/composables/models/components/GamList';
import type { SimpleTableRow } from '@/views/composables/models/components/SimpleTable';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, onUnmounted, type StyleValue, watch } from 'vue';

const props = withDefaults(defineProps<GamListType<T>>(), {
  selectFirst: true,
  spacerPadding: isMobile.value ? 90 : 110,
  footerPadding: isMobile.value ? 78 : 42,
});

const { userLocation } = storeToRefs(useGeolocationStore());
const tableStore = useTableStore<T>(props.listId)();
const { tableParams, itemList } = storeToRefs(tableStore);

onBeforeMount(() => {
  if (!props.loadCallback) return;
  tableStore.setLoadCallback(props.loadCallback);
});

onMounted(async () => {
  if (!props.useLocation || userLocation) {
    await getListData();
  }
});

onUnmounted(() => {
  tableStore.resetList();
});

const getListData = async (): Promise<void> => {
  await tableStore.loadItems({ selectFirstItem: !isMobile.value && props.selectFirst });
};

const getSpacerPadding = computed((): StyleValue => {
  return {
    height: `${props.spacerPadding}px`,
  };
});

const getFooterPadding = computed((): StyleValue => {
  return {
    height: `${props.footerPadding}px`,
  };
});

const getClasses = computed(() => {
  return {
    'no-data': itemList.value?.length === 0,
    'load-data': !!tableParams.value.afterCursor,
    'is-mobile': isMobile.value,
    'is-padded': props.isHorizontallyPadded,
    'is-lower': props.isLower,
  };
});

const getIntersect = computed((): GamIntersectType => {
  return {
    id: props.listId,
    endInfo: props.endInfo,
    noDataInfo: props.noDataInfo,
    scrollSpacing: props.scrollSpacing,
    widthSpacing: props.widthSpacing,
    showMore: !!tableParams.value.afterCursor,
    noData: itemList.value?.length === 0,
    useLocation: props.useLocation,
    spacerPadding: props.spacerPadding,
    footerPadding: props.footerPadding,
    itemsGap: props.gap,
  };
});

watch(userLocation, async (value, oldValue) => {
  if (LocationHelper.shouldUpdateUserLocation(value?.location, oldValue?.location)) {
    // TODO: Find out why this causes location result duplication on first load
    // await getListData();
  }
});
</script>

<style scoped lang="scss">
.header {
  padding-top: calc(var(--spacing-mobile-wrapper) + var(--spacing-l));
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-l);
  padding-bottom: var(--spacing-menu);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(var(--color-dark-700) 66%, transparent 85%);
}

.header.is-padded {
  padding-right: var(--spacing-xxl);
  padding-left: var(--spacing-xxl);
}

.header.is-lightly-padded {
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-l);
}

.header.is-mobile {
  padding-top: calc(var(--spacing-l) + var(--spacing-s));
  padding-right: var(--spacing-mobile-wrapper);
  padding-left: var(--spacing-mobile-wrapper);
  padding-bottom: calc(var(--spacing-l) + var(--spacing-s) + var(--spacing-s));
}

.header.is-mobile.is-lower {
  padding-top: calc(var(--spacing-mobile-wrapper) + var(--spacing-l));
}

.gam-list-view {
  width: 100%;
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  &.no-data {
    .spacer {
      display: none;
    }
  }

  &.is-mobile {
    .gam-list-container {
      .list-content {
        padding: 0 var(--spacing-l);
      }
    }

    &:after {
      height: calc(var(--spacing-mobile-bottom) + 30px);
    }
  }

  .gam-list-container {
    width: 100%;
    max-height: 100%;
    height: auto;
    display: flex;
    flex: 1 0 auto;

    .spacer {
      width: 100%;
      height: 0;
      transition: height var(--transition-fast);
      display: block;
    }

    .list-content {
      padding: 0 var(--spacing-xxl) 0 var(--spacing-xxl);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-l);

      @media (max-width: 1180px) {
        padding: 0 var(--spacing-l);
      }
    }
  }
}
</style>
