<template>
  <!-- <details v-touch:tap="toggleAccordion" class="gam-details" :class="[getStyleClasses]" :open="isOpened"> -->
  <details class="gam-details" :class="[getStyleClasses]" :open="isOpened">
    <summary class="affordance">
      <span>{{ props.title }}</span>
      <GamIcon :name="GamIconName.PLUS" :size="GamIconSize.LARGE" class="plus" />
      <GamIcon :name="GamIconName.MINUS" :size="GamIconSize.LARGE" class="minus" />
    </summary>

    <div class="disclosure">
      <slot />
    </div>
  </details>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { GamIconName, GamIconSize } from '../composables/constants/components/gamIcon.constants';
import GamIcon from './GamIcon.vue';

interface Props {
  title: string;
  isOpened?: boolean;
}

const props = defineProps<Props>();

const getStyleClasses = computed(() => {
  return {
    opened: props.isOpened,
  };
});
</script>

<style scoped lang="scss">
@use '@/ui/css/partial';

summary::-webkit-details-marker {
  display: none !important;
}

.gam-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-l);
  border-radius: var(--radius-small);
  min-width: 0;
  @extend .gam-special-border !optional;

  &:before {
    background: var(--color-dark-600);
  }

  .affordance {
    cursor: pointer;
    width: calc(100% + 2 * var(--spacing-l));
    line-height: var(--spacing-xl);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    list-style: none;
    margin-left: calc(var(--spacing-l) * -1);
    margin-right: calc(var(--spacing-l) * -1);
    margin-top: calc(var(--spacing-l) * -1);
    margin-bottom: calc(var(--spacing-l) * -1);
    padding-left: calc(var(--spacing-l) + var(--spacing-s));
    padding-right: calc(var(--spacing-l) + var(--spacing-s));

    span,
    .minus,
    .plus {
      display: flex;
      align-items: center;
      height: calc(var(--spacing-xl) + var(--spacing-md) * 2);
      line-height: var(--spacing-xl);
    }

    .minus,
    .plus {
      width: var(--spacing-l);
    }

    &::marker,
    ::-webkit-details-marker {
      display: none;
    }

    &:before {
      background: var(--color-linear-gradient);
      opacity: 0.3;
    }
  }

  summary.affordance {
    .minus {
      display: none;
    }

    .plus {
      display: flex;
    }
  }

  &[open] summary {
    .minus {
      display: flex;
    }

    .plus {
      display: none;
    }
  }

  .disclosure {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l);
    margin-top: var(--spacing-l);
  }
}
</style>
