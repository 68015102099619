<template>
  <div class="gam-rating-lichess">
    <div class="base-ratings">
      <div class="rating-row">
        <gam-input
          v-bind="getRatingInput(RatingValueType.RAPID, ratings.chessComRapidRating)"
          v-model="ratings.chessComRapidRating"
        />
        <gam-input
          v-bind="getRatingInput(RatingValueType.BLITZ, ratings.chessComBlitzRating)"
          v-model="ratings.chessComBlitzRating"
        />
      </div>
      <div class="rating-row">
        <gam-input
          v-bind="getRatingInput(RatingValueType.BULLET, ratings.chessComBulletRating)"
          v-model="ratings.chessComBulletRating"
        />
        <gam-input
          v-bind="getRatingInput(RatingValueType.CLASSICAL, ratings.chessComClassicalRating)"
          v-model="ratings.chessComClassicalRating"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RatingValueType } from '@/core/data/rating/rating.type';
import { localize } from '@/core/gambits';
import { useRatingStore } from '@/stores/rating.store';
import GamInput from '@/views/components/GamInput.vue';
import { GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import { storeToRefs } from 'pinia';

const ratingStore = useRatingStore();
const { ratings, maxRating } = storeToRefs(ratingStore);

const getRatingInput = (type: RatingValueType, value?: string | number): GamInputType => {
  return {
    name: `rating-${type}`,
    placeholder: localize(`app.component.ratings.input.${type.toString()}`),
    value,
    type: GamInputValueType.NUMBER,
    canClear: true,
    max: maxRating.value,
  };
};
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-rating-lichess {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);

  .base-ratings {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    .rating-row {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
    }
  }
}
</style>
