<template>
  <GamButton v-bind="getPreviousButton()" />
  <GamButton v-bind="getNextButton()" />

  <div v-if="swipeMessage" :class="['help-wrapper', { 'is-swipe-aware': isSwipeAware }]">
    <div class="first-line">
      <div>{{ localize(swipeMessage.firstLine) }}</div>
      <GamIcon :name="GamIconSpecialName.KEY_DOWN" />
      <GamIcon :name="GamIconSpecialName.KEY_UP" />
    </div>
    <div v-if="swipeMessage.secondLine" class="second-line">
      {{ localize(swipeMessage.secondLine) }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { useNavigationStore } from '@/stores/navigation.store';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName, GamIconSpecialName } from '@/views/composables/constants/components/gamIcon.constants';
import { Key, KeyEvent } from '@/views/composables/constants/gam.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamDetailSwipeMessage } from '@/views/composables/models/components/GamDetail';
import { useKeypress } from 'vue3-keypress';
import GamButton from '../GamButton.vue';
import GamIcon from '../GamIcon.vue';

interface DetailHeaderProps {
  previousButton: GamButtonType;
  nextButton: GamButtonType;
  swipeMessage?: GamDetailSwipeMessage;
  isSwipeAware?: boolean;
}
const props = withDefaults(defineProps<DetailHeaderProps>(), {
  swipeMessage: {
    // @ts-ignore
    firstLine: 'club.details.header.description.one',
    secondLine: 'club.details.header.description.two',
  },
});

const navigationStore = useNavigationStore();

const getPreviousButton = (): GamButtonType => {
  return {
    label: 'club.details.header.button.previous',
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    rightIcon: GamIconName.SMALL_ARROW_UP,
    ...props.previousButton,
  };
};
const getNextButton = (): GamButtonType => {
  return {
    label: 'club.details.header.button.next',
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.SECONDARY,
    leftIcon: GamIconName.SMALL_ARROW_DOWN,
    ...props.nextButton,
  };
};

useKeypress({
  keyEvent: KeyEvent.KEY_DOWN,
  keyBinds: [
    {
      keyCode: Key.DOWN,
      success: () => {
        const button = getNextButton();
        if (!button.isDisabled && button.onClick) button.onClick(new MouseEvent('click'));
        navigationStore.setSwipeAware(true);
      },
    },
    {
      keyCode: Key.UP,
      success: () => {
        const button = getPreviousButton();
        if (!button.isDisabled && button.onClick) button.onClick(new MouseEvent('click'));
        navigationStore.setSwipeAware(true);
      },
    },
  ],
});
</script>

<style lang="scss" scoped>
.help-wrapper {
  opacity: 1;
}

.help-wrapper.is-swipe-aware {
  opacity: 0;
  transition: opacity 2s linear;
}

.detail-header {
  position: absolute;
  width: 100%;
  padding: var(--spacing-xxl) var(--spacing-xxl) var(--spacing-menu);
  max-width: calc(596px + var(--spacing-xxl) * 2);
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  pointer-events: none;

  .gam-button {
    pointer-events: auto;
  }
}
</style>
