export enum GamStoreId {
  APP = 'app',
  ADMIN = 'admin',
  AUTH = 'auth',
  NAVIGATION = 'navigation',
  PLAYER = 'player',
  INTERSECT = 'intersect',
  SIDE_PANEL = 'side-panel',
  TABLE = 'table',
  FILTER = 'filter',
  FORM = 'form',
  MAP = 'map',
  GEOLOCATION = 'geolocation',
  RATING = 'rating',
}

export enum TableStoreAction {
  SET_SEARCH_QUERY = 'setSearchQuery',
  INFINITE_LOAD = 'infiniteLoad',
}

export enum FilterStoreAction {
  SET_FILTERS = 'setFilters',
  SET_ACTIVE_TAB = 'setActiveTab',
}

export enum IntersectStoreAction {
  SET_LOAD = 'setLoadState',
}

export enum MapStoreAction {
  SET_USER_LOCATION = 'setUserLocation',
}
