<template>
  <gam-side-panel v-bind="getSidePanelData">
    <div class="player-filters">
      <gam-filter-item v-for="(item, index) in playerFilters" v-bind="item" :key="`f-item-${index}`" ref="filterItem" />
    </div>
  </gam-side-panel>
</template>

<script setup lang="ts">
import FilterHelper from '@/core/helpers/filter.helper';
import type { GetPlayerTableFilters } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import GamFilterItem from '@/views/components/gam-filter-item/GamFilterItem.vue';
import GamSidePanel from '@/views/components/side-panel/GamSidePanel.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamCollapseDirection, GamSidePanelId } from '@/views/composables/constants/components/gamSidePanel.constants';
import { getPlayerFilters } from '@/views/composables/constants/main/filterPlayer.constants';
import type { GamFilterType } from '@/views/composables/models/components/GamFilter';
import type { GamSidePanelType } from '@/views/composables/models/components/GamSidePanel';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const panelId = GamSidePanelId.PLAYER_FILTER;
const listId = GamListId.PLAYERS;
const playerLocationId = GamListId.PLAYERS_FILTER_LOCATION;

const filterStore = useFilterStore<GetPlayerTableFilters>(listId)();
const filterItem = ref();
const playerFilters = ref<GamFilterType[]>();

const router = useRouter();
const route = useRoute();

onBeforeMount(() => {
  playerFilters.value = getFilters();
});

const getFilters = (): GamFilterType[] => {
  return getPlayerFilters(playerLocationId, filterStore.getFilters());
};

const getSidePanelData = computed((): GamSidePanelType => {
  return {
    id: panelId,
    header: {
      title: 'players.filters.title',
      icon: GamIconName.FILTER,
    },
    bodyClass: 'profile-panel',
    footer: {
      action: {
        variant: GamButtonVariant.PRIMARY,
        size: GamButtonSize.LARGE,
        label: 'players.filters.button.apply',
        isFullSize: true,
        center: true,
        onClick: saveFilters,
      },
      clear: {
        variant: GamButtonVariant.TERTIARY,
        size: GamButtonSize.LARGE,
        label: 'players.filters.button.clear',
        isFullSize: true,
        center: true,
        onClick: () => {
          filterStore.setFilters(null);
          closeSave();
        },
      },
    },
    direction: GamCollapseDirection.RIGHT,
    isOpened: isOpened.value,
    onClose: closeSave,
  };
});

const isOpened = computed(() => {
  return route.query.side_panel === GamSidePanelId.PLAYER_FILTER;
});

const closeSave = () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      side_panel: undefined,
    },
  });
};

const saveFilters = (): void => {
  const filters = FilterHelper.getFilterValues(filterItem.value, GamListId.PLAYERS);
  filterStore.setFilters(filters);
  // Close panel
  playerFilters.value = getFilters();
  closeSave();
};
</script>

<style lang="scss">
#player-filter {
  .panel-body {
    width: var(--side-panel-form-width) !important;
  }

  .panel-body.is-mobile {
    width: 100svw !important;
  }
}

.player-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  overflow-x: hidden;
  align-self: stretch;
}
</style>
