<template>
  <div class="gam-filter-item-radio">
    <div v-if="items?.length" class="radio-items">
      <gam-switch v-for="item in items" v-bind="getItem(item)" :key="item.value" v-model="radioValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GamSwitch from '@/views/components/GamSwitch.vue';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamFilterItem, GamFilterType } from '@/views/composables/models/components/GamFilter';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import { whenever } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps<GamFilterType>();
const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);
const getSelectedValue = (): string | undefined => {
  return props.items?.find((item) => item.selected)?.value || undefined;
};
const radioValue = ref<string | undefined>(getSelectedValue());

const getItem = (item: GamFilterItem): GamSwitchType => {
  return {
    label: item.label,
    name: item.name || '',
    value: item.value,
    type: GamSwitchValueType.RADIO,
    position: GamSwitchPosition.RIGHT,
    checked: item.selected,
  };
};

whenever(radioValue, (value) => {
  emit(GamComponentsEmits.UPDATE_VALUE, value);
});
</script>

<style scoped lang="scss">
.gam-filter-item-radio {
  display: flex;
  width: 100%;

  .radio-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
}
</style>
