<template>
  <RouterLink ref="cardElement" :to="getGambitLinkProps" class="gam-gambit-card" :class="getClass">
    <h3>
      <span>{{ gambit?.title || 'Unnamed gambit' }}</span>
    </h3>

    <div v-if="isCreator || isOrganizer" class="gam-owner">
      <GamGambitOwner
        :is-list="isList()"
        :is-detail="isDetail()"
        :is-creator="isCreator && !isOrganizer"
        :is-organizer="isOrganizer"
      />
    </div>

    <GamGambitDate v-bind="getGambitDate" />

    <gam-gambit-location v-bind="getGambitLocation" :class="{ skeleton: !gambit }" />

    <div v-if="gambit" class="gambit-tags-wrapper">
      <gam-gambit-simple-tags :gambit="gambit" is-small is-horizontally-padded />
    </div>

    <div v-if="isDetail() && false" class="gambit-address-info">
      <gam-button v-bind="getMapButton" :class="{ skeleton: !gambit }" />
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { useAuthStore } from '@/stores/auth.store';
import GamButton from '@/views/components/GamButton.vue';
import GamGambitDate from '@/views/components/gam-gambits/GamGambitDate.vue';
import GamGambitLocation from '@/views/components/gam-gambits/GamGambitLocation.vue';
import GamGambitOwner from '@/views/components/gam-gambits/GamGambitOwner.vue';
import GamGambitSimpleTags from '@/views/components/gam-gambits/GamGambitSimpleTags.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamGambitCardCategory, GamGambitDateSize } from '@/views/composables/constants/components/gamGambit.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type {
  GamGambitCardType,
  GamGambitDateType,
  GamGambitLocationType,
} from '@/views/composables/models/components/GamGambit';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const props = defineProps<GamGambitCardType>();

const cardElement = ref<HTMLDivElement>();

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const isList = (): boolean => {
  return props.category === GamGambitCardCategory.LIST;
};

const isDetail = (): boolean => {
  return props.category === GamGambitCardCategory.DETAIL;
};

const isCreator = computed(() => {
  const ownerId = props.gambit?.ownerId;
  if (typeof ownerId !== 'string') return false;
  const userId = user.value?.id;
  if (typeof userId !== 'string') return false;

  return ownerId === userId;
});

const isOrganizer = computed(() => {
  return props.gambit?.canEdit;
});

const getGambitDate = computed((): GamGambitDateType => {
  return {
    start: props.gambit?.start,
    end: props.gambit?.end,
    size: isList() ? GamGambitDateSize.MEDIUM : GamGambitDateSize.LARGE,
  };
});

const getGambitLocation = computed((): GamGambitLocationType => {
  return {
    isList: isList(),
    distance: props.gambit?.distance,
    locationImageHash: props.gambit?.locationImageBlobHash,
  };
});

const getMapButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    label: props.gambit?.address || 'Address does not exists!',
    leftIcon: GamIconName.LOCATION_EMPTY,
    rightIcon: GamIconName.ARROW_RIGHT,
  };
});

const getGambitLinkProps = computed(() => {
  return {
    name: RouterNameType.GAMBIT,
    params: { id: props.gambit?.id },
  };
});

const getClass = computed(() => {
  return {
    selected: props.selected,
    'is-list': isList(),
    'is-detail': isDetail(),
    'is-mobile': props.isMobile,
  };
});

watch(
  () => [props.selected, cardElement.value],
  ([selected, card]) => {
    if (!selected || !card) return;

    // @ts-ignore
    card?.$el?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
  },
);
</script>

<style lang="scss" scoped>
@use '@/ui/css/partial';

.gam-gambit-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l);
  position: relative;
  height: calc(192px - 24px);
  border-radius: 16px;
  overflow: hidden;
  background-color: #222;
  cursor: pointer;
  border: 1px solid transparent;

  .gam-owner {
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: 1;
  }

  &.selected {
    border: 1px solid #555;
    background-color: var(--color-dark-500);
  }

  &:hover {
    background-color: var(--color-dark-500);
    box-shadow: var(--shadow-card-hover);
  }

  h3 {
    position: absolute;
    left: calc(96px + 16px);
    right: 0;
    top: 0;
    bottom: 40px;
    z-index: 5;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 0;
    margin: 0;
    color: var(--color-white-100);
    font-weight: 500;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      text-align: left;
      word-break: break-word;
      hyphens: auto;
      overflow: hidden;
      line-height: 1.3;
    }
  }

  &.is-mobile {
    h3 {
      left: calc(72px + 16px);
    }
  }

  .gam-gambit-date {
    position: absolute;
    z-index: 6;
    padding-left: 8px;
    top: 0;
    left: 0;
    bottom: calc(32px + 8px);
    padding-bottom: 20px;
    padding-left: 16px;
  }

  .gam-gambit-location {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    mask-image: linear-gradient(rgb(0 0 0 / 100%), transparent);
  }

  .gambit-tags-wrapper {
    position: absolute;
    z-index: 5;
    bottom: 12px;
    left: 0;
    right: 0;
  }
}

.gambit-base-info {
  width: 100%;
}
</style>
