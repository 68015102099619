<template>
  <RouterLink :to="{ name: RouterNameType.PLAYER, params: { username: player.username } }" :class="['attendee-row']">
    <div class="info">
      <img v-if="player.profileImageBlobHash" class="picture" :src="getImageSrc()" />
      <div v-else class="picture missing">
        <GamIcon :name="GamIconName.PAWN" :size="GamIconSize.X_LARGE" />
      </div>

      <div class="username">@{{ player.username }}</div>
    </div>

    <div class="icons">
      <GamIcon v-if="isOrganizer" :name="GamIconName.CROWN" :size="GamIconSize.X_LARGE" is-primary />
      <div class="vibe">{{ player.emoji }}</div>
      <GamChip v-if="typeof player.rating === 'number'" :left-icon="GamIconName.G_CROWN" class="rating">
        {{ player.rating }}
      </GamChip>
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { AppEndpoint } from '@/core/network/api/constants/api.url.constant';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { RouterLink } from 'vue-router';
import GamChip from '../GamChip.vue';
import GamIcon from '../GamIcon.vue';

export interface AttendeeProps {
  player: BaseUserDto;
  isOrganizer?: boolean;
}

const props = defineProps<AttendeeProps>();

const getImageSrc = (): string => {
  const url = `${gambits.configService.getServiceRoutingHost()}${AppEndpoint.BLOB}`;
  return url.replace(new RegExp('{blobHash}', 'g'), props.player.profileImageBlobHash || '');
};
</script>

<style lang="scss" scoped>
.attendee-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  background-color: var(--color-white-5);
  border-radius: 56px !important;
  padding: 4px 16px 4px 4px;
  gap: 1rem;

  text-decoration: none;
  color: var(--color-white-100);

  &:hover {
    background-color: var(--color-white-10);
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  gap: 0.75rem;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-shrink: 1;
  gap: 0.75rem;
}

.picture {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  object-fit: cover;
  overflow: hidden;
  border-radius: 48px !important;

  &.missing {
    background: var(--color-linear-gradient);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.username {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  text-align: left;
  word-break: break-word;
  width: 100%;
}
</style>
