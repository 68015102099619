<template>
  <div class="list-header">
    <div class="tabs-row">
      <gam-button v-bind="getPlayButton" />

      <gam-tab v-bind="getTabItems" @update="onTabItemClick" />
    </div>

    <div v-if="false" class="filtering">
      <div class="filter-actions">
        <gam-switch v-bind="getSwitch" v-model:checked="switchValue" class="switch" />
        <gam-button v-bind="getFilterButton" />
        <gam-button v-bind="getSearchButton" />
      </div>

      <transition name="slide">
        <div v-if="showSearchInput" class="search-input">
          <gam-input-search v-bind="getSearchInput" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterNameType, TabItemType } from '@/core/data/config/uiConfig.interface';
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import type { GetPlayerTableFilters } from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useFilterStore } from '@/stores/filter.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamButton from '@/views/components/GamButton.vue';
import GamInputSearch from '@/views/components/GamInputSearch.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import GamTab from '@/views/components/gam-tab/GamTab.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { GamTabSize } from '@/views/composables/constants/components/gamTab.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputSearchType } from '@/views/composables/models/components/GamInput';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { GamTabType } from '@/views/composables/models/components/GamTab';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

const listId = GamListId.GAMBITS;
const tableStore = useTableStore<BaseGambitDto>(listId)();

const filterStore = useFilterStore<GetPlayerTableFilters>(GamListId.GAMBITS)();
const { listTabItems } = storeToRefs(filterStore);

const switchValue = ref<boolean>(false);
const showSearchInput = ref<boolean>(false);

const mapStore = useMapStore();
const { currentLocation } = storeToRefs(mapStore);
const geolocationStore = useGeolocationStore();
const { userLocation } = storeToRefs(geolocationStore);

onBeforeMount(() => {
  filterStore.setListTabItems(gambits.configService.getGambitsViewTabItems());
});

const authStore = useAuthStore();
const router = useRouter();

const onTabItemClick = (name: TabItemType) => {
  if (!authStore.isAuthenticated()) {
    router.push('/auth');
    return;
  }

  filterStore.setActiveTab(name);
  tableStore.loadItems({ selectFirstItem: !isMobile.value });
};

const getTabItems = computed((): GamTabType => {
  return {
    tabs: listTabItems.value,
    background: true,
    size: GamTabSize.SMALL,
  };
});

const getPlayButton = computed((): GamButtonType => {
  return {
    size: GamButtonSize.SMALL,
    variant: GamButtonVariant.PRIMARY,
    label: isMobile.value ? 'Create' : 'gambits.general.button.label',
    onClick: () => {
      if (!authStore.isAuthenticated()) {
        router.push('/auth');
        return;
      }

      router.push({
        name: RouterNameType.LOCATIONS,
        query: {
          lat: currentLocation.value.lat || userLocation.value?.location.lat || 0,
          lng: currentLocation.value.lng || userLocation.value?.location.lng || 0,
          zoom: 15,
          side_panel: 'add_gambit',
        },
      });
    },
  };
});

const getSwitch = computed((): GamSwitchType => {
  return {
    label: 'gambits.general.header.switch.needCoPlayer.label',
    name: '',
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.LEFT,
  };
});

const getSearchButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.SEARCH,
    isIconOnly: true,
    size: GamButtonSize.LARGE,
    variant: GamButtonVariant.TERTIARY,
    isIconWhite: true,
    onClick: () => {
      showSearchInput.value = !showSearchInput.value;
    },
  };
});

const getFilterButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.FILTER,
    isIconOnly: true,
    size: GamButtonSize.LARGE,
    variant: GamButtonVariant.TERTIARY,
    isIconWhite: true,
  };
});

const getSearchInput = computed((): GamInputSearchType<BaseUserDto> => {
  return {
    id: GamListId.GAMBITS,
    input: {
      name: GamInputName.SEARCH,
      placeholder: 'gambits.general.search.placeholder',
      leftIcon: GamIconName.SEARCH,
    },
    selectFirstItem: !isMobile.value,
  };
});
</script>

<style scoped lang="scss">
.list-header {
  .tabs-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: stretch;
    gap: var(--spacing-menu);

    .gam-button {
      flex-shrink: 0;
    }
  }

  .filtering {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-xs);
    transition: height var(--transition);
    position: relative;

    .filter-actions {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: var(--spacing-xs);
    }

    .search-input {
      width: 100%;
      top: 0;
      position: relative;
    }
  }
}
</style>
