<template>
  <div class="gam-rating-lichess">
    <div class="base-ratings">
      <div class="rating-row">
        <gam-input
          v-bind="getRatingInput(RatingValueType.RAPID, ratings.lichessComRapidRating)"
          v-model="ratings.lichessComRapidRating"
        />
        <gam-input
          v-bind="getRatingInput(RatingValueType.BLITZ, ratings.lichessComBlitzRating)"
          v-model="ratings.lichessComBlitzRating"
        />
      </div>
      <div class="rating-row">
        <gam-input
          v-bind="getRatingInput(RatingValueType.BULLET, ratings.lichessComBulletRating)"
          v-model="ratings.lichessComBulletRating"
        />
        <gam-input
          v-bind="getRatingInput(RatingValueType.CLASSICAL, ratings.lichessComClassicalRating)"
          v-model="ratings.lichessComClassicalRating"
        />
      </div>
    </div>
    <div class="connected-data">
      <div class="connected-item rating" :class="{ connected: ratingStore.isLichessConnected() }">
        <div class="label">
          <div>{{ localize('app.component.ratings.item.lichess.connected.fide') }}</div>
        </div>
        <gam-button v-if="!ratingStore.isLichessConnected()" v-bind="getConnectButton" />
        <div v-else class="connected-value">
          <gam-input v-bind="getRatingInput(RatingValueType.FIDE, ratings.fideRating)" v-model="ratings.fideRating" />
        </div>
      </div>
      <div class="connected-item" :class="{ connected: ratingStore.isLichessConnected() }">
        <div class="label">
          <div>{{ localize('app.component.ratings.item.lichess.connected.title') }}</div>
        </div>
        <gam-button v-if="!ratingStore.isLichessConnected()" v-bind="getConnectButton" />
        <div v-else class="connected-value">
          <div class="title">
            <span v-if="ratings.title">
              {{ localize(PlayerHelper.getPlayerTitleLocale(ratings.title, true)) }}
            </span>
            <gam-icon :name="GamIconName.MINUS" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RatingValueType } from '@/core/data/rating/rating.type';
import { localize } from '@/core/gambits';
import { useRatingStore } from '@/stores/rating.store';
import GamButton from '@/views/components/GamButton.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import GamInput from '@/views/components/GamInput.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamInputValueType } from '@/views/composables/constants/components/gamInput.constants';
import { GamRatingPlatform } from '@/views/composables/constants/main/ratings.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamInputType } from '@/views/composables/models/components/GamInput';
import type { RatingTypeData } from '@/views/composables/models/components/GamRatings';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import PlayerHelper from '../../../../core/helpers/player.helper';

const props = defineProps<RatingTypeData>();
const ratingStore = useRatingStore();
const { ratings } = storeToRefs(ratingStore);

const getRatingInput = (type: RatingValueType, value?: string | number): GamInputType => {
  return {
    name: `rating-${type}`,
    placeholder: localize(`app.component.ratings.input.${type.toString()}`),
    value,
    type: GamInputValueType.NUMBER,
    readOnly: true,
    canClear: true,
    isDisabled: !value,
  };
};

const getConnectButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    label: 'app.component.ratings.item.lichess.connect',
    isLoading: props.rating.isLoading,
    onClick: () => {
      props.rating.connect?.(GamRatingPlatform.LICHESS);
    },
  };
});
</script>

<style scoped lang="scss">
@use '@/ui/css/partial';

.gam-rating-lichess {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-md);

  .base-ratings {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    .rating-row {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
    }
  }

  .connected-data {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l);
    padding: var(--spacing-md) 0;

    .connected-item {
      display: flex;
      align-items: center;
      gap: var(--spacing-md);
      align-self: stretch;
      flex: 1 0 0;

      &.connected {
        &.rating {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .label {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        flex-grow: 1;
        min-height: 34px;
        align-items: center;
      }

      .connected-value {
        width: 100%;

        .title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-align: right;
          justify-content: flex-end;
          display: flex;

          span {
            background: var(--color-linear-gradient);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px;
            font-weight: 700;
            line-height: 110%; /* 30.8px */
          }
        }
      }
    }
  }
}
</style>
