<template>
  <div disabled-v-on-click-outside="closeDropdown" class="gam-input-search">
    <gam-input
      ref="searchInput"
      v-bind="getSearchInput"
      v-model="searchInputValue"
      @clear="clearInput"
      @close="closeDropdown"
    />
    <gam-dropdown v-if="getDropdown && isDropdownOpened" v-bind="getDropdown" />
  </div>
</template>

<script setup lang="ts" generic="T extends BaseUserDto | GamDropdownItem | SimpleTableRow">
import type { SearchLocationDto } from '@/core/data/location/location.interface';
import type { BaseUserDto } from '@/core/data/user/user.interface';
import { localize } from '@/core/gambits';
import { useFilterStore } from '@/stores/filter.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamDropdown from '@/views/components/GamDropdown.vue';
import GamInput from '@/views/components/GamInput.vue';
import { GamDropdownCategory } from '@/views/composables/constants/components/gamDropdown.constants';
import { GamInputName } from '@/views/composables/constants/components/gamInput.constants';
import type { GamDropdownItem, GamDropdownType } from '@/views/composables/models/components/GamDropdown';
import type { GamInputSearchType, GamInputType } from '@/views/composables/models/components/GamInput';
import type { SimpleTableRow } from '@/views/composables/models/components/SimpleTable';
import { whenever } from '@vueuse/core';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeMount, onUnmounted, type Ref, ref, watch } from 'vue';
import type { GamListId } from '../composables/constants/components/gamIntersect.constants';

const props = defineProps<GamInputSearchType<T>>();

const tableStore = useTableStore<T>(props.id as GamListId)();
const { selectedItem, tableParams } = storeToRefs(tableStore);

const mapStore = useMapStore();
const { newLocation } = storeToRefs(mapStore);

const filterStore = useFilterStore(props.id as GamListId)();

const searchInput = ref();
const searchInputValue = ref<string | number>();
const loadedOnInit = ref<boolean>(false);
const tempSelectedItem = ref<T | null>(null) as Ref<T | null>;

onBeforeMount(async () => {
  searchInputValue.value = tableParams.value.search;
  setDefaultItem();
  if (props.loadCallback) {
    tableStore.setLoadCallback(props.loadCallback);

    if (searchInputValue.value && props.loadOnInit) {
      await initLoad();
    }
  }
});

const initLoad = async () => {
  setInitParams();
  await tableStore.loadItems({ loadOnInit: true });
  loadedOnInit.value = true;
};

const setInitParams = () => {
  if (props.loadOnInit && props.initParams && !isSearchEntered()) {
    filterStore.setFilters(props.initParams);
  } else {
    filterStore.setFilters();
  }
};

const isSearchEntered = (): boolean => {
  const inputValue = searchInputValue.value?.toString() || '';
  return inputValue.length > 0 && !isDefaultValue();
};

const setDefaultItem = (): void => {
  if (props.dropdown?.selected) {
    tableStore.setSelectedItem(props.dropdown.selected as T, 0);
  } else if (props.dropdown?.defaultItem) {
    tableStore.setSelectedItem(props.dropdown.defaultItem as T, -1);
  } else if (tempSelectedItem.value) {
    tableStore.setSelectedItem(tempSelectedItem.value as T, 0);
    tempSelectedItem.value = null;
  }
  setSelectedValue();
  if (!isDefaultValue()) {
    tableStore.setSearchQuery({ query: searchInputValue.value?.toString(), preventLoad: true });
  }
};

const isDefaultSelected = (): boolean => {
  return !!props.dropdown?.defaultItem && selectedItem.value?.id === props.dropdown?.defaultItem?.id;
};

const isDefaultValue = (): boolean => {
  const isLength: boolean = !!searchInputValue.value && searchInputValue.value.toString().length > 1;
  return isLength && searchInputValue.value === localize(props.dropdown?.defaultItem?.label || '');
};

const setSelectedValue = () => {
  if (!selectedItem.value) return;
  if (props.dropdown?.category === GamDropdownCategory.PLAYERS) {
    const player = selectedItem.value as BaseUserDto;
    searchInputValue.value = player.username;
  } else {
    const item = selectedItem.value as GamDropdownItem;
    searchInputValue.value = localize(item.label);
  }
  closeDropdown();
};

const closeDropdown = () => {
  if (searchInput.value && props.dropdown) {
    searchInput.value.isActive = false;
  }
};

const clearInput = () => {
  loadedOnInit.value = true;
  if (props.input.canClear) {
    tableStore.setSelectedItem(null, 0);
    tempSelectedItem.value = null;
  }
};

const isSearchInputActive = computed((): boolean => {
  return searchInput.value?.isActive;
});

const isDropdownOpened = computed((): boolean => {
  const isInputActive = isSearchInputActive.value;
  return !!props.dropdown && ((isInputActive && isSearchEntered()) || (props.loadOnInit && isInputActive));
});

const getSearchInput = computed((): GamInputType => {
  return {
    ...props.input,
    name: GamInputName.SEARCH,
    value: searchInputValue.value,
    isSearch: true,
    isDropdownOpened: props.dropdown && isDropdownOpened.value,
    flagIcon: selectedItem.value && 'flagIcon' in selectedItem.value ? selectedItem.value.flagIcon : undefined,
  };
});

const getDropdown = computed((): GamDropdownType | undefined => {
  if (!props.dropdown) return;
  return {
    ...props.dropdown,
    opened: isDropdownOpened.value,
  };
});

whenever(selectedItem, (selected) => {
  if (props.dropdown && selected) {
    nextTick(() => {
      tempSelectedItem.value = selectedItem.value;
      searchInputValue.value = tableParams.value.search;
      setSelectedValue();
    });
  }
});

watch(isDropdownOpened, async (opened: boolean) => {
  if (props.dropdown) {
    if (opened) {
      await nextTick(async () => {
        if (props.loadOnInit && !loadedOnInit.value) {
          await initLoad();
        } else {
          tempSelectedItem.value = selectedItem.value;
          tableStore.setLoaded();
          loadedOnInit.value = true;
        }
      });
    } else if (loadedOnInit.value) {
      if (selectedItem.value) {
        if (isDefaultSelected()) {
          await tableStore.setSearchQuery({ preventLoad: true });
          searchInputValue.value = localize(props.dropdown?.defaultItem?.label || '');
        } else if (props.dropdown?.category === GamDropdownCategory.DEFAULT) {
          await tableStore.setSearchQuery({ query: '', preventLoad: true });
        } else {
          const item = selectedItem.value as GamDropdownItem;
          await tableStore.setSearchQuery({
            query: item?.label,
            preventLoad: false,
          });
          setSelectedValue();
        }
      } else {
        setDefaultItem();
      }
      loadedOnInit.value = !!props.input.readOnly;
    }
  }
});

watch(
  searchInputValue,
  debounce(async (query) => {
    if (props.dropdown) {
      if (props.dropdown?.category === GamDropdownCategory.PLAYERS) {
        const player = selectedItem.value as BaseUserDto;
        if (searchInputValue.value !== player?.username) {
          await tableStore.setSearchQuery({ query });
        }
      } else if (!isDefaultValue()) {
        if (isDropdownOpened.value && loadedOnInit.value) {
          setInitParams();
          await tableStore.setSearchQuery({ query, preventLoad: false, loadOnInit: true });
        }
      }
    } else {
      await tableStore.setSearchQuery({ query });
    }
  }, 400),
);

watch(newLocation, async (value?: SearchLocationDto) => {
  if (value?.coordinates) {
    searchInputValue.value = value.address;
  }
});

watch(
  () => searchInput.value?.isActive,
  (value) => {
    if (!value && props.dropdown) {
      setDefaultItem();
    }
  },
);

onUnmounted(async () => {
  tempSelectedItem.value = null;
});
</script>

<style scoped lang="scss">
.gam-input-search {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
}
</style>
