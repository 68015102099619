<template>
  <ScrollOverflow class="scroll-container" is-vertical>
    <div :class="['main-view profile-view', { 'is-mobile': isMobile }]">
      <my-profile />
      <profile-links />
    </div>
  </ScrollOverflow>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import MyProfile from '@/views/main/profile/MyProfile.vue';
import ProfileLinks from '@/views/main/profile/ProfileLinks.vue';
import { onMounted, watch } from 'vue';

const authStore = useAuthStore();

onMounted(async () => {
  await authStore.fetchUserInfo(true);
});

watch(
  () => authStore.isAuthenticated(),
  (isLoggedIn) => {
    if (!isLoggedIn) return;
    authStore.fetchUserInfo(true);
  },
);
</script>

<style scoped lang="scss">
.scroll-container.is-mobile {
  height: calc(100svh - 68px);
}

.profile-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: var(--spacing-xxl) 0;

  &.is-mobile {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-mobile-wrapper);
    gap: var(--spacing-menu);
  }

  &:not(.is-mobile) {
    .profile-links {
      margin-top: 4rem;
    }
  }
}
</style>
