import type { UpdateProfileFormData, UserImagesFormData } from '@/core/data/auth/auth.interface';
import type { BaseClubDto } from '@/core/data/club/club.interface';
import type { GeoLocation } from '@/core/data/location/location.interface';
import type { BaseUserDto, BaseUserInfo, UserDto, ValidateUserDto } from '@/core/data/user/user.interface';
import { baseUserFields, baseUserInfoFields, detailUserFields } from '@/core/network/api/constants/api.fields.constant';
import { CloudflareEndpoint, NotificationEndpoint, UserEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse, GetPlayerTableFilters, GetTableParams } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { NotificationTopics } from '@/views/composables/constants/gam.constants';
import type { AxiosResponse } from 'axios';
export class UserApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getUsersParams(id: GamListId, location?: GeoLocation): GetPlayerTableFilters {
    const tableStore = useTableStore<UserDto>(id)();
    const filterStore = useFilterStore<GetPlayerTableFilters>(id)();

    return {
      ...tableStore.tableParams,
      ...filterStore.getFilters(),
      fields: baseUserFields.join(','),
      relationship: filterStore.getActiveTab(),
      latitude: location?.lat || tableStore.getLocationParam()?.latitude,
      longitude: location?.lng || tableStore.getLocationParam()?.longitude,
    };
  }

  async getLocation(): Promise<AxiosResponse<GamResponse<any>>> {
    const endpoint = process.env.NODE_ENV === 'production' ? CloudflareEndpoint.LOCATE : UserEndpoint.GET_USER_LOCATION;
    return this.httpClient.get<any>(endpoint);
  }

  async updateLocation(location: GeoLocation): Promise<AxiosResponse<GamResponse<GeoLocation>>> {
    return await this.httpClient.put(UserEndpoint.UPDATE_LOCATION, {
      data: { location },
    });
  }

  async getUsers(listId: GamListId, location?: GeoLocation): Promise<AxiosResponse<GamResponse<BaseUserDto[]>>> {
    return this.httpClient.get<BaseUserDto[], GetPlayerTableFilters>(UserEndpoint.USERS, {
      config: {
        params: this.getUsersParams(listId, location),
      },
    });
  }

  async getUser(id: string): Promise<AxiosResponse<GamResponse<UserDto>>> {
    return this.httpClient.get<UserDto, GetTableParams>(UserEndpoint.GET_USER_DETAILS, {
      urlParams: {
        id,
      },
      config: {
        params: {
          fields: detailUserFields.join(','),
        },
      },
    });
  }

  async getUserByName(username: string): Promise<AxiosResponse<GamResponse<UserDto>>> {
    return this.httpClient.get<UserDto, GetTableParams>(UserEndpoint.GET_USER_DETAILS_BY_NAME, {
      config: {
        params: {
          fields: detailUserFields.join(','),
        },
      },
      urlParams: { username },
    });
  }

  async getUserInfo(): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return this.httpClient.get<BaseUserInfo>(UserEndpoint.GET_USER_INFO, {
      config: {
        params: {
          fields: baseUserInfoFields.join(','),
        },
      },
    });
  }

  async getUserClubs(): Promise<AxiosResponse<GamResponse<BaseClubDto[]>>> {
    return this.httpClient.get<BaseClubDto[]>(UserEndpoint.GET_USER_CLUBS, {
      config: {
        params: {
          fields: baseUserInfoFields.join(','),
        },
      },
    });
  }

  async updateProfile(profile: UpdateProfileFormData): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return await this.httpClient.put(UserEndpoint.USERS, {
      data: profile,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async updateImages(profile: UserImagesFormData): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return await this.httpClient.put(UserEndpoint.UPDATE_IMAGES, {
      data: profile,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async validateUsername(username: string): Promise<AxiosResponse<GamResponse<ValidateUserDto>>> {
    return this.httpClient.get<ValidateUserDto>(UserEndpoint.VALIDATE_USERNAME, {
      config: {
        params: {
          username,
        },
      },
    });
  }

  async storeNotificationToken(
    token: string,
    topics: NotificationTopics[] = [],
  ): Promise<AxiosResponse<GamResponse<boolean>>> {
    return await this.httpClient.put(NotificationEndpoint.NOTIFICATION_TOKEN, {
      data: { token, enabledNotifications: topics },
    });
  }

  async toggleFollow(id: string, isFollowing?: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.post(isFollowing ? UserEndpoint.UNFOLLOW_USER : UserEndpoint.FOLLOW_USER, {
      urlParams: { id },
    });
  }

  async toggleBlock(id: string, isBlocked?: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.post(isBlocked ? UserEndpoint.UNBLOCK_USER : UserEndpoint.BLOCK_USER, {
      urlParams: { id },
    });
  }
}
