import type {
  AddLocationCommentData,
  AddLocationFormData,
  BaseLocationDto,
  CommentDto,
  DetailLocationDto,
  GeoLocation,
  GooglePlaceQuery,
  GooglePlaceResultDto,
  ImageDto,
  MapLocationDto,
  SearchLocationDto,
  SearchLocationOptions,
} from '@/core/data/location/location.interface';
import {
  baseLocationFields,
  detailsLocationCommentsFields,
  detailsLocationFields,
  detailsLocationImagesFields,
  publicLocationFields,
} from '@/core/network/api/constants/api.fields.constant';
import { LocationEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type {
  GamResponse,
  GetLocationTableFilters,
  GetTableParams,
  SearchLocationParams,
} from '@/core/network/http/httpClient.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useFilterStore } from '@/stores/filter.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterSortLocation } from '@/views/composables/constants/main/filter.constants';
import type { AxiosResponse } from 'axios';
import { storeToRefs } from 'pinia';

export class LocationApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getLocationsParams(id: GamListId, geo: GeoLocation): GetLocationTableFilters {
    const tableStore = useTableStore(id)();
    const filterStore = useFilterStore<GetLocationTableFilters>(GamListId.LOCATIONS)();
    const { currentRadius } = storeToRefs(useMapStore());
    const { userLocation } = storeToRefs(useGeolocationStore());

    const userLatitude = geo.lat || userLocation.value?.location.lat;
    const userLongitude = geo.lng || userLocation.value?.location.lng;

    return {
      ...tableStore.tableParams,
      ...tableStore.getLocationParam(),
      ...filterStore.getFilters(),
      latitude: userLatitude || tableStore.getLocationParam()?.latitude || filterStore.getFilters()?.latitude,
      longitude: userLongitude || tableStore.getLocationParam()?.longitude || filterStore.getFilters()?.longitude,
      userLatitude,
      userLongitude,
      radius: currentRadius.value, // we store radius in m
    };
  }

  private getLocationsFields() {
    const { user } = storeToRefs(useAuthStore());

    return user.value ? baseLocationFields.join(',') : publicLocationFields.join(',');
  }

  private getSearchLocationParams(id: GamListId, geo: GeoLocation): SearchLocationParams {
    // const geolocationStore = useGeolocationStore();
    const tableStore = useTableStore<BaseLocationDto>(id)();

    const query = String(tableStore.tableParams.search || '');
    const hasTwoValues = query.split(',').length === 2;
    const firstValue = parseFloat(query.split(',')?.[0]);
    const secondValue = parseFloat(query.split(',')?.[1]);

    if (hasTwoValues && firstValue > 0 && secondValue > 0) {
      return {
        latitude: firstValue,
        longitude: secondValue,
        mapbox: true,
        internal: true,
      };
    } else {
      return {
        query: tableStore.tableParams.search || '',
        // TODO: This was always geo, because we didn't access userLocation.value (ref)
        // latitude: geolocationStore.userLocation?.location.lat || geo.lat,
        // longitude: geolocationStore.userLocation?.location.lng || geo.lng,
        latitude: geo.lat,
        longitude: geo.lng,
        mapbox: true,
        internal: true,
      };
    }
  }

  async getLocations(
    listId: GamListId,
    geo: GeoLocation,
    includeTopLocation?: boolean,
  ): Promise<AxiosResponse<GamResponse<BaseLocationDto[]>>> {
    return this.httpClient.get<BaseLocationDto[], GetLocationTableFilters>(LocationEndpoint.GET_LOCATIONS, {
      config: {
        params: {
          ...this.getLocationsParams(listId, geo),
          sortBy: GamFilterSortLocation.DISTANCE,
          fields: this.getLocationsFields(),
          includeTopLocation,
        },
      },
    });
  }

  async getMapLocations(listId: GamListId, geo: GeoLocation): Promise<AxiosResponse<GamResponse<MapLocationDto[]>>> {
    return this.httpClient.get<MapLocationDto[], GetLocationTableFilters>(LocationEndpoint.GET_LOCATIONS_MAP, {
      config: {
        params: this.getLocationsParams(listId, geo),
      },
    });
  }

  async searchLocations(listId: GamListId, geo: GeoLocation): Promise<AxiosResponse<GamResponse<SearchLocationDto[]>>> {
    return this.httpClient.get<SearchLocationDto[], SearchLocationParams | GeoLocation>(
      LocationEndpoint.GET_LOCATIONS_SEARCH,
      {
        config: {
          params: this.getSearchLocationParams(listId, geo),
        },
      },
    );
  }

  async fetchGooglePlaceDetails(data: GooglePlaceQuery): Promise<AxiosResponse<GamResponse<GooglePlaceResultDto>>> {
    return await this.httpClient.post(LocationEndpoint.GOOGLE_PLACE_DETAILS, {
      data,
    });
  }

  async reverseGeocode(latitude: number, longitude: number): Promise<AxiosResponse<GamResponse<SearchLocationDto>>> {
    return await this.httpClient.get(LocationEndpoint.GOOGLE_REVERSE_GEOCODE, {
      config: {
        params: {
          latitude,
          longitude,
        },
      },
    });
  }

  // TODO: Remove fallback location
  async searchLocationsByQuery(
    query?: string,
    options?: SearchLocationOptions,
  ): Promise<AxiosResponse<GamResponse<SearchLocationDto[]>>> {
    const mapStore = useMapStore();

    return this.httpClient.get<SearchLocationDto[], SearchLocationParams>(LocationEndpoint.AUTOCOMPLETE_LOCATIONS, {
      config: {
        params: {
          query,
          internal: options?.internal,
          mapbox: options?.mapbox,
          latitude: options?.geo?.lat || mapStore.currentLocation?.lat,
          longitude: options?.geo?.lng || mapStore.currentLocation?.lng,
        },
      },
    });
  }

  async searchLocationsByCoords(lat: number, lng: number): Promise<AxiosResponse<GamResponse<SearchLocationDto[]>>> {
    return this.httpClient.get<SearchLocationDto[], SearchLocationParams>(LocationEndpoint.GET_LOCATIONS_SEARCH, {
      config: {
        params: {
          mapbox: true,
          latitude: lat,
          longitude: lng,
        },
      },
    });
  }

  async getLocationPlace(mapBoxId: string): Promise<AxiosResponse<GamResponse<GeoLocation>>> {
    return this.httpClient.get<GeoLocation>(LocationEndpoint.GET_LOCATION_PLACE, {
      urlParams: { id: mapBoxId },
    });
  }

  async getLocation(id: string): Promise<AxiosResponse<GamResponse<DetailLocationDto>>> {
    return this.httpClient.get<DetailLocationDto, GetTableParams>(LocationEndpoint.GET_LOCATION_DETAILS, {
      config: {
        params: {
          fields: detailsLocationFields.join(','),
        },
      },
      urlParams: { id },
    });
  }

  async getLocationImages(id: string): Promise<AxiosResponse<GamResponse<ImageDto[]>>> {
    return this.httpClient.get<ImageDto[], GetTableParams>(LocationEndpoint.LOCATION_IMAGES, {
      urlParams: { id },
      config: {
        params: {
          fields: detailsLocationImagesFields.join(','),
        },
      },
    });
  }

  async getLocationComments(id: string): Promise<AxiosResponse<GamResponse<CommentDto[]>>> {
    return this.httpClient.get<CommentDto[], GetTableParams>(LocationEndpoint.LOCATION_COMMENTS, {
      urlParams: { id },
      config: {
        params: {
          fields: detailsLocationCommentsFields.join(','),
        },
      },
    });
  }

  async addLocation(data: AddLocationFormData): Promise<AxiosResponse<GamResponse<BaseLocationDto>>> {
    return await this.httpClient.post(LocationEndpoint.GET_LOCATIONS, {
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  // async updateLocation(data: AddLocationFormData): Promise<AxiosResponse<GamResponse<BaseLocationDto>>> {
  //   return await this.httpClient.put(LocationEndpoint.GET_LOCATIONS, {
  //     data,
  //     config: {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     },
  //   });
  // }

  async toggleFavorite(id: string, favorite?: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    if (favorite) {
      return await this.httpClient.post(LocationEndpoint.LOCATION_FAVORITES, {
        urlParams: { id },
      });
    } else {
      return await this.httpClient.delete(LocationEndpoint.LOCATION_FAVORITES, {
        urlParams: { id },
      });
    }
  }

  async toggleImageLike(id: string, like: boolean): Promise<AxiosResponse<GamResponse<void>>> {
    if (like) {
      return await this.httpClient.post(LocationEndpoint.LOCATION_IMAGES_LIKE, {
        urlParams: { id },
      });
    } else {
      return await this.httpClient.delete(LocationEndpoint.LOCATION_IMAGES_LIKE, {
        urlParams: { id },
      });
    }
  }

  async deleteLocationImage(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(LocationEndpoint.LOCATION_IMAGE, {
      urlParams: { id },
    });
  }

  async addPhoto(id: string, photo?: File | string | null): Promise<AxiosResponse<GamResponse<ImageDto>>> {
    return await this.httpClient.post(LocationEndpoint.LOCATION_IMAGES, {
      urlParams: { id },
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      data: {
        image: photo,
      },
    });
  }

  async addComment(id: string, data: AddLocationCommentData): Promise<AxiosResponse<GamResponse<CommentDto>>> {
    return await this.httpClient.post(LocationEndpoint.LOCATION_COMMENTS, {
      urlParams: { id },
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async deleteComment(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(LocationEndpoint.LOCATION_COMMENT_DELETE, {
      urlParams: { id },
    });
  }

  // async getUserLocation(apiKey: string): Promise<AxiosResponse<GamResponse<UserGeoData>>> {
  //   return this.httpClient.post<UserGeoData, GetTableParams>(this.getGeoLocationUrl.replace(/%s/g, apiKey));
  // }

  async checkIn(id: string): Promise<AxiosResponse<GamResponse<any>>> {
    const url = `${LocationEndpoint.CHECK_IN}`;

    return await this.httpClient.post(url, {
      urlParams: { id },
    });
  }

  async checkOut(id: string): Promise<AxiosResponse<GamResponse<any>>> {
    const url = `${LocationEndpoint.CHECK_IN}`;

    return await this.httpClient.delete(url, {
      urlParams: { id },
    });
  }
}
