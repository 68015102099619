import type { BaseGambitDto, GambitFormData, GambitRecurrenceOptions } from '@/core/data/gambit/gambit.interface';
import type { GeoLocation } from '@/core/data/location/location.interface';
import type { UserDto } from '@/core/data/user/user.interface';
import { baseGambitFields, detailGambitFields } from '@/core/network/api/constants/api.fields.constant';
import { GambitEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse, GetGambitTableFilters, GetTableParams } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterSortGambits } from '@/views/composables/constants/main/filter.constants';
import type { GambitDetailDto } from '@/views/composables/models/components/GamGambit';
import type { AxiosResponse } from 'axios';

export class GambitApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getGambitsParams(id: GamListId): GetGambitTableFilters {
    const store = useTableStore<UserDto>(id)();
    const gambitFilters = useFilterStore(id)();

    const activeTab = gambitFilters.getActiveTab();

    return {
      ...store.tableParams,
      // ...store.getLocationParam(),
      myGambits: activeTab === 'mine',
      fields: baseGambitFields.join(','),
      sortBy: GamFilterSortGambits.GAMBIT_TIME,
    };
  }

  async addGambit(data: GambitFormData): Promise<AxiosResponse<GamResponse<BaseGambitDto>>> {
    return await this.httpClient.post(GambitEndpoint.GET_GAMBITS, {
      data,
    });
  }

  async updateGambit(
    id: string,
    data: GambitFormData,
    options?: GambitRecurrenceOptions,
  ): Promise<AxiosResponse<GamResponse<BaseGambitDto>>> {
    let url;
    if (options?.recurrences) {
      url = `${GambitEndpoint.GET_GAMBIT_DETAILS}?recurrences=true`;
    } else {
      url = GambitEndpoint.GET_GAMBIT_DETAILS;
    }

    return await this.httpClient.put(url, {
      urlParams: { id },
      data,
    });
  }

  async deleteGambit(
    id: string,
    options?: GambitRecurrenceOptions,
  ): Promise<AxiosResponse<GamResponse<BaseGambitDto>>> {
    let url;
    if (options?.recurrences) {
      url = `${GambitEndpoint.GET_GAMBIT_DETAILS}?recurrences=true`;
    } else {
      url = GambitEndpoint.GET_GAMBIT_DETAILS;
    }

    return await this.httpClient.delete(url, {
      urlParams: { id },
    });
  }

  async createAttendance(id: string): Promise<AxiosResponse<GamResponse<BaseGambitDto>>> {
    const url = `${GambitEndpoint.GET_GAMBIT_DETAILS}/attendance`;

    return await this.httpClient.post(url, {
      urlParams: { id },
    });
  }

  async deleteAttendance(id: string): Promise<AxiosResponse<GamResponse<BaseGambitDto>>> {
    const url = `${GambitEndpoint.GET_GAMBIT_DETAILS}/attendance`;

    return await this.httpClient.delete(url, {
      urlParams: { id },
    });
  }

  async getGambits(listId: GamListId, location: GeoLocation): Promise<AxiosResponse<GamResponse<BaseGambitDto[]>>> {
    return this.httpClient.get<BaseGambitDto[], GetGambitTableFilters>(GambitEndpoint.GET_GAMBITS, {
      config: {
        params: { ...this.getGambitsParams(listId), ...{ latitude: location.lat, longitude: location.lng } },
      },
    });
  }

  async getGambit(id: string): Promise<AxiosResponse<GamResponse<GambitDetailDto>>> {
    return this.httpClient.get<GambitDetailDto, GetTableParams>(GambitEndpoint.GET_GAMBIT_DETAILS, {
      urlParams: { id },
      config: {
        params: {
          fields: detailGambitFields.join(','),
        },
      },
    });
  }
}
