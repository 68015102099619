import type { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import type { LocationGambitDto } from '@/core/data/gambit/gambit.interface';
import type {
  AddLocationCommentData,
  AddLocationFormData,
  BaseLocationDto,
  CommentDto,
  DetailLocationDto,
  GamPinObject,
  GeoLocation,
  GooglePlaceQuery,
  GooglePlaceResultDto,
  ImageDto,
  MapClusterData,
  MapLocationDto,
  SearchLocationDto,
  SearchLocationOptions,
} from '@/core/data/location/location.interface';
import { MapLocationType } from '@/core/data/location/location.type';
import type { StorageRepository } from '@/core/data/storage/storage.repository';
import type { LocalizationService } from '@/core/domain/localization.service';
import GambitHelper from '@/core/helpers/gambit.helper';
import LocationHelper from '@/core/helpers/location.helper';
import { createLogger } from '@/core/helpers/logger.helper';
import { LocationApi } from '@/core/network/api/location/location.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HttpError } from '@/core/network/http/httpError';
import router from '@/router';
import { useMapStore } from '@/stores/map.store';
import { GamDateType } from '@/views/composables/constants/components/gamGambit.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { RouterNameType } from '../config/uiConfig.interface';
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

const logger = createLogger('LocationRepository');

export class LocationRepository {
  private readonly httpAPI: LocationApi;
  private readonly storage: StorageRepository;
  private readonly config: ClientConfigRepository;
  private readonly localizationService: LocalizationService;
  private pinElements: { [key: string]: GamPinObject } = {};

  constructor(
    httpAPI: LocationApi,
    storage: StorageRepository,
    config: ClientConfigRepository,
    localizationService: LocalizationService,
  ) {
    this.httpAPI = httpAPI;
    this.storage = storage;
    this.config = config;
    this.localizationService = localizationService;
  }

  async getLocations(
    listId: GamListId,
    location: GeoLocation,
    includeTopLocation?: boolean,
  ): Promise<GamResponse<BaseLocationDto[]> | null> {
    try {
      const response = await this.httpAPI.getLocations(listId, location, includeTopLocation);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getMapLocations(listId: GamListId, center: GeoLocation): Promise<GamResponse<MapLocationDto[]> | null> {
    try {
      const response = await this.httpAPI.getMapLocations(listId, center);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async searchLocations(listId: GamListId): Promise<GamResponse<SearchLocationDto[]> | null> {
    try {
      const response = await this.httpAPI.searchLocations(listId, useMapStore().currentLocation);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async searchLocationsByQuery(
    query?: string,
    options?: SearchLocationOptions,
  ): Promise<GamResponse<SearchLocationDto[]> | null> {
    try {
      const response = await this.httpAPI.searchLocationsByQuery(query, options);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async fetchGooglePlaceDetails(data: GooglePlaceQuery): Promise<GamResponse<GooglePlaceResultDto> | null> {
    try {
      const response = await this.httpAPI.fetchGooglePlaceDetails(data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async reverseGeocode(lat: number, lng: number): Promise<GamResponse<SearchLocationDto> | null> {
    try {
      const response = await this.httpAPI.reverseGeocode(lat, lng);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async searchLocationsByCoords(lat: number, lng: number): Promise<GamResponse<SearchLocationDto[]> | null> {
    try {
      const response = await this.httpAPI.searchLocationsByCoords(lat, lng);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getLocationPlace(mapBoxId: string): Promise<GamResponse<GeoLocation> | null> {
    try {
      const response = await this.httpAPI.getLocationPlace(mapBoxId);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getLocation(id: string): Promise<GamResponse<DetailLocationDto> | null> {
    try {
      const response = await this.httpAPI.getLocation(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getLocationImages(id: string): Promise<GamResponse<ImageDto[]> | null> {
    try {
      const response = await this.httpAPI.getLocationImages(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getLocationComments(id: string): Promise<GamResponse<CommentDto[]> | null> {
    try {
      const response = await this.httpAPI.getLocationComments(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async addNewLocation(data: AddLocationFormData): Promise<GamResponse<BaseLocationDto> | null> {
    try {
      const response = await this.httpAPI.addLocation(data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  // async updateLocation(data: AddLocationFormData): Promise<GamResponse<BaseLocationDto> | null> {
  //   try {
  //     const response = await this.httpAPI.updateLocation(data);
  //     return response.data?.data ? { data: response.data.data } : null;
  //   } catch (e) {
  //     const httpError = e as HttpError;
  //     logger.error(`${httpError.error.code} => ${httpError.error.message}`);
  //     throw httpError.error;
  //   }
  // }

  async addNewComment(id: string, data: AddLocationCommentData): Promise<GamResponse<CommentDto> | null> {
    try {
      const response = await this.httpAPI.addComment(id, data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async deleteComment(id: string): Promise<boolean> {
    try {
      const response = await this.httpAPI.deleteComment(id);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async toggleFavorite(id: string, favorite?: boolean): Promise<boolean> {
    try {
      const response = await this.httpAPI.toggleFavorite(id, favorite);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async toggleImageLike(id: string, like: boolean): Promise<boolean> {
    try {
      const response = await this.httpAPI.toggleImageLike(id, like);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async addPhoto(id: string, photo?: File | null | string): Promise<GamResponse<ImageDto> | null> {
    try {
      const response = await this.httpAPI.addPhoto(id, photo);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async deleteLocationImage(id: string): Promise<boolean> {
    try {
      const response = await this.httpAPI.deleteLocationImage(id);
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  getClusterAlgorithm(): SuperClusterAlgorithm {
    return new SuperClusterAlgorithm(this.config.parseClusterConfig());
  }

  getClusterPin(count: number): HTMLDivElement {
    const clusterPinWrapper: HTMLDivElement = document.createElement('div');
    clusterPinWrapper.classList.add('gam-pin-cluster-wrapper');
    clusterPinWrapper.innerHTML = `<div class="cluster-count">${count.toString()}</div>`;
    return clusterPinWrapper;
  }

  // @ts-ignore
  private markerElementClass: typeof AdvancedMarkerElement;

  async getBaseMarkers(data: MapClusterData): Promise<AdvancedMarkerElement[]> {
    if (!data.items) return [];
    if (!this.markerElementClass) {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      this.markerElementClass = AdvancedMarkerElement;
    }

    const locationId = String(router.currentRoute.value.query.location);

    return data.items.map((item) => {
      const pinObject = this.getLocationPin(item, locationId);
      this.pinElements[item.id] = pinObject;
      const marker = new this.markerElementClass({
        position: pinObject.coordinates,
        content: pinObject.element,
      });

      marker.id = item.id;

      if (typeof data.onClickMarker === 'function') {
        marker.addListener('gmp-click', () => data.onClickMarker?.(item));
      }

      return marker;
    });
  }

  getSelectedPin(id: string): GamPinObject {
    return this.pinElements[id];
  }

  updateSelectedPin(location: MapLocationDto, selected?: string): GamPinObject {
    this.pinElements[location.id] = this.getLocationPin(location, selected);
    return this.pinElements[location.id];
  }

  clearMapPins(): void {
    this.pinElements = {};
  }

  // TODO: Move to gambit-related module
  private isGambitLive(gambit?: LocationGambitDto | null): boolean {
    if (gambit) {
      const dateType = GambitHelper.getGambitsDateType(gambit.start, false, gambit.end);
      return dateType === GamDateType.NOW;
    }
    return false;
  }

  private getLocationPin(item: MapLocationDto, selected?: string | null): GamPinObject {
    const gamMapPin: HTMLDivElement = document.createElement('div');
    gamMapPin.id = `pin-${item.id}`;
    gamMapPin.classList.add('gam-map-pin', LocationHelper.getPinType(item.type, item.gambit));

    if (selected === item.id) {
      gamMapPin.classList.add('selected');
    }

    const gamMapPinContainer: HTMLDivElement = document.createElement('div');
    gamMapPinContainer.classList.add('gam-map-pin-container');

    if (item.type === 'gambit' && item.gambit) {
      gamMapPinContainer.classList.add('gam-map-pin-clickable');
      gamMapPinContainer.onclick = () => {
        router.push({ name: RouterNameType.GAMBIT, params: { id: gamMapPin.id } });
      };
    }

    const pinContent: HTMLDivElement = document.createElement('div');
    pinContent.classList.add('content');

    if (item.type === MapLocationType.LOCATION) {
      const pinContentIcon: HTMLDivElement = document.createElement('div');
      pinContentIcon.classList.add('icon-pin');
      if (item.private) pinContentIcon.classList.add('private');
      else if (item.favorite) pinContentIcon.classList.add('favorite');
      else pinContentIcon.classList.add(LocationHelper.getLocationTypeIcon(item.locationTypes));

      pinContent.appendChild(pinContentIcon);
    } else {
      const pin: HTMLDivElement = document.createElement('div');
      pin.classList.add('icon-pin');
      pin.classList.add('gambit');
      pinContent.appendChild(pin);
    }

    gamMapPinContainer.appendChild(pinContent);
    gamMapPin.appendChild(gamMapPinContainer);

    if (this.isGambitLive(item.gambit)) {
      const liveContent: HTMLDivElement = document.createElement('div');
      liveContent.classList.add('live');
      liveContent.innerHTML = `<span>${this.localizationService.localize('app.component.map.pin.live')}</span>`;
      gamMapPinContainer.appendChild(liveContent);
    }

    return {
      element: gamMapPin,
      coordinates: item.coordinates,
    };
  }

  async checkIn(id: string): Promise<GamResponse<any> | null> {
    try {
      const response = await this.httpAPI.checkIn(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async checkOut(id: string): Promise<GamResponse<any> | null> {
    try {
      const response = await this.httpAPI.checkOut(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }
}
