export enum GamLoaderSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum GamLoaderColor {
  WHITE = 'white',
  GRADIENT = 'gradient',
  DARK = 'dark',
}
