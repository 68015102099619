export class ExternalLoader {
  constructor(urls: string[], cb: (ev: Event) => void) {
    urls.forEach((url) => {
      const script = document.createElement('script');
      script.src = url;
      document.head.appendChild(script);
      script.onload = cb;
    });
  }
}
