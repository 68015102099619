<template>
  <div>
    <div class="auth-content" @keydown.enter="submitCode">
      <div class="auth-context left">
        <span class="auth-title">{{ localize('auth.page.otp.title') }}</span>
        <span
          v-if="otpData?.email"
          class="auth-subtitle"
          v-html="localizeWithValues('auth.page.otp.subtitle', [otpData.email])"
        />
      </div>
      <div class="otp-code-wrapper">
        <form @submit="handleSubmit">
          <gam-code v-bind="getGamCode" v-model="form.otp" />
        </form>
        <div class="otp-resend">
          <span class="label">{{ localize('auth.page.otp.code.label') }}</span>
          <span v-touch:tap="sendAgain" class="link" :class="{ disable: otpCountdown > 0 }" @click="sendAgain">
            {{ getLinkText() }}
          </span>
        </div>
      </div>
      <div class="auth-methods-group">
        <div class="methods-row">
          <gam-button v-bind="getContinueButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OtpMailData } from '@/core/data/auth/auth.interface';
import { RouterAuthType, RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseUserInfo } from '@/core/data/user/user.interface';
import { gambits, localize, localizeWithValues } from '@/core/gambits';
import { useAuthStore } from '@/stores/auth.store';
import GamButton from '@/views/components/GamButton.vue';
import GamCode from '@/views/components/GamCode.vue';
import {
  GamButtonMessageVariant,
  GamButtonSize,
  GamButtonVariant,
} from '@/views/composables/constants/components/gamButton.constants';
import type { GamButtonMessageType, GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamCodeType } from '@/views/composables/models/components/GamCode';
import useValidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();
const isLoading = ref<boolean>(false);
const errorMessage = ref<GamButtonMessageType | null>(null);

const otpData = ref<OtpMailData | null>(gambits.authService.getOtpMailData());
const otpCountdown = ref<number>(gambits.authService.getOtpSendDelay());

const otpSize = gambits.configService.getOtpSize();
const form = reactive({
  otp: '',
});
const validators = computed((): { otp: any } => {
  return {
    otp: { required, minLength: minLength(otpSize) },
  };
});
const v$ = useValidate(validators, form);

onMounted(() => {
  setOtpCountdown();
});

watch(v$, (validator) => {
  if (validator.$invalid) {
    return;
  }

  submitCode();
});

const handleSubmit = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  submitCode();
};

const setOtpCountdown = (): void => {
  setTimeout(() => {
    if (otpCountdown.value > 0) {
      otpCountdown.value -= 1;
      setOtpCountdown();
    }
  }, 1000);
};

const getLinkText = (): string => {
  if (otpCountdown.value <= 0) {
    return localize('auth.page.otp.code.link');
  } else {
    return localizeWithValues('auth.page.otp.code.retry', [otpCountdown.value.toString()]);
  }
};

const sendAgain = async (): Promise<void> => {
  if (otpCountdown.value <= 0 && otpData.value) {
    isLoading.value = true;
    errorMessage.value = null;
    const result = await gambits.authService.authWithEmail(otpData.value.email);

    if (result.data) {
      await nextTick(() => {
        otpCountdown.value = gambits.authService.getOtpSendDelay();
        setOtpCountdown();
      });
    } else if (result.error) {
      errorMessage.value = {
        label: result.error.message,
        variant: GamButtonMessageVariant.ERROR,
      };
    }
    isLoading.value = false;
  }
};

const getGamCode = computed((): GamCodeType => {
  return {
    length: otpSize,
    autofocus: true,
    message: errorMessage.value,
    isError: !!errorMessage.value,
  };
});

const submitCode = async () => {
  isLoading.value = true;
  errorMessage.value = null;
  const result = await gambits.authService.validateOtp({
    otp: form.otp,
    email: otpData.value?.email || '',
  });

  if (result.data) {
    await authStore.authorizeUser(result.data, true);
    const { data: userDetails } = result;
    const { username, firstName, lastName, ratings } = userDetails.info as BaseUserInfo;

    if (userDetails.isActive) {
      await router.push({ name: RouterNameType.LOCATIONS });
      return;
    }

    if (!username || !firstName || !lastName) {
      await router.push({ name: RouterAuthType.AUTH_REGISTRATION });
      return;
    }

    if (
      !Array.isArray(ratings) ||
      ratings.findIndex((r) => {
        return r.rating !== null;
      }) === -1
    ) {
      await router.push({ name: RouterAuthType.AUTH_RATINGS });
    }
  } else if (result.error) {
    errorMessage.value = {
      label: result.error.message,
      variant: GamButtonMessageVariant.ERROR,
    };
  }
  isLoading.value = false;
};

const getContinueButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.LARGE,
    label: 'auth.page.otp.continue.button.label',
    isFullSize: true,
    center: true,
    isDisabled: v$.value.otp.$invalid,
    isLoading: isLoading.value,
    onClick: async () => submitCode(),
  };
});
</script>

<style scoped lang="scss">
.auth-content {
  gap: var(--spacing-menu) !important;

  .otp-code-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-menu) !important;

    .otp-resend {
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-xs);

      .label {
        font-size: 16px;
        font-weight: 300;
        color: var(--color-white-70);
        line-height: 110%; /* 17.6px */
      }

      .link {
        font-size: 16px;
        font-weight: 700;
        line-height: 110%;
        background: var(--color-linear-gradient);
        background-clip: text;
        cursor: pointer;
        position: relative;
        transition: opacity var(--transition-fast);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &.disable {
          opacity: 0.7;
          cursor: default;
          pointer-events: none;

          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: var(--color-linear-gradient);
          transition: opacity var(--transition-fast);
          opacity: 0;
          left: 0;
          bottom: -2px;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
