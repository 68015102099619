<template>
  <div class="gam-filter-item">
    <div v-if="title" class="filter-item-title">
      {{ localize(title) }}
    </div>
    <div class="filter-content">
      <gam-filter-item-dropdown
        v-if="props.variant === GamFilterVariant.DROPDOWN"
        v-bind="props"
        v-model="inputValue"
      />
      <gam-filter-item-range v-if="props.variant === GamFilterVariant.RANGE" v-bind="props" v-model="inputValue" />
      <gam-filter-item-checkbox
        v-if="props.variant === GamFilterVariant.CHECKBOX"
        v-bind="props"
        v-model="inputValue"
      />
      <gam-filter-item-switch v-if="props.variant === GamFilterVariant.SWITCH" v-bind="props" v-model="inputValue" />
      <gam-filter-item-radio v-if="props.variant === GamFilterVariant.RADIO" v-bind="props" v-model="inputValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import GamFilterItemCheckbox from '@/views/components/gam-filter-item/variants/GamFilterItemCheckbox.vue';
import GamFilterItemDropdown from '@/views/components/gam-filter-item/variants/GamFilterItemDropdown.vue';
import GamFilterItemRadio from '@/views/components/gam-filter-item/variants/GamFilterItemRadio.vue';
import GamFilterItemRange from '@/views/components/gam-filter-item/variants/GamFilterItemRange.vue';
import GamFilterItemSwitch from '@/views/components/gam-filter-item/variants/GamFilterItemSwitch.vue';
import { GamFilterVariant } from '@/views/composables/constants/main/filter.constants';
import type { GamFilterType } from '@/views/composables/models/components/GamFilter';
import { ref } from 'vue';

const props = defineProps<GamFilterType>();
const inputValue = ref<any>();

defineExpose({
  props,
  inputValue,
});
</script>

<style scoped lang="scss">
.gam-filter-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md);

  .filter-item-title {
    flex: 1 0 0;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: var(--spacing-s);
  }

  .filter-content {
    width: 100%;
    display: flex;
  }
}
</style>
