export enum GamPlayerCardSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum GamPlayerImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  S_SQUARE = 's-square',
  XS_SQUARE = 'xs-square',
  XXS_CIRCULAR = 'xxs-circular',
}
