import { LocationType } from '@/core/data/location/location.type';

export function isLocationValid(payload: any) {
  if (!payload) return;

  const { form } = payload || {};
  const { name, address, coordinates, locationTypes, numberOfChessboards, numberOfGiantChessboards } = form || {};

  let isValid = true;

  // Name and address are required
  if (!name || !address) isValid = false;

  // Temporary: Must select at least one location type
  if (!Array.isArray(locationTypes) || locationTypes.length === 0) isValid = false;

  // Coordinates are required
  // TODO: Ensure coordinates are cast as floating point numbers
  if (!coordinates || !coordinates.lat || !coordinates.lng) isValid = false;

  // If the location is marked as having giant chess tables, require a non-zero value
  if (
    Array.isArray(locationTypes) &&
    locationTypes.includes(LocationType.GIANT_CHESS) &&
    (typeof numberOfGiantChessboards !== 'number' || numberOfGiantChessboards < 1)
  )
    isValid = false;

  // If the location is marked as having chess tables, require a non-zero value
  if (
    Array.isArray(locationTypes) &&
    locationTypes.includes(LocationType.CHESS_TABLE) &&
    (typeof numberOfChessboards !== 'number' || numberOfChessboards < 1)
  )
    isValid = false;

  return isValid;
}
