import type { AdminStatsDto } from '@/core/data/admin/admin.interface';
import type { AdminReportDto } from '@/core/data/report/report.interface';
import { ReportType } from '@/core/data/report/report.type';
import { type GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { SimpleTableRow } from '@/views/composables/models/components/SimpleTable';
import type { AxiosResponse } from 'axios';
import type { AdminDetailLocationDto } from '../data/location/location.interface';
import type { AdminApi } from '../network/api/admin.api';
import type { ApiError, GamResponse, Result } from '../network/http/httpClient.interface';
import { generalError } from '../network/http/httpError';

export class AdminService {
  private readonly httpApi: AdminApi;

  constructor(httpApi: AdminApi) {
    this.httpApi = httpApi;
  }

  async getUsers(listId: GamListId): Promise<Result<SimpleTableRow[]>> {
    try {
      const response = await this.httpApi.getUsers(listId);
      return response.data.data
        ? {
            data: response.data.data.map((item) => {
              return { ...item } as SimpleTableRow;
            }),
            cursor: response.data.cursor,
          }
        : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getLocations(listId: GamListId): Promise<Result<SimpleTableRow[]>> {
    try {
      const response = await this.httpApi.getLocations(listId);
      return response.data.data
        ? {
            data: response.data.data.map((item) => {
              return { ...item } as SimpleTableRow;
            }),
            cursor: response.data.cursor,
          }
        : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getLocationById(id: string): Promise<Result<AdminDetailLocationDto>> {
    try {
      const response = await this.httpApi.getLocationById(id);
      return response.data.data ? { data: response.data.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async verifyLocation(id: string, verified: boolean): Promise<Result<boolean>> {
    try {
      const response = await this.httpApi.verifyLocation(id, verified);
      return { data: response.status >= 200 && response.status < 300 };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateLocation(location: AdminDetailLocationDto): Promise<Result<boolean>> {
    try {
      const response = await this.httpApi.updateLocation(location);
      return { data: response.status >= 200 && response.status < 300 };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteLocation(id: string): Promise<Result<boolean>> {
    try {
      const response = await this.httpApi.deleteLocation(id);
      return { data: response.status >= 200 && response.status < 300 };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteUser(id: string): Promise<Result<boolean>> {
    try {
      const response = await this.httpApi.deleteUser(id);
      return { data: response.status >= 200 && response.status < 300 };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async redactUser(id: string, payload: any): Promise<Result<boolean>> {
    try {
      const response = await this.httpApi.redactUser(id, payload);
      return { data: response.status >= 200 && response.status < 300 };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async fetchStats(): Promise<Result<AdminStatsDto>> {
    try {
      const response = await this.httpApi.fetchStats();
      return response.data.data ? { data: response.data.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  private getReportByType(listId: GamListId, type: ReportType): Promise<AxiosResponse<GamResponse<AdminReportDto[]>>> {
    if (type === ReportType.LOCATION) {
      return this.httpApi.getLocationReports(listId);
    } else if (type === ReportType.LOCATION_COMMENT) {
      return this.httpApi.getLocationCommentReports(listId);
    } else {
      return this.httpApi.getBlobReports(listId);
    }
  }

  async getReports(listId: GamListId, type: ReportType): Promise<Result<SimpleTableRow[]>> {
    try {
      const response = await this.getReportByType(listId, type);
      return response.data.data
        ? {
            data: response.data.data.map((item) => {
              return { ...item } as SimpleTableRow;
            }),
            cursor: response.data.cursor,
          }
        : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
