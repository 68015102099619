import { PlayerType } from '@/core/data/user/user.type';
import type { GetPlayerTableFilters } from '@/core/network/http/httpClient.interface';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamFilterSortPlayer, GamFilterVariant } from '@/views/composables/constants/main/filter.constants';
import type { GamFilterType } from '@/views/composables/models/components/GamFilter';

export enum GamPlayerFilterSortKey {
  LOCATION = 'location',
  DISTANCE = 'distance',
  RATING = 'rating',
  VERIFIED = 'verified',
  AGE = 'age',
  PLAYER_TYPES = 'playerTypes',
  TITLED_PLAYERS = 'titledPlayers',
  SORT = 'sortBy',
}

// const playerFilterLocation = (listId: GamListId): GamFilterType => {
//   return {
//     title: 'players.filters.location.title',
//     variant: GamFilterVariant.DROPDOWN,
//     sortKey: GamPlayerFilterSortKey.PLAYER_TYPES,
//     searchDropdown: {
//       id: listId,
//       input: {
//         name: GamInputName.SEARCH,
//         leftIcon: GamIconName.LOCATION_EMPTY,
//         placeholder: 'players.filters.location.input.search.placeholder',
//         canClear: true,
//       },
//       loadOnInit: true,
//       dropdown: {
//         id: listId,
//         category: GamDropdownCategory.LOCATIONS,
//         size: GamDropdownSize.LARGE,
//         itemSpacing: 0,
//         defaultItem: LocationHelper.getMyLocationOption(),
//         selected: gambits.filterService.getSavedTableLocation(listId),
//       },
//       initParams: {
//         favorite: true,
//       } as GetLocationTableFilters,
//       loadCallback: async (listId) => {
//         const data = await gambits.locationService.searchLocations(listId);
//         return {
//           ...data,
//           data: LocationHelper.mapSearchLocations(data.data),
//         };
//       },
//     },
//   };
// };
//
// const playerFilterLocationRange = (filters?: GetPlayerTableFilters | null): GamFilterType => {
//   return {
//     variant: GamFilterVariant.RANGE,
//     sortKey: GamPlayerFilterSortKey.DISTANCE,
//     range: {
//       value: [0, filters?.distanceLte || 50],
//       min: {
//         value: 0,
//         isReadyOnly: true,
//       },
//       max: {
//         value: 50,
//       },
//       suffix: 'km',
//       isSetRange: true,
//     },
//   };
// };

const playerFilterRatingRange = (filters?: GetPlayerTableFilters | null): GamFilterType => {
  return {
    variant: GamFilterVariant.RANGE,
    title: 'players.filters.rating.title',
    sortKey: GamPlayerFilterSortKey.RATING,
    range: {
      value: [filters?.ratingGte || 0, filters?.ratingLte || 3000],
      min: {
        value: 0,
      },
      max: {
        value: 3000,
      },
      isSetRange: true,
      isUnlimited: true,
    },
  };
};

const playerFilterPlayerType = (filters?: GetPlayerTableFilters | null): GamFilterType => {
  const name = GamPlayerFilterSortKey.PLAYER_TYPES;
  return {
    variant: GamFilterVariant.CHECKBOX,
    sortKey: name,
    items: [
      {
        label: 'players.filters.playerTypes.checkbox.option.coach',
        name,
        value: PlayerType.COACH,
        selected: filters?.playerTypes?.includes(PlayerType.COACH),
      },
      {
        label: 'players.filters.playerTypes.checkbox.option.streamer',
        name,
        value: PlayerType.STREAMER,
        selected: filters?.playerTypes?.includes(PlayerType.STREAMER),
      },
    ],
  };
};

const playerFilterTitledPlayers = (filters?: GetPlayerTableFilters | null): GamFilterType => {
  const name = GamPlayerFilterSortKey.TITLED_PLAYERS;
  return {
    variant: GamFilterVariant.CHECKBOX,
    isBoolean: true,
    sortKey: name,
    title: 'players.filters.playerTypes.title',
    items: [
      {
        label: 'players.filters.playerTypes.radio.option.true',
        name,
        value: true,
        selected: filters?.titledPlayers === 'true',
      },
      {
        label: 'players.filters.playerTypes.radio.option.false',
        name,
        value: false,
        selected: filters?.titledPlayers === 'false',
      },
    ],
  };
};

const playerFilterAgeRange = (filters?: GetPlayerTableFilters | null): GamFilterType => {
  return {
    variant: GamFilterVariant.RANGE,
    sortKey: GamPlayerFilterSortKey.AGE,
    title: 'players.filters.age.title',
    range: {
      value: [filters?.ageGte || 14, filters?.ageLte || 99],
      min: {
        value: 0,
      },
      max: {
        value: 99,
      },
      isSetRange: true,
      isUnlimited: true,
    },
  };
};

const playerFilterSortBy = (filters?: GetPlayerTableFilters | null): GamFilterType => {
  return {
    variant: GamFilterVariant.RADIO,
    sortKey: GamPlayerFilterSortKey.SORT,
    title: 'players.filters.sortBy.title',
    items: [
      {
        label: 'players.filters.sortBy.radio.option.distance',
        name: GamPlayerFilterSortKey.SORT,
        value: GamFilterSortPlayer.DISTANCE,
        selected: filters?.sortBy === GamFilterSortPlayer.DISTANCE,
      },
      {
        label: 'players.filters.sortBy.radio.option.rating',
        name: GamPlayerFilterSortKey.SORT,
        value: GamFilterSortPlayer.RATING,
        selected: filters?.sortBy === GamFilterSortPlayer.RATING,
      },
    ],
  };
};

export const getPlayerFilters = (listId: GamListId, filters?: GetPlayerTableFilters | null): GamFilterType[] => {
  return [
    // playerFilterLocation(listId),
    // playerFilterLocationRange(filters),
    playerFilterRatingRange(filters),
    playerFilterTitledPlayers(filters),
    playerFilterPlayerType(filters),
    playerFilterAgeRange(filters),
    playerFilterSortBy(filters),
  ];
};
