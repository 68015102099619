<template>
  <div id="auth-view" :style="getAuthBg">
    <div class="auth-modal" :class="{ 'is-mobile': isMobile, 'image-expanded': imageExpanded }">
      <div v-if="connecting" class="connecting">
        <simple-content-dialog v-bind="getConnecting" />
      </div>
      <ScrollOverflow ref="scrollbar" class="auth-slide-wrapper" is-vertical>
        <router-view v-slot="{ Component }">
          <transition name="router" mode="out-in">
            <component :is="Component" class="auth-wrapper" />
          </transition>
        </router-view>
      </ScrollOverflow>

      <div v-if="!connecting && isCloseButtonVisible" class="close-button">
        <gam-button v-bind="getCloseButton" />
      </div>

      <div v-if="!connecting && isBackButtonVisible" class="back-button">
        <gam-button v-bind="getBackButton" />
      </div>

      <div v-if="!connecting && isLogoutButtonVisible" class="logout-button">
        <gam-button v-bind="getLogoutButton" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LoginMethodType } from '@/core/data/auth/auth.type';
import { RouterAuthType, RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { useMapStore } from '@/stores/map.store';
import SimpleContentDialog from '@/views/components/gam-dialog/dialog-contents/SimpleContentDialog.vue';
import GamButton from '@/views/components/GamButton.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { SimpleContentDialogType } from '@/views/composables/models/dialog.interface';
import { storeToRefs } from 'pinia';
import { computed, type StyleValue } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ScrollOverflow from '../components/ScrollOverflow.vue';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const { connecting } = storeToRefs(authStore);
const appStore = useAppStore();
const { imageExpanded } = storeToRefs(appStore);
const mapStore = useMapStore();
const { currentLocation, currentZoom } = storeToRefs(mapStore);

const isCloseButtonVisible = computed(() => route.name === RouterAuthType.AUTH_SELECT);

const isBackButtonVisible = computed(() =>
  [RouterAuthType.AUTH_EMAIL, RouterAuthType.AUTH_OTP].includes(route.name as RouterAuthType),
);

const isLogoutButtonVisible = computed(() =>
  [RouterAuthType.AUTH_REGISTRATION, RouterAuthType.AUTH_RATINGS].includes(route.name as RouterAuthType),
);

const getBgName = (): string => {
  switch (route.name) {
    case RouterAuthType.AUTH_EMAIL:
    case RouterAuthType.AUTH_OTP:
      return 'otp-view';
    case RouterAuthType.AUTH_REGISTRATION:
      return 'register-view';
    case RouterAuthType.AUTH_RATINGS:
      return 'rating-view';
    default:
      return 'select-view';
  }
};

const getConnectingGoogle = () => {
  return {
    loaderIcon: GamIconName.GOOGLE,
    title: localize('auth.connecting.google.title'),
    message: localize('auth.connecting.google.message'),
  };
};

const getConnectingLichess = () => {
  return {
    loaderIcon: GamIconName.LICHESS,
    title: localize('auth.connecting.lichess.title'),
    message: localize('auth.connecting.lichess.message'),
  };
};

const getCloseButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.X_CLOSE,
    isIconOnly: true,
    size: GamButtonSize.ACTIONS,
    variant: GamButtonVariant.PRIMARY,
    onClick: () => {
      router.push({ name: RouterNameType.LOCATIONS, query: { ...currentLocation.value, zoom: currentZoom.value } });
    },
  };
});

const getBackButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.SMALL_ARROW_LEFT,
    isIconOnly: true,
    size: GamButtonSize.ACTIONS,
    variant: GamButtonVariant.PRIMARY,
    onClick: () => {
      if (route.name === RouterAuthType.AUTH_EMAIL) {
        router.push({ name: RouterAuthType.AUTH_SELECT });
      } else if (route.name === RouterAuthType.AUTH_OTP) {
        router.push({ name: RouterAuthType.AUTH_EMAIL });
      }
    },
  };
});

const getLogoutButton = computed((): GamButtonType => {
  return {
    leftIcon: GamIconName.X_CLOSE,
    isIconOnly: true,
    size: GamButtonSize.ACTIONS,
    variant: GamButtonVariant.PRIMARY,
    onClick: async () => {
      if (window.confirm('Are you sure you want to log out?')) {
        await gambits.authService.logout();
      }
    },
  };
});

const getAuthBg = computed((): StyleValue => {
  return {
    background: `var(--color-dark-700) url(/images/auth/${getBgName()}.jpg) no-repeat`,
  };
});

const getConnecting = computed((): SimpleContentDialogType => {
  if (connecting.value === LoginMethodType.Google) {
    return getConnectingGoogle();
  } else {
    return getConnectingLichess();
  }
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

#auth-view {
  width: 100%;
  height: 100vh;
  background: var(--color-dark-700);
  background-size: cover !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;

  #auth-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
  }

  .auth-modal {
    display: flex;
    width: 426px;
    max-height: calc(100vh - var(--spacing-xxl)) !important;
    padding: var(--spacing-xxl) var(--spacing-menu);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-md);
    flex-shrink: 0;
    border-radius: var(--radius-large);
    background: var(--color-dark-700);
    box-shadow: var(--shadow-backdrop-panel-downer) var(--shadow-pink-40);
    z-index: 100;
    @extend .gam-special-border !optional;

    &.is-mobile {
      width: calc(100% - 48px);
      padding: var(--spacing-xxl) var(--spacing-menu) var(--spacing-menu);
      padding: var(--spacing-xl) var(--spacing-md) var(--spacing-menu);
      padding: 0 var(--spacing-md) 0;
      min-width: 0;

      .auth-slide-wrapper {
        margin-bottom: var(--spacing-l);

        .auth-content {
          padding: var(--spacing-xl) var(--spacing-l) var(--spacing-menu);
          gap: var(--spacing-md);
        }

        .auth-methods-group {
          padding: calc(var(--spacing-menu) / 2) var(--spacing-l) 0;
        }
      }
    }

    &:before {
      padding: 1px 0 0;
      background: var(--color-linear-gradient-70);
    }

    .connecting {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      background: inherit;
      border-radius: inherit;
      z-index: 100;
      top: 0;
    }

    .auth-slide-wrapper {
      .auth-wrapper {
        width: 100%;
        height: 100%;
        min-width: 0;
        min-height: 0;
        position: relative;

        .auth-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          gap: var(--spacing-md);

          .auth-context {
            display: flex;
            width: 100%;
            padding: var(--spacing-l) 0;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: var(--spacing-l);

            &.left {
              .auth-title,
              .auth-subtitle {
                text-align: left !important;
              }
            }

            .auth-title {
              font-size: 24px;
              font-style: normal;
              font-weight: 800;
              line-height: 110%; /* 26.4px */
              color: var(--color-white-100);
              text-align: center;
              align-self: stretch;
            }

            .auth-subtitle {
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 110%; /* 17.6px */
              color: var(--color-white-70);
              text-align: center;
              align-self: stretch;

              span {
                color: var(--color-white-100);
              }
            }

            .auth-description {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%; /* 15.4px */
              color: var(--color-white-70);
              opacity: 0.5;

              span {
                color: var(--color-white-100);
              }
            }
          }
        }
      }

      .auth-methods-group {
        display: flex;
        padding: var(--spacing-l) 10px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .methods-row {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
        }
      }
    }

    .back-button,
    .close-button,
    .logout-button {
      position: absolute;
      bottom: -16px;
    }
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    background: linear-gradient(180deg, var(--color-dark-30) 63%, var(--color-dark-700) 100%);
    z-index: 4;
  }

  &:after {
    background: var(--color-linear-gradient);
    mix-blend-mode: color;
    z-index: 3;
  }
}
</style>
