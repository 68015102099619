<template>
  <div class="gam-filter-item-switch">
    <div v-if="items?.length" class="switch-items">
      <gam-switch v-for="item in items" v-bind="getItem(item)" :key="item.value" v-model:checked="switchValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GamSwitch from '@/views/components/GamSwitch.vue';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamFilterItem, GamFilterType } from '@/views/composables/models/components/GamFilter';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import { onMounted, ref, watch } from 'vue';

defineProps<GamFilterType>();
const emit = defineEmits([GamComponentsEmits.UPDATE_VALUE]);
const switchValue = ref<boolean>(false);

onMounted(() => {
  emit(GamComponentsEmits.UPDATE_VALUE, switchValue.value);
});

const getItem = (item: GamFilterItem): GamSwitchType => {
  return {
    label: item.label,
    name: item.value,
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.RIGHT,
    checked: item.selected,
  };
};

watch(switchValue, (value) => {
  emit(GamComponentsEmits.UPDATE_VALUE, value);
});
</script>

<style scoped lang="scss">
.gam-filter-item-switch {
  display: flex;
  width: 100%;

  .switch-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
}
</style>
