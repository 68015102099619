<template>
  <GamMainView class="gambits-view">
    <div class="list-wrapper gambit">
      <gam-list-view v-bind="getGambitList">
        <template #header>
          <GambitsListHeader />
        </template>

        <template #list>
          <GamGambitCard
            v-for="gambit in itemList"
            v-bind="getGambitCard(gambit)"
            :id="gambit.id"
            :key="gambit.id"
            @click="navigateToGambit(gambit.id)"
          />
        </template>
      </gam-list-view>
    </div>

    <EditGambit v-if="authStore.isAuthenticated() && gambitDetails" :key="gambitDetails.id" :gambit="gambitDetails" />

    <GamDetailView v-bind="getDetailView" :open="true">
      <ScrollOverflow v-if="gambitDetails" class="detail-scroll" is-vertical>
        <div :class="['detail-container', 'gambit', { 'is-mobile': isMobile }]">
          <GamGambitDetail v-bind="getSelectedGambitCard"></GamGambitDetail>
        </div>
      </ScrollOverflow>

      <PlaceholderSplash
        v-else-if="!isLoading && !route.params.id"
        button-label="Create a Gambit"
        :button-variant="GamButtonVariant.SECONDARY"
        :button-size="GamButtonSize.SMALL"
        :handle-create-click="handleCreateClick"
      >
        {{ randomPun }}
      </PlaceholderSplash>
    </GamDetailView>
  </GamMainView>
</template>

<script lang="ts" setup>
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import type { BaseGambitDto } from '@/core/data/gambit/gambit.interface';
import { gambits } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useGeolocationStore } from '@/stores/geolocation.store';
import { FALLBACK_LOCATION, useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import GamDetailView from '@/views/components/detail-view/GamDetailView.vue';
import GamGambitCard from '@/views/components/gam-gambits/GamGambitCard.vue';
import GamGambitDetail from '@/views/components/gam-gambits/GamGambitDetail.vue';
import GamListView from '@/views/components/gam-list-view/GamListView.vue';
import GamMainView from '@/views/components/GamMainView.vue';
import PlaceholderSplash from '@/views/components/PlaceholderSplash.vue';
import ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamErrorInfoSize } from '@/views/composables/constants/components/gamErrorInfo.constants';
import { GamGambitCardCategory } from '@/views/composables/constants/components/gamGambit.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GamDetailType } from '@/views/composables/models/components/GamDetail';
import type {
  GambitDetailDto,
  GamGambitCardType,
  GamGambitDetailViewType,
} from '@/views/composables/models/components/GamGambit';
import type { GamListType } from '@/views/composables/models/components/GamList';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import GambitsListHeader from './GambitsListHeader.vue';
import EditGambit from './side-panels/EditGambit.vue';

const PUNS = [
  'Turn an afternoon drink with friends into a knightmare.',
  "You don't have to wait for someone to make a move. Creating a Gambit is a blitz!!",
];

const randomPun = computed(() => PUNS[Math.floor(Math.random() * 2)]);

const listId = GamListId.GAMBITS;
const tableStore = useTableStore<BaseGambitDto>(listId)();
const { selectedItem, detailsOpened, itemList, isLoading } = storeToRefs(tableStore);
const mapStore = useMapStore();
const { currentLocation, currentZoom } = storeToRefs(mapStore);
const authStore = useAuthStore();
const geolocationStore = useGeolocationStore();
const { userLocation } = storeToRefs(geolocationStore);

const route = useRoute();
const router = useRouter();

const navigateToGambit = (id: string) => {
  router.replace({
    name: isMobile.value ? RouterNameType.GAMBIT : RouterNameType.GAMBITS,
    query: { ...route.query, side_panel: undefined },
    params: { id },
  });
};

function handleCreateClick(e: MouseEvent) {
  e.preventDefault();

  router.push({ name: RouterNameType.LOCATIONS, query: { side_panel: 'add_gambit' } });
}

watch(
  () => selectedItem.value,
  (gambit) => {
    if (gambit === null || !gambit.id) {
      return;
    }

    router.replace({ name: RouterNameType.GAMBITS, query: route.query, params: { id: gambit.id } });
  },
);

const gambitDetails = ref<GambitDetailDto | null>(null);

onMounted(async () => {
  const locationItem = gambits.filterService.getSavedTableLocation(listId);
  const coords = locationItem?.coordinates || userLocation.value?.location;
  coords && tableStore.setLocationParam(coords);

  if (!selectedItem.value?.id) return;

  gambitDetails.value = await getGambitDetail(selectedItem.value.id);
});

onUnmounted(() => {
  tableStore.resetList(true);
});

const currentGambitIndex = computed(() => {
  const id = route.params.id;

  if (!id || !Array.isArray(itemList.value) || itemList.value.length < 1) {
    // console.warn('Can not find gambit', id, itemList.value);
    return null;
  }

  const gambitIndex = itemList.value.findIndex((c) => c.id === id);

  if (gambitIndex < 0) {
    // console.warn('Couldnt find gambit index', id, itemList.value);
    return null;
  } else {
    return gambitIndex;
  }
});

watch(
  () => currentGambitIndex.value,
  (index) => {
    if (typeof index !== 'number') {
      // console.warn('Gambit index is not a number', index);
      return;
    }

    tableStore.selectItem(index);
  },
);

const getGambitDetail = async (id: string): Promise<GambitDetailDto | null> => {
  const gambitResult = await gambits.gambitService.getGambit(id);

  if (gambitResult?.data) {
    return gambitResult.data as unknown as GambitDetailDto;
  }

  return null;
};

const getGambitCard = (gambit: BaseGambitDto): GamGambitCardType => {
  return {
    gambit,
    selected: gambitDetails.value?.id === gambit.id,
    category: GamGambitCardCategory.LIST,
    isMobile: isMobile.value,
  };
};

const getSelectedGambitCard = computed((): GamGambitDetailViewType => {
  return {
    gambit: gambitDetails.value as GambitDetailDto,
    category: GamGambitCardCategory.DETAIL,
    isMobile: isMobile.value,
  };
});

const getDetailView = computed((): GamDetailType => {
  return {
    listId,
    showListCursors: !!gambitDetails.value,
    swipeMessage: {
      firstLine: 'gambit.details.header.description.one',
      secondLine: 'gambit.details.header.description.two',
    },
    nextButton: { isDisabled: !selectedItem.value, label: 'gambit.details.header.button.next' },
    previousButton: { isDisabled: !selectedItem.value, label: 'gambit.details.header.button.previous' },
  };
});

const getGambitList = computed((): GamListType<BaseGambitDto> => {
  return {
    listId,
    endInfo: {
      id: listId,
      title: 'No more Gambits in this area',
      message:
        'Showing results near' +
        ' ' +
        (currentLocation.value.lat !== 0
          ? `${currentLocation.value.lat.toFixed(1)}°N, ${currentLocation.value.lng.toFixed(1)}°E`
          : 'you') +
        ' — ' +
        'the area you chose on the map.',
      size: GamErrorInfoSize.SMALL,
      actionPrimary: {
        variant: GamButtonVariant.SLAB,
        size: GamButtonSize.SMALL,
        label: 'Change coordinates',
        onClick: () => {
          router.push({
            name: RouterNameType.LOCATIONS,
            query: { ...currentLocation.value, zoom: Number(currentZoom.value) - 1 },
          });
        },
      },
    },
    noDataInfo: {
      id: listId,
      title: 'No upcoming gambits',
      message:
        'Showing results near' +
        ' ' +
        `${currentLocation.value.lat.toFixed(1)}°N, ${currentLocation.value.lng.toFixed(1)}°E` +
        ' — ' +
        'the area you browsed on the map.',
      size: GamErrorInfoSize.SMALL,
      actionPrimary: {
        variant: GamButtonVariant.SLAB,
        size: GamButtonSize.SMALL,
        label: 'Change coordinates',
        onClick: () => {
          router.push({
            name: RouterNameType.LOCATIONS,
            query: { ...currentLocation.value, zoom: Number(currentZoom.value) - 1 },
          });
        },
      },
    },
    scrollSpacing: true,
    isHorizontallyPadded: true,
    loadCallback: (listId) => {
      const location = currentLocation.value ? currentLocation.value : FALLBACK_LOCATION;
      return gambits.gambitService.getGambits(listId, location);
    },
  };
});

watch(
  () => [selectedItem.value, route.query.reload],
  async ([item, reload]) => {
    if (!item) return;

    gambitDetails.value = await getGambitDetail((item as BaseGambitDto).id);
    detailsOpened.value = true;

    if (reload === 'true') {
      router.replace({
        ...route,
        query: { ...route.query, reload: undefined },
      });
    }
  },
);
</script>

<style scoped lang="scss">
.main-view {
  .detail-scroll {
    width: 100%;
    height: 100svh;

    .detail-container {
      max-width: 596px;
      margin: calc(var(--spacing-xxl) + var(--spacing-menu)) auto 0;

      padding: 0 var(--spacing-xl) var(--spacing-xxl);
    }

    .detail-container:not(.is-mobile) {
      @media (max-width: 1180px) {
        max-width: 512px;
        // margin-top: 0;
        padding: 0 var(--spacing-l) var(--spacing-xxl);
      }
    }

    .detail-container.is-mobile {
      @media (max-width: 1180px) {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.list-wrapper {
  width: 600px;

  @media (max-width: 1024px) {
    width: 420px;
  }

  @media (max-width: 1180px) {
    width: 420px;
  }
}

.right-side-panel,
.gam-form-content {
  width: 100%;
  height: calc(100% - 5rem);
}

.gambits-view.is-mobile {
  .gam-gambit-card {
    .gambit-base-info {
      max-width: calc(100svw - 58px);
    }
  }
}
</style>
