<template>
  <div class="gam-card">
    <div :class="['gam-card-container', getContainerClasses]">
      <div class="title">
        {{ localize(title) }}
      </div>
      <div class="value">
        <span :class="['main', { skeleton: value === null }]">
          {{ value }}
        </span>
        <span v-if="hasSubValue" :class="['sub', { skeleton: subValue === null }]">
          {{ subValue }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import type { GamCardType } from '@/views/composables/models/components/GamCard';
import { computed } from 'vue';

const props = defineProps<GamCardType>();

const getContainerClasses = computed(() => ({
  'is-mobile': isMobile.value,
  'is-sub': props.hasSubValue,
}));
</script>

<style lang="scss" scoped>
.gam-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  flex: 1 1 0;

  .gam-card-container {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--radius-small);
    position: relative;
    padding: 12px 12px 16px 12px;
    background: var(--color-white-5);
    flex: 1 0 0;
    align-self: stretch;

    &.is-mobile {
      padding: 12px 8px 16px 8px;

      .title {
        font-size: 12px;
      }

      .value {
        .main {
          font-size: 16px;

          &.skeleton {
            height: 16px;
          }
        }
      }
    }

    &.is-sub {
      padding-bottom: 10px !important;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      align-self: stretch;

      .main {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-white-100);
        text-align: center;

        &.skeleton {
          display: block;
          width: 100%;
          height: 20px;
        }
      }

      .sub {
        background: var(--color-linear-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        &.skeleton {
          display: block;
          width: 100%;
          height: 12px;
        }

        &:before {
          content: '/';
          margin-right: 2px;
        }
      }
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-white-70);
      opacity: 0.5;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.1;
    }
  }
}
</style>
